// src\react-i18next\i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './locales/resources';


const options = {

    order: ['localStorage', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',

    // cache user language
    //caches: ['localStorage'],
    //excludeCacheFor: ['cimode'],
    //cookieMinutes: 10,
    //cookieDomain: 'myDomain'
  };



i18n
    .use(initReactI18next)
	//.use(LanguageDetector)
	.init({
		resources,
        detection: options,
        lng: "en",
		fallbackLng: "en",
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		}
	});

export default i18n;
