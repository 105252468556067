import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_ACCOUNTS_AUDIT_TRAIL_DATA,
} from './constants';

export function resetAccountsAuditTrailData() {
  return {
    type: MANAGE_SUBS_RESET_ACCOUNTS_AUDIT_TRAIL_DATA,
  };
}

export function useResetAccountsAuditTrailData() {
  const dispatch = useDispatch();
  const accountsAuditData = useSelector(state => state.manageSubs.accountsAuditData);
  const boundAction = useCallback((...params) => dispatch(resetAccountsAuditTrailData(...params)), [dispatch]);
  return { accountsAuditData,resetAccountsAuditTrailData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_ACCOUNTS_AUDIT_TRAIL_DATA:
      return {
        ...state,
        accountsAuditData: null,
      };

    default:
      return state;
  }
}
