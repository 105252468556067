import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_ACCOUNT_DATA,
} from './constants';

export function resetAccountData() {
  return {
    type: MANAGE_SUBS_RESET_ACCOUNT_DATA,
  };
}

export function useResetAccountData() {
  const dispatch = useDispatch();
  const accountData = useSelector(state => state.manageSubs.accountData);
  const boundAction = useCallback((...params) => dispatch(resetAccountData()), [dispatch]);
  return { accountData, resetAccountData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_ACCOUNT_DATA:
      return {
        ...state,
        accountData: null,
      };

    default:
      return state;
  }
}
