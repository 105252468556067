import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import fileDownload from 'js-file-download';
import { EXPORT_BOS_API_REQUEST_URL } from '../../../common/apiConfig';
import {
  MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_BEGIN,
  MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_SUCCESS,
  MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_FAILURE,
  MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_DISMISS_ERROR,
} from './constants';

export function exportOutsData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      if(args.nationalIds && args.nationalIds.length === 0) {
        delete args.nationalIds
      }
      if(args.itemCategory && args.itemCategory.length === 0) {
        delete args.itemCategory
      }
      const doRequest = axios({
        url: `${EXPORT_BOS_API_REQUEST_URL}/exportManageOutsOptimize`,
        method: 'post',
        data: args,
        responseType: 'blob',
        headers: {
          'Authorization': token,
        }
      });
      
      doRequest.then((res) => {
        // const fileName = res.headers.filename || 'outs.xlsx';
        dispatch({
          type: MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_SUCCESS,
          data: res.data,
          // fileName,
        });
        resolve(res);
      },
      // Use rejectHandler as the second argument so that render errors won't be caught.
      (err) => {
        const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
        dispatch({
          type: MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_FAILURE,
          data: { error: errorMsg },
        });
        reject(err);
      },);
    });
    
    return promise;
  };
}

export function dismissExportOutsDataError() {
  return {
    type: MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_DISMISS_ERROR,
  };
}

export function useExportOutsData() {
  const dispatch = useDispatch();
  const { exportOutsDataPending, exportOutsDataError } = useSelector(
    state => ({
      exportOutsDataPending: state.manageSubs.exportOutsDataPending,
      exportOutsDataError: state.manageSubs.exportOutsDataError,
    }),
    shallowEqual,
  );
  
  const boundAction = useCallback((...args) => {
    return dispatch(exportOutsData(...args));
  }, [dispatch]);
  
  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportOutsDataError());
  }, [dispatch]);
  
  return {
    exportOutsData: boundAction,
    exportOutsDataPending,
    exportOutsDataError,
    dismissExportOutsDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportOutsDataPending: true,
        exportOutsDataError: null,
      };
    case MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_SUCCESS:
      // The request is success
      fileDownload(action.data, "Outs Report.xlsx");
      return {
        ...state,
        exportOutsDataPending: false,
        exportOutsDataError: null,
      };
    case MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_FAILURE:
      // The request is failed
      return {
        ...state,
        exportOutsDataPending: false,
        exportOutsDataError: action.data.error,
      };
    case MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportOutsDataError: null,
      };
    default:
      return state;
  }
}
