import { Modal, Button, Form, Input, Table, Spin, message, Tooltip, } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DATE_TIME_ONE_FORMAT, DATE_TIME_TWO_FORMAT } from '../../common/constants';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useValidateAgent, useSaveAndEmail } from './redux/hooks'

const EditableContext = React.createContext(null);
// const formArr = [];

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  // formArr[props['data-row-key']] = form;
  // if(!formArr[props['data-row-key']]) {
  //   formArr[props['data-row-key']] = form;
  // }
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values }, form, toggleEdit);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const onPressEnterHandle = () => {
    inputRef.current.blur()
  };

  let childNode = children;

  if (editable) {
    if(dataIndex === "agentId") {
      const id = `agent_${record.key}`
      childNode = editing ? (
        <>
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            // rules={[
            //   {
            //     required: true,
            //     message: `${title} is required.`,
            //   },
            // ]}
          >
            <Input ref={inputRef} onPressEnter={onPressEnterHandle} onBlur={save} />
            {/* <div>
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              <div><label style={{color:"#666666", fontSize:"12px"}}>Ranjini.Vedamuthu</label></div>
            </div> */}
          </Form.Item>
          {/* <Form.Item className="tips-content-item concept-tip" name="nationalName">
            <label style={{color:"#666666", fontSize:"12px"}}>Ranjini.Vedamuthu</label>
          </Form.Item> */}
        </>
      ) : (
        <>
          <div
            id={id}
            className="editable-cell-value-wrap"
            style={{
              paddingRight: 24,
              height: 22,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              wordBreak: 'keep-all',
              borderBottom: "1px solid #d9d9d9",
            }}
            onClick={toggleEdit}
          >
            {children}
          </div>
          <div><label style={{color:"#666666", fontSize:"12px"}}>{record.agentName}</label></div>
        </>
      );
    }else if(dataIndex === "agentEmail") {
      childNode = editing ? (
        <>
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <Input ref={inputRef} onPressEnter={onPressEnterHandle} onBlur={save} />
          </Form.Item>
        </>
      ) : (
        <>
          <div
            className="editable-cell-value-wrap"
            style={{
              paddingRight: 24,
              height: 22,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              wordBreak: 'keep-all',
              borderBottom: "1px solid #d9d9d9",
            }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        </>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function StartOfDayEmailModal(props) {
  const {
    title,
    isModalVisible,
    width,
    initValueData,
    onToggleModal,
    cancelText,
    okText,
  } = props;

  const {
    validateAgentPending,
    saveAndEmailPending,
  } = useSelector(state => ({
    validateAgentPending: state.manageSubs.validateAgentPending,
    saveAndEmailPending: state.manageSubs.saveAndEmailPending,
  }));

  const { validateAgent } = useValidateAgent();
  const { saveAndEmail } = useSaveAndEmail();

  const onModalClose = () => {
    onToggleModal();
  };

  const onCancel = () => {
    onToggleModal();
  };

  const onFinish = () => {
    let isEmptyNetworkId = false
    let isValid = true
    let len = dataSource.length
    for(let i = 0; i < len; i++) {
      const item = dataSource[i]
      if(item.isValid && item.checkedFlag && !item.agentId) {
        isEmptyNetworkId = true
        isValid = false
      }else if(!item.isValid){
        isValid = false
      }
    }

    if(isEmptyNetworkId) {
      message.error("If Selected, Then Network Id Cannot Be Empty, Please Check!");
      return
    }
    if(isValid) {
      const formData = dataSource.map(item => {
        return {
          site: item.site,
          siteDesc: item.siteDesc,
          agentId: item.agentId,
          agentName: item.agentName,
          agentEmail: item.agentEmail,
          checkedFlag: item.checkedFlag,
          createTimestamp: item.createTimestamp,
          updateTimestamp: item.updateTimestamp,
          lastSentOn: item.lastSentOn,
          sentStatus: item.sentStatus,
          // createTimestamp: item.createTimestamp && moment(item.createTimestamp).format(DATE_TIME_TWO_FORMAT),
          // lastSentOn: '',
        }
      })
      saveAndEmail(formData).then(res => {
        onToggleModal()
      })
    }
  };

  const [dataSource, setDataSource] = useState(initValueData);

  const handleReset = (key) => {
    const newData = JSON.parse(JSON.stringify(dataSource))
    const index = newData.findIndex((item) => item.key === key);
    const item = newData[index];
    const oldItem = initValueData[initValueData.findIndex((item) => item.key === key)]
    item.agentId = oldItem.agentId
    item.agentEmail = oldItem.agentEmail
    setDataSource(newData);
  };

  const handleEdit = (record) => {
    let dom = document.getElementById(`agent_${record.key}`)
    if(dom) {
      dom.click()
    }else {
      dom = document.querySelector(`tr[data-row-key='${record.key}'] #agentId`)
      if(dom) {
        dom.focus()
      }
    }
  };

  const handleSave = (row, rowForm, toggleEdit) => {
    let networkId = row.agentId ? row.agentId.trim() : ""
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    if(networkId === item.agentId) {
      if(item.isValid) {
        rowForm.setFields([])
        toggleEdit();
      }else {
        rowForm.setFields([{
          "name": ["agentId"],
          "errors": ['Invalid Network Id!'],
        }]);
      }
      return
    }
    let isValid = false
    if(networkId) {
      validateAgent({networkId}).then(res => {
        if(typeof res === 'object') {
          isValid = true;
          newData.splice(index, 1, { ...item, ...row, "isValid": isValid, "agentId": networkId, "agentName": res.name, "agentEmail": res.emailAddress, });
          rowForm.setFields([]);
          toggleEdit();
        }else {
          isValid = false
          newData.splice(index, 1, { ...item, ...row, "isValid": isValid, "agentId": networkId, "agentName": "", "agentEmail": "", });
          rowForm.setFields([{
            "name": ["agentId"],
            "errors": ['Invalid Network Id!'],
          }]);
        }
        setDataSource(newData);
      },
      err => {
        isValid = false
        newData.splice(index, 1, { ...item, ...row, "isValid": isValid, "agentId": networkId, "agentName": "", "agentEmail": "", });
        setDataSource(newData);
        rowForm.setFields([{
          "name": ["agentId"],
          "errors": ['Invalid Network Id!'],
        }]);
      },)
    }else {
      isValid = true
      newData.splice(index, 1, { ...item, ...row, "isValid": isValid, "agentId": networkId, "agentName": "", "agentEmail": "", });
      setDataSource(newData);
      rowForm.setFields([]);
      toggleEdit();
    }
  };

  const defaultColumns = [
    {
      title: 'Site',
      dataIndex: 'site',
      width: '28%',
      editable: false,
      render: (_, record) => (
        <div>{record.site} - {record.siteDesc}</div>
      ),
    },
    {
      title: 'Agent',
      dataIndex: 'agentId',
      width: 250,
      editable: true,
      ellipsis: true,
    },
    {
      title: 'Agent Email',
      dataIndex: 'agentEmail',
      // width: '30%',
      editable: false,
      // ellipsis: true,
      ellipsis: {
        showTitle: false,
      },
      render: (agentEmail) => (
        <Tooltip placement="topLeft" title={agentEmail} color="">
          {agentEmail}
        </Tooltip>
      ),
    },
    {
      title: 'Last Sent On',
      dataIndex: 'lastSentOn',
      width: 160,
      editable: false,
      ellipsis: true,
      render: (_, record) => {
        const lastSentOn = record.lastSentOn && moment(record.lastSentOn).format(DATE_TIME_ONE_FORMAT)
        return (
          <div>{lastSentOn}</div>
        )
      },
    },
    /*{
      title: 'Actions',
      dataIndex: 'actions',
      width: 80,
      render: (_, record) =>
        <div className="actions-cell">
          <Button type="link" onClick={() => handleEdit(record)}>
            <FontAwesomeIcon icon={faPen} color='#6b6b6c' style={{ height: "15px", width: "15px" }}/> 
          </Button>
        </div>
    },*/
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      const newData = [...dataSource];
      if(selectedRowKeys.length === 0) {
        newData.forEach(item => {
          item.checkedFlag = false
        })
      }else {
        newData.forEach(item => {
          if(selectedRowKeys.includes(item.key)) {
            item.checkedFlag = true
          }else {
            item.checkedFlag = false
          }
        })
      }
      setDataSource(newData)
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Modal
      className="start-of-day-email-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onModalClose}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={false}
      width={width || 520}
    >
      <Spin spinning={validateAgentPending || saveAndEmailPending}>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered={false}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowSelection={rowSelection}
        />
        <div className="btn-footer flex justify-end">
          <Button type="link" onClick={onCancel} className="default-btn" style={{marginLeft:"2px"}}>
            {cancelText}
          </Button>
          <Button onClick={onFinish} type="primary" className='primary-btn' style={{marginLeft:"2px"}}>
            {okText}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

StartOfDayEmailModal.propTypes = {};
StartOfDayEmailModal.defaultProps = {};
