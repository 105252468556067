import React from 'react';
import { CONST_SUBSTITUTIONS_UOM_OPTIONS } from '../../common/manageSubsConstants';
import { Form, Select } from 'antd';



export default function ManageSubstitutionsOperateModalUomDropdown(props) {
  const { handleItemChange, originalUom } = props;
  return (
    <>
      <Form.Item name="selectedUom" className='manage-subs-uom-dropdown' label="UOM"
        rules={[{ required: originalUom==='CS-EA'? true:false, message: 'Please select UOM!' }]}>
        
        <Select data-testid="selecttest" disabled={originalUom!=='CS-EA'? true:false} onChange={value => handleItemChange(value, 'selectedUom')}>
          {
            CONST_SUBSTITUTIONS_UOM_OPTIONS.map(item => {
              return (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.label}
                </Select.Option>
              );
            })
          }
        </Select>
      </Form.Item>
    </>
  );
}