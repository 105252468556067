import XLSX from 'xlsx';
import XLSXStyle from 'xlsx-style-correct';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { renderMergedColumn } from '../features/reports-new/formatData';

export const downloadFile = (fileName, sheetObj) => {
  try {
    //map excel file headers with data field
    const workbook = XLSX.utils.book_new();
    sheetObj.forEach(sheet => {
      let rowIndex = 1; //just for order-trail business event log report index column
      const data = sheet.data;
      const headers = sheet.headers;
      const sheetName = sheet.sheetName || 'sheet1';
      const sheetColConfig = sheet.sheetColConfig;
      const formattedData = _.chain(data)
        .map(item => {
          let newItem = _.cloneDeep(item);
          //if excel sheet headers is as same as data field
          
          const newRzt = headers.reduce((newData, key) => {
            if (key.dataIndex !== undefined && key.dataIndex) {
              const itemValue = newItem[key.dataIndex];
              if (key.dataIndex === 'index') {
                newData[key.title] = rowIndex;
                rowIndex = rowIndex + 1;
              } else if (key.title === 'subFlag') {
                if (
                  newItem.substituteReferenceLineNbr !== undefined &&
                  newItem.substituteReferenceLineNbr.toString() !== '0'
                ) {
                  newData[key.title] = 'true';
                } else {
                  newData[key.title] = 'false';
                }
              } else if (key.dataIndex === 'totals') {
                newData[key.title] = `$${itemValue}`;
              } else if (key.dataIndex === 'subItemUnitPrice' || key.dataIndex === 'originalItemUnitPrice' || key.dataIndex === 'orderDetailExtendedPrice') {
                newData[key.title] = (itemValue === 0? '$0.00' : itemValue && `$${itemValue.toLocaleString('zh',{minimumFractionDigits:2})}`);
              }else if(key.dataIndex === 'scId'){
                newData[key.title] = renderMergedColumn(newItem.scName,newItem.scId,'-');
              }else if(key.dataIndex === 'district'){
                newData[key.title] = renderMergedColumn(newItem.dsmName,newItem.district,'-');
              }else if(key.dataIndex === 'region'){
                newData[key.title] = renderMergedColumn(newItem.rsmName, newItem.region, '-');
              }
              else {
                newData[key.title] = itemValue;
              }
            } else {
              //for order header table
              newData[key.title] = key.value;
            }
            return newData;
          }, {});
          return newRzt;
        })
        .value();

      if (_.isEmpty(formattedData)) {
        console.log('No Records');
        return;
      }

      let ws = XLSX.utils.json_to_sheet(formattedData);
      if(sheetColConfig) {
        ws["!cols"] = sheetColConfig;
        Object.keys(ws).forEach(key => {
          if (key.indexOf('!') < 0) {
            if (key.length === 2 && key.indexOf('1') > -1) {
              ws[key].s = {
                fill: {
                  fgColor: { rgb: '1B4F72' },
                },
                alignment: {
                  vertical: 'center',
                  horizontal: 'center',
                  wrapText: true,
                },
                font: {
                  color: { rgb: 'FFFFFF' },
                  bold: true,
                },
              };
            }
          }
        });
      }
      XLSX.utils.book_append_sheet(workbook, ws, sheetName);
    });

    const wopts = { bookType: 'xlsx', bookSST: false, type: 'buffer' };
    const wbout = XLSXStyle.write(workbook, wopts);
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  } catch (err) {
    console.log(err, err.stack);
  }
};
