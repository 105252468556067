import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Button } from 'antd';
import { useFetchBusinessEventLogData } from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';
import { CONST_ORDER_TRAIL_COLUMNS } from '../../common/orderTrailConstants';
import { datadogRum } from '@datadog/browser-rum';

export default function OrderTrailOrders(props) {
  const { orderTrailData, fetchOrderTrailDataPending } = useSelector(state => ({
    orderTrailData: state.orderTrail.orderTrailData,
    fetchOrderTrailDataPending: state.orderTrail.fetchOrderTrailDataPending,
  }));
  const { fetchBusinessEventLogData } = useFetchBusinessEventLogData();

  const fetchOrderList = record => {
    const { setSelectedOrder } = props;
    const { opco, orderNo, customerNo, orderDate, uomOrderNo } = record;
    const formData = {
      opco: opco,
      orderNo: orderNo,
      customerNo: customerNo,
      uomOrderNo: uomOrderNo ? uomOrderNo : '',
      orderDate: orderDate,
    };
    const timing = Date.now();
    fetchBusinessEventLogData(formData);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch Business Event Log`,timing);
    });
    setSelectedOrder(record);
  };

  const tabKey = CONST_ORDER_TRAIL_COLUMNS.key;
  const actionColumn = {
    title: '',
    dataIndex: 'action',
    render: (value, record) => {
      return (
        <Button
          type="primary"
          // className="green-btn"
          onClick={() => fetchOrderList(record)}
          size="small"
        >
          Select
        </Button>
      );
    },
  };
  let columns = renderTableColumns(CONST_ORDER_TRAIL_COLUMNS.columns, tabKey);
  columns.splice(columns.length, 0, actionColumn);
  const setScrollValue = () => {
    let scrollGap = 0;
    if(orderTrailData) {
      scrollGap = (orderTrailData.length - 1) * 35;
    }
    return 300 - scrollGap;
  }
  return (
    <div className="order-trail-order-trail-orders">
      <div className="chart-title font-size-16-bold">{tabKey}</div>
      <Table
        rowKey={record => `${record.id}`}
        className="dashboard-order-report-table"
        loading={fetchOrderTrailDataPending}
        columns={columns}
        dataSource={orderTrailData}
        pagination={false}
        scroll={{ x: 'max-content' ,y: 100}}
      />
    </div>
  );
}

OrderTrailOrders.propTypes = {};
OrderTrailOrders.defaultProps = {};
