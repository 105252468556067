// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as fetchScInActionDataReducer } from './fetchScInActionData';
import { reducer as resetReportsCustomerLevelDataReducer } from './resetReportsCustomerLevelData';
import { reducer as resetReportsOrderLevelDataReducer } from './resetReportsOrderLevelData';
import { reducer as resetReportsDataReducer } from './resetReportsData';
import { reducer as exportScInActionReportDataReducer } from './exportScInActionReportData';
import { reducer as fetchScInActionCustomerDataReducer } from './fetchScInActionCustomerData';
import { reducer as fetchScInActionOrderDataReducer } from './fetchScInActionOrderData';
import { reducer as exportScInActionCustomerReportReducer } from './exportScInActionCustomerReport';
import { reducer as exportScInActionOrderReportReducer } from './exportScInActionOrderReport';
import { reducer as fetchSummaryDataReducer } from './fetchSummaryData';
import { reducer as exportSummaryReportReducer } from './exportSummaryReport';
import { reducer as fetchSummaryCustomerDataReducer } from './fetchSummaryCustomerData';
import { reducer as fetchSummaryOrderDataReducer } from './fetchSummaryOrderData';
import { reducer as exportSummaryCustomerReportReducer } from './exportSummaryCustomerReport';
import { reducer as exportSummaryOrderReportReducer } from './exportSummaryOrderReport';
import { reducer as fetchOrdersDataReducer } from './fetchOrdersData';
import { reducer as exportOrdersReportReducer } from './exportOrdersReport';
import { reducer as fetchOrdersReportByChannelTypeReducer } from './fetchOrdersReportByChannelType';
import { reducer as fetchSummaryDataFromChannelTypeReducer } from './fetchSummaryDataFromChannelType';
import { reducer as resetReportsDataAccountReducer } from './resetReportsDataAccount';
import { reducer as resetReportsDataOrdersReducer } from './resetReportsDataOrders';
import { reducer as exportOrdersReportByChannelTypeReducer } from './exportOrdersReportByChannelType';
import { reducer as fetchOrderDetailReportReducer } from './fetchOrderDetailReport';
import { reducer as resetReportsOrderDetailLevelDataReducer } from './resetReportsOrderDetailLevelData';
import { reducer as exportOrderDetailReportReducer } from './exportOrderDetailReport';
import { reducer as fetchSummaryDataByRegionReducer } from './fetchSummaryDataByRegion';
import { reducer as exportSummaryRegionReportReducer } from './exportSummaryRegionReport';
import { reducer as fetchOosReportBySiteReducer } from './fetchOosReportBySite';
import { reducer as fetchOosReportByCategoryReducer } from './fetchOosReportByCategory';
import { reducer as fetchOosReportByOrderNumberReducer } from './fetchOosReportByOrderNumber';
import { reducer as exportOosReportBySiteReducer } from './exportOosReportBySite';
import { reducer as exportOosReportByCategoryReducer } from './exportOosReportByCategory';
import { reducer as exportOosReportByOrderNumberReducer } from './exportOosReportByOrderNumber';
import { reducer as fetchOosAccountAccountTypeReducer } from './fetchOosAccountAccountType';
import { reducer as fetchOosAccountCustomerReducer } from './fetchOosAccountCustomer';
import { reducer as exportOosAccountAccountTypeReducer } from './exportOosAccountAccountType';
import { reducer as exportOosAccountCustomerReducer } from './exportOosAccountCustomer';
import { reducer as fetchOosAccountOrderReducer } from './fetchOosAccountOrder';
import { reducer as exportOosAccountOrderReducer } from './exportOosAccountOrder';
const reducers = [
  fetchScInActionDataReducer,
  resetReportsCustomerLevelDataReducer,
  resetReportsOrderLevelDataReducer,
  resetReportsDataReducer,
  exportScInActionReportDataReducer,
  fetchScInActionCustomerDataReducer,
  fetchScInActionOrderDataReducer,
  exportScInActionCustomerReportReducer,
  exportScInActionOrderReportReducer,
  fetchSummaryDataReducer,
  exportSummaryReportReducer,
  fetchSummaryCustomerDataReducer,
  fetchSummaryOrderDataReducer,
  exportSummaryCustomerReportReducer,
  exportSummaryOrderReportReducer,
  fetchOrdersDataReducer,
  exportOrdersReportReducer,
  fetchOrdersReportByChannelTypeReducer,
  fetchSummaryDataFromChannelTypeReducer,
  resetReportsDataAccountReducer,
  resetReportsDataOrdersReducer,
  exportOrdersReportByChannelTypeReducer,
  fetchOrderDetailReportReducer,
  resetReportsOrderDetailLevelDataReducer,
  exportOrderDetailReportReducer,
  fetchSummaryDataByRegionReducer,
  exportSummaryRegionReportReducer,
  fetchOosReportBySiteReducer,
  fetchOosReportByCategoryReducer,
  fetchOosReportByOrderNumberReducer,
  exportOosReportBySiteReducer,
  exportOosReportByCategoryReducer,
  exportOosReportByOrderNumberReducer,
  fetchOosAccountAccountTypeReducer,
  fetchOosAccountCustomerReducer,
  exportOosAccountAccountTypeReducer,
  exportOosAccountCustomerReducer,
  fetchOosAccountOrderReducer,
  exportOosAccountOrderReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  newState=state;
  return reducers.reduce((s, r) => r(s, action), newState);
}
