import React from 'react';
import { Row, Col, Empty } from 'antd';

export default function SnapshotOrderHeader(props) {
  const { headerColumns } = props;
  return (
    <div className="order-trail-snapshot-order-header">
      <Row>
        {headerColumns ? (
          headerColumns.map((item, index) => {
            return (
              <Col span={8} key={item.title} className="flex header-row">
                <div className="flex justify-space-between header-item">
                  <span className="header-title">{item.title}</span>
                  <span className="header-value">{item.value}</span>
                </div>
              </Col>
            );
          })
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Row>
    </div>
  );
}

SnapshotOrderHeader.propTypes = {};
SnapshotOrderHeader.defaultProps = {};
