import React from 'react';
import { Form } from 'antd';
import { CustomLabel } from './';

export default function SubDetail(props) {
  const { name, form, label } = props
  const formVals = form.getFieldValue(label)
  let divStyle = {}
  if(formVals && formVals[name]) {
    let tmp = formVals[name]
    if(tmp.isInvalidUOM) {
      divStyle = {opacity:0}
    } else {
      divStyle = {opacity:1}
    }
  } else {
    divStyle = {opacity:1}
  }
  return (
    <>
      <div className="flex align-items-center series-subs-tips-content-items" style={divStyle}>
        <Form.Item className="tips-content-item" name={[name, 'substituteItemDescription']}>
          <CustomLabel suffix=" | " />
        </Form.Item>
      </div>
      <div className="flex align-items-center series-subs-tips-content-items-1" style={divStyle}>
        <Form.Item className="tips-content-item" name={[name, 'substituteUom']}>
          <CustomLabel suffix=" | " />
        </Form.Item>
        <Form.Item className="tips-content-item" name={[name, 'substitutePack']}>
          <CustomLabel suffix="/" />
        </Form.Item>
        <Form.Item className="tips-content-item" name={[name, 'substituteSize']}>
          <CustomLabel suffix=" | " />
        </Form.Item>
        <Form.Item className="tips-content-item" name={[name, 'substituteBrand']}>
          <CustomLabel suffix=" | " />
        </Form.Item>
        <Form.Item className="tips-content-item" name={[name, 'substituteStorageCode']}>
          <CustomLabel />
        </Form.Item>
      </div>
    </>
  );
};

SubDetail.propTypes = {};
SubDetail.defaultProps = {};
