import moment from 'moment';
import { SLASH_DATE_FORMAT } from '../../common/constants';
import { CONST_SUB_RULE_TYPE, CONST_NONE_SUB_RULE_TYPE, CONST_NEVER_SUB_RULE_TYPE, CONST_DROP_OUT_RULE_TYPE } from '../../common/manageSubsConstants';
import { GROUP_ID_PREFIX } from '../../common/manageGroupsConstants';
/*export const transferFromResultToForm = (originalData) => {
  let resultData = JSON.parse(JSON.stringify(originalData));
  const ruleType = resultData.expandedItems !== null ? true : false;
  let expandedSubItems = [];
  let expandedNoSubItems = [];
  if(resultData.nationalId.length === 0) {
    resultData.nationalId = resultData.customerNumber;
    resultData.nationalName = resultData.customerName;
  }
  //add endDate for editing never sub and drop out rule
  resultData.endDate = resultData.endDate && moment(resultData.endDate);
  if(resultData.ruleType === CONST_DROP_OUT_RULE_TYPE){
    let dropoutRule = resultData.ruleType;
    resultData = { ...resultData, ruleType: dropoutRule };
  }else if(resultData.expandedItems) {
    resultData.expandedItems.forEach(item => {
      item.subPriority = item.subPriority && item.subPriority.toString();
      item.endDate = item.endDate && moment(item.endDate);
      item.ruleType = item.ruleType === CONST_SUB_RULE_TYPE;
      if(item.ruleType) {
        expandedSubItems.push(item);
      } else {
        expandedNoSubItems.push(item);
      }
    });
    delete resultData.expandedItems;
    resultData = {...resultData, expandedSubItems, expandedNoSubItems, ruleType};
  } else {
    delete resultData.expandedItems;    
    resultData = {...resultData, ruleType};
  }
  return resultData;
}*/

export const transferFromResultToForm = (originalData) => {
  let resultData = JSON.parse(JSON.stringify(originalData));
  const ruleType = resultData.expandedItems !== null ? true : false;
  let expandedSubItems = [];
  let expandedNoSubItems = [];
  if(resultData.nationalId.length === 0) {
    resultData.nationalId = resultData.customerNumber;
    resultData.nationalName = resultData.customerName;
  }
  //add endDate for editing never sub and drop out rule
  resultData.endDate = resultData.endDate && moment(resultData.endDate);
  if(resultData.ruleType === CONST_DROP_OUT_RULE_TYPE){
    let dropoutRule = resultData.ruleType;
    delete resultData.expandedItems;
    resultData = { ...resultData, ruleType: dropoutRule, 'dropOutRuleEndDate': resultData.endDate, expandedSubItems, expandedNoSubItems };
  }else if(resultData.expandedItems) {
    resultData.expandedItems.forEach(item => {
      item.subPriority = item.subPriority && item.subPriority.toString();
      item.endDate = item.endDate && moment(item.endDate);
      if(item.ruleType === CONST_SUB_RULE_TYPE) {
        item.ruleType = true
        expandedSubItems.push(item);
      }else if(item.ruleType === CONST_NONE_SUB_RULE_TYPE) {
        item.ruleType = false
        expandedNoSubItems.push(item);
      }else if(item.ruleType === CONST_DROP_OUT_RULE_TYPE) {
        resultData.dropOutRuleEndDate = item.endDate
      }
    });
    delete resultData.expandedItems;
    resultData = {...resultData, expandedSubItems, expandedNoSubItems, ruleType};
  } else {
    delete resultData.expandedItems;    
    resultData = {...resultData, ruleType};
  }
  return resultData;
}

/*export const transferFromFormToResult = originalData => {
  let resultData = JSON.parse(JSON.stringify(originalData));
  if(originalData.ruleType === CONST_DROP_OUT_RULE_TYPE){
    resultData = {...resultData, expandedItems: null}; 
  }else if(resultData.expandedSubItems === undefined && resultData.expandedNoSubItems === undefined) {
    const ruleType = CONST_NEVER_SUB_RULE_TYPE;
    resultData = {...resultData, expandedItems: null, ruleType};  //there is no sub items, it is never sub
  } else {
    let expandedItems = [];
    expandedItems = expandedItems.concat(resultData.expandedSubItems);
    expandedItems = expandedItems.concat(resultData.expandedNoSubItems);
    expandedItems.forEach(item => {
      
      item.endDate = item.endDate && moment(item.endDate).format(SLASH_DATE_FORMAT);
      
      item.ruleType = item.ruleType ? CONST_SUB_RULE_TYPE : CONST_NONE_SUB_RULE_TYPE;
      //add by jennifer
      //defect:COT-4991,sub ratio can accept valid numeric values with 0 appended in the front (e.g.: 09,080,etc),if 0 appended in the front,will save as removed 0
      if(item.subRatio !== null) {
        item.subRatio = removeSubRatioFrontZero(item.subRatio);
      }
    });

    delete resultData.expandedSubItems;
    delete resultData.expandedNoSubItems;

    const ruleType = '';
    resultData = {...resultData, expandedItems, ruleType};  //they are sub/no-sub items.
  }
  //save end date filed for drop out rule and never sub
  resultData.endDate = resultData.endDate && moment(resultData.endDate).format(SLASH_DATE_FORMAT);
  return resultData;
};*/
export const transferFromFormToResult = (originalData, extraInfo) => {
  let resultData = JSON.parse(JSON.stringify(originalData));
  if(resultData.expandedSubItems === undefined && resultData.expandedNoSubItems === undefined && !extraInfo.isExistDropOutRule) {
    const ruleType = CONST_NEVER_SUB_RULE_TYPE;
    resultData = {...resultData, expandedItems: null, ruleType};  //there is no sub items, it is never sub
  } else {
    let expandedItems = [];
    if(resultData.expandedSubItems && resultData.expandedSubItems.length > 0) {
      expandedItems = expandedItems.concat(resultData.expandedSubItems);
    }
    if(resultData.expandedNoSubItems && resultData.expandedNoSubItems.length > 0) {
      expandedItems = expandedItems.concat(resultData.expandedNoSubItems); 
    }
    expandedItems.forEach(item => {
      item.endDate = item.endDate && moment(item.endDate).format(SLASH_DATE_FORMAT);
      item.ruleType = item.ruleType ? CONST_SUB_RULE_TYPE : CONST_NONE_SUB_RULE_TYPE;
      //add by jennifer
      //defect:COT-4991,sub ratio can accept valid numeric values with 0 appended in the front (e.g.: 09,080,etc),if 0 appended in the front,will save as removed 0
      if(item.subRatio !== null) {
        item.subRatio = removeSubRatioFrontZero(item.subRatio);
      }
      //defect:COT-5561,trim the extra space for substituteItem
      item.substituteItem = item.substituteItem.trim();
    });
    if(extraInfo.isExistDropOutRule) {
      expandedItems.push({
        "ruleType": CONST_DROP_OUT_RULE_TYPE,
        "endDate": originalData.dropOutRuleEndDate && moment(originalData.dropOutRuleEndDate).format(SLASH_DATE_FORMAT),
        "subPriority": "0",
        "substituteItem": "",
        "substituteItemDescription": null,
        "substitutePack": null,
        "substituteSize": null,
        "substituteStorageCode": null,
        "substituteUom": null,
        "substituteBrand": null,
        "subRatio": null,
        "reasonCode": originalData.reasonCode,                   //* if drop out rule need pass reason code and reason description to web service  when update substitution
        "reasonDescription": originalData.reasonDescription,
        "modifiedTimestamp": extraInfo.modifiedTimestamp,
        "modifiedById": extraInfo.modifiedById,
        "modifiedByName": extraInfo.modifiedByName,
        "dropFlag": "Y",
        "createTimestamp": extraInfo.createTimestamp,
        "createdById": extraInfo.createdById,
        "createdByName": extraInfo.createdByName,
      })
    }
    delete resultData.expandedSubItems;
    delete resultData.expandedNoSubItems;
    const ruleType = '';
    resultData = {...resultData, expandedItems, ruleType};  //they are sub/no-sub items.
  }
  //save end date filed for drop out rule and never sub
  resultData.endDate = resultData.endDate && moment(resultData.endDate).format(SLASH_DATE_FORMAT);
  return resultData;
};
//add by jennifer
//defect:COT-4991,sub ratio can accept valid numeric values with 0 appended in the front (e.g.: 09,080,etc),if 0 appended in the front,will save as removed 0
const removeSubRatioFrontZero = value =>{
  if(value.indexOf('.') < 0){
    return Number(value).toString();
  }else{
    return value;
  }
}
//To validate search text is only string or note
export const validateStringRegExp = value => {
  const pattString = new RegExp('^[a-zA-Z]+$', 'g');
  const isString = pattString.test(value);
  return isString;
};
  //To validate search text is only numeric or not
export const validateNumericRegExp = value => {
  const pattNumeric = new RegExp('^[0-9]+$', 'g');
  const isNumeric = pattNumeric.test(value);
  return isNumeric;
};

//To validate ConceptId
export const validateConceptIdRegExp = value => {
  const pattMix = new RegExp('^[a-zA-Z0-9]+$', 'g');
  const pattString = new RegExp('[a-zA-Z]+', 'g');
  return pattMix.test(value) && pattString.test(value)
};

export const checkDropOutRule = (data) => {
  let ret = false
  if(data.expandedItems) {
    const len = data.expandedItems.length
    for(let i = 0; i < len; i++) {
      let item = data.expandedItems[i]
      if(item.ruleType === CONST_DROP_OUT_RULE_TYPE) {
        ret = true
        break
      }
    }
  }else if(data.ruleType === CONST_DROP_OUT_RULE_TYPE) {
    ret = true
  }
  return ret
}

export const getDropOutRuleCreatedInfo = (data, dropOutRuleCreateInfo) => {
  let createTimestamp = null
  let createdById = null
  let createdByName = null
  if(data) {
    if(data.expandedItems) {
      const len = data.expandedItems.length
      for(let i = 0; i < len; i++) {
        let item = data.expandedItems[i]
        if(item.ruleType === CONST_DROP_OUT_RULE_TYPE) {
          createTimestamp = item.createTimestamp
          createdById = item.createdById
          createdByName = item.createdByName
          break
        }
      }
    }else if(data.ruleType === CONST_DROP_OUT_RULE_TYPE) {
      createTimestamp = data.createTimestamp
      createdById = data.createdById
      createdByName = data.createdByName
    }
  }
  dropOutRuleCreateInfo.createTimestamp = createTimestamp
  dropOutRuleCreateInfo.createdById = createdById
  dropOutRuleCreateInfo.createdByName = createdByName
}

const prefixInteger = (num, length) => {
  return (Array(length).join('0') + num).slice(-length)
};

export const deleteOriginalItem = (arr, originalVal) => {
  if((originalVal + '').length < 7) {
    originalVal = prefixInteger(originalVal, 7)
  }
  if(arr.length === 0) {
    return null
  }
  const len = arr.length
  let index = -1
  for(let i = 0; i < len; i++) {
    if(arr[i].supc == originalVal) {
      index = i
      break
    }
  }
  if(index >= 0) {
    arr.splice(index, 1)
  }
  if(arr.length) {
    return arr
  } else {
    return null
  }
}

export const getUom = subItemInfo => {
  let {shipSplitsOnly, minimumSplit} = subItemInfo
  let uom = ''
  if(shipSplitsOnly == "Y") uom = "EA"
  if(shipSplitsOnly != "Y" && minimumSplit == 0) uom = "CS"
  if(shipSplitsOnly != "Y" && minimumSplit != 0) uom = "CS-EA"
  return uom
}

export const convertStorageCode = code => {
  const obj = {
    "C": "COOLER",
    "D": "DRY",
    "F": "FROZEN",
    "H": "HAZARDOUS",
    "I": "ICE CREAM",
    "K": "CAUSTIC",
    "M": "MEDICAL",
  }
  return obj[code]
}

export const handleAsohAndPrice = (products, isFromOuts, isAsohSuccess, asohObg, isPriceSuccess, priceObg, isSubFrequencySuccess, subFrequencyObg, totalOuts,isSecondAsohSuccess,secondAsohObg) => {
 
  products.data.forEach(subItem => {
    
    const subItemAsoh = asohObg[subItem.supc]
    const subItemSecondAsoh = secondAsohObg[subItem.supc]
    
    if(isAsohSuccess && subItemAsoh) {
      const { availableOnHand } = subItemAsoh
      
      if(availableOnHand !== null && availableOnHand !== undefined && availableOnHand !== "") {
        subItem.asoh = availableOnHand
      } else {
        subItem.asoh = "---"
      }
    } else {
      subItem.asoh = "---"
    }
    if(isSecondAsohSuccess && subItemSecondAsoh) {
      const { availableOnHand } = subItemSecondAsoh
      
      if(availableOnHand !== null && availableOnHand !== undefined && availableOnHand !== "") {
        subItem.secondAsoh = availableOnHand
      } else {
        subItem.secondAsoh = "---"
      }
    } else {
      subItem.secondAsoh = "---"
    }


    const subItemPrice = priceObg[subItem.supc]
    if(isPriceSuccess && subItemPrice) {
      const { unitPrice } = subItemPrice
      if(unitPrice !== null && unitPrice !== undefined && unitPrice !== "") {
        subItem.price = unitPrice
      } else {
        subItem.price = "---"
      }
    } else {
      subItem.price = "---"
    }

    const subItemFrequency = subFrequencyObg[subItem.supc]
    if(isSubFrequencySuccess && subItemFrequency) {
      const { frequency } = subItemFrequency
      if(frequency !== null && frequency !== undefined && frequency !== "") {
        subItem.frequency = frequency
      } else {
        subItem.frequency = 0
      }
    } else {
      subItem.frequency = 0
    }
  })

  if(isFromOuts) {
    products.data = products.data.filter(item => {
      return item.asoh !== '---' && item.asoh > 0 && item.asoh >= totalOuts
    })
  }
}
//validate group ID,start with ## and only allow Characters and Numbers in other place,and maxlength(include ##) is 10
export const validateGroupIdRegExp = value => {
  const pattGroupId = new RegExp('^##([a-zA-Z0-9]{3,8})$', 'g');
  const ispattGroupId = pattGroupId.test(value);
  return ispattGroupId;
};
//if Group Id start with ##
export const validateGroupIdPrefix = value => {
  return value && value.indexOf(GROUP_ID_PREFIX) === 0;
};