import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    MANAGE_SUBS_FETCH_INVENTORY_DATA_BEGIN,
    MANAGE_SUBS_FETCH_INVENTORY_DATA_SUCCESS,
    MANAGE_SUBS_FETCH_INVENTORY_DATA_FAILURE,
    MANAGE_SUBS_FETCH_INVENTORY_DATA_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function fetchInventoryData(args = {}) {
    return (dispatch, getState) => { // optionally you can have getState as the second argument
        dispatch({
            type: MANAGE_SUBS_FETCH_INVENTORY_DATA_BEGIN,
        });
        const promise = new Promise((resolve, reject) => {
            const cognitoIdToken = getState().home.auth.cognitoIdToken;
            const token = `Bearer ${cognitoIdToken}`;
            const doRequest = axios.post(`${API_REQUEST_URL}/getInventoryDetail`, args, {
                headers: {
                    'Authorization': token,
                }
            });
            doRequest.then(
                (res) => {
                    let data = [];
                    if(res.data !== "Server internal error."){
                        data = res.data && res.data.data;
                    }
                    dispatch({
                        type: MANAGE_SUBS_FETCH_INVENTORY_DATA_SUCCESS,
                        data: data,
                    });
                    resolve(data);
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                (err) => {
                    const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
                    dispatch({
                        type: MANAGE_SUBS_FETCH_INVENTORY_DATA_FAILURE,
                        data: { error: errorMsg },
                    });
                    reject(err);
                },
            );
        });
        return promise;
    };
}

export function dismissFetchInventoryDataError() {
    return {
        type: MANAGE_SUBS_FETCH_INVENTORY_DATA_DISMISS_ERROR,
    };
}

export function useFetchInventoryData() {
    const dispatch = useDispatch();
    const { inventoryData, fetchInventoryDataPending, fetchInventoryDataError } = useSelector(
        state => ({
            inventoryData: state.manageSubs.inventoryData,
            fetchInventoryDataPending: state.manageSubs.fetchInventoryDataPending,
            fetchInventoryDataError: state.manageSubs.fetchInventoryDataError,
        }),
        shallowEqual,
    );

    const boundAction = useCallback((...args) => {
        return dispatch(fetchInventoryData(...args));
    }, [dispatch]);

    const boundDismissError = useCallback(() => {
        return dispatch(dismissFetchInventoryDataError());
    }, [dispatch]);

    return {
        inventoryData,
        fetchInventoryData: boundAction,
        fetchInventoryDataPending,
        fetchInventoryDataError,
        dismissFetchInventoryDataError: boundDismissError,
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case MANAGE_SUBS_FETCH_INVENTORY_DATA_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                fetchInventoryDataPending: true,
                fetchInventoryDataError: null,
            };

        case MANAGE_SUBS_FETCH_INVENTORY_DATA_SUCCESS:
            // The request is success
            return {
                ...state,
                inventoryData: action.data,
                fetchInventoryDataPending: false,
                fetchInventoryDataError: null,
            };

        case MANAGE_SUBS_FETCH_INVENTORY_DATA_FAILURE:
            // The request is failed
            return {
                ...state,
                fetchInventoryDataPending: false,
                fetchInventoryDataError: action.data.error,
            };

        case MANAGE_SUBS_FETCH_INVENTORY_DATA_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                fetchInventoryDataError: null,
            };

        default:
            return state;
    }
}
