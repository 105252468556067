import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { EXPORT_API_REQUEST_URL } from '../../../common/apiConfig';
import {
  REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN,
  REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS,
  REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE,
  REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR,
} from './constants';

export function exportOrdersReportByChannelType(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(`${EXPORT_API_REQUEST_URL}/exportOrdersByChannelType`, args);
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS,
            data: res.data.data,
          });
          resolve(res.data.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissExportOrdersReportByChannelTypeError() {
  return {
    type: REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR,
  };
}

export function useExportOrdersReportByChannelType() {
  const dispatch = useDispatch();

  const { exportOrdersReportByChannelTypePending, exportOrdersReportByChannelTypeError } = useSelector(
    state => ({
      exportOrdersReportByChannelTypePending: state.reports.exportOrdersReportByChannelTypePending,
      exportOrdersReportByChannelTypeError: state.reports.exportOrdersReportByChannelTypeError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(exportOrdersReportByChannelType(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportOrdersReportByChannelTypeError());
  }, [dispatch]);

  return {
    exportOrdersReportByChannelType: boundAction,
    exportOrdersReportByChannelTypePending,
    exportOrdersReportByChannelTypeError,
    dismissExportOrdersReportByChannelTypeError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportOrdersReportByChannelTypePending: true,
        exportOrdersReportByChannelTypeError: null,
      };

    case REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS:
      // The request is success
      return {
        ...state,
        exportOrdersReportByChannelTypePending: false,
        exportOrdersReportByChannelTypeError: null,
      };

    case REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE:
      // The request is failed
      return {
        ...state,
        exportOrdersReportByChannelTypePending: false,
        exportOrdersReportByChannelTypeError: action.data.error,
      };

    case REPORTS_EXPORT_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportOrdersReportByChannelTypeError: null,
      };

    default:
      return state;
  }
}
