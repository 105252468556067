/* This is the Root component mainly initializes Redux and React Router. */

import React, { useState,useEffect } from 'react';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { hot, setConfig } from 'react-hot-loader';
import configureStore from './common/store';
import routeConfig from './common/routeConfig';
import history from './common/history';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ConfigProvider } from 'antd';
import en from 'antd/es/locale/en_US';
import fr from 'antd/es/locale/fr_FR';
import 'moment/locale/fr'
import 'moment/locale/en-au'
import i18n from './i18n/i18n';




const { store, persistor } = configureStore();

setConfig({
  logLevel: 'debug',
});

function renderRouteConfigV3(routes, contextPath) {
  // Resolve route config object in React Router v3.
  const children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath);
      children.push(
        <Route
          key={newContextPath}
          render={props => <item.component {...props}>{childRoutes}</item.component>}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <Route key={newContextPath} component={item.component} path={newContextPath} exact />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach(r => renderRoute(r, newContextPath));
    }
  };

  routes.forEach(item => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
}

function Root() {
  const children = renderRouteConfigV3(routeConfig, '/');

  const [locale, setLocal] = useState(en);
  
  useEffect(() => {

    store.subscribe(() => {
      const territory = store.getState().dashboard.territoryData;
      if (territory && territory.languageLocaleKey && territory.languageLocaleKey.includes('fr', 0)) {
        changeLanguage("fr", fr);
      } else {
        changeLanguage("en", en);
      }
    });

    function changeLanguage(language, lng) {
      if(i18n.language !== language) {
        setLocal(lng);
        i18n.changeLanguage(language);
      }
    }
  }, []);
  return (
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
}

export default hot(module)(Root);
