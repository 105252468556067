import fr from "./fr.json";
import en from "./en.json";

export const resources = {
	"fr": {
		translation: fr
	},
	"en": {
		translation: en
	}
}
