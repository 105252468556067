import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Table, Button, Modal } from 'antd';
import { ExportAlertModal } from '../common';
import { CONST_PAGINATION, SLASH_DATE_FORMAT } from '../../common/constants';
import { downloadFile } from '../../common/downloadReportFunc';
import { useFetchBusinessEventLogData, useFetchOrderTrailData, 
 useResetOrderTrailData, useResetBusinessEventLogData } from '../order-trail/redux/hooks';
import { formatFormdata } from './formatFormdata';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

export default function BusinessEventLogModal(props) {
  const {
    businessEventLogData,
    fetchBusinessEventLogDataPending,
    dashboardConditionData,
    territoryData,
    fetchOrderTrailDataPending,
  } = useSelector(state => ({
    businessEventLogData: state.orderTrail.businessEventLogData,
    fetchBusinessEventLogDataPending: state.orderTrail.fetchBusinessEventLogDataPending,
    dashboardConditionData: state.dashboard.dashboardConditionData,
    territoryData: state.dashboard.territoryData,
    fetchOrderTrailDataPending: state.orderTrail.fetchOrderTrailDataPending,
  }));
  const { columns, tabKey, keyValue, isVisible, onToggleModal, onChangeReportModalKey } = props;
  const [pagination, setPagination] = useState(CONST_PAGINATION);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const { fetchOrderTrailData } = useFetchOrderTrailData();
  const { fetchBusinessEventLogData } = useFetchBusinessEventLogData();
  const { resetOrderTrailData } = useResetOrderTrailData();
  const { resetBusinessEventLogData } = useResetBusinessEventLogData();
  useEffect(() => {
    if (!isVisible) {
      //reset state when close report modal
      setPagination(CONST_PAGINATION);
      setSorting();
      setShowAlert(false);
    } else {
      let formdata = {
        ...condition,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...sorting,
      }
      //fetch customer level report data, and update state
      reportActionIndex[tabKey](formdata);
    }
  }, [isVisible, pagination, sorting]); // eslint-disable-line
  const dataSource = businessEventLogData;
  let title = `Track My ${tabKey} Details`;
  const reportActionIndex = {
    'Orders': () => fetchOrderDetailsData(),
    'Future Orders': () => fetchOrderDetailsData(),
  };
  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };
   let condition = formatFormdata(dashboardConditionData, territoryData);
   condition = {
     ...condition,
     customerNumber: keyValue.customerNumber || '',
     scId: (tabKey === 'SC in Action' && keyValue.scId) || '',
     accountType: (tabKey === 'Summary' && keyValue.accountType) || '',
     offDay: tabKey === 'Summary' && keyValue.offDaySales ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
   };
   const handleAfterClose = () => {
    // if(tabKey === 'Orders' || tabKey === 'Future Orders') {
      resetOrderTrailData();
      resetBusinessEventLogData();
      onChangeReportModalKey({
       ...keyValue,
       orderNumber: null,
     });
    // } else if(fromCustomer) {
    //   resetReportsCustomerLevelData();
    //   onChangeReportModalKey(DEFAULT_REPORT_MODAL_KEY_VALUE);
    // } else {
    //   resetReportsOrderLevelData();
    //   onChangeReportModalKey({
    //     ...keyValue,
    //     customerNumber: null,
    //   });
    // }
  }
  const fetchOrderDetailsData = () => {
    const orderTrailCond = {
      opco: keyValue.opco,
      orderNo: keyValue.orderNo,
      customerNo: '',
      uomOrderNo: '',
      fromDate: moment(condition.deliveryDate).format(SLASH_DATE_FORMAT),
      toDate: moment(condition.deliveryDate).format(SLASH_DATE_FORMAT),
    };
    fetchOrderTrailData(orderTrailCond).then(order => {
      if(order) {
        const { opco, orderNo, orderDate, uomOrderNo } = order[0];
        const logCond = {
          opco: opco,
          orderNo: orderNo,
          customerNo: keyValue.customerNumber,
          uomOrderNo: uomOrderNo ? uomOrderNo : '',
          orderDate: orderDate,
        };
        fetchBusinessEventLogData(logCond);
      }
    });
  }
  const exportReports = () => {
    // if (dataTotals > 5001) {
    //   setShowAlert(true);
    // } else {
      setShowAlert(false);
      let sheetColConfig = [];
      columns.forEach(col => {
        let sheetColWidth = 10;
        if(col.width) {
          sheetColWidth = col.width/10;
        }
        sheetColConfig.push({ wch: sheetColWidth });
      });
      // if(tabKey === 'Orders' || tabKey === 'Future Orders') {
        const sheetObj = [{
          headers: columns,
          data: businessEventLogData,
          sheetName: null,
          sheetColConfig,
        }];
        downloadFile(`${title} Report`, sheetObj);
      // } else {
      //   exportReportActionIndex[tabKey](condition).then(data => {
      //     const sheetObj = [{
      //       headers: columns,
      //       data,
      //       sheetName: null,
      //       sheetColConfig,
      //     }];
      //     downloadFile(`${title} Report`, sheetObj);
      //   });
      // }
    // }
  };
  return (

      <Modal
        className="reports-details-report-modal"
        title={
          <>
            <div>{title}</div>
            <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
          </>
        }
        visible={isVisible}
        onCancel={onToggleModal}
        width={900}
        destroyOnClose={true}
        footer={null}
        afterClose={handleAfterClose}
        maskClosable={false}
      >
        <div className="report-export-button-modal flex justify-end">
          {/* <Button onClick={exportReports}>Export</Button>
           */}
          <Button type="link" title="Export" style={{ marginLeft: '-20px' }} onClick={exportReports} disabled={_.isEmpty(dataSource)}>
            <FontAwesomeIcon icon={faFileExport}  color={_.isEmpty(dataSource)?"#a1a1a2":"#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color:(_.isEmpty(dataSource)?"#a1a1a2":"#6b6b6c")}}>Export</p>
          </Button>
        </div>
        <Table
          rowKey={record => `${record.eventId}-${record.event}`}
          className="report-table"
          loading={fetchOrderTrailDataPending || fetchBusinessEventLogDataPending}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          onChange={onTableChange}
          scroll={{ x: 'max-content', y: 250 }}
        />
        <div className="align-items-center justify-space-between">
          {/* <Pagination
            style={{ textAlign: 'right', margin: '16px 0px' }}
            className={'report-pagination'}
            showSizeChanger
            current={pagination.pageIndex}
            pageSize={pagination.pageSize}
            total={fromCustomer ? reportsCustomerTotals : reportsOrderTotals}
            pageSizeOptions={pagination.pageSizeOptions}
            onShowSizeChange={onPaginationChange}
            onChange={onPaginationChange}
          /> */}
        </div>
      </Modal>
  );
};

BusinessEventLogModal.propTypes = {};
BusinessEventLogModal.defaultProps = {};
