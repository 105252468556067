import React from 'react';
import { useLocation } from 'react-router-dom';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
import { CommonPage } from '../reports-new';
export default function OrderAccountType() {
  const location = useLocation();
  let tabKey = '';
  const strCurrPath = location.pathname.replace('/', '').split('/');
  if(strCurrPath[0] === 'orders'){
    tabKey = 'Orders';
  }else{
    tabKey = 'Future Orders';
  }
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].customer.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].customer.level;
  return (
    <div className="orders-order-account-type">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

OrderAccountType.propTypes = {};
OrderAccountType.defaultProps = {};
