import { SELECT_ALL_OPTION } from '../../common/constants';
import { sortFiltersListByKey } from '../../common/sorterFunc';
import _ from 'lodash';
const getRegionSiteList = (marketList, regionList, orgList) => {
  let list = {
    market: [],
    region: [],
    sites: [],
  };
  orgList.map(marketItem => {
    //if role is Corp level, market/region/site are null
    //if role is Market level, region/site are null
    //if role is Region level, site is null
    //Others are Site level/ onChange event
    if (!marketList) {
      list.market.push(marketItem.market);
    }
    marketItem.regions.map(regionItem => {
      if (marketList) {
        if (marketList.indexOf(marketItem.market) > -1) {
          list.region.push(regionItem.region);
        }
      } else {
        list.region.push(regionItem.region);
      }
      if (regionList) {
        if (regionList.indexOf(regionItem.region) > -1) {
          regionItem.sites.map(siteItem => {
            return list.sites.push({ site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc });
          });
        }
      } else {
        if (marketList && marketList.indexOf(marketItem.market) > -1) {
          regionItem.sites.map(siteItem => {
            return list.sites.push({ site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc });
          });
        } else if (!marketList || marketList.length === 0) {
          regionItem.sites.map(siteItem => {
            return list.sites.push({ site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc });
          });
        }
      }
      return null;
    });
    return null;
  });
  list.region = sortFiltersListByKey(list.region);
  list.sites = sortFiltersListByKey(list.sites, 'site_nbr');
  return list;
};

export const getInitAllDropdownList = (orgList, market, region, sites) => {
  let initOrgList = {
    market: [],
    region: [],
    sites: [],
  };
  const list = getRegionSiteList(market, region, orgList);
  if (sites) {
    initOrgList = {
      market: market,
      region: region,
      sites: sites,
    };
  } else if (region) {
    initOrgList = {
      market: market,
      region: region,
      sites: list.sites,
    };
  } else if (market) {
    initOrgList = {
      market: market,
      region: list.region,
      sites: list.sites,
    };
  } else {
    initOrgList = {
      market: list.market,
      region: list.region,
      sites: list.sites,
    };
  }

  return initOrgList;
};

export const getTerritoryList = (siteIdList, territoryData) => {
  if (territoryData) {
    const territoryList = territoryData.filter(item => siteIdList.indexOf(item.opcoId) > -1);
    return territoryList;
  }
  return [];
};

export const uniqueTerritoryList = territoryList => {
  let newList = [];
  let tempObj = {};
  if (territoryList) {
    for (let i = 0; i < territoryList.length; i++) {
      if (!tempObj[territoryList[i].terrName]) {
        tempObj[territoryList[i].terrName] = 1;
        newList.push(territoryList[i]);
      }
    }
  }

  return newList;
};

export const getOrgSelectedList = (
  value,
  label,
  orgSelectedList,
  orgList,
  orgDropdownList,
  setOrgDropdownList,
  territoryData,
  setTerritoryList,
  territoryList,
  conceptData,
  enabledSites
) => {
  let selectedList = value;
  let currTerritoryList = selectedList;
  const lastedSelectOption = selectedList[selectedList.length - 1];
  let newList = {
    newOrgSelectedList: {},
  };
  const formattedLabel = label && label.toLowerCase();
  if (formattedLabel === 'market' || formattedLabel === 'corp') {
    //if corp user set default org selected market and region otherwise all option not working
    let currSelectedList = {
      market: [],
      region: [],
      sites: [],
    };
    if(formattedLabel === 'corp'){
      currSelectedList = {
        ...currSelectedList,
        market: orgDropdownList.market,
        region: orgDropdownList.region,
        sites:orgDropdownList.sites
      }
    }else{
      currSelectedList = getRegionSiteList(selectedList, null, orgList);
    }
    // let currSelectedList = orgList.sites;
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      currSelectedList = getRegionSiteList(orgDropdownList.market, null, orgList);
      selectedList = orgDropdownList.market;
    }
    const currSiteIdList = currSelectedList.sites.map(item => item.site_nbr);
    currTerritoryList = getTerritoryList(currSiteIdList, territoryData);
    if(formattedLabel === 'corp'){
      newList.newOrgSelectedList = {
        market: orgDropdownList.market,
        region: orgDropdownList.region,
        sites: [],
        territoryInfoList: [],
      };
    }else{
      newList.newOrgSelectedList = {
        market: selectedList,
        region: [],
        sites: [],
        territoryInfoList: [],
      };
    }
    setOrgDropdownList({
      ...orgDropdownList,
      region: currSelectedList.region,
      sites: currSelectedList.sites,
    });
    setTerritoryList(uniqueTerritoryList(currTerritoryList));
  } else if (formattedLabel === 'region') {
    let currSelectedList = getRegionSiteList(orgSelectedList.market, selectedList, orgList);
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      currSelectedList = getRegionSiteList(orgSelectedList.market, null, orgList);
      selectedList = currSelectedList.region;
    }
    const currSiteIdList = currSelectedList.sites.map(item => item.site_nbr);
    currTerritoryList = getTerritoryList(currSiteIdList, territoryData);
    newList.newOrgSelectedList = {
      market: orgSelectedList.market,
      region: selectedList,
      sites: [],
      territoryInfoList: [],
    };
    setOrgDropdownList({
      ...orgDropdownList,
      sites: currSelectedList.sites,
    });
    setTerritoryList(uniqueTerritoryList(currTerritoryList));
  } else if (formattedLabel === 'sites' || formattedLabel === 'ma') {
    let allSiteList = null;
    //the latest option is all
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      allSiteList = getRegionSiteList(orgSelectedList.market, orgSelectedList.region, orgList);
      //if select all will select OrgDropDownList. otherwise will select more than dorp down list sites
      if(enabledSites){
        allSiteList = orgDropdownList;
      }
      selectedList = allSiteList.sites.map(item => item.site_nbr);
    }
    currTerritoryList = getTerritoryList(selectedList, territoryData);
    newList.newOrgSelectedList = {
      market: orgSelectedList.market,
      region: orgSelectedList.region,
      sites: selectedList,
      territoryInfoList: formattedLabel === 'ma' ? territoryList.map(item => item.terrName) : [],
    };
    setTerritoryList(uniqueTerritoryList(currTerritoryList));
  } else if (formattedLabel === 'territoryinfolist') {
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      selectedList = territoryList.map(item => item.terrName);
    }
    newList.newOrgSelectedList = {
      ...orgSelectedList,
      [label]: selectedList,
    };
  } else if(formattedLabel === 'conceptid') {
    if (lastedSelectOption === SELECT_ALL_OPTION) {
      selectedList = conceptData;
    }
    newList.newOrgSelectedList = {
      ...orgSelectedList,
      [label]: selectedList,
    };
  }
  return newList;
};
export const getEnabledSites = (sortedOrgData,enabledData)=>{
  let newData = {market:[],region:[],sites:[]};
  enabledData.forEach(item => {
    const { market, region, siteNbr, siteDesc } = item
    if(newData.market.indexOf(market) < 0){
      newData.market.push(market);
    }
    if(newData.region.indexOf(region) < 0){
      newData.region.push(region);
    }
    newData.sites.push({ site_nbr:siteNbr, site_desc:siteDesc})
  })
  newData.sites.sort((a,b) => a.site_nbr - b.site_nbr)
  return newData

  //old version
  /*let newData = {market:[],region:[],sites:[]};
  let sites = [];
  enabledData.map(enabledSites=>{
    const market = enabledSites.market;
    const region = enabledSites.region;
    const siteNbr = enabledSites.siteNbr;
    const siteDesc = enabledSites.siteDesc;
    if(sortedOrgData.market.indexOf(market)>-1){
      if(newData.market.indexOf(market) < 0){
        newData.market.push(market);
      }
      if(sortedOrgData.region.indexOf(region)>-1){
        if(newData.region.indexOf(region) < 0){
          newData.region.push(region);
        }
        if(sortedOrgData.sites.filter(item=>item.site_nbr === siteNbr)){
          sites.push({ site_nbr:siteNbr,site_desc:siteDesc})
        }
      }
    }
    newData.sites = sites;
  });
  if(!_.isEmpty(newData.market) && !_.isEmpty(newData.region) && !_.isEmpty(newData.region)){
    newData.market = sortFiltersListByKey(newData.market);
    newData.region = sortFiltersListByKey(newData.region);
    newData.sites = sortFiltersListByKey(newData.sites,"site_nbr");
    return newData;
  }else {
    newData = {market:[],region:[],sites:[]};
    enabledData.map(enabledSites=>{
      const {market, region, siteNbr, siteDesc} = enabledSites;
      if(newData.market.indexOf(market) < 0){
        newData.market.push(market);
      }
      if(newData.region.indexOf(region) < 0){
        newData.region.push(region);
      }
      newData.sites.push({ site_nbr:siteNbr,site_desc:siteDesc})
    })
    return newData
  }*/
}
export const uniqueGroupIdList = groupIdList => {
  let newList = [];
  let tempObj = {};
  if (groupIdList) {
    for (let i = 0; i < groupIdList.length; i++) {
      if (!tempObj[groupIdList[i].nationalId]) {
        tempObj[groupIdList[i].nationalId] = 1;
        newList.push(groupIdList[i]);
      }
    }
  }
  return newList;
};