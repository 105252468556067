import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { TagCard } from './';
import { ORDER_BY_TYPE_COLUMNS } from '../../common/chartConstants';
import _ from 'lodash';
import { useTranslation } from "react-i18next";

export default function OrderByTypeCard(props) {
  const { t } = useTranslation();
  const { orderByTypeSummaryData } = useSelector(state => ({
    orderByTypeSummaryData: state.dashboard.orderByTypeSummaryData,
  }));
  const showOrderTypeDetails = (label, totals) => {
    const { handleOrderTypeLabelClick } = props;
    if(!disabledBtn(label)) {
      handleOrderTypeLabelClick(label, totals);
    }
  }
  const disabledBtn = (label) => {
    if(orderByTypeSummaryData) {
      return _.isNull(orderByTypeSummaryData[label]) || orderByTypeSummaryData[label] === 0;
    }
    return true;
  }
  const renderTagCardItems = (startIndex, renderSize) => {
    return Object.keys(ORDER_BY_TYPE_COLUMNS).map((key, index) => {
      if(index >= startIndex && index < startIndex + renderSize) {
        const title = ORDER_BY_TYPE_COLUMNS[key];
        const className = 'primary-card';
        if(orderByTypeSummaryData.hasOwnProperty(key)) {
          //hardcode unconfirmed order count until they fixed backend issue
          const count = (key === 'unconfirmed' && orderByTypeSummaryData[key]? 0 : orderByTypeSummaryData[key]);
          return (
            <Col key={key} span={5} className="order-type-row" onClick={() => showOrderTypeDetails(key, count)}>
              <TagCard title={t(`ORDER_BY_TYPE_COLUMNS.${key}`)} className={className} count={count} />
            </Col>
          );
        }
      }
      return null;
    });
  }
  return (
    <div className="dashboard-order-by-type-card">
      <Row justify="space-between">
        {renderTagCardItems(0, 4)}
      </Row>
    </div>
  );
}

OrderByTypeCard.propTypes = {};
OrderByTypeCard.defaultProps = {};
