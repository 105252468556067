import React from 'react';
import moment from 'moment';

export default function CustomLabel(props) {
  const { value, dataType, suffix } = props;
  let dataValue = value;
  if (dataValue && dataType) {
    dataValue = moment(dataValue).format(dataType);
  }
  if (dataValue && suffix) {
    dataValue = `${dataValue}${suffix}`;
  }
  return <label className="manage-subs-custom-label custom-form-item-value">{dataValue}</label>;
}
