import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_RESET_ORDER_REPORT,
} from './constants';

export function resetOrderReport() {
  return {
    type: DASHBOARD_RESET_ORDER_REPORT,
  };
}

export function useResetOrderReport() {
  const dispatch = useDispatch();
  const orderReportData = useSelector(state => state.dashboard.orderReportData);
  const boundAction = useCallback(() => dispatch(resetOrderReport()), [dispatch]);
  return { orderReportData, resetOrderReport: boundAction };
}

export function reducer(state, action) {
  if(action.type===DASHBOARD_RESET_ORDER_REPORT){
    return {
      ...state,
      orderReportData: null,
    };

  }
  
      return state;
  
}
