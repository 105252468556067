// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { ReportsLayoutContent } from '../reports-new';
import { OosAccountCustomer, OosAccountItem, OosAccountType, } from './';

export default {
  path: 'oos-account',
  component: ReportsLayoutContent,
  childRoutes: [
    {
      path: '/',
      component: OosAccountType,
      isIndex: true,
    },
    {
      path: 'customer',
      component: OosAccountCustomer,
    },
    {
      path: 'order',
      component: OosAccountItem,
    },
  ],
};
