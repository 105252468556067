import React from 'react';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
import { CommonPage } from '../reports-new';
export default function ScOrderDetail() {
  const tabKey = 'SC in Action';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].orderDetail.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].orderDetail.level;
  return (
    <div className="sc-in-action-sc-order-detail">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

ScOrderDetail.propTypes = {};
ScOrderDetail.defaultProps = {};
