import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Button, Tag, message, Spin,Upload,Modal } from 'antd';
import { renderTableColumns } from '../../common/renderColumns';
import { CONST_ACCOUNTS_TABLE_COLUMNS } from '../../common/manageAccountsConstants';
import { CONST_PAGINATION_TEN, DATE_TIME_ONE_FORMAT, DATE_TIME_TWO_FORMAT } from '../../common/constants';
import { SearchFiltersPanel, MaintainAccountsOperateModal, StartOfDayEmailModal } from './';
import { CommonTable } from '../common';
import { DownloadIcon, UploadIcon } from '../common/CustomerIcons';
import { CONST_SITE_LIST } from '../../common/orderTrailConstants';
import BULK_TEMPLATE_FILE from '../../assets/ManageAccounts-BulkTemplate.xlsx';
import { useFetchAccountData, useResetAccountData, useUpdateAccountData, useDeleteAccountData, useExportAccountsData, useUploadAccounts,
  useCheckEditAccountLock, useUpdateEditAccountLock, useGetAllAgents, } from './redux/hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport, faPen, faMailBulk, } from '@fortawesome/free-solid-svg-icons';
import AccountWarningModal from './ManageOutsWarningModal';
import { datadogRum } from '@datadog/browser-rum';

export default function MaintainAccounts() {
  const {
    accountData,
    fetchAccountDataPending,
    searchConditionData,
    updateAccountDataPending,
    deleteAccountDataPending,
    exportAccountsDataPending,
    uploadAccountsPending,
    checkEditAccountLockPending,
    fetchTerritoryDataPending,
    fetchSearchPanelOrgInfoPending,
    orgDropdownList,
    getAllAgentsPending,
  } = useSelector(state => ({
    accountData: state.manageSubs.accountData,
    fetchAccountDataPending: state.manageSubs.fetchAccountDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    updateAccountDataPending: state.manageSubs.updateAccountDataPending,
    deleteAccountDataPending: state.manageSubs.deleteAccountDataPending,
    exportAccountsDataPending: state.manageSubs.exportAccountsDataPending,
    uploadAccountsPending: state.manageSubs.uploadAccountsPending,
    checkEditAccountLockPending: state.manageSubs.checkEditAccountLockPending,
    fetchTerritoryDataPending: state.dashboard.fetchTerritoryDataPending,
    fetchSearchPanelOrgInfoPending: state.manageSubs.fetchSearchPanelOrgInfoPending,
    orgDropdownList: state.manageSubs.orgDropdownList,
    getAllAgentsPending: state.manageSubs.getAllAgentsPending,
  }));
  const { auth } = useSelector(state => ({
    auth: state.home.auth,
  }));
  const { fetchAccountData } = useFetchAccountData();
  const { updateAccountData } = useUpdateAccountData();
  const { resetAccountData } = useResetAccountData();
  const { deleteAccountData } = useDeleteAccountData();
  const { exportAccountsData } = useExportAccountsData();
  const { uploadAccounts } = useUploadAccounts();
  const { checkEditAccountLock } = useCheckEditAccountLock();
  const { updateEditAccountLock } = useUpdateEditAccountLock();
  const { getAllAgents } = useGetAllAgents();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [resetActivePagination, setResetActivePagination] = useState(false);
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);
  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);
  const [dayEmailInfo, setDayEmailInfo] = useState(null);

  const onUploadAccounts = () => {
    const timing = Date.now();
    if(_.isEmpty(uploadFileList)) {
      message.destroy();
      message.info('Please select a file.');
      return;
    }
    let formData = new FormData();
    uploadFileList.forEach(file => {
      formData.append("file", file);
    });
    uploadAccounts(formData).then((response) => {
      setUploadFileList([]);
      message.destroy();
      message.info(JSON.stringify(response.data));
    },
    (err) => {
      let errMsg = err && err.response ? err.response.data.error : 'There is an error. Please refresh page or contact administrator.'
      message.destroy();
      message.error('Uploaded failed: ' + errMsg);
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Upload Accouts`,timing);
    });
  }
  const onExportSubs = () => {
    const timing = Date.now();
    exportAccountsData(searchConditionData);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Export Accounts`,timing);
    });
  }
  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  };
  const onEdit = record => {
    setEditableData(record);
    const formData = {
      site: record.site,
      customerNumber: record.customerNumber,
      nationalId: record.nationalId,
    }

    checkEditAccountLock(formData).then((response)=>{
      if(response && response.lockStatus === 'Y'){
        onToggleModal(setWarningModalVisible);
      }else{
        onToggleModal(setEditModalVisible);
        updateAccountRecordLock(record)
      }
    },(err)=>{
      message.destroy();
      message.error(err);
    })
  };
  const updateAccountRecordLock =(record)=>{
    const formData = {
      site: record.site,
      customerNumber: record.customerNumber,
      nationalId: record.nationalId,
      lockStatus: !editModalVisible?'Y':'N',
    }
    updateEditAccountLock(formData).then((response)=>{
    },(err)=>{
      message.error(`Update Accounts Record Lock Failed, please try again.`);
    });
  }
  const onUpdateAccounts = (args, onCloseModal, exists) => {
    const timing = Date.now();
    let mssg = `${exists ? 'Created' : 'Update'} Accounts Successfully.`;
    updateAccountData(args).then(data => {
      message.success(mssg);
      onCloseModal();
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      message.error(`Update Accounts Failed, please try again.`);
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Update Accounts`,timing);
    });
  }
  const onDeleteAccounts = (args, onCloseModal) => {
    const timing = Date.now();
    deleteAccountData({...args,modifiedById: auth?.user?.userId, modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT),modifiedByName: auth?.user?.userName}).then(data => {
      message.success(`Delete Accounts Successfully.`);
      onCloseModal();
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      message.error(`Delete Accounts Failed, please try again.`);
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Delete Accounts`,timing);
    });
  }
  const renderColumnsElement = [
    { //render siteID
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.siteDesc}</div>
          </>
        )
      }
    },
    { //render conceptID
      extendRender: (value, label, record) => {
        if(value && value.length > 0) {
          return (
            <>
              <div className="col-title">{value}</div>
              <div className="col-desc">{record.nationalName}</div>
            </>
          )
        }
        return (
          <>
            <div className="col-title">{record.customerNumber}</div>
            <div className="col-desc">{record.customerName}</div>
          </>
        )
      }
    },
    {//render L/C
      extendRender: (value, label, record) => {
        let text = ''
        if(value === 'LOCAL') {
          text = 'Local'
        } else if(value === 'CCC') {
          text = 'Central'
        } else if(value === 'CST') {
          text = 'CST'
        }
        return (
          <span>{text}</span>
        )
      }
    },
    { //render Inc/Exc
      extendRender: (value, label, record) => {
        const text = value === 'Y' ? 'YES' : 'NO';
        return (
          <Tag className={value === 'Y' ? 'yes-tag' : 'no-tag'}>{text}</Tag>
        )
      }
    },
    { //render Req Apv
      extendRender: (value, label, record) => {
        const text = value === 'Y' ? 'YES' : 'NO';
        return (
          <Tag className={value === 'Y' ? 'yes-tag' : 'no-tag'}>{text}</Tag>
        )
      }
    },
    {},//cutoff placeholder
    {},//route cutoff placeholder
    { //render SOC
      extendRender: (value, label, record) => {
        const text = value === 'Y' ? 'YES' : 'NO';
        return (
          <Tag className={value === 'Y' ? 'yes-tag' : 'no-tag'}>{text}</Tag>
        )
      }
    },
    { //render Send Email
      extendRender: (value, label, record) => {
        const text = value === 'Y' ? 'YES' : 'NO';
        return (
          <Tag className={value === 'Y' ? 'yes-tag' : 'no-tag'}>{text}</Tag>
        )
      }
    },
    { //render Account Owner
      extendRender: (value, label, record) => {
        const phone = record.accountPhoneNo ? record.accountPhoneNo : ""
        return value && (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{phone}</div>
          </>
        )
      }
    },
    { //render modifiedBy
      extendRender: (value, label, record) => {
        const modifiedDate = record.modifiedTimestamp && moment(record.modifiedTimestamp).format(DATE_TIME_ONE_FORMAT);
        return value && (
          <>
            <div className="col-title">{record.modifiedByName}</div>
            <div className="col-desc">{`${record.modifiedById} ${modifiedDate}`}</div>
          </>
        )
      }
    },
  ];
  let columns = renderTableColumns(CONST_ACCOUNTS_TABLE_COLUMNS, null, renderColumnsElement);
  columns.push({
    title: 'Actions',
    dataIndex: '',
    width: 80,
    render: (value, record) => {
      return (
        <div className="actions-cell">
          <Button type="link" onClick={() => onEdit(record)}>
            <FontAwesomeIcon icon={faPen} color='#6b6b6c' style={{ height: "15px", width: "15px" }}/> 
          </Button>
        </div>
      );
    }
  });

  const renderAddModal = (
    <MaintainAccountsOperateModal
      title="Add Account"
      isModalVisible={addModalVisible}
      width={780}
      siteData={CONST_SITE_LIST}
      onToggleModal={() => onToggleModal(setAddModalVisible)}
      cancelText="Reset"
      okText="Save"
      onOk={onUpdateAccounts}
      loading={updateAccountDataPending}
    />
  );

  const renderEditModal = (
    <MaintainAccountsOperateModal
      title="Edit Account"
      isModalVisible={editModalVisible}
      width={850}
      onToggleModal={() => {onToggleModal(setEditModalVisible); updateAccountRecordLock(editableData);}}
      cancelText="Reset"
      okText="Save"
      onOk={onUpdateAccounts}
      onDelete={onDeleteAccounts}
      initValueData={editableData}
      loading={updateAccountDataPending || deleteAccountDataPending}
    />
  );

  const renderWarningModal = (
    <AccountWarningModal
      title=""
      isModalVisible={isWarningModalVisible}
      footer={false}
      width={500}
      onToggleModal={() => {onToggleModal(setWarningModalVisible);}}
      content="Maintenance is disabled for this record as this is being worked on by another user session."
    />
  );

  const renderSendEmailModal = (
    <StartOfDayEmailModal
      title="Start Of Day Email"
      isModalVisible={sendEmailModalVisible}
      width={1000}
      onToggleModal={() => onToggleModal(setSendEmailModalVisible)}
      cancelText="Cancel"
      okText="Send"
      initValueData={dayEmailInfo}
    />
  );

  const handleStartOfDayEmail = () => {
    getAllAgents().then(res => {
      let initDataSource = [];
      if(orgDropdownList && orgDropdownList.sites) {
        initDataSource = orgDropdownList.sites.map((item, index) => {
          return {
            key: index,
            site: item.site_nbr,
            siteDesc: item.site_desc,
            agentId: '',
            agentName: '',
            agentEmail: '',
            lastSentOn: null,
            sentStatus: '',
            createTimestamp: null,
            updateTimestamp: null,
            isValid: true,
            checkedFlag: false,
          }
        })
      }
      if(res && Array.isArray(res) && res.length > 0) {
        let tempObj = {}
        res.forEach(item => {
          tempObj[item.site] = item
        })
        initDataSource.forEach(item => {
          const tempItem = tempObj[item.site]
          if(tempItem) {
            delete tempObj[item.site]
            item.site = tempItem.site
            item.siteDesc = tempItem.siteDesc
            item.agentId = tempItem.agentId
            item.agentName = tempItem.agentName
            item.agentEmail = tempItem.agentEmail
            item.lastSentOn = tempItem.lastSentOn
            item.sentStatus = tempItem.sentStatus
            item.createTimestamp = tempItem.createTimestamp
            item.updateTimestamp = tempItem.updateTimestamp
          }
        })
        let cnt = initDataSource.length
        Object.keys(tempObj).forEach((site, index) => {
          const tempItem = tempObj[site]
          initDataSource.push({
            key: index + cnt,
            site: tempItem.site,
            siteDesc: tempItem.siteDesc,
            agentId: tempItem.agentId,
            agentName: tempItem.agentName,
            agentEmail: tempItem.agentEmail,
            lastSentOn: tempItem.lastSentOn,
            sentStatus: tempItem.sentStatus,
            createTimestamp: tempItem.createTimestamp,
            updateTimestamp: tempItem.updateTimestamp,
            isValid: true,
            checkedFlag: false,
          })
        })
      }
      setDayEmailInfo(initDataSource)
      onToggleModal(setSendEmailModalVisible)
    })
  }

  const uploadProps = {
    multiple: false,
    onRemove: file => {
      setUploadFileList(prevState => {
        const index = prevState.indexOf(file);
        const fileList = prevState.slice();
        fileList.splice(index, 1);
        return fileList;
      });
    },
    beforeUpload: file => {
      const extensionName = file.name.split('.').pop().toLowerCase();
      if(extensionName !== 'xlsx') {
        message.error(`Please upload the latest xlsx template file.`);
        return Upload.LIST_IGNORE;
      }
      setUploadFileList(prevState => {
        return [file];
      });
      return false;
    },
    fileList: uploadFileList,
  };
  const renderUploadDownloadModal = (
    <Modal
      className="manage-subs-maintain-accounts-operate-modal"
      title={"Upload File"}
      visible={uploadModalVisible}
      onCancel={() => {onToggleModal(setUploadModalVisible);setUploadFileList([])}}
      okText="Done"
      destroyOnClose={true}
      maskClosable={false}
      onOk={onUploadAccounts}
    >
      <Spin spinning={uploadAccountsPending} tip="Uploding...">
        <div>
          <Button className="subs-upload-btn flex align-items-center" icon={<DownloadIcon style={{ fontSize: '36px' }} />} >
            <a href={BULK_TEMPLATE_FILE} download='ManageAccounts-BulkTemplate.xlsx'>Download the template</a>
          </Button>
        </div>
        <div>
          <Upload {...uploadProps}>
            <Button className="subs-upload-btn flex align-items-center" icon={<UploadIcon style={{ fontSize: '36px' }} />} >
            Upload the file
            </Button>
          </Upload>
        </div>
      </Spin>
    </Modal>
  );
  return (
    <div className="manage-subs-maintain-accounts">
      <Spin spinning={exportAccountsDataPending || getAllAgentsPending}>
      <div className="chart-title font-size-16-bold" style={{font: "Myriad Bold"}}>Search Accounts</div>
      <SearchFiltersPanel />
      <div className="flex align-item-center justify-end" style={{marginBottom:"20px", marginTop:"15px"}}>
        <Button className="add-btn" onClick={() => onToggleModal(setAddModalVisible)} >Add Accounts</Button>
        <span style={{fontSize:"25px", marginTop:"-7px", fontWeight:"lighter", transform:"scale(.5, 1)", color:"#c2c2c3"}}>&ensp;|</span>
        <Button type="link" title="Import" onClick={() => onToggleModal(setUploadModalVisible)}>
          <FontAwesomeIcon icon={faFileImport} color="#6b6b6c" style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"2px", color:"#6b6b6c"}}>Import</p>
        </Button>
        <Button type="link" title="Export" disabled={accountData === null} onClick={onExportSubs}>
          <FontAwesomeIcon icon={faFileExport} color={accountData?"#6b6b6c":"#a1a1a2"} style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"-3px", color:(accountData?"#6b6b6c":"#a1a1a2")}}>Export</p>
        </Button>
        <Button type="link" title="Start Of Day Email" disabled={fetchTerritoryDataPending || fetchSearchPanelOrgInfoPending} onClick={handleStartOfDayEmail}>
          <FontAwesomeIcon icon={faMailBulk} color="#6b6b6c" style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"-3px", color:"#6b6b6c"}}>Start Of Day Email</p>
        </Button> 
      </div>
      <CommonTable 
        rowKey={record => `${record.site}-${record.nationalId}-${record.customerNumber}-${record.managedBy}`}
        columns={columns}
        tableClassName={accountData ? "subs-report-table" : ""}
        datasource={accountData}
        loadingData={fetchAccountDataPending || updateAccountDataPending || checkEditAccountLockPending}
        paginationStyle={{textAlign: "right", margin: '16px 0px'}}
        triggerConditions={searchConditionData}
        onFecthData={fetchAccountData}
        onResetStore={resetAccountData}
        scrollStyle={{ x: 'max-content', y: 300 }}
        paginationSetting={CONST_PAGINATION_TEN}
        resetActivePagination={resetActivePagination}
      />
      {addModalVisible && renderAddModal}
      {editModalVisible && renderEditModal}
      {uploadModalVisible && renderUploadDownloadModal}
      {isWarningModalVisible && renderWarningModal}
      {sendEmailModalVisible && renderSendEmailModal}
      </Spin>
    </div>
  );
}
