import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_INVENTORY_DETAIL_DATA,
} from './constants';

export function resetInventoryDetailData() {
  return {
    type: MANAGE_SUBS_RESET_INVENTORY_DETAIL_DATA,
  };
}

export function useResetInventoryDetailData() {
  const dispatch = useDispatch();
  const inventoryData = useSelector(state => state.manageSubs.inventoryData);
  const boundAction = useCallback((...params) => dispatch(resetInventoryDetailData()), [dispatch]);
  return {inventoryData, resetInventoryDetailData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_INVENTORY_DETAIL_DATA:
      return {
        ...state,
        inventoryData: null
      };

    default:
      return state;
  }
}
