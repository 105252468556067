import {
  PROD_USER_POOL_ID,
  PROD_APP_CLIENT_ID,
  PROD_IDENTITY_POOL_ID,
  PROD_SIA_ENDPOINT,
  PROD_ORDER_TRAIL_ENDPOINT,
  PROD_AUTH_DOMAIN,
  PROD_REDIRECT,
} from '../common/apiConstants';
export default {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: PROD_USER_POOL_ID,
    APP_CLIENT_ID: PROD_APP_CLIENT_ID,
    IDENTITY_POOL_ID: PROD_IDENTITY_POOL_ID,
  },
  endpoints: [
    {
      name: 'siaplus',
      endpoint: PROD_SIA_ENDPOINT,
      service: 'lambda',
      region: 'us-east-1',
    },
    {
			name: 'ordertrail',
			endpoint: PROD_ORDER_TRAIL_ENDPOINT,
			service: "lambda",
			region: "us-east-1"
		},
  ],
  oauth: {
    // Domain name
    domain: PROD_AUTH_DOMAIN,
    // Authorized scopes
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn: PROD_REDIRECT, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    // Sign out URL
    redirectSignOut: PROD_REDIRECT, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    // Note that REFRESH token will only be generated when the responseType is code
    responseType: 'token',
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: true,
    },

    urlOpener: null,
  },
};
