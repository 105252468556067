import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_FETCH_DROP_OUTS_REASON_BEGIN,
  MANAGE_SUBS_FETCH_DROP_OUTS_REASON_SUCCESS,
  MANAGE_SUBS_FETCH_DROP_OUTS_REASON_FAILURE,
  MANAGE_SUBS_FETCH_DROP_OUTS_REASON_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import { sortObject } from '../../../common/sorterFunc';
export function fetchDropOutsReason(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_DROP_OUTS_REASON_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/getDropOutsReason`, {
        headers: {
          Authorization: token,
        },
      });
      doRequest.then(
        (res) => {
          let formattedData = res.data;
          if(res.data){          
            formattedData = Object.entries(res.data).map(([key, value]) => ({label:value, value:key}));
            formattedData = sortObject(formattedData,'value','ascend');
          }
          dispatch({
            type: MANAGE_SUBS_FETCH_DROP_OUTS_REASON_SUCCESS,
            data: formattedData,
          });
          resolve(formattedData);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_DROP_OUTS_REASON_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchDropOutsReasonError() {
  return {
    type: MANAGE_SUBS_FETCH_DROP_OUTS_REASON_DISMISS_ERROR,
  };
}

export function useFetchDropOutsReason() {
  const dispatch = useDispatch();

  const { dropOutsReasonData,fetchDropOutsReasonPending, fetchDropOutsReasonError } = useSelector(
    state => ({
      dropOutsReasonData: state.manageSubs.dropOutsReasonData,
      fetchDropOutsReasonPending: state.manageSubs.fetchDropOutsReasonPending,
      fetchDropOutsReasonError: state.manageSubs.fetchDropOutsReasonError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchDropOutsReason(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchDropOutsReasonError());
  }, [dispatch]);

  return {
    dropOutsReasonData,
    fetchDropOutsReason: boundAction,
    fetchDropOutsReasonPending,
    fetchDropOutsReasonError,
    dismissFetchDropOutsReasonError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_DROP_OUTS_REASON_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchDropOutsReasonPending: true,
        fetchDropOutsReasonError: null,
      };

    case MANAGE_SUBS_FETCH_DROP_OUTS_REASON_SUCCESS:
      // The request is success
      return {
        ...state,
        dropOutsReasonData: action.data,
        fetchDropOutsReasonPending: false,
        fetchDropOutsReasonError: null,
      };

    case MANAGE_SUBS_FETCH_DROP_OUTS_REASON_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchDropOutsReasonPending: false,
        fetchDropOutsReasonError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_DROP_OUTS_REASON_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchDropOutsReasonError: null,
      };

    default:
      return state;
  }
}
