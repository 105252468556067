import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_ORDER_DETAIL_DATA,
} from './constants';

export function resetOrderDetailData() {
  return {
    type: MANAGE_SUBS_RESET_ORDER_DETAIL_DATA,
  };
}

export function useResetOrderDetailData() {
  const dispatch = useDispatch();
  const orderDetail = useSelector(state => state.manageSubs.orderDetail);
  const boundAction = useCallback((...params) => dispatch(resetOrderDetailData()), [dispatch]);
  return { orderDetail,resetOrderDetailData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_ORDER_DETAIL_DATA:
      return {
        ...state,
        orderDetail: null
      };

    default:
      return state;
  }
}
