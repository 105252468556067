import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_BEGIN,
  MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_FAILURE,
  MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import _ from 'lodash';
export function fetchSubsAuditTrailData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "descend",
          sortedBy: "createTimestamp",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/subsEventSearch`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          let data = res?.data?.data;
          data && data.forEach((rowList, index) => {
            let modifiedDate = '';
            let modifiedTimestamp = '';
              if(rowList?.createTimestamp) {
                modifiedDate = rowList?.createTimestamp?.split(' ')[0];
                modifiedTimestamp = rowList?.createTimestamp?.split(' ')[1];
              }
              rowList.modifiedDate = modifiedDate;
              rowList.modifiedTimestamp = !_.isEmpty(modifiedTimestamp)?modifiedTimestamp + " CST":modifiedTimestamp;
            },
          );
          dispatch({
            type: MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(errorMsg);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchSubsAuditTrailDataError() {
  return {
    type: MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_DISMISS_ERROR,
  };
}

export function useFetchSubsAuditTrailData() {
  const dispatch = useDispatch();

  const { subsAuditTrailData,fetchSubsAuditTrailDataPending, fetchSubsAuditTrailDataError } = useSelector(
    state => ({
      subsAuditTrailData: state.manageSubs.subsAuditTrailData,
      fetchSubsAuditTrailDataPending: state.manageSubs.fetchSubsAuditTrailDataPending,
      fetchSubsAuditTrailDataError: state.manageSubs.fetchSubsAuditTrailDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchSubsAuditTrailData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchSubsAuditTrailDataError());
  }, [dispatch]);

  return {
    subsAuditTrailData,
    fetchSubsAuditTrailData: boundAction,
    fetchSubsAuditTrailDataPending,
    fetchSubsAuditTrailDataError,
    dismissFetchSubsAuditTrailDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchSubsAuditTrailDataPending: true,
        fetchSubsAuditTrailDataError: null,
      };

    case MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchSubsAuditTrailDataPending: false,
        fetchSubsAuditTrailDataError: null,
        subsAuditTrailData: action.data,
      };

    case MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSubsAuditTrailDataPending: false,
        fetchSubsAuditTrailDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchSubsAuditTrailDataError: null,
      };

    default:
      return state;
  }
}
