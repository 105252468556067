import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_SAVE_SEARCH_CONDITION_DATA,
} from './constants';

export function saveSearchConditionData(data) {
  return {
    type: MANAGE_SUBS_SAVE_SEARCH_CONDITION_DATA,
    data,
  };
}

export function useSaveSearchConditionData() {
  const dispatch = useDispatch();
  const searchConditionData = useSelector(state => state.manageSubs.searchConditionData);
  const boundAction = useCallback((...params) => dispatch(saveSearchConditionData(...params)), [dispatch]);
  return { searchConditionData, saveSearchConditionData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_SAVE_SEARCH_CONDITION_DATA:
      return {
        ...state,
        searchConditionData: action.data,
      };

    default:
      return state;
  }
}
