import React from 'react';
import { CONST_REPORT_TABS_NEW_COLUMNS, CONST_REPORT_TABS_COLUMNS } from '../../common/reportsConstants';
import { CommonPage} from '../reports-new';
export default function ScInAction() {
//  const tableColumns = CONST_REPORT_TABS_COLUMNS.filter(item => item.key === 'SC in Action')[0];
  const tabKey = 'SC in Action';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].summary.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].summary.level;
  return (
    <div className="sc-in-action-sc-in-action">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

ScInAction.propTypes = {};
ScInAction.defaultProps = {};
