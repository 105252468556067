import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_BEGIN,
  MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_SUCCESS,
  MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_FAILURE,
  MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_DISMISS_ERROR,
} from './constants';
import { EXPORT_BOS_API_REQUEST_URL } from '../../../common/apiConfig';
export function uploadSubstitutions(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const user = getState().home.auth.user;
      const params = {
        userId: user.userId,
        emailId: user.email,
        userName: user.userName,
      }
      const doRequest = axios({
        url: `${EXPORT_BOS_API_REQUEST_URL}/uploadSubs`,
        method: 'post',
        params,
        data: args,
        headers: { "content-type": "multipart/form-data" },
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUploadSubstitutionsError() {
  return {
    type: MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_DISMISS_ERROR,
  };
}

export function useUploadSubstitutions() {
  const dispatch = useDispatch();

  const { uploadSubstitutionsPending, uploadSubstitutionsError } = useSelector(
    state => ({
      uploadSubstitutionsPending: state.manageSubs.uploadSubstitutionsPending,
      uploadSubstitutionsError: state.manageSubs.uploadSubstitutionsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(uploadSubstitutions(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUploadSubstitutionsError());
  }, [dispatch]);

  return {
    uploadSubstitutions: boundAction,
    uploadSubstitutionsPending,
    uploadSubstitutionsError,
    dismissUploadSubstitutionsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        uploadSubstitutionsPending: true,
        uploadSubstitutionsError: null,
      };

    case MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_SUCCESS:
      // The request is success
      return {
        ...state,
        uploadSubstitutionsPending: false,
        uploadSubstitutionsError: null,
      };

    case MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_FAILURE:
      // The request is failed
      return {
        ...state,
        uploadSubstitutionsPending: false,
        uploadSubstitutionsError: action.data.error,
      };

    case MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        uploadSubstitutionsError: null,
      };

    default:
      return state;
  }
}
