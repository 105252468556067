import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_BEGIN,
  REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_SUCCESS,
  REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_FAILURE,
  REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function fetchOosAccountCustomer(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "ascend",
          sortedBy: "site",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getOosAccountCustomer`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
        //   res.data={
        //     "data": [
        //         {
        //             "oos": 12,
        //             "lostSales": 149.6,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 12,
        //             "site": "056",
        //             "customerName": "10-SUPP & EQUIP",
        //             "customerNumber": "000018",
        //         },
        //         {
        //             "oos": 8,
        //             "lostSales": 729,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 27.27,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 3,
        //             "preShipAllQnty": 11,
        //             "site": "056",
        //             "customerName": "000019-SUPP & EQUIP",
        //             "customerNumber": "000019",
        //         },
        //         {
        //             "oos": 22,
        //             "lostSales": 873.07,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 22,
        //             "site": "056",
        //             "customerName": "000017-SUPP & EQUIP",
        //             "customerNumber": "000017",
        //         },
        //         {
        //             "oos": 12,
        //             "lostSales": 355.437,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 12,
        //             "site": "056",
        //             "customerName": "000016-SUPP & EQUIP",
        //             "customerNumber": "000016",
        //         }
        //     ],
        //     "totals": 4,
        //     "summary": {
        //         "oos": 299,
        //         "preShipAllSubs": 5.08,
        //         "lostSales": 15385.787,
        //         "preShipDirSubs": 0
        //     }
        // }
          dispatch({
            type: REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              customerSummary: res.data && res.data.summary
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOosAccountCustomerError() {
  return {
    type: REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR,
  };
}

export function useFetchOosAccountCustomer() {
  const dispatch = useDispatch();

  const { reportsCustomerData, reportsCustomerTotals, customerSummary,fetchOosAccountCustomerPending, fetchOosAccountCustomerError } = useSelector(
    state => ({
      reportsCustomerData: state.reports.reportsCustomerData,
      reportsCustomerTotals: state.reports.reportsCustomerTotals,
      customerSummary: state.reports.customerSummary,
      fetchOosAccountCustomerPending: state.reports.fetchOosAccountCustomerPending,
      fetchOosAccountCustomerError: state.reports.fetchOosAccountCustomerError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOosAccountCustomer(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOosAccountCustomerError());
  }, [dispatch]);

  return {
    reportsCustomerData,
    reportsCustomerTotals, 
    customerSummary,
    fetchOosAccountCustomer: boundAction,
    fetchOosAccountCustomerPending,
    fetchOosAccountCustomerError,
    dismissFetchOosAccountCustomerError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOosAccountCustomerPending: true,
        fetchOosAccountCustomerError: null,
      };

    case REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_SUCCESS:
      // The request is success
      return {
        ...state,
        reportsCustomerData: action.data.data,
        reportsCustomerTotals: action.data.totals,
        customerSummary: action.data.customerSummary,
        fetchOosAccountCustomerPending: false,
        fetchOosAccountCustomerError: null,
      };

    case REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOosAccountCustomerPending: false,
        fetchOosAccountCustomerError: action.data.error,
      };

    case REPORTS_FETCH_OOS_ACCOUNT_CUSTOMER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOosAccountCustomerError: null,
      };

    default:
      return state;
  }
}
