export const CONST_CREATE_GROUPS_AUDIT_TRAIL_TABLE_COLUMNS = [
    {
      title: 'Site',
      dataIndex: 'site',
      width: 80,
      blankWidth: 80,
      sorter: false,
      render: false,
    },
    {
      title: 'Account#',
      dataIndex: 'nationalId',
      width: 100,
      blankWidth: 100,
      sorter: false,
      render: false,
      extendRender: true,
    },
    {
      title: 'Log',
      dataIndex: 'log',
      width: 130,
      blankWidth: 130,
      sorter: false,
      render: false,
    },
    {
      title: 'Value Before Change',
      dataIndex: 'oldValue',
      width: 145,
      blankWidth: 145,
      sorter: false,
      render: false,
    },
    {
      title: 'Value After Change',
      dataIndex: 'newValue',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Log',
      dataIndex: 'userId',
      width: 200,
      blankWidth: 160,
      render: false,
      extendRender: true,
    },
  ];