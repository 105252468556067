import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Table, Pagination, Button, Modal, Badge } from 'antd';
import { ExportAlertModal } from '../common';
import { CONST_PAGINATION, DEFAULT_REPORT_MODAL_KEY_VALUE, SLASH_DATE_FORMAT } from '../../common/constants';
import { downloadFile } from '../../common/downloadReportFunc';
import {
  useFetchScInActionCustomerData, useFetchScInActionOrderData,
  useExportScInActionCustomerReport, useExportScInActionOrderReport,
  useFetchSummaryCustomerData, useFetchSummaryOrderData,
  useExportSummaryCustomerReport, useExportSummaryOrderReport,
  useResetReportsCustomerLevelData, useResetReportsOrderLevelData, useFetchSummaryDataFromChannelType, useFetchOrdersData,
  useExportOrdersReport, useResetReportsDataAccount, useResetReportsDataOrders,useExportSummaryReport,
} from './redux/hooks';
import {
  useFetchBusinessEventLogData, useFetchOrderTrailData,
  useResetOrderTrailData, useResetBusinessEventLogData,
} from '../order-trail/redux/hooks';
import { convertToFooterColumns, formatFormdata } from './formatFormdata';
import TableFooter from './TableFooter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import BusinessEventLogModal from './BusinessEventLogModal';
import { renderTableColumns } from '../../common/renderColumns';

export default function OrdersDetailsReportModal(props) {
  const {
    dashboardConditionData,
    territoryData,
    businessEventLogData,
    reportsDataAccount,
    reportsTotalsAccount,
    summaryAccount,
    reportsDataOrders,
    reportsTotalsOrders,
    summaryOrders,
    fetchSummaryDataFromChannelTypePending,
    fetchOrdersDataPending,
    exportSummaryReportPending,
    exportOrdersReportPending,
  } = useSelector(state => ({
    dashboardConditionData: state.dashboard.dashboardConditionData,
    territoryData: state.dashboard.territoryData,
    businessEventLogData: state.orderTrail.businessEventLogData,
    reportsDataAccount: state.reports.reportsDataAccount,
    reportsTotalsAccount: state.reports.reportsTotalsAccount,
    summaryAccount: state.reports.summaryAccount,
    reportsDataOrders: state.reports.reportsDataOrders,
    reportsTotalsOrders: state.reports.reportsTotalsOrders,
    summaryOrders: state.reports.summaryOrders,
    fetchSummaryDataFromChannelTypePending: state.reports.fetchSummaryDataFromChannelTypePending,
    fetchOrdersDataPending: state.reports.fetchOrdersDataPending,
    exportSummaryReportPending: state.reports.exportSummaryReportPending,
    exportOrdersReportPending: state.reports.exportOrdersReportPending,
    
  }));
  const { columns, tabKey, keyValue, isVisible, onToggleModal, onChangeReportModalKey, fromChannelType } = props;
  const [pagination, setPagination] = useState(CONST_PAGINATION);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const { resetReportsCustomerLevelData } = useResetReportsCustomerLevelData();
  const { resetReportsOrderLevelData } = useResetReportsOrderLevelData();
  const { fetchScInActionCustomerData } = useFetchScInActionCustomerData();
  const { fetchScInActionOrderData } = useFetchScInActionOrderData();
  const { exportScInActionCustomerReport } = useExportScInActionCustomerReport();
  const { exportScInActionOrderReport } = useExportScInActionOrderReport();
  const { fetchSummaryCustomerData } = useFetchSummaryCustomerData();
  const { fetchSummaryOrderData } = useFetchSummaryOrderData();
  const { exportSummaryCustomerReport } = useExportSummaryCustomerReport();
  const { exportSummaryOrderReport } = useExportSummaryOrderReport();
  const { resetOrderTrailData } = useResetOrderTrailData();
  const { resetBusinessEventLogData } = useResetBusinessEventLogData();
  const { fetchSummaryDataFromChannelType } = useFetchSummaryDataFromChannelType();
  const { fetchOrdersData } = useFetchOrdersData();
  const { exportOrdersReport } = useExportOrdersReport();
  const { resetReportsDataAccount } = useResetReportsDataAccount();
  const { resetReportsDataOrders } = useResetReportsDataOrders();
  const { exportSummaryReport } = useExportSummaryReport();
  let condition = formatFormdata(dashboardConditionData, territoryData);
  condition = {
    ...condition,
    customerNumber: keyValue.customerNumber || '',
    scId: (tabKey === 'SC in Action' && keyValue.scId) || '',
    accountType: keyValue.accountType || '',
    offDay: keyValue.offDaySales || parseInt(keyValue.offDaySales) === 0 ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
    channelType: keyValue.remoteOrderEntryMode || '', //parse remoteOrderEntryMode to channelType into param
    futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null, //bug fix:if Future Orders need pass futureOrders field
  };
  let title = '';
  if (tabKey === 'Orders' || tabKey === 'Future Orders') {
    if (keyValue.accountType) {
      title = `${tabKey} for Account Type - ${keyValue.accountType}`;
    } else if (keyValue.orderChannelType) {
      title = `${tabKey} for Channel Type - ${keyValue.orderChannelType}`;
    }
  }
  const dataSource = fromChannelType ? reportsDataAccount : reportsDataOrders;
  const dataTotals = fromChannelType ? reportsTotalsAccount : reportsTotalsOrders;
  const summaryData = fromChannelType ? summaryAccount : summaryOrders;
  const reportActionIndex = {
    'Orders': fromChannelType ? (data) => fetchSummaryDataFromChannelType(data) : (data) => fetchOrdersData(data),
    'Future Orders': fromChannelType ? (data) => fetchSummaryDataFromChannelType(data) : (data) => fetchOrdersData(data),
  };
  const exportReportActionIndex = {
    'Orders': fromChannelType ? (data) => exportSummaryReport(data) : data => exportOrdersReport(data),
    'Future Orders': fromChannelType ? (data) => exportSummaryReport(data) : data => exportOrdersReport(data),
  };

  useEffect(() => {
    if (!isVisible) {
      //reset state when close report modal
      setPagination(CONST_PAGINATION);
      setSorting();
      setShowAlert(false);
    } else {
      let formdata = {
        ...condition,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...sorting,
      }
      //fetch customer level report data, and update state
      reportActionIndex[tabKey](formdata);
    }
  }, [isVisible, pagination, sorting]); // eslint-disable-line
  const exportReports = () => {
    if (dataTotals > 5001) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      let sheetColConfig = [];
      columns.forEach(col => {
        let sheetColWidth = 10;
        if (col.width) {
          sheetColWidth = col.width / 10;
        }
        sheetColConfig.push({ wch: sheetColWidth });
      });
      // if (tabKey === 'Orders' || tabKey === 'Future Orders') {
      //   const sheetObj = [{
      //     headers: columns,
      //     data: businessEventLogData,
      //     sheetName: null,
      //     sheetColConfig,
      //   }];
      //   downloadFile(`${title} Report`, sheetObj);
      // } else {
        exportReportActionIndex[tabKey](condition).then(data => {
          const sheetObj = [{
            headers: columns,
            data,
            sheetName: null,
            sheetColConfig,
          }];
          downloadFile(`${title} Report`, sheetObj);
        });
      // }
    }
  };

  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
          sortedBy: sorter.field,
          sort: sorter.order,
        }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };

  const onPaginationChange = (page, changedPageSize) => {
    setShowAlert(false);
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
  };

  const handleAfterClose = () => {
    // if(tabKey === 'Orders' || tabKey === 'Future Orders') {
    resetOrderTrailData();
    resetBusinessEventLogData();
    if (fromChannelType) {
      // resetReportsCustomerLevelData();
      resetReportsDataAccount();
      onChangeReportModalKey({
        ...keyValue,
        remoteOrderEntryMode: null,
        orderChannelType: null,
      });
    } else {
      resetReportsDataOrders();
      onChangeReportModalKey({
        ...keyValue,
        accountType: null,
        offDaySales: null,
      });
    }

    // } else if(fromChannelType) {
    //   resetReportsCustomerLevelData();
    //   onChangeReportModalKey(DEFAULT_REPORT_MODAL_KEY_VALUE);
    // } else {
    //   resetReportsOrderLevelData();
    //   onChangeReportModalKey({
    //     ...keyValue,
    //     customerNumber: null,
    //   });
    // }
  }
  useEffect(() => {
    if (summaryData) {
      let parentScroll = document.querySelector('.ant-modal-body .report-table-account .ant-table-body');
      let footerScroll = document.querySelector('.ant-modal-body .report-table-account .ant-table-footer .ant-table-body');
      if(!fromChannelType){
        parentScroll = document.querySelector('.ant-modal-body .report-table-order .ant-table-body');
        footerScroll = document.querySelector('.ant-modal-body .report-table-order .ant-table-footer .ant-table-body')
      }
      if (parentScroll && footerScroll) {
        const scale = (parentScroll.scrollWidth - parentScroll.clientWidth) / (footerScroll.scrollWidth - footerScroll.clientWidth);
        let flag = 0;

        parentScroll.addEventListener('scroll', () => {
          if (flag !== 1) return;
          footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
        });
        footerScroll.addEventListener('scroll', () => {
          if (flag !== 2) return;
          parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
        });
        parentScroll.addEventListener('mouseover', () => {
          flag = 1;
        }, true);
        footerScroll.addEventListener('mouseover', () => {
          flag = 2;
        }, true);
        return () => {
          parentScroll.removeEventListener('scroll', () => {
            if (flag !== 1) return;
            footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
          });
          footerScroll.removeEventListener('scroll', () => {
            if (flag !== 2) return;
            parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
          });
          parentScroll.removeEventListener('mouseover', () => {
            flag = 1;
          }, true);
          footerScroll.removeEventListener('mouseover', () => {
            flag = 2;
          }, true);
        }
      }
    }
  }, [summaryData,fromChannelType]);
  //insert all index into summary data
  let footerDetailData = JSON.parse(JSON.stringify(summaryData));
  if (footerDetailData) {
    footerDetailData.totalIndex = true;
  }
  let footerDetailTableColumns = convertToFooterColumns(columns);
  const renderFooter = () => {
    if (summaryData && footerDetailData && !_.isEmpty(dataSource)) {
      return (
        <TableFooter tabKey={tabKey} summary={footerDetailData} columns={footerDetailTableColumns} isFromModal={true}/>
      )
    }
  }
  return (
    <Modal
      className="reports-details-report-modal"
      title={
        <>
          <div>{title}</div>
          <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
        </>
      }
      visible={isVisible}
      onCancel={onToggleModal}
      width={fromChannelType ? 1100 : 1000}
      destroyOnClose={true}
      footer={null}
      afterClose={handleAfterClose}
      maskClosable={false}
    >
      <div className="report-export-button-modal flex justify-end">
        <Button type="link" title="Export" style={{ marginLeft: '-20px' }} onClick={exportReports} disabled={_.isEmpty(dataSource)}>
          <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(dataSource)?"#a1a1a2":"#6b6b6c"} style={{ height: "20px", width: "20px" }} />
          <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: (_.isEmpty(dataSource) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
        </Button>
      </div>
      <Table
        rowKey={record => fromChannelType ? `${record.accountType}-${tabKey}` : `${record.customerNumber}-${record.orderNumber}-${tabKey}`}
        className={fromChannelType?"report-table report-table-account":"report-table report-table-order"}
        loading={fetchSummaryDataFromChannelTypePending || fetchOrdersDataPending 
          || exportSummaryReportPending || exportOrdersReportPending}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={onTableChange}
        scroll={{ x: 'max-content', y: 250 }}
        footer={renderFooter}
      />
      <div className="align-items-center justify-space-between">
        <Pagination
          style={{ textAlign: 'right', margin: '16px 0px' }}
          className={'report-pagination'}
          showSizeChanger
          current={pagination.pageIndex}
          pageSize={pagination.pageSize}
          total={dataTotals}
          pageSizeOptions={pagination.pageSizeOptions}
          onShowSizeChange={onPaginationChange}
          onChange={onPaginationChange}
        />
      </div>
    </Modal>

  );
}

OrdersDetailsReportModal.propTypes = {};
OrdersDetailsReportModal.defaultProps = {};