import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Row, Col, Carousel, Input, Select, message, Empty, Spin, Modal } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { ManageOutsProductCard } from '.';
import { STORAGE_CODE_LIST } from '../../common/manageOutsConstants';
import { useSearchSubstituteProducts, useResetSubstituteProducts, useUpdateSubstitutionsData, useFetchOutsData, useFetchDropoutsData, useResetOutsData, useResetDropoutsData, useCheckIfExists4thSubRule, } from './redux/hooks'
import { deleteOriginalItem, convertStorageCode } from './formattedSubsData'
import moment from 'moment';
import { DATE_TIME_TWO_FORMAT, SLASH_DATE_FORMAT, CONST_PAGINATION_TEN } from '../../common/constants';
import { datadogRum } from '@datadog/browser-rum';

export default function ManageOutsAddSub(props) {
  const { onCancel, initialValues, onSelectSub,} = props;
  const { 
    userName,
    userId,
    substituteProducts,
    searchSubstituteProductsPending,
    searchConditionData,
  } = useSelector(state => ({
    userName: state.home.auth.user.userName,
    userId: state.home.auth.user.userId,
    substituteProducts: state.manageSubs.substituteProducts,
    searchSubstituteProductsPending: state.manageSubs.searchSubstituteProductsPending,
    searchConditionData: state.manageSubs.searchConditionData,
  }));
  const { searchSubstituteProducts } = useSearchSubstituteProducts();
  const { resetSubstituteProducts } = useResetSubstituteProducts();
  const { updateSubstitutionsData } = useUpdateSubstitutionsData();
  const { fetchOutsData } = useFetchOutsData();
  const { fetchDropoutsData } = useFetchDropoutsData();
  const { resetOutsData } = useResetOutsData();
  const { resetDropoutsData } = useResetDropoutsData();
  const { checkIfExists4thSubRule } = useCheckIfExists4thSubRule();
  const [form] = Form.useForm();

  const CustomLabel = props => {
    const { value, suffix, prefix, name, itemVal } = props;
    let dataValue = (itemVal !== undefined && itemVal !== null && itemVal !== '') ? itemVal : value;
    if(name === "originalAsoh") {
      return (
        <>
          <label className="custom-form-item-value" style={{color:'#0071bc'}}>{dataValue}</label>
          <label className="custom-form-item-value" >{suffix}</label>
        </>
      )
    }else if(name === "originalPrice") {
      dataValue && prefix && (dataValue = `${prefix}${dataValue}`)
      return (
        <label className="custom-form-item-value" style={{color:'#db930b'}}>{dataValue}</label>
      );
    }else {
      dataValue && suffix && (dataValue = `${dataValue}${suffix}`)
      dataValue && prefix && (dataValue = `${prefix}${dataValue}`)
      return (
        <label className="custom-form-item-value">{dataValue}</label>
      );
    }
  }
  const [existsModalVisible,setExistsModalVisible] = useState(false);
  const [substituteData,setSubstituteData] = useState(null);
  const onToggleModal =()=>{
    setExistsModalVisible(false);
  }
  const clickSubstitute = (product) => {
    const timing = Date.now();
    const { supc, sysco_brand, item_description, pack, size, site_details,rank } = product
    let ratioDom = document.getElementById(`addSubRecommendedProducts_subRatio_${supc}`) || document.getElementById(`addSubSearchedProducts_subRatio_${supc}`)
    const subRatio = ratioDom.value;
    const reg = new RegExp('^(((?!0+(?:\.0+)?$)(([1-9]{1}\\d{0,2})|(0{1}))(\\.\\d{1,2}))|((?!0+$)[0-9]{1}\\d{0,2}))?$', 'g');
    const reg1 = new RegExp('^[^a-zA-Z]+$', 'g');
    const reg2=new RegExp('(^[0-9]+[\\.]{0,1}[0-9]*(?<!\\.)$)', 'g');
    if(subRatio == "" || subRatio == undefined) {
      message.warning('Please input Sub Ratio!')
      return
    }
    //defect:COT-4991,sub ratio can accept valid numeric values with 0 appended in the front (e.g.: 09,080,etc)
    if(!reg1.test(subRatio)) {
      message.warning('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
      return
    }else if(!reg.test(parseFloat(subRatio))) {
      message.warning('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
      return
    }else if(!reg2.test(subRatio)) {
      message.warning('Invalid input! The sub ratio should be a value in the range 0.01 to 999.99!')
      return
    }
    
    let endDate = form.getFieldValue(`endDate_${supc}`);
    if(initialValues.isFromOuts) {
      const { site, siteDesc, nationalId, nationalName, originalItem, originalItemDescription, originalItemUom, originalPack, originalSize,
              originalBrand, originalStorageCode, customerNumber, customerName, orderNumbers, searchText, callFrom, } = initialValues
      let params = {
        "site": site,
        "siteDesc": siteDesc,
        "nationalId": nationalId ? nationalId : "",
        "nationalName": nationalName ? nationalName : "",
        "originalItem": originalItem,
        "originalItemDescription": originalItemDescription,
        "originalUom": originalItemUom,
        "originalPack": originalPack,
        "originalSize": originalSize,
        "originalBrand": originalBrand,
        "originalStorageCode": originalStorageCode,
        "ruleType": "AS",
        "createdByName": userName,
        "createdById": userId,
        "createTimestamp": moment().format(DATE_TIME_TWO_FORMAT),
        "modifiedByName": userName,
        "modifiedById": userId,
        "modifiedTimestamp": moment().format(DATE_TIME_TWO_FORMAT),
        "customerNumber": customerNumber ? customerNumber : "",
        "customerName": customerName ? customerName : "",
        "expandedItems": [
          {
            "createTimestamp": moment().format(DATE_TIME_TWO_FORMAT),
            "createdById": userId,
            "createdByName": userName,
            "endDate": endDate ? moment(endDate).format(SLASH_DATE_FORMAT) : '',
            "modifiedById": userId,
            "modifiedByName": userName,
            "modifiedTimestamp": moment().format(DATE_TIME_TWO_FORMAT),
            "reasonCode": null,
            "reasonDescription": null,
            "subPriority": "1",
            "subRatio": subRatio,
            "substituteBrand": sysco_brand,
            "substituteItem": supc,
            "substituteItemDescription": item_description,
            "substitutePack": pack,
            "substituteSize": size,
            "substituteStorageCode": convertStorageCode(site_details[0].storageCode),
            "substituteUom": originalItemUom, //getUom(site_details[0])
            "ruleType": "sub"
          }
        ],
        "rank": rank,
        "deliveryDate": searchConditionData.deliveryDate,
        "callFrom": callFrom,
        "orders": orderNumbers,
        "customerNumbers": initialValues.customerNumbers, //if group,pass customerNumbers to web service for sync data to U5
      }
      //COT-7911,need pass orderLines parameter in updateSubstitutionsData api when Substitute,COT-7911,need pass orderLines parameter in updateSubstitutionsData api when Substitute,not only for dropOuts
      params = { ...params, "orderLines": initialValues.orderLines }
      //COT-8821:check if exists 4th sub rule firstly, if exists 4th sub rule will show replace/delete message,cancel button and proceed button.
      checkIfExists4thSubRule({
        "customerNumber": customerNumber ? customerNumber : "",
        "nationalId": nationalId ? nationalId : "",
        "originalItem": originalItem,
        "originalUom": originalItemUom,
        "site": site, 
      }).then(res=>{
        if(res?.exists4thSubs){
          //show replace message
          setSubstituteData(params);
          setExistsModalVisible(true);
          return;
        }else{
          updateSubstitutions(params);
        }
      });
    } else {
      onSelectSub(product, initialValues.label, initialValues.keyIndex, subRatio, endDate);
      onCancel();
    }
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Substitute Item`,timing);
    });
  }
  const updateSubstitutions=(params)=>{
    const {searchText,callFrom } = initialValues;
     updateSubstitutionsData(params).then(data => {
        message.success(`Update Substitutions Successfully.`);
        onCancel();
        if(searchConditionData) {
          const formData = {
            ...searchConditionData,
            pageIndex: CONST_PAGINATION_TEN.pageIndex,
            pageSize: CONST_PAGINATION_TEN.pageSize,
          };
          if(searchText) {
            formData.originalItem = searchText
          }
          //COT-8309:Latency issue when user drop out or search for subs in manage outs
          // formData.optimizationEnable = optimizationEnable;  //COT-8330
          if(callFrom === 'dropOuts') {
            resetDropoutsData()
            fetchDropoutsData(formData)
          }else {
            resetOutsData();
            fetchOutsData(formData);
          }
        }
      }).catch(err => {
        message.error(`Update Substitutions Failed, please try again.`);
      });
  }
  const productCardView = (recommendedProducts) => {
    let productsView = [];
    recommendedProducts.forEach((item) => {
      const subItem = item.supc;
      productsView.push(
        <div className='product-card' key={subItem}>
          <ManageOutsProductCard product={item} index={subItem} onSubstitute={clickSubstitute} originalItemUom={initialValues.originalItemUom} isFromOuts={initialValues.isFromOuts} deliveryDate={initialValues.deliveryDate} />
        </div>)
    })
    return productsView;
  }
  const grouprecommendedProducts = (array, subGroupLength) => {
    let productsView = productCardView(array);
    let newRecommendCards = [];
    let index = 0;
    // let newArray = [];
    while (index < productsView.length) {
      var childArr = productsView.slice(index, index += subGroupLength);
      if (childArr.length < subGroupLength) {
        var len = subGroupLength - childArr.length;
        for (let i = 0; i < len; i++) {
          childArr.push([]);
        }
      }
      newRecommendCards.push(childArr);
    }
    // recommendedLength=newRecommendCards.length-1;
    return newRecommendCards;
  }

  const onReset = () => {
    form.setFieldsValue({
      description: undefined,
      SUPC: undefined,
      pack: undefined,
      size: undefined,
      storage: undefined,
      brand: undefined,
    });
    resetSubstituteProducts();
  }
  let searchResults = substituteProducts ? 
        ((substituteProducts.data && substituteProducts.data.length) ? deleteOriginalItem(substituteProducts.data, initialValues.originalItem) : null) : null;
  let searchResultVisible= substituteProducts ? 
  ((substituteProducts.data && substituteProducts.data.length) ? "results" : "no-results-found"): "no-search";
  return (
    <div className="manage-subs-manage-outs-add-sub">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === 'addSubSearchForm' || name === 'addSubSearchedProducts') {
            const { addSubSearchForm } = forms;
            const inputInfo = {
              description: addSubSearchForm.getFieldValue("description") ? addSubSearchForm.getFieldValue("description").trim() : "",
              supc: addSubSearchForm.getFieldValue("SUPC") ? addSubSearchForm.getFieldValue("SUPC").trim() : "",
              pack: addSubSearchForm.getFieldValue("pack") ? addSubSearchForm.getFieldValue("pack").trim() : "",
              size: addSubSearchForm.getFieldValue("size") ? addSubSearchForm.getFieldValue("size").trim() : "",
              storage: addSubSearchForm.getFieldValue("storage"),
              brand: addSubSearchForm.getFieldValue("brand") ? addSubSearchForm.getFieldValue("brand").trim() : "",
            }
            if(!inputInfo.description && !inputInfo.supc) {
              message.warning('Please input Description or SUPC!')
            } else {
              const { site, originalItemUom, originalItem, nationalId, customerNumber, isFromOuts, totalOuts } = initialValues             
              let params = {
                "site": [site],
                "originalItemUom": originalItemUom,
                "originalItem": originalItem,
                "nationalId": nationalId,
                "customerNumber": customerNumber,
                "isFromOuts": isFromOuts,
                "totalOuts": totalOuts,
              }
              if(inputInfo.supc) {
                params["searchBy"] = "SUPC"
                params["searchInput"] = inputInfo.supc
              } else {
                params["searchBy"] = "Item Description"
                params["searchInput"] = inputInfo.description
              }
              if(inputInfo.pack) params['pack'] = inputInfo.pack
              if(inputInfo.size) params['size'] = inputInfo.size
              if(inputInfo.storage) params['storageCode'] = inputInfo.storage
              if(inputInfo.brand) params['brand'] = [inputInfo.brand]
              searchSubstituteProducts(params).catch(err => {
                message.error(`Search Substitutions Failed, please try again.`);
              });
            }
          }
        }}
      >

        <Form
          form={form}
          name="addSubBasic"
          autoComplete="off"
          initialValues={initialValues}
        >
          <Row gutter={4} justify="space-between" className="sub-item-header">
            <Col span={4}>
              <Form.Item className="static-form-item-value" label="Site ID" name="site">
                <CustomLabel />
              </Form.Item>
              <Form.Item className="tips-content-item site-tip" style={{'marginLeft':'50px'}} name="siteDesc">
                <CustomLabel />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item className="static-form-item-value" label="Group/Ship To" name="conceptId">
                <CustomLabel />
              </Form.Item>
              <Form.Item className="tips-content-item concept-tip" style={{'marginLeft':'92px'}} name="conceptName">
                <CustomLabel />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="static-form-item-value" label="Original Item" name="originalItem">
                <CustomLabel />
              </Form.Item>
              <div className='series-tips' style={{'marginLeft':'90px'}}>
                <div className="flex align-items-center series-tips-content-items">
                  <Form.Item className="tips-content-item" name="originalItemDescription">
                    <CustomLabel suffix=' ' />
                  </Form.Item>
                </div>
                <div className="flex align-items-center series-tips-content-items-1">
                  <Form.Item className="tips-content-item" name="originalItemUom">
                    <CustomLabel suffix=' | ' />
                  </Form.Item>
                  <Form.Item className="tips-content-item" name="originalPack">
                    <CustomLabel suffix='/' />
                  </Form.Item>
                  <Form.Item className="tips-content-item" name="originalSize">
                    <CustomLabel suffix=' | ' />
                  </Form.Item>
                  <Form.Item className="tips-content-item" name="originalBrand">
                    <CustomLabel suffix=' | ' />
                  </Form.Item>
                  <Form.Item className="tips-content-item" name="originalStorageCode">
                    <CustomLabel />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <Form.Item className="static-form-item-value" label="ASOH" name="originalAsoh">
                <CustomLabel name="originalAsoh" itemVal={initialValues.originalAsoh} suffix={` ${initialValues.originalItemUom}`} />
              </Form.Item>
              <Form.Item className="tips-content-item aosh-tip" style={{'marginLeft':'46px'}} name="originalPrice">
                <CustomLabel 
                //COT-5367,when the price fetch is failed, add a ‘Market Price’ label in place of the price
                prefix={initialValues.originalPrice === '---'?'':'$'} 
                name="originalPrice" 
                itemVal={initialValues.originalPrice === '---'?'Market Price':initialValues.originalPrice} />
              </Form.Item>
            </Col>
            {/* COT-5366, Show kosher for original item */}
            { initialValues.kosher && (
               <Col span={2}>
              <Form.Item className="static-form-item-value">
                <span>K</span>
              </Form.Item>
            </Col> 
            )}
            
          </Row>
        </Form>
        <div className='prodcuts-title'>Recommended Products</div>
        <Form form={form} name="addSubRecommendedProducts" className='add-sub-recommended-products-form'>
          {initialValues && initialValues.recommendedProducts && initialValues.recommendedProducts.length > 0 ?
            <Carousel dots={false} arrows={true} infinite={false} prevArrow={<LeftOutlined/>} nextArrow={<RightOutlined/>} /* afterChange={onChange} */>
              {grouprecommendedProducts(initialValues.recommendedProducts, 3).map((item, index) =>
                <div className='grouped-product-card' key={index}>
                  {item}
                </div>
              )}
            </Carousel>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Recommended Substitution found" />
          }
        </Form>
        <div className='prodcuts-title'> Additional Search</div>
         
        
        <Form form={form} name="addSubSearchForm" className='add-sub-search-form flex'>
          <Row gutter={27}>
            <Col>
              <Form.Item name="description">
                <Input placeholder="Description" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="pack">
                <Input placeholder="Pack" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="size">
                <Input placeholder="Size" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="brand">
                <Input placeholder="Brand" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="storage">
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  placeholder="Storage"
                // onChange={value => handleSelectChange(value, 'storageCode')}
                >
                  {STORAGE_CODE_LIST.map(item => {
                    return (
                      <Select.Option key={item.label} value={item.label}>
                        {item.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="SUPC">
                <Input placeholder="SUPC" />
              </Form.Item>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <Form.Item>
                <Row>
                  <Col>
                    <Button htmlType="button" onClick={onReset} className="default-btn">
                      Reset
                    </Button>
                  </Col>
                  <Col offset={1}>
                    <Button htmlType="submit" type="primary" className="primary-btn">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>   
        {searchResultVisible==="results" && (
        <div className='search-prodcut'>  
         <div className="prodcuts-title">
           Results
         </div>
         <Form form={form} name="addSubSearchedProducts" className='add-sub-recommended-products-form'>
         <Spin spinning={searchSubstituteProductsPending}>
           {searchResults && searchResults.length > 0 && (
             <Carousel dots={false} infinite={false} arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
               {grouprecommendedProducts(searchResults, 3).map((item, index) =>
                 <div className='grouped-product-card' key={index}>
                   {item}
                 </div>
               )}
             </Carousel>)
           }
         </Spin>
         </Form>
       </div>
       )}
        {searchResultVisible==="no-results-found" && (
          <Spin spinning={searchSubstituteProductsPending}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Results found" />
          </Spin>
       )}
        
      </Form.Provider>
      <Modal
        className='manage-subs-manage-outs-operate-modal'
        visible={existsModalVisible}
        onCancel={onToggleModal}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
        width={520}
      >
        <p style={{ marginBottom: '2px' }}>There are four subrules present for this original item. Adding the 5th sub rule will delete/replace the 4th subrule. Do you want to continue?</p>
        <div className="subs-footer flex justify-end">
          <Button id="cancelbutton" className="default-btn" onClick={onToggleModal} style={{ marginLeft: "2px" }}>
            Cancel
          </Button>
          <Button id="okbutton" onClick={() => updateSubstitutions(substituteData)} type="primary" className='primary-btn' style={{ marginLeft: "2px" }}>
            Proceed
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ManageOutsAddSub.propTypes = {};
ManageOutsAddSub.defaultProps = {};
