import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorPage } from '../common';
import { TerritoryFilters } from '../dashboard';
import { AUTH_ERROR_MSG } from '../../common/constants';
import { redirectUrl } from '../../common/apiConfig';
import { ReportTabs } from './';
import { Row, Col, Spin, Layout } from 'antd';


export default function Reports() {
  const {
    fetchScInActionDataError,
    exportScInActionReportDataError,
    fetchScInActionCustomerDataError,
    fetchScInActionOrderDataError,
    exportScInActionCustomerReportError,
    exportScInActionOrderReportError,
    fetchSummaryDataError,
    exportSummaryReportError,
  } = useSelector(state => ({
    fetchScInActionDataError: state.reports.fetchScInActionDataError,
    exportScInActionReportDataError: state.reports.exportScInActionReportDataError,
    fetchScInActionCustomerDataError: state.reports.fetchScInActionCustomerDataError,
    fetchScInActionOrderDataError: state.reports.fetchScInActionOrderDataError,
    exportScInActionCustomerReportError: state.reports.exportScInActionCustomerReportError,
    exportScInActionOrderReportError: state.reports.exportScInActionOrderReportError,
    fetchSummaryDataError: state.reports.fetchSummaryDataError,
    exportSummaryReportError: state.reports.exportSummaryReportError,
  }));
  const isErrorPage = fetchScInActionDataError || fetchSummaryDataError || exportScInActionReportDataError ||
  fetchScInActionCustomerDataError || fetchScInActionOrderDataError || exportScInActionCustomerReportError ||
  exportScInActionOrderReportError || exportSummaryReportError;
  if (fetchScInActionDataError === AUTH_ERROR_MSG ||
    fetchScInActionCustomerDataError === AUTH_ERROR_MSG ||
    fetchScInActionOrderDataError === AUTH_ERROR_MSG ||
    fetchSummaryDataError === AUTH_ERROR_MSG ) {
    window.location.assign(redirectUrl);
  } else if (isErrorPage) {
    return <ErrorPage />;
  }
  return (
    <div className="reports-reports">
      <Spin
        size="large"
        tip="Loading..."
        spinning={false}
      >
      <Layout>
        <Layout.Sider width="220" theme="light">
          <TerritoryFilters />
        </Layout.Sider>
        <Layout.Content className="sub-layout-content">
        <Row className="chart-panel">
          <Col className="chart-item" span={23}>
            <div className="chart-item-panel">
              <div className="chart-title font-size-16-bold"></div>
              <ReportTabs />
            </div>
          </Col>
        </Row>
        </Layout.Content>
        </Layout>
      </Spin>
    </div>
  );
};

Reports.propTypes = {};
Reports.defaultProps = {};
