import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_BEGIN,
    MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_SUCCESS,
    MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_FAILURE,
    MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function allocateOriginalItem(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/Publish`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          const data = res.data;
          dispatch({
            type: MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_SUCCESS,
            data: data,
          });
          resolve(data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissAllocateOriginalItemError() {
  return {
    type: MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_DISMISS_ERROR,
  };
}

export function useAllocateOriginalItem() {
  const dispatch = useDispatch();

  const { allocateOriginalItemPending, allocateOriginalItemError } = useSelector(
    state => ({
        allocateOriginalItemPending: state.manageSubs.allocateOriginalItemPending,
        allocateOriginalItemError: state.manageSubs.allocateOriginalItemError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(allocateOriginalItem(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissAllocateOriginalItemError());
  }, [dispatch]);

  return {
    allocateOriginalItem: boundAction,
    allocateOriginalItemPending,
    allocateOriginalItemError,
    dismissAllocateOriginalItemError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        allocateOriginalItemPending: true,
        allocateOriginalItemError: null,
      };

    case MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_SUCCESS:
      // The request is success
      return {
        ...state,
        allocateOriginalItemPending: false,
        allocateOriginalItemError: null,
      };

    case MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_FAILURE:
      // The request is failed
      return {
        ...state,
        allocateOriginalItemPending: false,
        allocateOriginalItemError: action.data.error,
      };

    case MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        allocateOriginalItemError: null,
      };

    default:
      return state;
  }
}
