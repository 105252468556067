import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components';
import { BarChart, PictorialBarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import moment from 'moment';
import { COMMON_DATE_FORMAT } from '../../common/constants';
import { useTranslation } from "react-i18next";


export default function OrderInflowTrend(props) {
  echarts.use([
    DatasetComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    PictorialBarChart,
    CanvasRenderer,
  ]);

  const { t } = useTranslation();
  const { orderTrendSummaryData, salesTrendSummaryData, detailsCondition } = useSelector(state => ({
    orderTrendSummaryData: state.dashboard.orderTrendSummaryData,
    salesTrendSummaryData: state.dashboard.salesTrendSummaryData,
    detailsCondition: state.dashboard.detailsCondition,
  }));

  const { isInverse } = props;
  const formattedDataSource = (data, label) => {
    let filterDate = detailsCondition && detailsCondition.deliveryDate;
    if(label === '1week') {
      filterDate = Number(moment(filterDate,COMMON_DATE_FORMAT).subtract(7, 'days').format(COMMON_DATE_FORMAT));
    } else if(label === '2week') {
      filterDate = Number(moment(filterDate,COMMON_DATE_FORMAT).subtract(14, 'days').format(COMMON_DATE_FORMAT));
    }
    if(data) {
      const filterData = data.filter(item => item.deliveryDate === filterDate);
      if(filterData && filterData.length > 0) {
        const values = Object.values(filterData[0]);
        return values.splice(0, values.length - 1);
      }
      return [];
    }
    return [];
  }
  const initChart = (dataSource) => {
    let chartDom = document.getElementById('order-inflow-trend');
    let myChart = echarts.init(chartDom);
    let option = {
      legend: {
        left: '5%',
        itemGap: 20,
        itemWidth: 8,
        itemHeight: 8,
        icon:'circle',
        textStyle: {
          fontSize: 12,
          fontFamily: 'MyriadPro-Bold, sans-serif',
        },
      },
      tooltip: {},
      grid: {
        left: "3%",
        right: "3%",
        bottom: "13%",
        top: "16%",
      },
      xAxis: {
        data: [t("shopOrders"), t("sMX/SourceOrders"), t("eDIOrders"), t("otherChannels")],
        axisLabel: {
          interval: 0,
          margin: 10,
          fontWeight: 600,
          fontFamily: 'MyriadPro, sans-serif',
          boundaryGap: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        show: false,
        axisLabel: {
          formatter: function (value, index) {
            if(value > 10000) {
              return (value/1000) + 'K';
            }
            return value;
          }
        }
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {
          name: t("today"),
          type: 'bar',
          data: dataSource && formattedDataSource(dataSource, 'today'),
          symbol: 'rect',
          itemStyle: {
            color: '#0070D2',
          },
          symbolRepeat: true,
          symbolSize: [20, 5],
          symbolMargin: 2,
          barWidth: "21px",
        },
        {
          name: t("1week"),
          type: 'bar',
          data: dataSource && formattedDataSource(dataSource, '1week'),
          symbol: 'rect',
          itemStyle: {
            color: '#1B4F72',
          },
          symbolRepeat: true,
          symbolSize: [20, 5],
          symbolMargin: 2,
          barWidth: "21px",
        },
        {
          name: t("2week"),
          type: 'bar',
          data: dataSource && formattedDataSource(dataSource, '2week'),
          symbol: 'rect',
          itemStyle: {
            color: '#56B146',
          },
          symbolRepeat: true,
          symbolSize: [20, 5],
          symbolMargin: 2,
          barWidth: "21px",
          barGap: "20%",
        },
      ],
    };
    option && myChart.setOption(option);
    window.addEventListener('resize', () => {      
      myChart.resize();
    });
  };

  useEffect(() => {
    const dataSource = isInverse ? salesTrendSummaryData : orderTrendSummaryData;
    initChart(dataSource);
  }, [isInverse, salesTrendSummaryData, orderTrendSummaryData]);  // eslint-disable-line

  return (
    <div className="dashboard-order-inflow-trend">
      <div id="order-inflow-trend" />
    </div>
  );
}

OrderInflowTrend.propTypes = {};
OrderInflowTrend.defaultProps = {};
