import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Descriptions, Table, Spin } from 'antd';
import { renderTableColumns } from '../../common/renderColumns';
import { CONST_OUTS_ORDER_DETAIL_TABLE_COLUMNS } from '../../common/manageOutsConstants';
import _ from 'lodash';
import { ManageOutsAddSub } from '.';
import { deleteOriginalItem } from './formattedSubsData'
import { useIdleTimer } from 'react-idle-timer';

export default function ManageOutsOperateModal(props) {
  const { 
    orderDetail,
    inventoryData,
    recommendedProducts,
    fetchOrderDetailDataPending,
    fetchInventoryDataPending,
    fetchRecommendedProductsPending,
    updateSubstitutionsDataPending,
    fetchInventoryDataError,
  } = useSelector(state => ({
    orderDetail: state.manageSubs.orderDetail,
    inventoryData: state.manageSubs.inventoryData,
    recommendedProducts: state.manageSubs.recommendedProducts,
    fetchOrderDetailDataPending: state.manageSubs.fetchOrderDetailDataPending,
    fetchInventoryDataPending: state.manageSubs.fetchInventoryDataPending,
    fetchRecommendedProductsPending: state.manageSubs.fetchRecommendedProductsPending,
    updateSubstitutionsDataPending: state.manageSubs.updateSubstitutionsDataPending,
    fetchInventoryDataError: state.manageSubs.fetchInventoryDataError,
  }));

  const { title, isModalVisible, footer, width, onToggleModal, cancelText, okText, onTableChange, baseData, onSelectSub, idle, } = props;
  const onIdle = ()=> {
    idle()
    onToggleModal()
  }
  const [idleTime]= useState(1000*60*5);
  const idleTimer = useIdleTimer({ onIdle,timeout: idleTime, })
  if(props.fromSubs || title === 'VIEW ORDER DETAILS' || title === 'VIEW INVENTORY DETAILS'){
    idleTimer.pause();
  }
  const renderParentElement = [
    { //render conceptID
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.conceptName}</div>
          </>
        )
      }
    },
    { //render originalItem
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">
              {
                `${record.orignalItemDescription}`
              }
            </div>
            <div className="col-desc">
              {
                `${record.orignaItemUOM} | ${record.orignalItmpack} / ${record.orignalItemsize} | ${record.orignalItemBrand} | ${record.orignalItemStorageCode}`
              }
            </div>
          </>
        )
      }
    },
    { //render shipTo
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.customerShipToName}</div>
          </>
        )
      }
    },
    //orderNo placeholder
    {},
    //orderLine placeholder
    {},
    //orderQuantity placeholder
    {},
    // allocateQuantity placeholder
    {},
    //outQuantity placeholder
    {},
  ];
  let orderDetailParentColumns = renderTableColumns(CONST_OUTS_ORDER_DETAIL_TABLE_COLUMNS, null, renderParentElement);
  const modalClassName = title === 'ADDSUB' ? 'manage-subs-manage-outs-add-sub manage-subs-manage-outs-operate-modal' : 'manage-subs-manage-outs-operate-modal';
  let initialValues = {
    ...baseData,
    "originalAsoh": recommendedProducts ? recommendedProducts.originalAsoh : "",
    "originalPrice": recommendedProducts ? recommendedProducts.originalPrice : "",
    "recommendedProducts": recommendedProducts ? 
          ((recommendedProducts.data && recommendedProducts.data.length) ? deleteOriginalItem(recommendedProducts.data, baseData.originalItem) : null) : null,
  };
  return (
    <Modal
      className={modalClassName}
      title={title}
      visible={isModalVisible}
      onCancel={onToggleModal}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={footer || true}
      width={width || 520}
    >
      <Spin spinning={fetchOrderDetailDataPending || fetchInventoryDataPending || fetchRecommendedProductsPending || updateSubstitutionsDataPending}>
        {title === 'ADDSUB' &&  //this header shows just for Add screen 
          <ManageOutsAddSub
            // footer={footer}
            // cancelText={cancelText}
            // okText={okText}
            onCancel={onToggleModal}
            onSelectSub={onSelectSub}
            initialValues={initialValues}
            // optimizationEnable={optimizationEnable} //COT-8330
          />
        }
        {title === 'VIEW INVENTORY DETAILS' && Array.prototype.isPrototypeOf(inventoryData) && !_.isEmpty(inventoryData)? //this header shows just for inventory screen 
          <div style={{ height: 350 }} className='outs-inventory'>
            <Descriptions bordered column={2} size='small'>
              <Descriptions.Item label="Original Item" span={2}>
                <div className='col-static-value'>{inventoryData[0].originalItemNumber}</div>
                <div className="col-desc">
                  {
                    `${inventoryData[0].orignalitemDescription}` 
                  }
                </div>
                <div className="col-desc">
                  {
                `${inventoryData[0].orignaItemUOM} | ${inventoryData[0].orignalItemPack} / ${inventoryData[0].orignalItemSize} | ${inventoryData[0].orignalItemBrand} | ${inventoryData[0].orignalItemstorageCode}`
                  }
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="ASOH" >{inventoryData[0].asoh}</Descriptions.Item>
              <Descriptions.Item label="Quantity On Hand">{inventoryData[0].quantityOnHand}</Descriptions.Item>

              <Descriptions.Item label="Next Receive Date">{inventoryData[0].nextReceiveDate}</Descriptions.Item>
              {/* <Descriptions.Item label="Quantity On Order">{inventoryData[0].quantityOnOrder}</Descriptions.Item> */}
              <Descriptions.Item label="Quantity On Order">NA</Descriptions.Item>

              <Descriptions.Item label="Next Rec Quantity">{inventoryData[0].nextReceiveQuantity}</Descriptions.Item>
              {/* <Descriptions.Item label="Quantity On Allocated">{inventoryData[0].quantityOnAllocated}</Descriptions.Item> */}
              <Descriptions.Item label="Quantity On Allocated">NA</Descriptions.Item>
              <Descriptions.Item label="Next Rec PO#">{inventoryData[0].nextPoNumber}</Descriptions.Item>
              {/* <Descriptions.Item label="Quantity On Hold">{inventoryData[0].quantityOnHold}</Descriptions.Item> */}
              <Descriptions.Item label="Quantity On Hold">NA</Descriptions.Item>

              <Descriptions.Item label=""></Descriptions.Item>
              {/* <Descriptions.Item label="Quantity On Outside Storage">{inventoryData[0].quantityOnOutsideStorage}</Descriptions.Item> */}
              <Descriptions.Item label="Quantity On Outside Storage">NA</Descriptions.Item>
            </Descriptions>
          </div>:title === 'VIEW INVENTORY DETAILS' && !_.isEmpty(fetchInventoryDataError)?"404 NOT_FOUND \"ITEM NOT FOUND.\"":null
        }
        {title === 'VIEW ORDER DETAILS' && !_.isEmpty(orderDetail)? //this header shows just for order details screen 
          <Table
            rowKey={record => `${record.orderNumber}-${record.orderLine}`}
            className="outs-order-detail-table"
            loading={fetchOrderDetailDataPending}
            bordered={false}
            columns={orderDetailParentColumns}
            dataSource={orderDetail}
            pagination={false}
            onChange={onTableChange}
          // scroll={{ x: 'max-content', y: 290 }}
          />
          :title === 'VIEW ORDER DETAILS' && _.isEmpty(orderDetail)?"404 NOT_FOUND \"ORDER NOT FOUND.\"":null
        }
      </Spin>
    </Modal>
  );
}
