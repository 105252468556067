import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { TagCard } from './';
import { EXCEPTIONS_DETAILS_COLUMNS } from '../../common/chartConstants';
import _ from 'lodash';
import { useTranslation } from "react-i18next";

export default function ExceptionsDetails(props) {

  const { t } = useTranslation();
  const { salesSummaryData } = useSelector(state => ({
    salesSummaryData: state.dashboard.salesSummaryData,
  }));
  const showExceptionsDetails = (label, totals) => {
    const { handleHealthCheckLabelClick } = props;
    if(!disabledBtn(label)) {
      handleHealthCheckLabelClick(label, totals);
    }
  }
  const disabledBtn = (label) => {
    if(salesSummaryData) {
      return _.isNull(salesSummaryData[label]) || salesSummaryData[label] === 0;
    }
    return true;
  }
  const renderTagCardItems = (startIndex, renderSize) => {
    return Object.keys(EXCEPTIONS_DETAILS_COLUMNS).map((key, index) => {
      if(index >= startIndex && index < startIndex + renderSize) {
        const title = EXCEPTIONS_DETAILS_COLUMNS[key];
        let className='primary-card';
        if(title === 'Errored Lines') className='orange-card';
        if(salesSummaryData.hasOwnProperty(key)) {
          const count = salesSummaryData[key];
          return (
            <Col key={key} span={5} className="exceptions-details-row">
              <TagCard title={t(`EXCEPTIONS_DETAILS_COLUMNS.${key}`)} className={className} count={count} onClick={() => showExceptionsDetails(key, count)}/>
            </Col>
            );
        }
      }
      return null;
    });
  }
  return (
    <div className="dashboard-exceptions-details">
      <Row justify="flex-start">
          {renderTagCardItems(0,3)}
      </Row>
    </div>
  );
};

ExceptionsDetails.propTypes = {};
ExceptionsDetails.defaultProps = {};
