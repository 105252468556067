import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_RESET_CONCEPT_IDS_DATA,
} from './constants';

export function resetConceptIdsData() {
  return {
    type: DASHBOARD_RESET_CONCEPT_IDS_DATA,
  };
}

export function useResetConceptIdsData() {
  const dispatch = useDispatch();
  const conceptData = useSelector(state => state.dashboard.conceptData);
  const boundAction = useCallback(() => dispatch(resetConceptIdsData()), [dispatch]);
  return { conceptData, resetConceptIdsData: boundAction };
}

export function reducer(state, action) {
  if(action.type===DASHBOARD_RESET_CONCEPT_IDS_DATA){
    return {
      ...state,
      conceptData: null,
    };

  }
  
      return state;
  
}
