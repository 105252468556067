import React from 'react';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
import { CommonPage } from '../reports-new';
// import PropTypes from 'prop-types';

export default function ScOrder() {
  const tabKey = 'SC in Action';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.level;
  return (
    <div className="sc-in-action-sc-order">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

ScOrder.propTypes = {};
ScOrder.defaultProps = {};
