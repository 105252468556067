import { Button, Table, Badge, Pagination, Empty, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from './Header';
import ReportsFilter from './ReportsFilter';
import TotalPanel from './TotalPanel';
import { renderTableColumns } from '../../common/renderColumns';
import { CONST_PATH_LEVEL_MAPPING } from '../../common/reportsConstants'
import { convertToFooterColumns, formatFormdata, renderMergedColumn, returnFloat } from './formatData';
import _ from 'lodash';
import { CONST_PAGINATION_TEN, REPORTS_HEADER_TITLE_SUMMARY } from '../../common/constants';
import { useFetchSummaryData, useExportSummaryReport, useResetReportsData, 
  useFetchOrdersReportByChannelType, useExportOrdersReportByChannelType,
  useFetchScInActionData ,useExportScInActionReportData,useFetchSummaryCustomerData,useFetchSummaryOrderData,
  useFetchSummaryDataFromChannelType,useFetchOrdersData,useExportOrdersReport,
  useFetchScInActionCustomerData,useFetchScInActionOrderData,useExportSummaryCustomerReport,useExportSummaryOrderReport,
  useExportScInActionCustomerReport,useExportScInActionOrderReport, useFetchOrderDetailReport, useExportOrderDetailReport,
  useFetchSummaryDataByRegion, useExportSummaryRegionReport,
  useFetchOosReportBySite,useFetchOosReportByCategory,useFetchOosReportByOrderNumber,useExportOosReportBySite,useExportOosReportByCategory,useExportOosReportByOrderNumber, 
  useExportOosAccountAccountType, useExportOosAccountCustomer, useExportOosAccountOrder,
  useFetchOosAccountAccountType, useFetchOosAccountCustomer, useFetchOosAccountOrder,
} from '../reports/redux/hooks';
import TableFooter from '../reports/TableFooter';
import { downloadFile } from '../../common/downloadReportFunc';
import { ExportAlertModal } from '../common';
import ExportButton from './ExportButton';
import { useSaveReportsKeyValue } from './redux/hooks';
import { datadogRum } from '@datadog/browser-rum';
import { resetReportsCustomerLevelData } from '../reports/redux/resetReportsCustomerLevelData';
import { resetReportsDataOrders } from '../reports/redux/resetReportsDataOrders';
import { useTranslation } from "react-i18next";

export default function CommonPage(props) {
  const { t } = useTranslation();
  const {
    dashboardConditionData,
    territoryData,
    reportsKeyValue,
    fetchScInActionDataPending,
    fetchSummaryDataPending,
    fetchOrdersReportByChannelTypePending,
    exportSummaryReportPending,
    exportOrdersReportByChannelTypePending,
    exportScInActionReportDataPending,
    reportsData,
    reportsTotals,
    summary,
    reportsCustomerData,
    reportsCustomerTotals,
    customerSummary,
    reportsOrderData,
    reportsOrderTotals,
    orderSummary,
    reportsOrderDetailData,
    reportsOrderDetailTotals,
    orderDetailSummary,
    fetchSummaryCustomerDataPending,
    fetchSummaryOrderDataPending,
    exportSummaryCustomerReportPending,
    exportSummaryOrderReportPending,
    fetchScInActionCustomerDataPending,
    fetchScInActionOrderDataPending,
    exportScInActionCustomerReportPending,
    exportScInActionOrderReportPending,
    fetchSummaryDataFromChannelTypePending,
    fetchOrdersDataPending,
    exportOrdersReportPending,
    fetchOrderDetailReportPending,
    exportOrderDetailReportPending,
    reportsRegionData,
    reportsRegionTotals,
    regionSummary,
    isSiteUser,
    fetchSummaryDataByRegionPending,
    exportSummaryRegionReportPending,

    fetchOosReportBySitePending,
    fetchOosReportByCategoryPending,
    fetchOosReportByOrderNumberPending,
    exportOosReportBySitePending,
    exportOosReportByCategoryPending,
    exportOosReportByOrderNumberPending,
    fetchOosAccountAccountTypePending,
    fetchOosAccountCustomerPending,
    fetchOosAccountOrderPending,
    exportOosAccountAccountTypePending,
    exportOosAccountCustomerPending,
    exportOosAccountOrderPending,
  } = useSelector(state => ({
    dashboardConditionData: state.dashboard.dashboardConditionData,
    territoryData: state.dashboard.territoryData,
    reportsKeyValue: state.reportsNew.reportsKeyValue,
    fetchScInActionDataPending:state.reports.fetchScInActionDataPending,
    fetchSummaryDataPending: state.reports.fetchSummaryDataPending,
    fetchOrdersReportByChannelTypePending: state.reports.fetchOrdersReportByChannelTypePending,
    exportSummaryReportPending: state.reports.exportSummaryReportPending,
    exportOrdersReportByChannelTypePending: state.reports.exportOrdersReportByChannelTypePending,
    exportScInActionReportDataPending: state.reports.exportScInActionReportDataPending,
    reportsData: state.reports.reportsData,
    reportsTotals: state.reports.reportsTotals,
    summary: state.reports.summary,
    reportsCustomerData: state.reports.reportsCustomerData,
    reportsCustomerTotals: state.reports.reportsCustomerTotals,
    customerSummary: state.reports.customerSummary,
    reportsOrderData: state.reports.reportsOrderData,
    reportsOrderTotals: state.reports.reportsOrderTotals,
    orderSummary: state.reports.orderSummary,
    reportsOrderDetailData: state.reports.reportsOrderDetailData,
    reportsOrderDetailTotals: state.reports.reportsOrderDetailTotals,
    orderDetailSummary: state.reports.orderDetailSummary,
    fetchSummaryCustomerDataPending: state.reports.fetchSummaryCustomerDataPending,
    fetchSummaryOrderDataPending: state.reports.fetchSummaryOrderDataPending,
    exportSummaryCustomerReportPending: state.reports.exportSummaryCustomerReportPending,
    exportSummaryOrderReportPending: state.reports.exportSummaryOrderReportPending,
    fetchScInActionCustomerDataPending: state.reports.fetchScInActionCustomerDataPending,
    fetchScInActionOrderDataPending: state.reports.fetchScInActionOrderDataPending,
    exportScInActionCustomerReportPending: state.reports.exportScInActionCustomerReportPending,
    exportScInActionOrderReportPending: state.reports.exportScInActionOrderReportPending,
    fetchSummaryDataFromChannelTypePending: state.reports.fetchSummaryDataFromChannelTypePending,
    fetchOrdersDataPending: state.reports.fetchOrdersDataPending,
    exportOrdersReportPending: state.reports. exportOrdersReportPending,
    fetchOrderDetailReportPending: state.reports.fetchOrderDetailReportPending,
    exportOrderDetailReportPending: state.reports.exportOrderDetailReportPending,
    reportsRegionData: state.reports.reportsRegionData,
    reportsRegionTotals: state.reports.reportsRegionTotals,
    regionSummary: state.reports.regionSummary,
    isSiteUser: state.dashboard.isSiteUser,
    fetchSummaryDataByRegionPending: state.reports.fetchSummaryDataByRegionPending,
    exportSummaryRegionReportPending: state.reports.exportSummaryRegionReportPending,
    fetchOosReportBySitePending: state.reports.fetchOosReportBySitePending,
    fetchOosReportByCategoryPending: state.reports.fetchOosReportByCategoryPending,
    fetchOosReportByOrderNumberPending: state.reports.fetchOosReportByOrderNumberPending,
    exportOosReportBySitePending: state.reports.exportOosReportBySitePending,
    exportOosReportByCategoryPending: state.reports.exportOosReportByCategoryPending,
    exportOosReportByOrderNumberPending: state.reports.exportOosReportByOrderNumberPending,
    fetchOosAccountAccountTypePending: state.reports.fetchOosAccountAccountTypePending,
    fetchOosAccountCustomerPending: state.reports.fetchOosAccountCustomerPending,
    fetchOosAccountOrderPending: state.reports.fetchOosAccountOrderPending,
    exportOosAccountAccountTypePending: state.reports.exportOosAccountAccountTypePending,
    exportOosAccountCustomerPending: state.reports.exportOosAccountCustomerPending,
    exportOosAccountOrderPending: state.reports.exportOosAccountOrderPending,
  }));
  const {tabKey,columnsProp,level}=props;
  const { fetchSummaryData } = useFetchSummaryData();
  const { resetReportsData } = useResetReportsData();
  const { saveReportsKeyValue } = useSaveReportsKeyValue();
  const {fetchOrdersReportByChannelType} = useFetchOrdersReportByChannelType();
  const {exportOrdersReportByChannelType} = useExportOrdersReportByChannelType();
  const {fetchScInActionData} = useFetchScInActionData();
  const { fetchSummaryCustomerData } = useFetchSummaryCustomerData();
  const { fetchSummaryOrderData } = useFetchSummaryOrderData();
  const { fetchSummaryDataFromChannelType } = useFetchSummaryDataFromChannelType();
  const { fetchOrdersData } = useFetchOrdersData();
  const { exportOrdersReport } = useExportOrdersReport();
  const { fetchScInActionCustomerData } = useFetchScInActionCustomerData();
  const { fetchScInActionOrderData } = useFetchScInActionOrderData();
  const { exportSummaryReport } = useExportSummaryReport();
  const { exportSummaryCustomerReport } = useExportSummaryCustomerReport();
  const { exportSummaryOrderReport } = useExportSummaryOrderReport();
  const { exportScInActionReportData } = useExportScInActionReportData();
  const { exportScInActionCustomerReport } = useExportScInActionCustomerReport();
  const { exportScInActionOrderReport } = useExportScInActionOrderReport();
  const { fetchOrderDetailReport } = useFetchOrderDetailReport();
  const { exportOrderDetailReport } = useExportOrderDetailReport();
  const { fetchSummaryDataByRegion } = useFetchSummaryDataByRegion();
  const { exportSummaryRegionReport} = useExportSummaryRegionReport();
  const [showFilter, setShowFilter] = useState((tabKey !== 'Summary' && level === 1) || (tabKey === 'Summary' && !isSiteUser && level === 0) || (tabKey === 'Summary' && isSiteUser && level === 1));
  const { fetchOosReportBySite } = useFetchOosReportBySite();
  const { fetchOosReportByCategory } = useFetchOosReportByCategory();
  const { fetchOosReportByOrderNumber } = useFetchOosReportByOrderNumber();
  const { exportOosReportBySite } = useExportOosReportBySite();
  const { exportOosReportByCategory } = useExportOosReportByCategory();
  const { exportOosReportByOrderNumber } = useExportOosReportByOrderNumber();
  const { fetchOosAccountAccountType } = useFetchOosAccountAccountType();
  const { fetchOosAccountCustomer } = useFetchOosAccountCustomer();
  const { fetchOosAccountOrder } = useFetchOosAccountOrder();
  const { exportOosAccountAccountType } = useExportOosAccountAccountType();
  const { exportOosAccountCustomer } = useExportOosAccountCustomer();
  const { exportOosAccountOrder } = useExportOosAccountOrder ();
  const [pagination, setPagination] = useState(CONST_PAGINATION_TEN);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  let condition = formatFormdata(dashboardConditionData, territoryData);
  const [isOpenPop, setIsOpenPop] = useState(false);
  const isOpenPopFun = () => {
    setIsOpenPop(true)
  };
  const isClosePopFun = () =>{
    setIsOpenPop(false)
  }
 
  useEffect(() => {
    setPagination(CONST_PAGINATION_TEN);
    // setSorting();
    setShowAlert(false);
    resetReportsData();
  }, [dashboardConditionData]); // eslint-disable-line

  useEffect(() => {
    if (!_.isEmpty(dashboardConditionData)) {
      fetchOrderReportList(pagination, sorting);
    }
  }, [pagination, sorting, dashboardConditionData]); // eslint-disable-line
  let dataSource = [];
  let backTo='';
  let totals= 0;
  let title = '';
  let footerData = [];
  if(tabKey === 'Summary'){
    let regionTitle = `${t(tabKey)} ${t('forRegion')}`;
    let accountTypeTitle = `${t(tabKey)} ${t('forAccountType')}`;
    switch (level) {
      case 0:
        title = t(REPORTS_HEADER_TITLE_SUMMARY);
        dataSource = reportsRegionData;
        totals = reportsRegionTotals;
        footerData = JSON.parse(JSON.stringify(regionSummary));
        backTo='';
        break;
      case 1:
        if(!isSiteUser){
          title = `${regionTitle} - ${reportsKeyValue.region}`;
          backTo='/summary';
          condition = {
            ...condition,
            sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
            regions: [reportsKeyValue.region],
          }
        }else{
          title = t(REPORTS_HEADER_TITLE_SUMMARY);
          backTo='';
        }
        dataSource = reportsData;
        totals = reportsTotals;
        footerData = JSON.parse(JSON.stringify(summary));
        break;
      case 2:
        if(!isSiteUser){
          title = `${regionTitle} - ${reportsKeyValue.region} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType}`;
        }else{ 
          title = `${accountTypeTitle} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType}`;
        }
       
        if(reportsKeyValue.offDaySales){
          title = `${title} - ${t('summary_offDay')}`;
        }
        dataSource = reportsCustomerData;
        totals = reportsCustomerTotals;
        footerData = JSON.parse(JSON.stringify(customerSummary));
        backTo=`/summary/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`;
        condition = {
          ...condition,
          accountTypes: reportsKeyValue.accountType ?[reportsKeyValue.accountType] : condition && condition.accountTypes,
          offDay: reportsKeyValue.offDaySales ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
          sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
        }
        break;
      case 3:
        if(!isSiteUser){
          title = `${regionTitle} - ${reportsKeyValue.region} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('customer')} - ${reportsKeyValue.customerNumber}`;
        }else{
          title = `${accountTypeTitle} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('customer')} - ${reportsKeyValue.customerNumber}`;
        }
        if(reportsKeyValue.offDaySales){
          title = `${title} - ${t('summary_offDay')}`;
        }
        dataSource = reportsOrderData;
        totals = reportsOrderTotals;
        footerData = JSON.parse(JSON.stringify(orderSummary));
        backTo=`/summary/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`;
        condition = {
          ...condition,
          customerNumber: reportsKeyValue.customerNumber || '',
          accountTypes: reportsKeyValue.accountType ?[reportsKeyValue.accountType] : condition && condition.accountTypes,
          offDay: reportsKeyValue.offDaySales ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
          sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
        }
        break;
      case 4:
        if(!isSiteUser){
          title = `${regionTitle} - ${reportsKeyValue.region} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order')} - ${reportsKeyValue.orderNumber}`;
        }else{
          title = `${accountTypeTitle} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order')} - ${reportsKeyValue.orderNumber}`;
        }
        if(reportsKeyValue.offDaySales){
          title = `${title} - ${t('summary_offDay')}`;
        }
        dataSource = reportsOrderDetailData;
        totals = reportsOrderDetailTotals;
        footerData = JSON.parse(JSON.stringify(orderDetailSummary));
        backTo=`/summary/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`;
        condition = {
          orderNumber: reportsKeyValue.orderNumber || '',
          site: reportsKeyValue.site,
        }
        break;
      default:
        backTo=`/summary`;
      }
  }
  if(tabKey === 'Orders' || tabKey === 'Future Orders'){
    let routePath = tabKey === 'Orders'?'orders':'future-orders';
    switch (level) {
      case 1:
        title = t(tabKey);
        dataSource = reportsData;
        totals = reportsTotals;
        footerData = JSON.parse(JSON.stringify(summary));
        backTo = ``;
        condition = {
          ...condition,
          futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null,
        }
        break;
      case 2:
        title = `${t(tabKey)} ${t('forChannelType')} - ${reportsKeyValue.orderChannelType}`;
        dataSource = reportsCustomerData;
        totals = reportsCustomerTotals;
        footerData = JSON.parse(JSON.stringify(customerSummary));
        backTo = `/${routePath}`;
        condition = {
          ...condition,
          channelType: reportsKeyValue.remoteOrderEntryMode || '', //parse remoteOrderEntryMode to channelType into param
          // offDay: reportsKeyValue.offDaySales ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
          sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
          futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null
        }
        break;
      case 3:
        title = `${t(tabKey)} ${t('forChannelType')} - ${reportsKeyValue.orderChannelType} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType}`;
        if(reportsKeyValue.offDaySales){
          title = `${t(tabKey)} ${t('forChannelType')} - ${reportsKeyValue.orderChannelType} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('summary_offDay')}`;
        }
        dataSource = reportsOrderData;
        totals = reportsOrderTotals;
        footerData = JSON.parse(JSON.stringify(orderSummary));
        backTo = `/${routePath}/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`
        condition = {
          ...condition,
          channelType: reportsKeyValue.remoteOrderEntryMode || '', //parse remoteOrderEntryMode to channelType into param
          accountTypes: reportsKeyValue.accountType ?[reportsKeyValue.accountType] : condition && condition.accountTypes,
          offDay: reportsKeyValue.offDaySales ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
          sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
          futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null
        }
        break;
      case 4:
        title = `${t(tabKey)} ${t('forChannelType')} - ${reportsKeyValue.orderChannelType} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('order')} - ${reportsKeyValue.orderNumber}`;
        if(reportsKeyValue.offDaySales){
          title = `${t(tabKey)} ${t('forChannelType')} - ${reportsKeyValue.orderChannelType} - ${t('summary_AccountType')} - ${reportsKeyValue.opco} - ${reportsKeyValue.accountType} - ${t('order')} - ${reportsKeyValue.orderNumber} - ${t('summary_offDay')}`;
        }
        dataSource = reportsOrderDetailData;
        totals = reportsOrderDetailTotals;
        footerData = JSON.parse(JSON.stringify(orderDetailSummary));
        backTo = `/${routePath}/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`
        condition = {
          orderNumber: reportsKeyValue.orderNumber || '',
          site: reportsKeyValue.site,
        }
        break;
      default:
        backTo=`/${routePath}`;
      }
  }
  if(tabKey === 'SC in Action'){
    switch (level) {
      case 1:
        title = `${t(tabKey)}`;
        dataSource = reportsData;
        totals = reportsTotals;
        footerData = JSON.parse(JSON.stringify(summary));
        backTo='';
        break;
      case 2:
        title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId}`;
        if(reportsKeyValue.offDayOrders){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('order_offDayOrders')}`;
        }else if(reportsKeyValue.largeHits){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('largeHits')}`;
        }else if(reportsKeyValue.orderUnderMin){
          title = `${t(tabKey)} ${t('forTerritory')}- ${reportsKeyValue.scId} - ${t('underXOrY')}`;
        }
        dataSource = reportsCustomerData;
        totals = reportsCustomerTotals;
        footerData = JSON.parse(JSON.stringify(customerSummary));
        backTo=`/sc-in-action`;
        condition = {
          ...condition,
          scId: reportsKeyValue.scId || '',
          largeHits: reportsKeyValue.largeHits ? 'LH' : '',
          orderUnderMin: reportsKeyValue.orderUnderMin ? 'OUM' : '',
          offDay: reportsKeyValue.offDayOrders ? 'OFFDAY' : '', //OFFDAY is hardcord flag for offday sales hyperlink
          sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
        }
        break;
      case 3:
        title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber}`;
        if(reportsKeyValue.offDayOrders){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order_offDayOrders')}`;
        }else if(reportsKeyValue.largeHits){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} -  ${t('largeHits')}`;
        }else if(reportsKeyValue.orderUnderMin){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('underXOrY')}`;
        }
        dataSource = reportsOrderData;
        totals = reportsOrderTotals;
        footerData = JSON.parse(JSON.stringify(orderSummary));
        backTo=`/sc-in-action/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`;
        condition = {
          ...condition,
          scId: reportsKeyValue.scId || '',
          largeHits: reportsKeyValue.largeHits ? 'LH' : '',
          orderUnderMin: reportsKeyValue.orderUnderMin ? 'OUM' : '',
          offDay: reportsKeyValue.offDayOrders ? 'OFFDAY' : '', //OFFDAY is hardcord flag for offday sales hyperlink
          customerNumber: reportsKeyValue.customerNumber || '',
          sites: reportsKeyValue.opco ? [reportsKeyValue.opco] : condition && condition.sites,
        };
        break;
      case 4:
        title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order')} - ${reportsKeyValue.orderNumber}`;
        if(reportsKeyValue.offDayOrders){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order')} - ${reportsKeyValue.orderNumber} - ${t('order_offDayOrders')}`;
        }else if(reportsKeyValue.largeHits){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order')} - ${reportsKeyValue.orderNumber} - ${t('largeHits')}`;
        }else if(reportsKeyValue.orderUnderMin){
          title = `${t(tabKey)} ${t('forTerritory')} - ${reportsKeyValue.scId} - ${t('customer')} - ${reportsKeyValue.customerNumber} - ${t('order')} - ${reportsKeyValue.orderNumber} - ${t('underXOrY')}`;
        }
        dataSource = reportsOrderDetailData;
        totals = reportsOrderDetailTotals;
        footerData = JSON.parse(JSON.stringify(orderDetailSummary));
        backTo=`/sc-in-action/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level-1)[0].path}`;
        condition = {
          orderNumber: reportsKeyValue.orderNumber || '',
          site: reportsKeyValue.site,
        };
        break;
      default:
        backTo=`/sc-in-action`;
      }
  }
  if(tabKey === 'OOS Item'){
    switch (level) {
      case 1:
        title = `${t(tabKey)}`;
        dataSource = reportsData;
        totals = reportsTotals;
        footerData = JSON.parse(JSON.stringify(summary));
        backTo='';
        break;
      case 2:
        // title = `${tabKey} for AccountType - ${reportsKeyValue.accountType}`;
        if(reportsKeyValue.category){
          title = `${t(tabKey)} ${t('forCategory')} - ${reportsKeyValue.category}`;
        }
        dataSource = reportsOrderData;
        totals = reportsOrderTotals;
        footerData = JSON.parse(JSON.stringify(orderSummary));
        backTo=`/out-of-stock`;
        condition = {
          ...condition,
          category: reportsKeyValue.category || '',
          sites: reportsKeyValue.site ? [reportsKeyValue.site] : condition && condition.sites,
        }
        break;
      case 3:
        title = `${t(tabKey)} ${t('forCategory')} - ${reportsKeyValue.category} - ${t('item#')} - ${reportsKeyValue.itemNumber}`;
        dataSource = reportsCustomerData;
        totals = reportsCustomerTotals;
        footerData = JSON.parse(JSON.stringify(customerSummary));
        backTo=`/out-of-stock/order`;
        condition = {
          ...condition,
          category: reportsKeyValue.category || '',
          sites: reportsKeyValue.site ? [reportsKeyValue.site] : condition && condition.sites,
          itemNumber: reportsKeyValue.itemNumber || '',
        };
        break;
      default:
        backTo=`/out-of-stock`;
      }
  }
  if(tabKey === 'OOS Account'){
    switch (level) {
      case 1:
        title = `${t('oosAccount')}`;
        dataSource = reportsData;
        totals = reportsTotals;
        footerData = JSON.parse(JSON.stringify(summary));
        backTo='';
        break;
      case 2:
        // title = `${tabKey} for AccountType - ${reportsKeyValue.accountType}`;
        if(reportsKeyValue.accountType){
          title = `${t('oosAccount')} ${t('forAccountType')} - ${reportsKeyValue.accountType}`;
        }
        dataSource = reportsCustomerData;
        totals = reportsCustomerTotals;
        footerData = JSON.parse(JSON.stringify(customerSummary));
        backTo=`/oos-account`;
        condition = {
          ...condition,
          accountTypes: reportsKeyValue.accountType ?[reportsKeyValue.accountType] : condition && condition.accountTypes,
          sites: reportsKeyValue.site ? [reportsKeyValue.site] : condition && condition.sites,
        }
        break;
      case 3:
        title = `${t('oosAccount')} ${t('forAccountType')} - ${reportsKeyValue.accountType} - ${t('customer')} - ${reportsKeyValue.customerNumber}`;
        dataSource = reportsOrderData;
        totals = reportsOrderTotals;
        footerData = JSON.parse(JSON.stringify(orderSummary));
        backTo=`/oos-account/customer`;
        condition = {
          ...condition,
          accountTypes: reportsKeyValue.accountType ?[reportsKeyValue.accountType] : condition && condition.accountTypes,
          sites: reportsKeyValue.site ? [reportsKeyValue.site] : condition && condition.sites,
          customerNumber: reportsKeyValue.customerNumber || '',
        };
        break;
      default:
        backTo=`/oos-account`;
      }
  }
  const reportActionIndex = {
    'Summary': {
      0: data => fetchSummaryDataByRegion(data),
      1: data => fetchSummaryData(data),
      2: data => fetchSummaryCustomerData(data),
      3: data => fetchSummaryOrderData(data),
      4: data => fetchOrderDetailReport(data),
    }, 
    'SC in Action':{
      1: data => fetchScInActionData(data),
      2: data => fetchScInActionCustomerData(data),
      3: data => fetchScInActionOrderData(data),
      4: data => fetchOrderDetailReport(data),
    },
    'Orders':{
      1: data => fetchOrdersReportByChannelType(data),
      2: data => fetchSummaryDataFromChannelType(data),
      3: data => fetchOrdersData(data),
      4: data => fetchOrderDetailReport(data),
    },
    'Future Orders':{
      1: data => fetchOrdersReportByChannelType(data),
      2: data => fetchSummaryDataFromChannelType(data),
      3: data => fetchOrdersData(data),
      4: data => fetchOrderDetailReport(data),
    },
    'OOS Item':{
      1: data => fetchOosReportBySite(data),
      2: data => fetchOosReportByCategory(data),
      3: data => fetchOosReportByOrderNumber(data),
    },
    'OOS Account':{
      1: data => fetchOosAccountAccountType(data),
      2: data => fetchOosAccountCustomer(data),
      3: data => fetchOosAccountOrder(data),
    }
  };
  const exportReportActionIndex = {
    'Summary': {
      0: data => exportSummaryRegionReport(data),
      1: data => exportSummaryReport(data),
      2: data => exportSummaryCustomerReport(data),
      3: data => exportSummaryOrderReport(data),
      4: data => exportOrderDetailReport(data),
    },
    'SC in Action': {
      1: data => exportScInActionReportData(data),
      2: data => exportScInActionCustomerReport(data),
      3: data => exportScInActionOrderReport(data),
      4: data => exportOrderDetailReport(data),
    },
    'Orders':{
      1: data => exportOrdersReportByChannelType(data),
      2: data => exportSummaryReport(data),
      3: data => exportOrdersReport(data),
      4: data => exportOrderDetailReport(data),
    },
    'Future Orders':{
      1: data => exportOrdersReportByChannelType(data),
      2: data => exportSummaryReport(data),
      3: data => exportOrdersReport(data),
      4: data => exportOrderDetailReport(data),
    },
    'OOS Item':{
      1: data => exportOosReportBySite(data),
      2: data => exportOosReportByCategory(data),
      3: data => exportOosReportByOrderNumber(data),
    },
    'OOS Account':{
      1: data => exportOosAccountAccountType(data),
      2: data => exportOosAccountCustomer(data),
      3: data => exportOosAccountOrder(data),
    }
  };

  const fetchOrderReportList = (paginationInfo, sortingInfo) => {
    const formdata = {
      ...condition,
      // futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null,
      pageIndex: paginationInfo.pageIndex,
      pageSize: paginationInfo.pageSize,
      ...sortingInfo,
    };
    const timing = Date.now();
    reportActionIndex[tabKey][level](formdata);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch ${title} report`,timing);
    });
  };
  const exportReports = () => {
    if (totals > 5001) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      let sheetColConfig = [];
      columnsProp.forEach(col => {
        col.title = t(col.title);
        let sheetColWidth = 12;
        if (col.width) {
          sheetColWidth = col.width / 10;
        }
        sheetColConfig.push({ wch: sheetColWidth });
      });
      const timing = Date.now();
      exportReportActionIndex[tabKey][level]({
        ...condition,
        futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null,
        ...sorting,
      }).then(data => {
        const sheetObj = [{
          headers: renderTableColumns(columnsProp.map(item => {
            item.title = t(item.title);
            return item;
          }), tabKey, renderElementIndex),
          data: data,
          sheetName: null,
          sheetColConfig,
        }];
        downloadFile(`${title} Report`, sheetObj);
        datadogRum.addAction(`Export ${title} report`,{
          headers: renderTableColumns(columnsProp.map(item => {
            item.title = t(item.title);
            return item;
          }), tabKey, renderElementIndex),
          data: data,
          sheetName: null,
          sheetColConfig,
        });
      });
      datadogRum.onReady(function(){
        datadogRum.addTiming(`Export ${title} report`,timing);
      });
    }
  };
   /**
   *  footer will scroll when horizontal scrolled table
   */
   useEffect(() => {
    if (dataSource) {
      const parentScroll = document.querySelector('.report-new-table .ant-table-body');
      const footerScroll = document.querySelector('.ant-table-footer .ant-table-body');
      if (parentScroll && footerScroll) {
        const scale = (parentScroll.scrollWidth - parentScroll.clientWidth) / (footerScroll.scrollWidth - footerScroll.clientWidth);
        let flag = 0;

        parentScroll.addEventListener('scroll', () => {
          if (flag !== 1) return;
          footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
        });
        footerScroll.addEventListener('scroll', () => {
          if (flag !== 2) return;
          parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
        });
        parentScroll.addEventListener('mouseover', () => {
          flag = 1;
        }, true);
        footerScroll.addEventListener('mouseover', () => {
          flag = 2;
        }, true);
        return () => {
          parentScroll.removeEventListener('scroll', () => {
            if (flag !== 1) return;
            footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
          });
          footerScroll.removeEventListener('scroll', () => {
            if (flag !== 2) return;
            parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
          });
          parentScroll.removeEventListener('mouseover', () => {
            flag = 1;
          }, true);
          footerScroll.removeEventListener('mouseover', () => {
            flag = 2;
          }, true);
        }
      }
    }
  }, [dataSource]);
  const renderTableLinkButton = (value, label, record, isNestColumn) => {
    return (
      <Button
        type="link"
        onClick={
          () => handleOnRowClick(value, label, record)
        }
        className={(label === 'offDaySales' || ((label === 'offDayOrders' || label === 'largeHits' || label === 'underXOrY') && tabKey === 'SC in Action')) && value === 0?"ant-btn-link-disabled":""}
      >
       {label === 'offDaySales'? `$${returnFloat(value)}`: label === 'scId'?renderMergedColumn(record.scName,record.scId,'-'):label === 'orderChannelType' && value==='SMX'?'SMX/Source':value}
      </Button>
    );
  };
  const renderBadge = (value, label) => {
    if (label === 'largeHits' && value === 'Y') {
      return <Badge className="order-badge-item" color="#56B146" />;
    } else if (label === 'underXOrY' && value === 'Y') {
      return <Badge className="order-badge-item" color="#D0021B" />;
    } else if (label === 'offDay' && value === 'Y') {
      return <Badge className="order-badge-item" color="#6A737B" />;
    } else {
      return null;
    }
  };
  const renderElementIndex = {
    toggleModal: renderTableLinkButton,
    renderBadge: renderBadge,
  };
  //insert all index into summary data
  if (footerData) {
    footerData.totalIndex = true;
  }
  let footerTableColumns = renderTableColumns(convertToFooterColumns(columnsProp.map(item => {
    item.title = t(item.title);
    return item;
  })), tabKey, renderElementIndex);
  const renderFooter = () => {
    if(level === 4){
      footerData = {...footerData,itemDescription:totals};
    }

    if (footerData && !_.isEmpty(dataSource)) {
      return (
        <TableFooter tabKey={tabKey} summary={footerData} columns={footerTableColumns} />
      )
    }
  }
  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
          sortedBy: sorter.field,
          sort: sorter.order,
        }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };
  const onPaginationChange = (page, changedPageSize) => {
    setShowAlert(false);
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
  };

  const history = useHistory();
  const handleOnRowClick = (value, label, record) => {
    if(tabKey === 'Summary'){
      switch (level){
        case 0:
          history.push('/summary/account-type');
          saveReportsKeyValue({ ...reportsKeyValue, [label]: value,tabKey:tabKey, });
          break;
        case 1:
          history.push('/summary/customer');
          let opco = {
            opco: record && record.site,
          }
          let objOffday = {};
          if (label === 'offDaySales') {
            objOffday = {
              accountType: record.accountType,
            };
          }
          saveReportsKeyValue({ ...reportsKeyValue, ...objOffday, ...opco, [label]: value,tabKey:tabKey, });
          break;
        case 2:
          history.push('/summary/order');
          saveReportsKeyValue({...reportsKeyValue,[label]:value,tabKey:tabKey});
          break;
        case 3:
          history.push('/summary/order-detail');
          saveReportsKeyValue({...reportsKeyValue,[label]:value,tabKey:tabKey,site:record.site});
          break;
        default:
      }
    }
    if(tabKey === 'Orders' || tabKey === 'Future Orders'){
      const routePath = tabKey === 'Orders'?'orders':'future-orders'
      switch (level){
        case 1:
          history.push(`/${routePath}/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level+1)[0].path}`);
          saveReportsKeyValue({
            ...reportsKeyValue, 
            remoteOrderEntryMode: record.remoteOrderEntryMode,
            orderChannelType: record.orderChannelType,
            tabKey:tabKey,
          });
          break;
        case 2:
          history.push(`/${routePath}/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level+1)[0].path}`);
          let opco = {
            opco: record && record.site,
          }
          let objOffday = {};
          if (label === 'offDaySales') {
            objOffday = {
              accountType: record.accountType,
            };
          }
          saveReportsKeyValue({ ...reportsKeyValue, ...objOffday, ...opco, [label]: value,tabKey:tabKey, });
          break;
        case 3:
            history.push(`/${routePath}/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level+1)[0].path}`);
            saveReportsKeyValue({ ...reportsKeyValue, [label]: value,tabKey:tabKey,site:record.site });
        default:
      }
    }
    if(tabKey === 'SC in Action') {
      let customerParam = { scId: record.scId,opco: record && record.site};
      switch(level){
        case 1:
          history.push(`/sc-in-action/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level+1)[0].path}`);
          if (label === 'offDayOrders') {
            customerParam ={
              ...customerParam,
              offDayOrders: value,
            }
          } else if (label === 'largeHits') {
            customerParam ={
              ...customerParam,
              largeHits: record.largeHits
            }
          } else if (label === 'underXOrY') {
            customerParam ={
              ...customerParam,
              orderUnderMin: record.underXOrY,
            }
          } 
          saveReportsKeyValue({
            ...reportsKeyValue,
            ...customerParam,
            tabKey: tabKey,
          });
          break;
          case 2: 
          history.push(`/sc-in-action/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level+1)[0].path}`);
          saveReportsKeyValue({...reportsKeyValue,[label]:value,tabKey:tabKey});
          break;
          case 3: 
          history.push(`/sc-in-action/${CONST_PATH_LEVEL_MAPPING.filter(item=>item.level === level+1)[0].path}`);
          saveReportsKeyValue({...reportsKeyValue,[label]:value,tabKey:tabKey,site:record.site});
          break;
        }
      }
    if(tabKey === 'OOS Item'){
      switch (level){
        case 1:
          history.push('/out-of-stock/order');
          let site = {
            site: record && record.site,
          }
          saveReportsKeyValue({ ...reportsKeyValue, ...site, [label]: value,tabKey:tabKey, });
          break;
        case 2:
          history.push('/out-of-stock/customer');
          let condition = {
            itemNumber: record && record.itemNumber,
            orderNumber: record && record.orderNumber,
            site: record && record.site,
          }
          saveReportsKeyValue({...reportsKeyValue,...condition,tabKey:tabKey});
          break;
        default:
      }
    }
    if(tabKey === 'OOS Account'){
      switch (level){
        case 1:
          history.push('/oos-account/customer');
          let site = {
            site: record && record.site,
          }
          saveReportsKeyValue({ ...reportsKeyValue, ...site, [label]: value,tabKey:tabKey, });
          break;
        case 2:
          history.push('/oos-account/order');
          if(label === "customerShipTo"){
            label = "customerNumber";
          }
          let condition = {
            site: record && record.site,
          }
          saveReportsKeyValue({...reportsKeyValue,...condition,tabKey:tabKey,[label]:value});
          break;
        default:
      }
    }
      resetReportsData();
      resetReportsCustomerLevelData();
      resetReportsDataOrders();
  }
  const renderMobileValue =(value,dataIndex,record,colItem)=>{
    if (colItem.toggleModal) {
      return renderTableLinkButton(value, dataIndex, record);
    } else if (dataIndex === 'totalSales' || dataIndex === 'averageSell'
      || dataIndex === 'offDaySales' || dataIndex === 'orderTotalSales'
      || dataIndex === 'lostSales') {
      return value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`;
    } else if (dataIndex === 'totalGP' || dataIndex === 'salesPercentage' || dataIndex === 'preShipDirSubs' || dataIndex === 'preShipAllSubs') {
      return value === null || value === 0 ? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`;
    // } else if (dataIndex === 'underXOrY' || dataIndex === 'offDay' || dataIndex === 'largeHits') {
    //   return value === 'Y' ? 'Yes' : value === 'N' ? 'No' : null;
    }else if(dataIndex === 'orderDetailExtendedPrice' || dataIndex === 'originalItemUnitPrice'){
      return value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh',{minimumFractionDigits:2})}`;
    }else if(dataIndex === 'scId'){
      return renderMergedColumn(record.scName,record.scId,'-')
    }else if(dataIndex === 'district'){
      return renderMergedColumn(record.dsmName,record.district,'-')
    }else if(dataIndex === 'region'){
      return renderMergedColumn(record.rsmName,record.region,'-')
    }
     else {
      return `${value}`;
    }
  }
  return (
    <div className="reports-new-common-page" id={`${tabKey}-${level}`}>
      <div className={isOpenPop ? "sys-mark fadeIn is-show-mobile":"sys-mark fadeOut none"}></div>
      <div className="sia-container">
        <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
        <Header backTo={backTo} title={title} level={level} />
        <div className="report-new-export">
          <ExportButton dataSource={dataSource} exportReports={exportReports} />
        </div>
        
      </div>
      <TotalPanel tabKey={tabKey} footerColumns={footerTableColumns} footerData={footerData} level={level}/>
      <div className='reports-new-tables'>
        <div className='reports-result-panel'>
          {showFilter && <ReportsFilter isOpenPop = {isOpenPop} isOpenPopFun={isOpenPopFun} isClosePopFun={isClosePopFun}/>}
          <Table
            rowKey={record => Math.random()}
            className="report-new-table is-show-pc"
            loading={fetchScInActionDataPending || fetchSummaryDataPending || fetchOrdersReportByChannelTypePending 
              || exportSummaryReportPending || exportOrdersReportByChannelTypePending || exportScInActionReportDataPending 
              || fetchSummaryCustomerDataPending || fetchSummaryOrderDataPending || exportSummaryCustomerReportPending || exportSummaryOrderReportPending 
              || fetchScInActionCustomerDataPending || fetchScInActionOrderDataPending || exportScInActionCustomerReportPending || exportScInActionOrderReportPending||
              fetchSummaryDataFromChannelTypePending || fetchOrdersDataPending || exportOrdersReportPending ||
              fetchOrderDetailReportPending || exportOrderDetailReportPending 
              || fetchSummaryDataByRegionPending || exportSummaryRegionReportPending
              || fetchOosReportBySitePending || fetchOosReportByCategoryPending || fetchOosReportByOrderNumberPending 
              || exportOosReportBySitePending || exportOosReportByCategoryPending || exportOosReportByOrderNumberPending
              || fetchOosAccountAccountTypePending || fetchOosAccountCustomerPending || fetchOosAccountOrderPending
              || exportOosAccountAccountTypePending || exportOosAccountCustomerPending || exportOosAccountOrderPending
            }
            columns={renderTableColumns(columnsProp.map(item => {
              item.title = t(item.title);
              return item;
            }), tabKey, renderElementIndex)}
            dataSource={dataSource}
            pagination={false}
            onChange={onTableChange}
            scroll={{
              x: 'max-content',
              y: tabKey === 'Out & Subs' ? 320 : 170
            }}
            footer={renderFooter}
          />
          <div className="is-show-mobile">
            <div className="reports-mobile-list">
              <Spin spinning={fetchScInActionDataPending || fetchSummaryDataPending || fetchOrdersReportByChannelTypePending 
              || exportSummaryReportPending || exportOrdersReportByChannelTypePending || exportScInActionReportDataPending 
              || fetchSummaryCustomerDataPending || fetchSummaryOrderDataPending || exportSummaryCustomerReportPending || exportSummaryOrderReportPending 
              || fetchScInActionCustomerDataPending || fetchScInActionOrderDataPending || exportScInActionCustomerReportPending || exportScInActionOrderReportPending||
              fetchSummaryDataFromChannelTypePending || fetchOrdersDataPending || exportOrdersReportPending ||
              fetchOrderDetailReportPending || exportOrderDetailReportPending 
              || fetchSummaryDataByRegionPending || exportSummaryRegionReportPending
              || fetchOosReportBySitePending || fetchOosReportByCategoryPending || fetchOosReportByOrderNumberPending 
              || exportOosReportBySitePending || exportOosReportByCategoryPending || exportOosReportByOrderNumberPending
              || fetchOosAccountAccountTypePending || fetchOosAccountCustomerPending || fetchOosAccountOrderPending
              || exportOosAccountAccountTypePending || exportOosAccountCustomerPending || exportOosAccountOrderPending
              }>
            <div className={!dataSource ? "reports-no-data":"none"}>
              <Empty />
            </div>
            {dataSource && dataSource.length >0 && dataSource.map((record,index) => {
              return (
                <ul key={`${tabKey}-${index}`}>
                  {
                    columnsProp.map((colItem,colIndex) => {
                      const title = t(colItem.title);
                      const dataIndex = colItem.dataIndex;
                      const value = record[dataIndex];
                      return (<li key={`${tabKey}-${dataIndex}-${colIndex}`}>
                        <span className="sub01">{title}</span>
                        <span className="sub02">
                        {renderMobileValue(value,dataIndex,record,colItem)}
                        </span>
                      </li>)
                    })
                  }
                </ul>
                );
            })}
            </Spin>
            </div>
          </div>
          <Pagination
            style={{ textAlign: 'center' }}
            className={'report-pagination'}
            showSizeChanger
            current={pagination.pageIndex}
            pageSize={pagination.pageSize}
            total={totals}
            pageSizeOptions={pagination.pageSizeOptions}
            onShowSizeChange={onPaginationChange}
            onChange={onPaginationChange}
          />
        </div>
      </div>
    </div>
  );
};

CommonPage.propTypes = {};
CommonPage.defaultProps = {};
