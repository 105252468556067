import React from 'react';
import {useState} from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons'
import USER_GUIDE_FILE from '../../assets/BOS 2.0 - User Guide.docx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars,faClose } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useTranslation } from "react-i18next";

export default function HeadPanel(props) {

  const { t } = useTranslation();

  const { fullName, } = useSelector(state => ({
    fullName: state.home.auth.user.fullName
  }));

  const {headerOpenNav,isOpenNav} = props

  return (
    <header className='home-head-panel'>
        <div className='header-left font-size-24'>
        <FontAwesomeIcon icon={faBars} style={{ height: "2rem", width: "2rem",color:"#99D1ED" }}
        className={isOpenNav ? "is-show-mobile fa-bars-icon roateRight":"is-show-mobile fa-bars-icon roateLeft"}
        onClick={headerOpenNav}/>
          <div className='sysco-logo' />
          <Divider className='custom-divider' type="vertical" />
          <div>{t('inAction')}</div>
        </div>
        <div className='header-right font-size-18'>
          <span>{` ${t('welcome')}, ${fullName} `}</span>
          <a href={USER_GUIDE_FILE} download='BOS 2.0 - User Guide.docx' title='Download User Guide' style={{ paddingLeft:'8px',color:'#ffffff'}}>
            <QuestionCircleTwoTone style={{ fontSize: '20px' }} />
            <p style={{marginBottom:'0px',marginTop:"-3px", fontSize:"10px",textAlign:'right'}}>{t('help')}</p>
          </a>
        </div>
    </header>
  );
};

HeadPanel.propTypes = {};
HeadPanel.defaultProps = {};
