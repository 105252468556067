import React from 'react';
import { Alert } from 'antd';
import {ERROR_EXPORT_ALERT_LIMIT_EXCEED} from './redux/constants'

export default function ExportAlertModal(props) {
  const { showAlert, setShowAlert } = props;
  if (showAlert) {
    return (
      <div className="common-export-alert-modal">
        <Alert
          className="limit-error-alert"
          type="error"
          showIcon
          closable
          onClose={() => setShowAlert(false)}
          message={ERROR_EXPORT_ALERT_LIMIT_EXCEED}       
           />
      </div>
    );
  }
  return null;
}

ExportAlertModal.propTypes = {};
ExportAlertModal.defaultProps = {};
