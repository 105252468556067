import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Tabs, Button } from 'antd';
import { DownloadOutlined, QuestionCircleFilled } from '@ant-design/icons';
import {  SnapshotOrderHeader, HelpWindow, SnapshotOrderDetails } from './';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
export default function SnapshotReportModal(props) {
  const { fetchSnapshotDataPending } = useSelector(state => ({
    fetchSnapshotDataPending: state.orderTrail.fetchSnapshotDataPending,
  }));
  const { exportSnapshotDetailReports, headerColumns, detailsColumns,
    snapshotModalVisible, setSnapshotModalVisible } = props;
  const [helpWindowVisible, setHelpWindowVisible] = useState(false);
  return (
    <>
      <Modal
        className="order-trail-snapshot-report-modal"
        visible={snapshotModalVisible && !fetchSnapshotDataPending}
        onCancel={() => setSnapshotModalVisible(false)}
        centered
        footer={[]}
        width="90%"
        destroyOnClose="true"
      >
        {/* <Button
          type="link"
          className="snapshot-download-btn green-link-btn"
          icon={<DownloadOutlined />}
          onClick={exportSnapshotDetailReports}
        /> */}
        <div className='snapshot-download-btn'>
          <Button type="link" title="Export" style={{ marginTop: '-10px' }} onClick={exportSnapshotDetailReports}>
            <FontAwesomeIcon icon={faFileExport} color={ "#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: "#6b6b6c" }}>Export</p>
          </Button>
        </div>
        <Button
          type="link"
          className="snapshot-help-btn green-link-btn"
          icon={<QuestionCircleFilled />}
          onClick={() => setHelpWindowVisible(true)}
        ></Button>
        <Tabs defaultActiveKey="1" className="snapshot-tabs">
          <Tabs.TabPane tab="ORDER HEADER" key="1">
            <SnapshotOrderHeader headerColumns={headerColumns} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="ORDER DETAILS" key="2">
            <SnapshotOrderDetails detailsColumns={detailsColumns} />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <Modal
        className="order-trail-snapshot-help-modal"
        visible={helpWindowVisible}
        onCancel={() => setHelpWindowVisible(false)}
        centered
        footer={null}
        width="60%"
        destroyOnClose="true"
      >
        <HelpWindow />
      </Modal>
    </>
  );
}

SnapshotReportModal.propTypes = {};
SnapshotReportModal.defaultProps = {};
