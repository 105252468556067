import React from 'react';
import { useSelector } from 'react-redux';
import { Descriptions } from 'antd';
import { useTranslation } from "react-i18next";
import { EXPECTED_SALES_COLUMNS_ONE, EXPECTED_SALES_COLUMNS_TWO, EXPECTED_SALES_COLUMNS_THREE } from '../../common/chartConstants';

export default function ExpectedSalesCard() {
  const salesSummaryData = useSelector(state => state.dashboard.salesSummaryData);
  const { t } = useTranslation();
  const renderDescriptionItems = (type) => {
    if(type === 1){
      return Object.keys(EXPECTED_SALES_COLUMNS_ONE).map((key) => {
        const title = EXPECTED_SALES_COLUMNS_ONE[key];
        if(salesSummaryData.hasOwnProperty(key)) {
          const count = salesSummaryData[key];
          return (
            <div className="sub" key={key}>
              <div  className="one-sub-01">{t(`EXPECTED_SALES_COLUMNS_ONE.${key}`)}</div>
              <div className="one-sub-02">{count && (key === 'totalSales' ? `$${count.toLocaleString('zh',{minimumFractionDigits:2})}` : `${count.toLocaleString()}`)}</div>
            </div>
          );
        }
        return null;
      });
    }else if(type === 2){
      return Object.keys(EXPECTED_SALES_COLUMNS_TWO).map((key) => {
        const title = EXPECTED_SALES_COLUMNS_TWO[key];
        if(salesSummaryData.hasOwnProperty(key)) {
          const count = salesSummaryData[key];
          return (
            <div className="sub" key={key}>
              <div  className="two-sub-01">{t(`EXPECTED_SALES_COLUMNS_TWO.${key}`)}</div>
              <div  className="two-sub-02">{count && (key === 'totalSales' ? `$${count.toLocaleString('zh',{minimumFractionDigits:2})}` : `${count.toLocaleString()}`)}</div>
            </div>
          );
        }
        return null;
      });
    }else if(type === 3){
      return Object.keys(EXPECTED_SALES_COLUMNS_THREE).map((key) => {
        const title = EXPECTED_SALES_COLUMNS_THREE[key];
        if(salesSummaryData.hasOwnProperty(key)) {
          const count = salesSummaryData[key];
          return (
            <div className="sub" key={key}>
              <div className="three-sub-01">{t(`EXPECTED_SALES_COLUMNS_THREE.${key}`)}</div>
              <div className="three-sub-02">{count && (key === 'totalSales' ? `$${count.toLocaleString('zh',{minimumFractionDigits:2})}` : `${count.toLocaleString()}`)}</div>
            </div>
          );
        }
        return null;
      });
    }
    
  }
  return (
    <div className="dashboard-expected-sales-card">
      <ul>
        <li>{renderDescriptionItems(1)}</li>
        <li>{renderDescriptionItems(2)}</li>
        <li>{renderDescriptionItems(3)}</li>
      </ul>
    </div>
  );
}

ExpectedSalesCard.propTypes = {};
ExpectedSalesCard.defaultProps = {};