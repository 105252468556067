import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { HOME_MENU_DASHBOARD,HOME_MENU_MANAGESUBS,HOME_MENU_ORDERTRAIL,HOME_MENU_REPORTS,REPORTS_MENU } from './redux/constants';
import { Layout, Menu,Spin} from "antd";
import { HomeOutlined, AppstoreOutlined, FileTextOutlined } from '@ant-design/icons';
import { useAuthrizationReset } from './redux/hooks';
import { useResetConditionData } from '../manage-subs/redux/hooks'; 
import _ from 'lodash';
import { useResetReportsKeyValue } from '../reports-new/redux/resetReportsKeyValue';
import { DvrIcon, GridViewIcon, LabProfileIcon, ManageHistoryIcon } from '../common/CustomerIcons';
import {useResetReportsData, useResetReportsOrderLevelData, useResetReportsCustomerLevelData,
  useResetReportsDataAccount, useResetReportsDataOrders,useResetReportsOrderDetailLevelData } from '../reports/redux/hooks';
import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from 'react-i18next';
const { Header } = Layout;
export default function CustomMenu(props) {
  const {headerOpenNav} = props
  const { t } = useTranslation();
  const {territoryData,fetchTerritoryDataPending,isCanadaUser} = useSelector(state => ({
    territoryData: state.dashboard.territoryData,
    fetchTerritoryDataPending: state.dashboard.fetchTerritoryDataPending,
    isCanadaUser: state.dashboard.isCanadaUser,
  }));
  const { authrizationReset } = useAuthrizationReset();
  const { resetConditionData } = useResetConditionData();
  const { resetReportsKeyValue } = useResetReportsKeyValue();
  const { resetReportsData } = useResetReportsData();
  const { resetReportsOrderLevelData } = useResetReportsOrderLevelData();
  const { resetReportsCustomerLevelData } = useResetReportsCustomerLevelData();
  const { resetReportsDataAccount } = useResetReportsDataAccount();
  const { resetReportsDataOrders } = useResetReportsDataOrders();
  const { resetReportsOrderDetailLevelData } = useResetReportsOrderDetailLevelData();

  const location = useLocation();
  const history = useHistory();
  const [selectedKeys, setSelectedKeys] = useState('dashboard');
  useEffect(() => {
    const objCurrPath = location.pathname;
    if(objCurrPath.length > 0 && objCurrPath.indexOf('/manage-subs') !== -1) {
      //set endpoint to datadog
      let screen = 'manage outs';
      if(objCurrPath.indexOf('maintain-accounts') !== -1){
        screen = 'manage account';
      }else if(objCurrPath.indexOf('sub-repositories') !== -1){
        screen = 'sub repositories';
      }
      datadogRum.setGlobalContextProperty('BosView',{
        endPoint:'manage-subs',
        screen: screen
      });
    }else{
      datadogRum.removeGlobalContextProperty("BosView");
    }
  }, [location.pathname]); //eslint-disable-line
  useEffect(() => {
    // const strCurrPath = location.pathname.replace('/', '');
    // const objCurrPath = strCurrPath.split('/');
    // //fixed refresh to render wrong menu tab issue
    // if()
    // else if(objCurrPath[0] !== selectedKeys) {
    //   setSelectedKeys(objCurrPath[0]);
    // }
    const strCurrPath = location.pathname;
    //fixed refresh to render wrong menu tab issue
    if(strCurrPath.length > 0 && strCurrPath !== selectedKeys) {
      if(strCurrPath === '/') {
        setSelectedKeys("dashboard");
      } else {
        const objCurrPath = strCurrPath.replace('/', '').split('/');
        if(objCurrPath.length > 1 && objCurrPath[0].length > 0){
          let parentPath = `/${objCurrPath[0]}`;
          if(REPORTS_MENU.filter(item=>item.to === parentPath).length > 0) {
            // reset all data if refreshed in summary,orders,future orders,sc in action menu
            resetReportsKeyValue();
            resetReportsData();
            resetReportsOrderLevelData();
            resetReportsCustomerLevelData();
            resetReportsDataAccount();
            resetReportsDataOrders();
            resetReportsOrderDetailLevelData();
            history.push(parentPath);
          }
        }        
        setSelectedKeys(objCurrPath[0]);
      }
    }
  }, [selectedKeys]); //eslint-disable-line

  useEffect(() => {
    let beginTime = 0;
    let differTime = 0;
    const beforeunload = () => {
      beginTime = new Date().getTime();
    }
    const unload = () => {
      differTime = new Date().getTime() - beginTime;
      //if differTime > 10, it is refreshing browser; else it is closing browser
      //when closing browser we will reset auth store
      if(differTime <= 10) {
        authrizationReset();
      }
    };

    window.addEventListener("beforeunload", beforeunload);
    window.addEventListener("unload", unload);
    return () => {
      window.removeEventListener("unload", unload);
      window.removeEventListener("beforeunload", beforeunload);
    };
  });
  
  const onMenuClick = ({ key, keyPath, domEvent }) => {
    if(key === '/'){
      setSelectedKeys('dashboard');
    }else{
      setSelectedKeys(key);
    }
    
    if(key !== "manage-subs") {
      resetConditionData();
    }
    //when user click on menu need reset reports params
      resetReportsKeyValue();
      //when user click on menu need reset reports data
      resetReportsData();
      resetReportsOrderLevelData();
      resetReportsCustomerLevelData();
      resetReportsOrderDetailLevelData();
  }
  //Access restriction manage subs menu for Sales Consultants
  //if Canada user,not show manage subs menu
  const renderManageSubsMenu = () => {
    if ((!_.isEmpty(territoryData) && territoryData.accessible === false) || isCanadaUser) {
      return;
    } else {
      return (
        <Menu.Item key="manage-subs" icon={<ManageHistoryIcon />} className='font-size-14 menu-item mobile-hidden' onClick={headerOpenNav}>
          <Link to="/manage-subs">{HOME_MENU_MANAGESUBS}</Link>
        </Menu.Item>
      )
    }
  }
  const renderReportsMenu = () => {
    return REPORTS_MENU.map((item) => {
      return (<Menu.Item key={item.key} icon={<LabProfileIcon />} className='font-size-14 menu-item' onClick={headerOpenNav}>
        <Link to={item.to}>{t(item.name)}</Link>
      </Menu.Item>)
    })
  }
  return (
    <Header className="home-custom-menu">
      <Menu
        className="menu-list"
        mode="inline"
        onClick={onMenuClick}
        selectedKeys={[selectedKeys]}
        style={{ width: 60 }}
      >
        <Menu.Item
          key="dashboard"
          icon={<GridViewIcon />}
          className="font-size-14 menu-item"
          onClick={headerOpenNav}
        >
          <Link to="/">{t(HOME_MENU_DASHBOARD)}</Link>
        </Menu.Item>
        {/* <Menu.Item key="reports" icon={<FileTextOutlined />} className='font-size-14 menu-item' onClick={headerOpenNav}>
          <Link to="/reports">{HOME_MENU_REPORTS}</Link>
        </Menu.Item> */}
        {renderReportsMenu()}
        
        {isCanadaUser ? null : (  //if Canada user,not show order-trail menu
          <Menu.Item
            key="order-trail"
            icon={<DvrIcon />}
            className="font-size-14 menu-item mobile-hidden"
            onClick={headerOpenNav}
          >
            <Link to="/order-trail">{HOME_MENU_ORDERTRAIL}</Link>
          </Menu.Item>
        )}
        {renderManageSubsMenu()}
      </Menu>
    </Header>
  );
};

CustomMenu.propTypes = {};
CustomMenu.defaultProps = {};
