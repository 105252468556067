import React from 'react';
import { CommonPage } from '../reports-new';
import { CONST_REPORT_TABS_NEW_COLUMNS, } from '../../common/reportsConstants';
export default function OosAccountCustomer() {
  const tabKey = 'OOS Account';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].customer.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].customer.level;
  return (
    <div className="oos-account-oos-account-customer">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

OosAccountCustomer.propTypes = {};
OosAccountCustomer.defaultProps = {};
