import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_BEGIN,
  REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS,
  REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_FAILURE,
  REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR,
} from './constants';
import { EXPORT_API_REQUEST_URL } from '../../../common/apiConfig';
export function exportOosReportByOrderNumber(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(`${EXPORT_API_REQUEST_URL}/orderDetail/exportOosReportByItemNumber`, args);
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS,
            data: res.data.data,
          });
          resolve(res.data.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissExportOosReportByOrderNumberError() {
  return {
    type: REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR,
  };
}

export function useExportOosReportByOrderNumber() {
  const dispatch = useDispatch();

  const { exportOosReportByOrderNumberPending, exportOosReportByOrderNumberError } = useSelector(
    state => ({
      exportOosReportByOrderNumberPending: state.reports.exportOosReportByOrderNumberPending,
      exportOosReportByOrderNumberError: state.reports.exportOosReportByOrderNumberError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(exportOosReportByOrderNumber(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportOosReportByOrderNumberError());
  }, [dispatch]);

  return {
    exportOosReportByOrderNumber: boundAction,
    exportOosReportByOrderNumberPending,
    exportOosReportByOrderNumberError,
    dismissExportOosReportByOrderNumberError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportOosReportByOrderNumberPending: true,
        exportOosReportByOrderNumberError: null,
      };

    case REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_SUCCESS:
      // The request is success
      return {
        ...state,
        exportOosReportByOrderNumberPending: false,
        exportOosReportByOrderNumberError: null,
      };

    case REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_FAILURE:
      // The request is failed
      return {
        ...state,
        exportOosReportByOrderNumberPending: false,
        exportOosReportByOrderNumberError: action.data.error,
      };

    case REPORTS_EXPORT_OOS_REPORT_BY_ORDER_NUMBER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportOosReportByOrderNumberError: null,
      };

    default:
      return state;
  }
}
