export const formatTerrirotyData = (data, territoryData) => {
  let newTerritoryList = [];
  data.territory.forEach(name => {
    const currTerritoryData = territoryData.territories.filter(item => item.terrName === name);
    if (currTerritoryData.length > 1) {
      const dupTerritory = currTerritoryData.filter(terr => data.site.indexOf(terr.opcoId) > -1);
      dupTerritory.forEach(terrItem => {
        newTerritoryList.push(terrItem);
      });
    } else {
      newTerritoryList.push(currTerritoryData[0]);
    }
  });
  return newTerritoryList;
};
