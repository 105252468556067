export const formatTerrirotyData = (data, territoryData) => {
  let newTerritoryList = [];
  if(territoryData.territories) {
    data.territoryInfoList.forEach(name => {
      const currTerritoryData = territoryData.territories.filter(item => item.terrName === name);
      if(currTerritoryData.length > 1) {
        const dupTerritory = currTerritoryData.filter(terr => data.sites.indexOf(terr.opcoId) > -1);
        dupTerritory.forEach(terrItem => {
          newTerritoryList.push(terrItem);
        });
      }else if(currTerritoryData.length === 1) {
        newTerritoryList.push(currTerritoryData[0]);
      }else {
        newTerritoryList.push({
          "terrName": name
        })
      }
    });
  }else {
    data.territoryInfoList.forEach(name => {
      newTerritoryList.push({
        "terrName": name
      })
    })
  }



  
  return newTerritoryList;
};