import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Badge, message, Spin, Input, Space,notification, Switch,} from 'antd';
import { renderTableColumns } from '../../common/renderColumns';
import { DropOutReasonModal, SearchFiltersPanel } from './';
import moment from 'moment';
import { CommonTable } from '../common';
import { useFetchOutsData, useFetchDropoutsData, useFetchInventoryData, useResetOutsData, useResetDropoutsData,
  useExportOutsData, useResetOrderDetailData, useResetInventoryDetailData, useFetchRecommendedProducts, useResetRecommendedProducts,
  useResetSubstituteProducts, useRemoveDropOuts, useRemoveAllocatedItem, useAllocateOriginalItem, useCheckOutsLock, useUpdateOutsRecordLock,
  useSaveSearchConditionData,useSaveOrderDetailData,useAlerts} from './redux/hooks';
import { useFetchTerritoryData } from '../dashboard/redux/hooks';
import ManageOutsOperateModal from './ManageOutsOperateModal';
import { CONST_PAGINATION_TEN, COMMON_DATE_FORMAT, DATE_TIME_TWO_FORMAT } from '../../common/constants';
import ManageOutsWarningModal from './ManageOutsWarningModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faArrowsRotate,faEye} from '@fortawesome/free-solid-svg-icons';
import { CartIcon,EditIcon,DropIcon } from '../common/CustomerIcons';
import { SearchOutlined } from '@ant-design/icons';
import { uniqueTerritoryList } from './getOriginalSelectedList';
import { formatTerrirotyData } from './formattedSearchCondition';
import { CONST_DROP_OUT_CST_FLAG_FROM_CCC, CONST_ROLE_OPTIONS_CCC, CONST_ROLE_OPTIONS_CST } from '../../common/manageOutsConstants';
import { CORP_USER_LIST } from '../../common/corpProfile';
import { datadogRum } from '@datadog/browser-rum';
import { validateGroupIdRegExp } from './formattedSubsData';

export default function ManageOuts() {
  const {
    outsData,
    orderDetail,
    fetchOutsDataPending,
    dropoutsData,
    fetchDropoutsDataPending,
    searchConditionData,
    exportOutsDataPending,
    checkOutsLockPending,
    updateOutsRecordLockPending,
    removeDropOutsPending,
    removeAllocatedItemPending,
    allocateOriginalItemPending,
    territoryData,
    originalList,
    userId,
  } = useSelector(state => ({
    outsData: state.manageSubs.outsData,
    orderDetail:state.manageSubs.orderDetail,
    fetchOutsDataPending: state.manageSubs.fetchOutsDataPending,
    dropoutsData: state.manageSubs.dropoutsData,
    fetchDropoutsDataPending: state.manageSubs.fetchDropoutsDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    exportOutsDataPending: state.manageSubs.exportOutsDataPending,
    checkOutsLockPending: state.manageSubs.checkOutsLockPending,
    updateOutsRecordLockPending: state.manageSubs.updateOutsRecordLockPending,
    removeDropOutsPending: state.manageSubs.removeDropOutsPending,
    removeAllocatedItemPending: state.manageSubs.removeAllocatedItemPending,
    allocateOriginalItemPending: state.manageSubs.allocateOriginalItemPending,
    territoryData: state.dashboard.territoryData,
    originalList: state.manageSubs.originalList,
    userId: state.home.auth.user.userId,
  }));

  const { auth } = useSelector(state => ({
    auth: state.home.auth,
  }));

  const { fetchTerritoryData } = useFetchTerritoryData();
  const { fetchOutsData } = useFetchOutsData();
  const { fetchDropoutsData } = useFetchDropoutsData();
  const { fetchInventoryData } = useFetchInventoryData();
  const { resetOutsData } = useResetOutsData();
  const { resetDropoutsData } = useResetDropoutsData();
  const { exportOutsData } = useExportOutsData();
  const { resetOrderDetailData } = useResetOrderDetailData();
  const { resetInventoryDetailData } = useResetInventoryDetailData();
  const { fetchRecommendedProducts } = useFetchRecommendedProducts();
  const { resetRecommendedProducts } = useResetRecommendedProducts();
  const { resetSubstituteProducts } = useResetSubstituteProducts();
  const { removeDropOuts } = useRemoveDropOuts();
  const { alerts } = useAlerts();
  const { removeAllocatedItem } = useRemoveAllocatedItem();
  const { allocateOriginalItem } = useAllocateOriginalItem();
  const { checkOutsLock } = useCheckOutsLock();
  const { updateOutsRecordLock } = useUpdateOutsRecordLock();
  const { saveOrderDetailData } = useSaveOrderDetailData();
  const [isOrderDetailModalVisible, setOrderDetailModalVisible] = useState(false);
  const [isInventoryDetailModalVisible, setInventoryDetailModalVisible] = useState(false);
  //viewdropout
  const [isViewDropOutVisible, setViewDropOutModalVisible] = useState(false);
  const [isAddSubModalVisible, setAddSubModalVisible] = useState(false);
  const [isLockedWarningModalVisible, setLockedWarningModalVisible] = useState(false);
  const [sorting, setSorting] = useState({ sort: 'descend', sortedBy: 'orderNumber' });
  const [baseData, setBaseData] = useState(null);
  const [selectedRecord,setSelectedRecord] = useState(null);
  const [isAsohAvailableWarningVisible, setAsohAvailableWarningVisible] = useState(false);
  const [isOnClickRefresh, setOnClickRefresh] = useState(false);

  const { saveSearchConditionData } = useSaveSearchConditionData();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isDropOutResonVisible, setDropOutReasonVisible] = useState(false);
  const [dropOutRecord, setDropOutRecord] = useState(null);
  const [dropOutReason, setDropOutReason] = useState("DR001");
  const [isReasonCodeHint, setReasonCodeHint] = useState(false)
  const [allocatedRecord, setAllocatedRecord] = useState(null)
  // const [optimizationEnable,setOptimizationEnable] = useState(true);  //COT-8330
  const [sortedInfo, setSortedInfo] = useState({
    order: 'ascend',
    columnKey: 'cutoff',
  });

  useEffect(() => {
    const userId = auth.user.userId;
    fetchTerritoryData({userId, "isSpecial": true});
  },[]);//eslint-disable-line

  const onTableChange = (page, filters, sorter) => {
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : {
          sortedBy: 'orderNumber',
          sort: 'descend',
        };
    setSorting(sort);
    const formData = {
      orderDetail,
      ...sort,
    }
    saveOrderDetailData(formData);
  }; 
  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  }
  const onExportOuts = () => {
    const params = {...searchConditionData}
    if(searchText) {
      params.originalItem = searchText
    }
    params.callFrom = isViewDropOutVisible ? 'dropOuts' : 'outs'
    params.optimizationEnable = true;   //COT-8330,If remove optimization toggle,need hardcode optimizationEnable for export api
    const context = datadogRum.getGlobalContext('BosView');
    const timing = Date.now();
    exportOutsData(params); 
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Export ${context.BosView.screen} data`,timing);
    });
  }
  const onRefreshOuts = () => {

   
    // if(searchConditionData) {
    //   const formData = {
    //     ...searchConditionData,
    //     pageIndex: CONST_PAGINATION_TEN.pageIndex,
    //     pageSize: CONST_PAGINATION_TEN.pageSize,
    //    // ...outsSortingForRefresh
    //   };
    //   if(searchText) {
    //     formData.originalItem = searchText
    //   }
    //   if(isViewDropOutVisible) {
    //     fetchDropoutsData(formData);
    //   }else {
    //     fetchOutsData(formData);
    //   }
      //defect:cot-5316,when click reload will reset ccc/cst local option as same as search condition
      setOnClickRefresh(!isOnClickRefresh);
    // }
  }
  //defect: order detail re-design
  const handleViewOrderDetail =(record)=>{
    saveOrderDetailData({orderDetail:record.orderDetailBeans});
    onToggleModal(setOrderDetailModalVisible);
  }
  const handleViewInventoryDetail =(record)=>{
    let inventoryCondition = {
      // products: [
      //   {
      //     supc: record.originalItem,
      //     splitFlag: false,
      //   }
      // ],
      // businessUnitNumber: record.site,
      site: record.site,
      conceptId: !_.isEmpty(record.nationalId) ? record.nationalId : record.customerNumber,
      originalItem: record.originalItem
    }
    const timing = Date.now();
    fetchInventoryData(inventoryCondition);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch inventory`,timing);
    });
    onToggleModal(setInventoryDetailModalVisible);
  }
  
  const handleCheckOutsLock = (record)=>{
    setSelectedRecord(record);
    const formData = {
      site: record.site,
      itemNumber: record.originalItem,
      orders:record.orderNumbers
    }
    checkOutsLock(formData).then((response)=>{
      if(response && response[0].recordLockFlag === 'Y'){
        onToggleModal(setLockedWarningModalVisible);
      }else{
        handleAddSub(record);
      }
    },(err)=>{
      message.destroy();
      message.error(err);
    })
  }
  const handleUpdateOutsRecordLock =(record)=>{
    const formData = {
      site: record.site,
      itemNumber: record.originalItem,
      recordLockFlag:!isAddSubModalVisible?'Y':'N',
      orders:record.orderNumbers
    }
    updateOutsRecordLock(formData).then((response)=>{
    },(err)=>{
      message.error(`Update Outs Record Lock Failed, please try again.`);
    });
  }
  //COT-8253:UI-Ability for CCC Agents to Select Reason for Dropped Items
  const handleRemoveDropOut = (record) => {
    if (record) {
      const nowTime = moment().format(DATE_TIME_TWO_FORMAT)
      let formData = {
        site: record.site,
        siteDesc: record.siteDesc,
        originalItem: record.originalItem,
        originalBrand: record.originalItemBrand,
        // originalItemCategory
        originalItemDescription: record.originalItemDescription,
        originalPack: record.originalItemPack,
        originalSize: record.originalItemSize,
        // originalSplitIndicator
        originalStorageCode: record.originalItemStorageCode,
        originalUom: record.originalItemUom,
        orders: record.orderDetailBeans.map(item => item.orderNumber),
        orderLines: record.orderDetailBeans.map(item => item.orderLine),
        customerNumbers: record.orderDetailBeans.map(item => item.customerShipTo),
        nationalId: record.nationalId ? record.nationalId : "",
        nationalName: record.nationalName ? record.nationalName : "",
        customerNumber: record.customerNumber ? record.customerNumber : "",
        customerName: record.customerName ? record.customerName : "",
        createdById: auth.user.userId,
        createdByName: auth.user.userName,
        createTimestamp: nowTime,
        modifiedById: auth.user.userId,
        modifiedByName: auth.user.userName,
        modifiedTimestamp: nowTime,
        // endDate: (searchConditionData.deliveryDate + '').replace(/^(\d{4})(\d{2})(\d{2})$/, "$2/$3/$1"),
        endDate: document.getElementById('manage-subs-filter-form_deliveryDate').value,
      };
      if (searchConditionData && searchConditionData.managedBy === CONST_ROLE_OPTIONS_CCC && !_.isEmpty(record.categorizationFlag)) {
        formData = { ...formData, dropFlag: CONST_DROP_OUT_CST_FLAG_FROM_CCC }
      }
      if (searchConditionData && searchConditionData.managedBy === CONST_ROLE_OPTIONS_CCC) {
        formData = { ...formData, dropOutReason: dropOutReason,managedBy: 'CCC' }
      }
      removeDropOuts(formData)
        .then(
          response => {
            resetOutsData();
            if (
              searchConditionData.managedBy === CONST_ROLE_OPTIONS_CCC ||
              searchConditionData.managedBy === CONST_ROLE_OPTIONS_CST
            ) {
              //Alerts API
              const data = [];
              for (let order of record.orderDetailBeans) {
                const formData = {
                  nationalCustomerId: record.nationalId ? record.nationalId : '',
                  createdByUserId: auth.user.userId ? auth.user.userId : '',
                  createdDate: moment().format('MM/DD/YYYY'),
                  customerNumber: order.customerShipTo,
                  modifiedByUserId: auth.user.userId ? auth.user.userId : '',
                  modifiedDate: moment().format('MM/DD/YYYY'),
                  orderDetailLineNumber: order.orderLine,
                  orderNumber: order.orderNumber,
                  originalItem: record.originalItem,
                  opcoNumber: record.site,
                  substituteItemNumber: '',
                  substituteQuantity: '',
                  splitCode: '',
                  salesForceId: '',
                  managedBy: searchConditionData.managedBy,
                  category: record.categorizationFlag,
                  alertType: '',
                  batchIdType: '',
                  dropDate: document.getElementById('manage-subs-filter-form_deliveryDate').value,
                };
                data.push(formData);
              }
              alerts(data);
            }
            const params = { ...searchConditionData };
            if (searchText) {
              params.originalItem = searchText;
            }
            fetchOutsData({...params});
            message.info('Drop Outs Successfully.');
          },
          err => {
            message.destroy();
            message.error(`Drop Outs Failed, please try again.`);
          },
        )
        .finally(() => {
          cancelReasonModal();
        });
    }
  }
  const cancelReasonModal = () => {
    onToggleModal(setDropOutReasonVisible);
    updateOutsRecordLock({
      site: dropOutRecord.site,
      itemNumber: dropOutRecord.originalItem,
      recordLockFlag: 'N',
      orders: dropOutRecord.orderNumbers,
    }).then(
      response => {},
      err => { message.error(`Update Outs Record Lock Failed, please try again.`);},
    );
  };
  const handleDropOuts = (record) => {
    const timing = Date.now();
    const lockData = {
      site: record.site,
      itemNumber: record.originalItem,
      orders: record.orderNumbers,
    }
    checkOutsLock(lockData).then((response) => {
        if(response && response[0].recordLockFlag === 'Y') {
          onToggleModal(setLockedWarningModalVisible);
        }else {
        //COT-8253:BOS 2.0 - UI-Ability for CCC Agents to Select Reason for Dropped Items
        if(searchConditionData.managedBy === CONST_ROLE_OPTIONS_CCC){
           setDropOutRecord(record);
          onToggleModal(setDropOutReasonVisible);
          updateOutsRecordLock({
              site: record.site,
              itemNumber: record.originalItem,
              recordLockFlag: 'Y',
              orders: record.orderNumbers,
            }).then((response) => {},(err)=>{
              message.error(`Update Outs Record Lock Failed, please try again.`);
            });
        }else{
          const nowTime = moment().format(DATE_TIME_TWO_FORMAT)
          let formData = {
            site: record.site,
            siteDesc: record.siteDesc,
            originalItem: record.originalItem,
            originalBrand: record.originalItemBrand,
            // originalItemCategory
            originalItemDescription: record.originalItemDescription,
            originalPack: record.originalItemPack,
            originalSize: record.originalItemSize,
            // originalSplitIndicator
            originalStorageCode: record.originalItemStorageCode,
            originalUom: record.originalItemUom,
            orders: record.orderDetailBeans.map(item => item.orderNumber),
            orderLines: record.orderDetailBeans.map(item => item.orderLine),
            customerNumbers: record.orderDetailBeans.map(item=>item.customerShipTo),
            nationalId: record.nationalId ? record.nationalId : "",
            nationalName: record.nationalName ? record.nationalName : "",
            customerNumber: record.customerNumber ? record.customerNumber : "",
            customerName: record.customerName ? record.customerName : "",
            createdById: auth.user.userId,
            createdByName: auth.user.userName,
            createTimestamp: nowTime,
            modifiedById: auth.user.userId,
            modifiedByName: auth.user.userName,
            modifiedTimestamp: nowTime,
            // endDate: (searchConditionData.deliveryDate + '').replace(/^(\d{4})(\d{2})(\d{2})$/, "$2/$3/$1"),
            endDate: document.getElementById('manage-subs-filter-form_deliveryDate').value,
            //COT-8253:BOS 2.0 - UI-Ability for CCC Agents to Select Reason for Dropped Items
            dropOutReason: null,
            managedBy: searchConditionData.managedBy,
          };
          if (searchConditionData && searchConditionData.managedBy === CONST_ROLE_OPTIONS_CCC && !_.isEmpty(record.categorizationFlag)) {
            formData = { ...formData, dropFlag: CONST_DROP_OUT_CST_FLAG_FROM_CCC }
          }
          updateOutsRecordLock({
            site: record.site,
            itemNumber: record.originalItem,
            recordLockFlag: 'Y',
            orders: record.orderNumbers,
            }).then((response) => {
              
              removeDropOuts(formData).then((response) => {
                resetOutsData();            
                if(searchConditionData.managedBy===CONST_ROLE_OPTIONS_CCC || searchConditionData.managedBy===CONST_ROLE_OPTIONS_CST)
                {
                    //Alerts API
                    const data = [];
                  for(let order of record.orderDetailBeans){
                    const formData = {
                      nationalCustomerId: record.nationalId?record.nationalId:"",
                      createdByUserId: auth.user.userId?auth.user.userId:"",
                      createdDate: moment().format("MM/DD/YYYY"),
                      customerNumber: order.customerShipTo,
                      modifiedByUserId: auth.user.userId?auth.user.userId:"",
                      modifiedDate: moment().format("MM/DD/YYYY"),
                      orderDetailLineNumber:order.orderLine,
                      orderNumber:order.orderNumber,
                      originalItem: record.originalItem,
                      opcoNumber: record.site,
                      substituteItemNumber: "",
                      substituteQuantity: "",
                      splitCode:"",
                      salesForceId:"",
                      managedBy: searchConditionData.managedBy,
                      category:record.categorizationFlag,
                      alertType:"",
                      batchIdType:"",
                      dropDate:document.getElementById('manage-subs-filter-form_deliveryDate').value,

                    }
                    data.push(formData);
                  }
                  
                  alerts(data);

              
            }
            const params = {...searchConditionData}
            if(searchText) {
              params.originalItem = searchText
            }
            fetchOutsData(params);
            message.info('Drop Outs Successfully.');
          },
          (err) => {
            message.destroy();
            message.error(`Drop Outs Failed, please try again.`);
          });
        }).catch((err) => {
          message.error(`Update Outs Record Lock Failed, please try again.`);
        }).finally(() => {
          updateOutsRecordLock({
            site: record.site,
            itemNumber: record.originalItem,
            recordLockFlag: 'N',
            orders: record.orderNumbers,
          })
        });
      }
      }
    },(err)=>{
      message.destroy();
      message.error(err);
    })
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Drop outs`,timing);
    });
  }
  const handleAddSub =(record)=>{
    //COT-7911,If Substitute from dropOuts page need pass orderLines parameter in updateSubstitutionsData api
    let orderNumbers = [];
    let orderLines = [];
    let customerNumbers = [];
    for(let order of record.orderDetailBeans){
      orderNumbers.push(String(order.orderNumber));
      orderLines.push(order.orderLine);
      // if(validateGroupIdRegExp(record.nationalId)){
        customerNumbers.push(order.customerShipTo);
      // }
    }
    setBaseData({
      "site": record.site,
      "siteDesc": record.siteDesc,
      "nationalId": record.nationalId,
      "nationalName": record.nationalName,
      "customerNumber": record.customerNumber,
      "customerName": record.customerName,
      "conceptId": record.nationalId ? record.nationalId : record.customerNumber,
      "conceptName": record.nationalName ? record.nationalName : record.customerName,
      "originalItem": record.originalItem,
      "originalItemDescription": record.originalItemDescription,
      "originalItemUom": record.originalItemUom,
      "originalPack": record.originalItemPack,
      "originalSize": record.originalItemSize,
      "kosher": record.kosher,//COT-5366 show original item kosher 
      "originalBrand": record.originalItemBrand,
      "originalStorageCode": record.originalItemStorageCode,
      "casePrice": '---',
      "caseQuantity": record.asoh,
      "isFromOuts": true,
      "orderNumbers": orderNumbers, //COT-7911,If Substitute from dropOuts page need pass orderLines parameter in updateSubstitutionsData api
      "orderLines": orderLines, //COT-7911,If Substitute from dropOuts page need pass orderLines parameter in updateSubstitutionsData api
      "deliveryDate": document.getElementById('manage-subs-filter-form_deliveryDate').value,
      "totalOuts": record.totalOuts,
      "searchText": searchText,
      "callFrom": isViewDropOutVisible ? 'dropOuts' : 'outs',
      "customerNumbers": customerNumbers,
    })
    const timing = Date.now();
    fetchRecommendedProducts({
      "searchBy": "Item Description",
      "searchInput": record.originalItemDescription,
      "site": [record.site],
      "originalItemUom": record.originalItemUom,
      "originalItem": record.originalItem,
      "nationalId": record.nationalId,
      "customerNumber": record.customerNumber,
      "isFromOuts": true,
      "totalOuts": record.totalOuts,
    }).catch(err => {
      message.error(`Search Recommended Substitutions Failed, please try again.`);
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch Recommended Products`,timing);
    });
    onToggleModal(setAddSubModalVisible);
    handleUpdateOutsRecordLock(record);
  }
  const resetOrderDetail = ()=>{
    resetOrderDetailData();
  }
  const resetInventoryData = ()=>{
    resetInventoryDetailData();
  }
  const handleAllocateOriginalItem = (record) => {
    setAllocatedRecord(record)
    const lockData = {
      site: record.site,
      itemNumber: record.originalItem,
      orders: record.orderNumbers,
    }

    checkOutsLock(lockData).then((response) => {
      if(response && response[0].recordLockFlag === 'Y') {
        onToggleModal(setLockedWarningModalVisible);
      }else {
        if(record.asoh < record.totalOuts) {
          onToggleModal(setAsohAvailableWarningVisible);
        }else {
          onToggleModal(setReasonCodeHint);
        }
      }
    },(err)=>{
      message.destroy();
      message.error(err);
    })
  }

  const CONST_OUTS_TABLE_COLUMNS = [
    {
      title: 'Site ID',
      dataIndex: 'site',
      width: 100,
      blankWidth: 100,
      // sorter: true,
      render: false,
      extendRender: true,
      onCell: (record, rowIndex) => {
        if (record.requestApprovalFlag) {
          return {
            style: {
              borderLeft: 'solid 3px #ff0000'
            }
          }
        } else {
          return {}
        }
      },
    },
    {
      title: 'Group/Ship To',
      dataIndex: 'nationalId',
      width: 140,
      blankWidth: 140,
      sorter: true,  //defect:COT-5601,Manage Outs - The sorting indicator in the columns remains highlighted on reloading the data in Manage Outs page
      render: false,
      extendRender: true,
      sortOrder: sortedInfo.columnKey === 'nationalId' ? sortedInfo.order : null,
    },
    {
      title: 'Original Item',
      dataIndex: 'originalItem',
      width: 190,
      blankWidth: 190,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'originalItem' ? sortedInfo.order : null,
      render: false,
      extendRender: true,
    },
    {
      title: 'ASOH',
      dataIndex: 'asoh',
      width: 50,
      blankWidth: 50,
      // sorter: true,
      // render: false,
      render: (text, record) =>renderCellAsoh(text,record)
    },
    {
      title: 'Total Orders',
      dataIndex: 'totalOrders',
      width: 100,
      blankWidth: 100,
      // sorter: true,
      // render: false,
      render: (text, record) =>renderCellTotalOrders(text,record)
    },
    {
      title: 'Total Outs',
      dataIndex: 'totalOuts',
      width: 90,
      blankWidth: 90,
      // sorter: true,
      render: false,
    },
    {
      title: 'Cutoff',
      dataIndex: 'cutoff',
      width: 70,
      blankWidth: 70,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'cutoff' ? sortedInfo.order : null,
      render: false,
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };
  const resetSortedInfo = () => {
    setSortedInfo({
      order: 'ascend',
      columnKey: 'cutoff',
    });
  };
  const renderCellAsoh = (text,record )=>{
    return (
        <div>
          <Button type="link" block onClick={()=>handleViewInventoryDetail(record)}>
            {
            `${record.asoh}`
            }
          </Button>   
        </div>
      )
  }
  const renderCellTotalOrders = (text,record )=>{
    return (
        <Button type="link" block onClick={()=>handleViewOrderDetail(record)}>
          {
          `${record.totalOrders}`
          }
        </Button>   
      )
  }
  // const [pagination, setPagination] = useState(paginationSetting);
  const renderAllocateOriginaItemlWarningModal = (
    <ManageOutsWarningModal
      title=""
      isModalVisible={isAsohAvailableWarningVisible}
      footer={false}
      width={500}
      onToggleModal={() => {onToggleModal(setAsohAvailableWarningVisible);}}
      content="Available ASOH of the original item is not enough.Kindly add a subs item."
    />
  );
  const handleCancel = () => {
    onToggleModal(setReasonCodeHint);
  };
  const handleOk = () => {
    onToggleModal(setReasonCodeHint);
    const data = [];
    for(let order of allocatedRecord.orderDetailBeans){
      const formData = {
        accountOwner: "",
        accountOwnerPhone: "",
        action: "A",
        approvalRequired: "",
        bosCutOff: 0,
        conceptId: allocatedRecord.nationalId?allocatedRecord.nationalId:"",
        createdBy: auth.user.userId?auth.user.userId:"",
        createdTimestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        customerNumber: order.customerShipTo,
        dataType: "",
        dropFlag: "",
        effectiveEndDate: "",
        inclusionExclusion: "",
        localCentral: "",
        modifiedBy: "",
        modifiedTimestamp: "",
        orderDetailLineNumber:order.orderLine,
        orderNumber:order.orderNumber,
        originalBrand: allocatedRecord.originalItemBrand ? allocatedRecord.originalItemBrand : '',
        originalItem: allocatedRecord.originalItem,
        originalItemDescription: allocatedRecord.originalItemDescription,
        originalPack: allocatedRecord.originalItemPack ? allocatedRecord.originalItemPack : '',
        originalSize: allocatedRecord.originalItemSize ? allocatedRecord.originalItemSize : '',
        originalStorageCode: allocatedRecord.originalItemStorageCode ? allocatedRecord.originalItemStorageCode : '',
        originalSplitIndicator: allocatedRecord.originalItemUom==='EA'?'Y':'N',
        reasonCode: "",
        reasonCodeDescription: "",
        ruleType: "AO",
        site: allocatedRecord.site,
        startOfDayEmail: "",
        subOutOfCatalog: "",
        subRatio: 0,
        subrulePriority: 0,
        substituteBrand: "",
        substituteItem: "",
        substituteItemDescription: "",
        substitutePack: "",
        substituteSize: "",
        substituteStorageCode: "",
        substitutelSplitIndicator: ""
      }
      data.push(formData);
    }
    const timing = Date.now();
    allocateOriginalItem(data).then((response) => {
      message.info('Allocate Original Item Successfully.');
    },
    (err) => {
      message.destroy();
      message.error(`Allocate Original Item Failed, please try again.`);
    });
    const removeData = {
      site: allocatedRecord.site,
      originalItem: allocatedRecord.originalItem,
      nationalId: allocatedRecord.nationalId?allocatedRecord.nationalId:"",
      customerNumber: allocatedRecord.customerNumber?allocatedRecord.customerNumber:"",
      orders: allocatedRecord.orderNumbers,
      createdById: auth.user.userId,
      originalItemDescription: allocatedRecord.originalItemDescription,
      callFrom: isViewDropOutVisible ? 'dropOuts' : 'outs',
    };
    removeAllocatedItem(removeData).finally(() => {
      const payload = {...searchConditionData}
      if(searchText) {
        payload.originalItem = searchText
      }
      //COT-8309:Latency issue when user drop out or search for subs in manage outs
      // payload.optimizationEnable = optimizationEnable;  //COT-8330
      if(isViewDropOutVisible) {
        resetDropoutsData();
        fetchDropoutsData({...payload});
      }else {
        resetOutsData();
        fetchOutsData({...payload});
      }
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Allocate Original Item`,timing);
    });
  };
  const renderDropOutResonModal =(
    <DropOutReasonModal
    // title=""
    isModalVisible={isDropOutResonVisible}
    footer={[
      <Button key="back" className="default-btn" onClick={() =>cancelReasonModal()}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" className='primary-btn' style={{marginLeft:"6px"}} onClick={()=>handleRemoveDropOut(dropOutRecord)}>
        Proceed
      </Button>,
    ]}
    width={400}
    onToggleModal={() => cancelReasonModal()}
    content="Please select drop out reason"
    dropOutReason={dropOutReason}
    setDropOutReason={setDropOutReason}
    />
  )
  const renderReasonCodeHintModal = (
    <ManageOutsWarningModal
      title=""
      isModalVisible={isReasonCodeHint}
      footer={[
        <Button key="back" className="default-btn" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" className='primary-btn' style={{marginLeft:"6px"}} onClick={handleOk}>
          Proceed
        </Button>,
      ]}
      width={500}
      onToggleModal={() => {onToggleModal(setReasonCodeHint);}}
      content="The original item was not allocated due to the below reason codes"
      record={allocatedRecord}
      isReasonCodeHint={true}
    />
  );
  const renderLockedWarningModal = (
    <ManageOutsWarningModal
      title=""
      isModalVisible={isLockedWarningModalVisible}
      footer={false}
      width={500}
      onToggleModal={() => {onToggleModal(setLockedWarningModalVisible);}}
      content="Maintenance is disabled for this record as this is being worked on by another user session."
    />
  );
  
  const renderOrderDetailModal = (
    <ManageOutsOperateModal
      title="VIEW ORDER DETAILS"
      isModalVisible={isOrderDetailModalVisible}
      footer={false}
      width={1100}
      onToggleModal={() => {onToggleModal(setOrderDetailModalVisible); resetOrderDetail();setSorting({ sort: 'descend', sortedBy: 'orderNumber'})}}
      onTableChange={onTableChange}
      idle={()=>{}}
    />
  );
  const renderInventoryDetailModal = (
    <ManageOutsOperateModal
      title="VIEW INVENTORY DETAILS"
      isModalVisible={isInventoryDetailModalVisible}
      footer={false}
      width={650}
      onToggleModal={() => {onToggleModal(setInventoryDetailModalVisible); resetInventoryData();}}
      idle={()=>{}}
      
    />
  );
  const renderAddSubModal = (
    <ManageOutsOperateModal
      title="ADDSUB"
      isModalVisible={isAddSubModalVisible}
      footer={false}
      width={1210}
      onToggleModal={() => {onToggleModal(setAddSubModalVisible); resetRecommendedProducts(); resetSubstituteProducts(); handleUpdateOutsRecordLock(selectedRecord)}}
      okText="Sub"
      baseData={baseData}
      idle={()=>{notification.warning({
        message: `Idle Time Surpassed 5 minutes`,
        placement:'top',
        duration:0,
      })}}
      // optimizationEnable={optimizationEnable}
    />
  );
  const renderOutsParentElement = [
    { //render siteID
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.siteDesc}</div>
          </>
        )
      }
    },
    { //render conceptID
      extendRender: (value, label, record) => {
        //defect:COT-5285, prioritize customer if customerNumber and national Id are not empty
        if(record.customerNumber && record.customerNumber.length>0){
          return (
            <>
              <div className="col-title">{record.customerNumber}</div>
              <div className="col-desc">{record.customerName}</div>
            </>
          )
        }
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.nationalName}</div>
          </>
        )
        // if(value && value.length > 0) {
        //   return (
        //     <>
        //       <div className="col-title">{value}</div>
        //       <div className="col-desc">{record.nationalName}</div>
        //     </>
        //   )
        // }
        // return (
        //   <>
        //     <div className="col-title">{record.customerNumber}</div>
        //     <div className="col-desc">{record.customerName}</div>
        //   </>
        // )
      }
    },
    { //render originalItem
      extendRender: (value, label, record) => {
        let originalItemDetails = ``;
        if(value) {
          originalItemDetails = `${record.originalItemUom} | ${record.originalItemPack}/${record.originalItemSize} | ${record.originalItemBrand} | ${record.originalItemStorageCode}`;
        }
        let category = record.categorizationFlag;
        let categoryIcon = '';
        if(category) {
          category = category.trim();
          categoryIcon = category === 'C' ? <Badge className="sub-badge-item" color="#7AC943"/>
            : category === 'D' ? <Badge className="sub-badge-item" color="#FF931E"/>
            : category === 'B' ? <Badge className="sub-badge-item" color="#FFFF00"/>
            : category === 'A' ? <Badge className="sub-badge-item" color="#FF1022"/>
            : <span className="blank-badge" />
        } else {
          categoryIcon = <span className="blank-badge" />
        }
        return (
          <>
            <div className="col-title">
              {categoryIcon}
              {value}
            </div>
            <div className="col-desc handle-overflow detail-info">{record.originalItemDescription}</div>
            <div className="col-desc handle-overflow detail-info">{originalItemDetails}</div>
          </>
        )
      }
    },
    //asoh placeholder
    {},
    //total orders placeholder
    {},
    //total outs placeholder
    {},
    // cut off placeholder
    {},
  ];
  const inputRef = useRef();
  let searchForm = null;
  let clearFiltersRef = null;
  let confirmRef = null;
  const handleTerritoryParam = (values) => {
    let isCorpProfileUser = false
    if(originalList.length === 0) {
      const user = CORP_USER_LIST.filter(item => item.userId === userId);
      if (!_.isEmpty(user)) {
        isCorpProfileUser = true
      }
    }
    if((territoryData && ((territoryData.role && territoryData.role.toLowerCase() === 'corp') || _.isEmpty(territoryData.role))) || isCorpProfileUser) {
      let temp = values.territoryInfoList ? values.territoryInfoList.trim() : ''
      if(temp) {
        return [{"terrName": temp}]
      }else {
        return []
      }
    }else {
      return values.territoryInfoList !== undefined && values.territoryInfoList !== null ? uniqueTerritoryList(formatTerrirotyData(values, territoryData)) : null
    }
  }
  const handleOriginalItemSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        deliveryDate: formVals.deliveryDate !== undefined && formVals.deliveryDate !== null ? parseInt(moment(formVals.deliveryDate).format(COMMON_DATE_FORMAT)) : null,
        customerNumber: formVals.customerNumber === '' ? null : formVals.customerNumber,
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const handleOriginalItemReset = (clearFilters, confirm, dataIndex) => {
    setSearchText('');
    clearFilters();
    confirm({
      closeDropdown: true,
    });
    setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        deliveryDate: formVals.deliveryDate !== undefined && formVals.deliveryDate !== null ? parseInt(moment(formVals.deliveryDate).format(COMMON_DATE_FORMAT)) : null,
        customerNumber: formVals.customerNumber === '' ? null : formVals.customerNumber,
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const originalItemSearchReset = () => {
    setSearchText('');
    if(clearFiltersRef) {
      clearFiltersRef()
    }
    if(confirmRef) {
      confirmRef({
        closeDropdown: true,
      });
    }
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      clearFiltersRef = clearFilters;
      confirmRef = confirm;
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={inputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleOriginalItemReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: filtered => <SearchOutlined style={{ fontSize: '16px', color: filtered ? 'blue' : undefined }} />,
    // onFilter: (value, record) =>{
    //   return record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : ''
    // },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select(), 100);
      }
    },
  });
  const searchFormCb = (form) => {
    searchForm = form;
  };
  CONST_OUTS_TABLE_COLUMNS[2] = {...CONST_OUTS_TABLE_COLUMNS[2], ...getColumnSearchProps('originalItem')};
  let outsParentColumns = renderTableColumns(CONST_OUTS_TABLE_COLUMNS, null, renderOutsParentElement);
  outsParentColumns.push({
    title: 'Actions',
    dataIndex: 'ruleType',
    width: 60,
    blankWidth: 60,
    render: (value, record) => {
      return (
        <div className="flex parent-actions">
          <Button title="Search for Sub" style={{width:'24px'}} disable="false" icon={<EditIcon />} type="link" onClick={()=>{handleCheckOutsLock(record)}}/>
          { !isViewDropOutVisible && (
          <Button title="Drop Out" style={{paddingLeft:'5px',width:'42px'}} disable="false" icon={<DropIcon  />} type="link"  onClick={()=>{handleDropOuts(record)}}/>)}
          <Button title="Allocate Original Item" style={{width:'30px'}} disable="false" icon={<CartIcon  />} type="link" onClick={()=>{handleAllocateOriginalItem(record)}}/>
          {/* <Button disable="false" icon={<EyeFilled />} type="link" onClick={(record)=>{handleViewOrderDetail(record)}}/>
          <Button disable="false" icon={<ExclamationCircleFilled />} type="link" onClick={(record)=>{handleViewInventoryDetail(record)}}/> */}
        </div>
      );
    }
  });
    
  // const onToggleOptimization = checked => {
  //   setOptimizationEnable(checked);
  // }
  return (
    <div className="manage-subs-manage-outs">
      <Spin spinning={exportOutsDataPending}>
        <div className="chart-title font-size-16-bold"  style={{font: "Myriad Bold"}}>Search Outs</div>
        {/* defect:cot-5316,when click reload will reset ccc/cst local option as same as search condition */}
        <SearchFiltersPanel isOnClickRefresh={isOnClickRefresh} searchForm={searchFormCb} originalItemSearchReset={originalItemSearchReset} resetSortedInfo={resetSortedInfo}/>
        <div className="flex align-item-center justify-end" style={{marginBottom:"20px", marginTop:"-45px"}}>
            
          {/* <Button className="subs-header-btn" onClick={onRefreshOuts} icon={<SyncOutlined />} disabled={_.isEmpty(searchConditionData)}/> */}
          <Button type="link" title="Reload" disabled={_.isEmpty(searchConditionData)} onClick={onRefreshOuts} >
            <FontAwesomeIcon icon={faArrowsRotate} color={_.isEmpty(searchConditionData)?"#a1a1a2":"#6b6b6c"} style={{ height: "20px", width: "20px" }}/>
            <p style={{marginTop:"-5px", fontSize:"11px", marginLeft:"-3px", color:(_.isEmpty(searchConditionData)?"#a1a1a2":"#6b6b6c")}}>Reload</p>
          </Button>

          <Button type="link" title="View Drop Outs" disabled={_.isEmpty(searchConditionData)} onClick={()=>{setViewDropOutModalVisible(!isViewDropOutVisible);}} style={{marginLeft:'-20px'}}>
            <FontAwesomeIcon icon={faEye} color={_.isEmpty(searchConditionData)?"#a1a1a2":(isViewDropOutVisible?"#40a9ff":"#6b6b6c")} style={{ height: "20px", width: "20px" }}/>
            <p style={{marginTop:"-5px", fontSize:"11px", marginLeft:"-3px", color:(_.isEmpty(searchConditionData)?"#a1a1a2":"#6b6b6c")}}>View Drop Outs</p>
          </Button>

          {/* <Button className="subs-header-btn" onClick={onExportOuts} icon={<DownloadOutlined />} disabled={_.isEmpty(searchConditionData)} /> */}
          <Button type="link" title="Export" disabled={_.isEmpty(searchConditionData)} onClick={onExportOuts} style={{marginLeft:'-20px'}}>
            <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(searchConditionData)?"#a1a1a2":"#6b6b6c"} style={{ height: "20px", width: "20px" }}/>
            <p style={{marginTop:"-5px", fontSize:"11px", marginLeft:"-3px", color:(_.isEmpty(searchConditionData)?"#a1a1a2":"#6b6b6c")}}>Export</p>
          </Button> 
        </div>
        <CommonTable 
        //defect:COT-5293、5294、5295, help Melody to commit
          rowKey={record => `${record.uid}-${record.site}-${record.nationalId}-${record.customerNumber}-${record.originalItem}-${record.originalItemUom}`}
          columns={outsParentColumns}
          tableClassName={outsData ? "outs-report-table" : ""}
          datasource={isViewDropOutVisible?dropoutsData: outsData}
          loadingData={fetchOutsDataPending || checkOutsLockPending || updateOutsRecordLockPending || removeDropOutsPending || 
            fetchDropoutsDataPending || removeAllocatedItemPending || allocateOriginalItemPending}
          paginationStyle={{textAlign: "right", margin: '16px 0px'}}
          triggerConditions={searchConditionData}
          searchText={searchText}
          onFecthData={isViewDropOutVisible? fetchDropoutsData : fetchOutsData}
          onResetStore={isViewDropOutVisible? resetDropoutsData : resetOutsData}
          scrollStyle={{ x: 'max-content', y: 300 }}
          paginationSetting={CONST_PAGINATION_TEN}
          isdropout={isViewDropOutVisible? "yes" : null}
          isRefreshed={isOnClickRefresh}
          onOutsTableChange={handleTableChange}
          // optimizationEnable={optimizationEnable}
        />
        {isOrderDetailModalVisible && renderOrderDetailModal}
        {isInventoryDetailModalVisible && renderInventoryDetailModal}
        {isAddSubModalVisible && renderAddSubModal}
        {isLockedWarningModalVisible && renderLockedWarningModal}
        {isAsohAvailableWarningVisible && renderAllocateOriginaItemlWarningModal}
        {isReasonCodeHint && renderReasonCodeHintModal}
        {isDropOutResonVisible && renderDropOutResonModal}
      </Spin>
      {/* <div className="optimization-btn">
        <Switch
          className="outs-switch"
          size="small"
          defaultChecked
          onChange={checked => onToggleOptimization(checked)}
          checked={optimizationEnable}
        />
      </div> */}
    </div>
  );
};
