import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_BEGIN,
  MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_SUCCESS,
  MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_FAILURE,
  MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function checkIfExists4thSubRule(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/checkIfExists4thSubRule`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCheckIfExists4thSubRuleError() {
  return {
    type: MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_DISMISS_ERROR,
  };
}

export function useCheckIfExists4thSubRule() {
  const dispatch = useDispatch();

  const { checkIfExists4thSubRulePending, checkIfExists4thSubRuleError } = useSelector(
    state => ({
      checkIfExists4thSubRulePending: state.manageSubs.checkIfExists4thSubRulePending,
      checkIfExists4thSubRuleError: state.manageSubs.checkIfExists4thSubRuleError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(checkIfExists4thSubRule(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCheckIfExists4thSubRuleError());
  }, [dispatch]);

  return {
    checkIfExists4thSubRule: boundAction,
    checkIfExists4thSubRulePending,
    checkIfExists4thSubRuleError,
    dismissCheckIfExists4thSubRuleError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        checkIfExists4thSubRulePending: true,
        checkIfExists4thSubRuleError: null,
      };

    case MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_SUCCESS:
      // The request is success
      return {
        ...state,
        checkIfExists4thSubRulePending: false,
        checkIfExists4thSubRuleError: null,
      };

    case MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_FAILURE:
      // The request is failed
      return {
        ...state,
        checkIfExists4thSubRulePending: false,
        checkIfExists4thSubRuleError: action.data.error,
      };

    case MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        checkIfExists4thSubRuleError: null,
      };

    default:
      return state;
  }
}
