import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   MANAGE_SUBS_RESET_DROPOUTS_DATA,
} from './constants';

export function resetDropoutsData() {
  return {
    type:  MANAGE_SUBS_RESET_DROPOUTS_DATA,
  };
}

export function useResetDropoutsData() {
  const dispatch = useDispatch();
  const  dropoutsData = useSelector(state => state.manageSubs.dropoutsData);
  const boundAction = useCallback((...params) => dispatch(resetDropoutsData()), [dispatch]);
  return {  dropoutsData, resetDropoutsData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case  MANAGE_SUBS_RESET_DROPOUTS_DATA:
      return {
        ...state,
         dropoutsData: null,
      };

    default:
      return state;
  }
}
