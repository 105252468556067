import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { OrderSearchFilters, OrderTrailOrders, BusinessEventLog } from './';
import { Spin, Layout } from 'antd';
import { ErrorPage } from '../common';
import { AUTH_ERROR_MSG } from '../../common/constants';
import { redirectUrl } from '../../common/apiConfig';
export default function OrderTrail() {
  const { fetchSnapshotDataPending,fetchOrderTrailDataError,fetchSnapshotDataError,fetchBusinessEventLogDataError,businessEventLogData,
    fetchBusinessEventLogDataPending, } = useSelector(state => ({
    fetchSnapshotDataPending: state.orderTrail.fetchSnapshotDataPending,
    fetchOrderTrailDataError: state.orderTrail.fetchOrderTrailDataError,
    fetchSnapshotDataError: state.orderTrail.fetchSnapshotDataError,
    fetchBusinessEventLogDataError: state.orderTrail.fetchBusinessEventLogDataError,
    businessEventLogData: state.orderTrail.businessEventLogData,
    fetchBusinessEventLogDataPending: state.orderTrail.fetchBusinessEventLogDataPending,
  }));
  const [selectedOrder, setSelectedOrder] = useState();
  const isErrorPage = fetchOrderTrailDataError || fetchSnapshotDataError || fetchBusinessEventLogDataError;
  if (fetchOrderTrailDataError === AUTH_ERROR_MSG || fetchSnapshotDataError === AUTH_ERROR_MSG || fetchBusinessEventLogDataError) {
    window.location.assign(redirectUrl);
  } else if (isErrorPage) {
    return <ErrorPage />;
  }
  return (
    <div className="order-trail-order-trail">
      <Spin size="large" tip="Loading..." 
        spinning={fetchSnapshotDataPending}
      >
        <div className='customizations-layout-sider'>
        <Layout>
          <Layout.Sider width="220" theme="light">
            <OrderSearchFilters setSelectedOrder={setSelectedOrder} />
          </Layout.Sider>
          <Layout.Content className="sub-layout-content">
            <OrderTrailOrders setSelectedOrder={setSelectedOrder} />
            {/* <BusinessEventLog selectedOrder={selectedOrder} /> */}
            {
              businessEventLogData?<BusinessEventLog selectedOrder={selectedOrder} />:null
            }
          </Layout.Content>
        </Layout>
        </div>
      </Spin>
    </div>
  );
}

OrderTrail.propTypes = {};
OrderTrail.defaultProps = {};
