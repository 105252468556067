import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_GROUP_IDS_DATA,
} from './constants';

export function resetGroupIdsData() {
  return {
    type: MANAGE_SUBS_RESET_GROUP_IDS_DATA,
  };
}

export function useResetGroupIdsData() {
  const dispatch = useDispatch();
  const groupIdsData = useSelector(state => state.manageSubs.groupIdsData);
  const boundAction = useCallback((...params) => dispatch(resetGroupIdsData(...params)), [dispatch]);
  return { groupIdsData,resetGroupIdsData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_GROUP_IDS_DATA:
      return {
        ...state,
        groupIdsData: null,
      };

    default:
      return state;
  }
}
