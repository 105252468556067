import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_EXPORT_OOS_ACCOUNT_ORDER_BEGIN,
  REPORTS_EXPORT_OOS_ACCOUNT_ORDER_SUCCESS,
  REPORTS_EXPORT_OOS_ACCOUNT_ORDER_FAILURE,
  REPORTS_EXPORT_OOS_ACCOUNT_ORDER_DISMISS_ERROR,
} from './constants';
import { EXPORT_API_REQUEST_URL } from '../../../common/apiConfig';
export function exportOosAccountOrder(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_EXPORT_OOS_ACCOUNT_ORDER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      if(args.sortedBy === undefined || args.sortedBy === null) {
        args = {
          ...args,
          sort: "ascend",
          sortedBy: "site",
        }
      }
      const doRequest = axios.post(`${EXPORT_API_REQUEST_URL}/oosAccount/exportItemReport`, args);
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_EXPORT_OOS_ACCOUNT_ORDER_SUCCESS,
            data: res.data.data,
          });
          resolve(res.data.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_EXPORT_OOS_ACCOUNT_ORDER_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissExportOosAccountOrderError() {
  return {
    type: REPORTS_EXPORT_OOS_ACCOUNT_ORDER_DISMISS_ERROR,
  };
}

export function useExportOosAccountOrder() {
  const dispatch = useDispatch();

  const { exportOosAccountOrderPending, exportOosAccountOrderError } = useSelector(
    state => ({
      exportOosAccountOrderPending: state.reports.exportOosAccountOrderPending,
      exportOosAccountOrderError: state.reports.exportOosAccountOrderError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(exportOosAccountOrder(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportOosAccountOrderError());
  }, [dispatch]);

  return {
    exportOosAccountOrder: boundAction,
    exportOosAccountOrderPending,
    exportOosAccountOrderError,
    dismissExportOosAccountOrderError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_EXPORT_OOS_ACCOUNT_ORDER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportOosAccountOrderPending: true,
        exportOosAccountOrderError: null,
      };

    case REPORTS_EXPORT_OOS_ACCOUNT_ORDER_SUCCESS:
      // The request is success
      return {
        ...state,
        exportOosAccountOrderPending: false,
        exportOosAccountOrderError: null,
      };

    case REPORTS_EXPORT_OOS_ACCOUNT_ORDER_FAILURE:
      // The request is failed
      return {
        ...state,
        exportOosAccountOrderPending: false,
        exportOosAccountOrderError: action.data.error,
      };

    case REPORTS_EXPORT_OOS_ACCOUNT_ORDER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportOosAccountOrderError: null,
      };

    default:
      return state;
  }
}
