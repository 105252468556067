import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { EXPORT_API_REQUEST_URL } from '../../../common/apiConfig';
import {
  DASHBOARD_EXPORT_REPORT_DATA_BEGIN,
  DASHBOARD_EXPORT_REPORT_DATA_SUCCESS,
  DASHBOARD_EXPORT_REPORT_DATA_FAILURE,
  DASHBOARD_EXPORT_REPORT_DATA_DISMISS_ERROR,
  ERROR_CONTACT_ADMIN
} from './constants';
export function exportReportData(condition = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_EXPORT_REPORT_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${EXPORT_API_REQUEST_URL}/getExportData`, condition, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: DASHBOARD_EXPORT_REPORT_DATA_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : ERROR_CONTACT_ADMIN;
          dispatch({
            type: DASHBOARD_EXPORT_REPORT_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissExportReportDataError() {
  return {
    type: DASHBOARD_EXPORT_REPORT_DATA_DISMISS_ERROR,
  };
}

export function useExportReportData() {
  const dispatch = useDispatch();

  const { allReportData, exportReportDataPending, exportReportDataError } = useSelector(
    state => ({
      allReportData: state.dashboard.allReportData,
      exportReportDataPending: state.dashboard.exportReportDataPending,
      exportReportDataError: state.dashboard.exportReportDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(exportReportData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportReportDataError());
  }, [dispatch]);

  return {
    allReportData,
    exportReportData: boundAction,
    exportReportDataPending,
    exportReportDataError,
    dismissExportReportDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_EXPORT_REPORT_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportReportDataPending: true,
        exportReportDataError: null,
      };

    case DASHBOARD_EXPORT_REPORT_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        allReportData: action.data,
        exportReportDataPending: false,
        exportReportDataError: null,
      };

    case DASHBOARD_EXPORT_REPORT_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        exportReportDataPending: false,
        exportReportDataError: action.data.error,
      };

    case DASHBOARD_EXPORT_REPORT_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportReportDataError: null,
      };

    default:
      return state;
  }
}
