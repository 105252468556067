export const CONST_OUTS_ORDER_DETAIL_TABLE_COLUMNS = [
  {
    title: 'Concept Id/Group',
    dataIndex: 'conceptId',
    width: 100,
    blankWidth: 100,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Original Item',
    dataIndex: 'orignalItem',
    width: 150,
    blankWidth: 150,
    // sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Ship to',
    dataIndex: 'customerShipTo',
    width: 80,
    blankWidth: 80,
    // sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Order No',
    dataIndex: 'orderNumber',
    width: 50,
    blankWidth: 50,
    sorter: true,
    render: false,
    extendRender: false,
  },
  {
    title: 'Order Line',
    dataIndex: 'orderLine',
    width: 70,
    blankWidth: 70,
    // sorter: true,
    render: false,
  },
  {
    title: 'Ordered Quantity',
    dataIndex: 'orderedQuantity',
    width: 60,
    blankWidth: null,
    // sorter: true,
    render: false,
  },
  {
    title: 'Allocated Quantity',
    dataIndex: 'allocatedQuantity',
    width: 60,
    blankWidth: 60,
    // sorter: true,
    render: false,
  },
  {
    title: 'Out Quantity',
    dataIndex: 'outQuantity',
    width: 60,
    blankWidth: 60,
    // sorter: true,
    render: false,
  },
];
export const CONST_OUTS_UOM_OPTIONS = [
  { label: 'EA', key: 'EA' },
  { label: 'CS', key: 'CS' },
];
export const STORAGE_CODE_LIST = [
  { value: 'Cooler', label: 'C' },
  { value: 'Dry', label: 'D' },
  { value: 'Frozen', label: 'F' },
  { value: 'Hazardous', label: 'H' },
  { value: 'Ice Cream', label: 'I' },
  { value: 'Caustic', label: 'K' },
  { value: 'Medical', label: 'M' },
]
export const CONST_ROLE_OPTIONS_CCC = 'CCC';
export const CONST_ROLE_OPTIONS_CST = 'CST';
export const CONST_ROLE_OPTIONS_LOCAL = 'LOCAL';
export const CONST_DROP_OUT_CST_FLAG_FROM_CCC = 'P';