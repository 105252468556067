import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  REPORTS_FETCH_SUMMARY_ORDER_DATA_BEGIN,
  REPORTS_FETCH_SUMMARY_ORDER_DATA_SUCCESS,
  REPORTS_FETCH_SUMMARY_ORDER_DATA_FAILURE,
  REPORTS_FETCH_SUMMARY_ORDER_DATA_DISMISS_ERROR,
} from './constants';

export function fetchSummaryOrderData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_SUMMARY_ORDER_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/getSalesSummaryOrderReport`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_FETCH_SUMMARY_ORDER_DATA_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              orderSummary: res.data && res.data.summary
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: REPORTS_FETCH_SUMMARY_ORDER_DATA_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchSummaryOrderDataError() {
  return {
    type: REPORTS_FETCH_SUMMARY_ORDER_DATA_DISMISS_ERROR,
  };
}

export function useFetchSummaryOrderData() {
  const dispatch = useDispatch();

  const { reportsOrderData, reportsOrderTotals,orderSummary, fetchSummaryOrderDataPending, fetchSummaryOrderDataError } = useSelector(
    state => ({
      reportsOrderData: state.reports.reportsOrderData,
      reportsOrderTotals: state.reports.reportsOrderTotals,
      orderSummary: state.reports.orderSummary,
      fetchSummaryOrderDataPending: state.reports.fetchSummaryOrderDataPending,
      fetchSummaryOrderDataError: state.reports.fetchSummaryOrderDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchSummaryOrderData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchSummaryOrderDataError());
  }, [dispatch]);

  return {
    reportsOrderData,
    reportsOrderTotals,
    orderSummary,
    fetchSummaryOrderData: boundAction,
    fetchSummaryOrderDataPending,
    fetchSummaryOrderDataError,
    dismissFetchSummaryOrderDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_SUMMARY_ORDER_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchSummaryOrderDataPending: true,
        fetchSummaryOrderDataError: null,
      };

    case REPORTS_FETCH_SUMMARY_ORDER_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        reportsOrderData: action.data.data,
        reportsOrderTotals: action.data.totals,
        orderSummary: action.data.orderSummary,
        fetchSummaryOrderDataPending: false,
        fetchSummaryOrderDataError: null,
      };

    case REPORTS_FETCH_SUMMARY_ORDER_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSummaryOrderDataPending: false,
        fetchSummaryOrderDataError: action.data.error,
      };

    case REPORTS_FETCH_SUMMARY_ORDER_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchSummaryOrderDataError: null,
      };

    default:
      return state;
  }
}
