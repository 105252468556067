import moment from 'moment';
import { TIME_HOUR_MINUES } from '../../common/constants';
export const transferFromResultToForm = (originalData) => {
  let resultData = JSON.parse(JSON.stringify(originalData));
  if(resultData.nationalId.length === 0) {
    resultData.nationalId = resultData.customerNumber;
    resultData.nationalName = resultData.customerName;
  }
  Object.keys(resultData).forEach(item => {
    if(resultData[item] === 'Y' || resultData[item] === 'LOCAL') {
      resultData[item] = true;
    } else if(resultData[item] === 'N' || resultData[item] === 'CCC') {
      resultData[item] = false;
    } else if(item === 'nationalIdCutoffTime') {
      resultData[item] = resultData[item] && moment(resultData[item], TIME_HOUR_MINUES);
    }
  });
  
  return resultData;
}

export const transferFromFormToResult = originalData => {
  let resultData = JSON.parse(JSON.stringify(originalData));
  Object.keys(resultData).forEach(item => {
    switch(item) {
      case 'managedBy':
        resultData[item] = resultData[item] === undefined || resultData[item] ? 'LOCAL' : 'CCC';
        return;
      case 'maintenanceFlag':
      case 'startOfDay':
        resultData[item] = resultData[item] === undefined || resultData[item] ? 'Y' : 'N';
        return;
      case 'approvalRequiredFlag':
      case 'catalogFlag':
        resultData[item] = resultData[item] === undefined || !resultData[item] ? 'N' : 'Y';
        return;
      case 'nationalIdCutoffTime':
        resultData[item] = moment(resultData[item]).format(TIME_HOUR_MINUES);
        return;
      default:
        return;
    };
  });
  return resultData;
};