import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Table, Button, Checkbox, Alert, Tag } from 'antd';
import _ from 'lodash';
import {
  CONST_BUSINESS_EVENT_LOG_COLUMNS,
  CONST_PLAIN_OPTION,
} from '../../common/orderTrailConstants';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadFile } from '../../common/downloadReportFunc';
import { useFetchSnapshotData } from './redux/hooks';
import { renderTableColumns, renderSheetData, renderSimpleTableColumns } from '../../common/renderColumns';
import { SnapshotReportModal } from './';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
export default function BusinessEventLog(props) {
  const {
    orderTrailData,
    businessEventLogData,
    fetchBusinessEventLogDataPending,
    businessEventBannerData,
    snapshotData,
  } = useSelector(state => ({
    orderTrailData: state.orderTrail.orderTrailData,
    businessEventLogData: state.orderTrail.businessEventLogData,
    fetchBusinessEventLogDataPending: state.orderTrail.fetchBusinessEventLogDataPending,
    businessEventBannerData: state.orderTrail.businessEventBannerData,
    snapshotData: state.orderTrail.snapshotData,
  }));
  const { selectedOrder } = props;
  const { eventHeader, eventDetailList } = snapshotData;
  const { fetchSnapshotData } = useFetchSnapshotData();
  const [checkEventId, setCheckEventId] = useState([]);
  const [snapshotModalVisible, setSnapshotModalVisible] = useState(false);

  const onEventIdCkbChange = values => {
    setCheckEventId(values);
  };

  const defColumns = [
    {
      title: 'EVENT ID',
      dataIndex: 'eventId',
      width: 130,
      className: 'event-col',
    },
  ];
  const logColumn = {
    title: 'Log',
    dataIndex: 'event',
    width: checkEventId.length > 0 ? 240 : 390,
    onHeaderCell: column => {
      return { width: 300 };
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div>
        <Checkbox.Group
          options={CONST_PLAIN_OPTION}
          defaultValue={['CorrelationId']}
          onChange={onEventIdCkbChange}
        />
      </div>
    ),
  };
  const actionColumn = {
    title: '',
    dataIndex: 'snapShotPresent',
    render: (snapShotPresent, record) => {
      if (snapShotPresent === 'true') {
        return (
          <div>
            <Button
              type="primary"
              // className="green-btn"
              onClick={() => fetchSnapshotDetails(record)}
              size="small"
            >
              View Details
            </Button>
            <Button
              type="link"
              className="blue-link-btn"
              icon={<DownloadOutlined />}
              onClick={exportSnapshotDetailReports}
            />
          </div>
        );
      } else {
        return null;
      }
    },
  };
  const tabKey = CONST_BUSINESS_EVENT_LOG_COLUMNS.key;
  let columns = renderTableColumns(CONST_BUSINESS_EVENT_LOG_COLUMNS.columns, tabKey);
  columns.splice(3, 0, logColumn);
  checkEventId.forEach(item => {
    defColumns.forEach(col => {
      if (col.title === item) {
        columns.splice(4, 0, col);
      }
    });
  });
  columns.splice(columns.length, 0, actionColumn);

  const exportBusinessEventLogReports = () => {
    const headers = columns.slice(0, columns.length - 1); //remove snapShotPresent action column
    const sheetColConfig = [
      { wch: 8 },
      { wch: 20 },
      { wch: 20 },
      { wch: 50 },
      { wch: 20 },
      { wch: 20 },
    ];
    const sheetObj = [
      {
        headers,
        data: businessEventLogData,
        sheetName: null,
        sheetColConfig,
      },
    ];
    downloadFile(`${tabKey} Report`, sheetObj);
  };

  const fetchSnapshotDetails = record => {
    const { opco, orderNo, customerNo, uomOrderNo, orderDate } = selectedOrder;
    const formData = {
      opco,
      orderNo,
      customerNo,
      uomOrderNo: uomOrderNo || '',
      orderDate,
      eventId: record.eventId,
    };
    fetchSnapshotData(formData);
    setSnapshotModalVisible(true);
  };

  const exportSnapshotDetailReports = () => {
    const timing = Date.now();
    const { opco, orderNo, customerNo } = selectedOrder;
    const currDatetime = moment().format('YYYYMMDD-HHmmss');
    let orderHeaderData = renderSheetData(eventHeader);
    let orderDetailsColumns = renderSimpleTableColumns(eventDetailList, renderSubFlag);
    const subColumn = {
      title: 'subFlag',
      dataIndex: 'subFlag',
      key: 'subFlag',
    };
    orderDetailsColumns.splice(1, 0, subColumn);
    const sheetObj = [
      {
        headers: orderHeaderData,
        sheetName: 'Order Header',
        data: [{ ...orderHeaderData }],
        sheetColConfig: null,
      },
      {
        headers: orderDetailsColumns,
        sheetName: 'Order Detail',
        data: eventDetailList,
        sheetColConfig: null,
      },
    ];
    downloadFile(`Snapshot-${opco}-${customerNo}-${orderNo}-${currDatetime}`, sheetObj);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Export Snapshot-${opco}-${customerNo}-${orderNo}-${currDatetime}`,timing);
    });
    datadogRum.addAction(`Export Snapshot-${opco}-${customerNo}-${orderNo}-${currDatetime}`,{
      requestPayload: sheetObj
    });
  };

  const renderSubFlag = value => {
    return (
      <div>
        {value}
        <Tag className="sub-tag" color="#56B146">SUB</Tag>
      </div>
    );
  };

  const setScrollValue = () => {
    let scrollGap = 0;
    if(orderTrailData) {
      scrollGap = (orderTrailData.length - 1) * 35;
    }
    return 300 - scrollGap;
  }

  return (
    <div className="order-trail-business-event-log">
      <div className="chart-title  flex justify-space-between">
       <p className='font-size-16-bold'>{tabKey}</p> 
        {/* <Button
          type="link"
          disabled={!businessEventLogData || businessEventLogData.length === 0}
          className="green-link-btn"
          icon={<DownloadOutlined />}
          onClick={exportBusinessEventLogReports}
        /> */}
        <div className="export-button">
          <Button type="link" title="Export" style={{ marginLeft: '-20px' }} onClick={exportBusinessEventLogReports} disabled={!businessEventLogData || businessEventLogData.length === 0}>
            <FontAwesomeIcon icon={faFileExport} color={(!businessEventLogData || businessEventLogData.length === 0) ? "#a1a1a2" : "#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: ((!businessEventLogData || businessEventLogData.length === 0) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
          </Button>
        </div>
      </div>
      {!_.isEmpty(businessEventBannerData)
        ? businessEventBannerData.map((item, index) => {
            return <Alert key={index} banner message={item.event} className="order-banner-list" />;
          })
        : null}
      <Table
        rowKey={record => `${record.eventId}-${record.event}`}
        className="dashboard-order-report-table"
        loading={fetchBusinessEventLogDataPending}
        columns={columns}
        dataSource={businessEventLogData}
        pagination={false}
        scroll={{ x: 'max-content', y: setScrollValue() }}
      />
      <SnapshotReportModal 
        snapshotModalVisible={snapshotModalVisible}
        setSnapshotModalVisible={setSnapshotModalVisible}
        exportSnapshotDetailReports={exportSnapshotDetailReports}
        headerColumns={renderSheetData(eventHeader)}
        detailsColumns={renderSimpleTableColumns(eventDetailList, renderSubFlag)}
      />
    </div>
  );
}

BusinessEventLog.propTypes = {};
BusinessEventLog.defaultProps = {};
