import React, { useCallback,useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { SLASH_DATE_FORMAT } from '../../common/constants';
import {ERROR_FIELD_REQUIRED,ERROR_SELECT_SITE,ERROR_SUS_SEVEN,ERROR_SUS_TEN} from './redux/constants';
import { CONST_SITE_LIST } from '../../common/orderTrailConstants';
import { Row, Col, Form, Select, DatePicker, Button, Input } from 'antd';
import { useFetchOrderTrailData, useResetOrderTrailData, useResetBusinessEventLogData } from './redux/hooks';
import _ from 'lodash';
import { useFetchTerritoryData } from '../dashboard/redux/fetchTerritoryData';
import { datadogRum } from '@datadog/browser-rum';

export default function OrderSearchFilters(props) {
  const { fetchOrderTrailData } = useFetchOrderTrailData();
  const { resetOrderTrailData } = useResetOrderTrailData();
  const { resetBusinessEventLogData } = useResetBusinessEventLogData();
  const [form] = Form.useForm();
  //Access restriction manage subs menu for Sales Consultants requirement,
  //when user is restricted user when refresh order trail screen will show manage sub menu,
  //so need get territory data again
  const { fetchTerritoryData } = useFetchTerritoryData();
  const { auth } = useSelector(state => ({
    auth: state.home.auth,
  }));
  useEffect(() => {
    const userId = auth.user.userId;
    fetchTerritoryData(userId);
  },[]);//eslint-disable-line

  const updateValidator = useCallback(() => {
    form.validateFields();
  }, [form.getFieldsValue()]);  // eslint-disable-line

  const onFinish = (values) => {
    handleResetData();
    const formData = {
      opco: values.opco,
      orderNo: _.isUndefined(values.orderNo) ? '' : values.orderNo,
      customerNo: _.isUndefined(values.customerNo) ? '' : values.customerNo,
      uomOrderNo: _.isUndefined(values.uomOrderNo) ? '' : values.uomOrderNo,
      fromDate: _.isUndefined(values.deliveryDate) || _.isNull(values.deliveryDate) ? '' : moment(values.deliveryDate[0]).format(SLASH_DATE_FORMAT),
      toDate: _.isUndefined(values.deliveryDate) || _.isNull(values.deliveryDate) ? '' : moment(values.deliveryDate[1]).format(SLASH_DATE_FORMAT),
    }
    const timing = Date.now();
    fetchOrderTrailData(formData);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch Order Trail Data`,timing);
    });
  }

  const onReset = () => {
    form.resetFields();
    handleResetData();
  };

  const handleResetData = () => {
    const { setSelectedOrder } = props;
    resetOrderTrailData();
    resetBusinessEventLogData();
    setSelectedOrder(null);
  };

  const requiredSomeoneValidator = (rule, value) => {
    const { orderNo, uomOrderNo, customerNo } = form.getFieldsValue();
    if (
      (_.isUndefined(orderNo) || orderNo.length === 0) &&
      (_.isUndefined(uomOrderNo) || uomOrderNo.length === 0) &&
      (_.isUndefined(customerNo) || customerNo.length === 0)
    ) {
      return Promise.reject(ERROR_FIELD_REQUIRED);
    }
    return Promise.resolve();
  };

  return (
    <div className="order-trail-order-search-filters">
      <Form form={form} className="filter-form" layout="vertical" onFinish={onFinish}>
        <Row className="font-size-16 sub-title">
          <Col>Order Search</Col>
        </Row>
        <Form.Item
          name="opco"
          label="Site"
          rules={[{ required: true, message: ERROR_SELECT_SITE }]}
        >
          <Select showSearch={true}>
            {CONST_SITE_LIST.map(item => {
              return (
                <Select.Option key={item.opcoNo} value={item.opcoNo}>
                  {item.opcoNo} - {item.opcoName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="multValidator" onChange={updateValidator} rules={[{ validator: requiredSomeoneValidator }]}>
          <Form.Item
            name="orderNo"
            label="SUS Order #" 
            rules={[{ max: 7, message: ERROR_SUS_SEVEN }]}
          >
            <Input type="number"/>
          </Form.Item>
          <span className="orTitle">or</span>
          <Form.Item
            name="uomOrderNo"
            label="Shop Order #"
            rules={[{ max: 10, message: ERROR_SUS_TEN }]}
          >
            <Input type="number"/>
          </Form.Item>
          <span className="orTitle">or</span>
          <Form.Item
            name="customerNo"
            label="Customer #"
            rules={[{ max: 7, message: ERROR_SUS_SEVEN }]}
          >
            <Input type="number"/>
          </Form.Item>
        </Form.Item>
        <Form.Item name="deliveryDate" 
          label="Delivery Date"
        >
          <DatePicker.RangePicker format={SLASH_DATE_FORMAT} />
        </Form.Item>
        <Form.Item style={{textAlign:'end' }}>
          <Button htmlType="button" type="link" onClick={onReset} className="default-btn filter-btn" style={{marginRight:'10px' }}>
            Reset
          </Button>
          <Button htmlType="submit" type="primary" className="primary-btn filter-primary-btn">
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

OrderSearchFilters.propTypes = {};
OrderSearchFilters.defaultProps = {};
