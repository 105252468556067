import React from 'react';
import CommonPage from './CommonPage';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
export default function DailySalesAccountType() {
  const tabKey = 'Summary';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].summary.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].summary.level;
  return (
    <div className="reports-new-daily-sales-account-type">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

DailySalesAccountType.propTypes = {};
DailySalesAccountType.defaultProps = {};
