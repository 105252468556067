import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { EXPORT_API_REQUEST_URL } from '../../../common/apiConfig';
import {
  REPORTS_EXPORT_SUMMARY_ORDER_REPORT_BEGIN,
  REPORTS_EXPORT_SUMMARY_ORDER_REPORT_SUCCESS,
  REPORTS_EXPORT_SUMMARY_ORDER_REPORT_FAILURE,
  REPORTS_EXPORT_SUMMARY_ORDER_REPORT_DISMISS_ERROR,
} from './constants';

export function exportSummaryOrderReport(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_EXPORT_SUMMARY_ORDER_REPORT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(`${EXPORT_API_REQUEST_URL}/exportSalesSummaryOrderInfo`, args);
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_EXPORT_SUMMARY_ORDER_REPORT_SUCCESS,
            data: res.data.data,
          });
          resolve(res.data.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: REPORTS_EXPORT_SUMMARY_ORDER_REPORT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissExportSummaryOrderReportError() {
  return {
    type: REPORTS_EXPORT_SUMMARY_ORDER_REPORT_DISMISS_ERROR,
  };
}

export function useExportSummaryOrderReport() {
  const dispatch = useDispatch();

  const { exportSummaryOrderReportPending, exportSummaryOrderReportError } = useSelector(
    state => ({
      exportSummaryOrderReportPending: state.reports.exportSummaryOrderReportPending,
      exportSummaryOrderReportError: state.reports.exportSummaryOrderReportError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(exportSummaryOrderReport(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportSummaryOrderReportError());
  }, [dispatch]);

  return {
    exportSummaryOrderReport: boundAction,
    exportSummaryOrderReportPending,
    exportSummaryOrderReportError,
    dismissExportSummaryOrderReportError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_EXPORT_SUMMARY_ORDER_REPORT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportSummaryOrderReportPending: true,
        exportSummaryOrderReportError: null,
      };

    case REPORTS_EXPORT_SUMMARY_ORDER_REPORT_SUCCESS:
      // The request is success
      return {
        ...state,
        exportSummaryOrderReportPending: false,
        exportSummaryOrderReportError: null,
      };

    case REPORTS_EXPORT_SUMMARY_ORDER_REPORT_FAILURE:
      // The request is failed
      return {
        ...state,
        exportSummaryOrderReportPending: false,
        exportSummaryOrderReportError: action.data.error,
      };

    case REPORTS_EXPORT_SUMMARY_ORDER_REPORT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportSummaryOrderReportError: null,
      };

    default:
      return state;
  }
}
