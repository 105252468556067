export const HOME_GET_AUTHRIZATION = 'HOME_GET_AUTHRIZATION';
export const HOME_AUTHRIZATION_RESET = 'HOME_AUTHRIZATION_RESET';
export const HOME_MENU_DASHBOARD = 'dashboard';
export const HOME_MENU_REPORTS = 'Reports';
export const HOME_MENU_ORDERTRAIL = 'Order Trail';
export const HOME_MENU_MANAGESUBS = 'Manage Subs';
export const HOME_FEEDBACK_BEGIN = 'HOME_FEEDBACK_BEGIN';
export const HOME_FEEDBACK_SUCCESS = 'HOME_FEEDBACK_SUCCESS';
export const HOME_FEEDBACK_FAILURE = 'HOME_FEEDBACK_FAILURE';
export const HOME_FEEDBACK_DISMISS_ERROR = 'HOME_FEEDBACK_DISMISS_ERROR';
export const REPORTS_MENU = [{
    key: 'summary', name: 'summary', to: '/summary',
  }, {
    key: 'orders', name: 'orders', to: '/orders',
  }, {
    key: 'future-orders', name: 'futureOrders', to: '/future-orders',
  },{
    key: 'sc-in-action', name: 'sCInAction', to: '/sc-in-action',
  },
  {
    key: 'out-of-stock', name: 'oos-Item', to: '/out-of-stock',
  },
  {
    key: 'oos-account', name: 'oos-Account', to: '/oos-account',
  },
]

