import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_RESET_DASHBOARD_CONDITION_DATA,
} from './constants';

export function resetDashboardConditionData() {
  return {
    type: DASHBOARD_RESET_DASHBOARD_CONDITION_DATA,
  };
}

export function useResetDashboardConditionData() {
  const dispatch = useDispatch();
  const dashboardConditionData = useSelector(state => state.dashboard.dashboardConditionData);
  const boundAction = useCallback((...params) => dispatch(resetDashboardConditionData()), [dispatch]);
  return { dashboardConditionData, resetDashboardConditionData: boundAction };
}

export function reducer(state, action) {
  if(action.type===DASHBOARD_RESET_DASHBOARD_CONDITION_DATA){
    return {
      ...state,
      dashboardConditionData: null,
    };

  }
 
      return state;
  
}
