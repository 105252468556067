import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Form, Modal, Select, Spin } from 'antd';
import { useFetchDropOutsReason } from './redux/fetchDropOutsReason';
// import PropTypes from 'prop-types';

export default function DropOutReasonModal(props) {
  const {
    title,
    footer,
    isModalVisible,
    width,
    onToggleModal,
    cancelText,
    okText,
    content,
    dropOutReason,
    setDropOutReason,
  } = props;
  const { dropOutsReasonData, fetchDropOutsReasonPending, removeDropOutsPending } = useSelector(
    state => ({
      dropOutsReasonData: state.manageSubs.dropOutsReasonData,
      fetchDropOutsReasonPending: state.manageSubs.fetchDropOutsReasonPending,
      removeDropOutsPending: state.manageSubs.removeDropOutsPending,
    }),
  );
  const { fetchDropOutsReason } = useFetchDropOutsReason();
  const [deafultReason, setDefaultReason] = useState(
    !_.isEmpty(dropOutsReasonData) ? dropOutsReasonData[0] : 'Description Mismatch',
  );
  useEffect(() => {
    if (_.isEmpty(dropOutsReasonData)) {
      fetchDropOutsReason();
    }
  }, [dropOutsReasonData]); //eslint-disable-line
  return (
    <Modal
      className="manage-subs-manage-outs-warning-modal reason-code-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onToggleModal}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={footer || null}
      width={width || 520}
    >
      <Spin spinning={fetchDropOutsReasonPending || removeDropOutsPending}>
        <Form.Item
          label="Drop Out Reason"
          name="dropOutReason"
          rules={[{ required: true, message: 'Please select Drop Out Reason!' }]}
        >
          <Select
            placeholder="Reason"
            onChange={value => setDropOutReason(value)}
            value={dropOutReason}
            defaultValue={deafultReason}
            style={{
              width: 250,
            }}
            options={dropOutsReasonData}
          ></Select>
        </Form.Item>
      </Spin>
    </Modal>
  );
}

DropOutReasonModal.propTypes = {};
DropOutReasonModal.defaultProps = {};
