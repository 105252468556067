import React from 'react';
import { CommonPage } from '.';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';

export default function SalesForOrder() {
  const tabKey = 'Summary';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.level;
  return (
    <div className="reports-new-sales-for-order">
     <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

SalesForOrder.propTypes = {};
SalesForOrder.defaultProps = {};
