import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DASHBOARD_RESET_SUMMARY_DATA } from './constants';

export function resetSummaryData() {
  return {
    type: DASHBOARD_RESET_SUMMARY_DATA,
  };
}

export function useResetSummaryData() {
  const dispatch = useDispatch();
const {
    salesSummaryData,
    detailsCondition,
    orderStatusSummaryData,
    orderTrendSummaryData,
    salesTrendSummaryData,
    orderByTypeSummaryData,
  } = useSelector(state => ({
    salesSummaryData: state.dashboard.salesSummaryData,
    detailsCondition: state.dashboard.detailsCondition,
    orderStatusSummaryData: state.dashboard.orderStatusSummaryData,
    orderTrendSummaryData: state.dashboard.orderTrendSummaryData,
    salesTrendSummaryData: state.dashboard.salesTrendSummaryData,
    orderByTypeSummaryData: state.dashboard.orderByTypeSummaryData,
  }));
  const boundAction = useCallback((...params) => dispatch(resetSummaryData()), [dispatch]);
  return {
    salesSummaryData,
    detailsCondition,
    orderStatusSummaryData,
    orderTrendSummaryData,
    salesTrendSummaryData,
    orderByTypeSummaryData,
    resetSummaryData: boundAction,
  };
}

export function reducer(state, action) {
  if(action.type=== DASHBOARD_RESET_SUMMARY_DATA){
    return {
      ...state,
      salesSummaryData: {
        totalAllocatedCases: null,
        totalAllocatedEaches: null,
        totalCases: null,
        totalEaches: null,
        totalExceptions: null,
        totalGPPercentage: null,
        totalLines: null,
        totalOrders: null,
        totalPickPieces: null,
        totalPickups: null,
        totalSales: null,
        exactExceptions: null,
      },
      detailsCondition: null,
      orderStatusSummaryData: {
        cancelled: null,
        creditHold: null,
        labelled: null,
        lockedOrErrored: null,
        merchHold: null,
        open: null,
        pendingApproval: null,
        routing: null,
        shippedOrInvoiced: null,
      },
      orderTrendSummaryData: [],
      salesTrendSummaryData: [],
      orderByTypeSummaryData: {
        dropShip: null,
        hotShot: null,
        regular: null,
        willCall: null,
      },
    };

  }
  
      return state;
  
}
