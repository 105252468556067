import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Spin, Layout, notification, Button, Space, Input } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ExceptionsDetails, TerritoryFilters } from './';
import { OrderHealthCheckCard, OrderInflowTrend ,ExpectedSalesCard, OrderByTypeCard, OrderReportModal} from './';
import { ErrorPage } from '../common';
import { AUTH_ERROR_MSG, USER_GUIDE_LINK } from '../../common/constants';
import { redirectUrl } from '../../common/apiConfig';
import { ORDER_HEALTH_CHECK_COLUMNS, ORDER_BY_TYPE_COLUMNS, EXCEPTIONS_DETAILS_COLUMNS } from '../../common/chartConstants';
import { ERROR_CONTACT_ADMINISTRATOR } from './redux/constants';
import { Header } from '../reports-new';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { useSaveNotificationStatus } from './redux/hooks';
import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const { t } = useTranslation();
  const [isOpenPop, setIsOpenPop] = useState(false);
  const isOpenPopFun = () => {
    setIsOpenPop(true)
  };
  const isClosePopFun = () =>{
    setIsOpenPop(false)
  }
  const [isInverse, setIsInverse] = useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [reportModalTitle, setReportModalTitle] = useState();
  const [reportModalTotals, setReportModalTotals] = useState();
  const [detailsTypeLabel, setDetailsTypeLabel] = useState();
  const [reportModalKey, setReportModalKey] = useState();
  const {
    fetchOrgInfoPending,
    fetchTerritoryDataPending,
    fetchSummaryDataPending,
    fetchOrgInfoError,
    fetchTerritoryDataError,
    fetchSummaryDataError,
    fetchOrderReportDataError,
    fetchNationalAccountDataPending,
    fetchNationalAccountDataError,
    fetchConceptIdsDataPending,
    fetchConceptIdsDataError,
    territoryData,
    orderTrendSummaryData,
    notificationStatus,
    isCanadaUser,
    fetchOrderDetailReportError,
  } = useSelector(state => ({
    fetchOrgInfoPending: state.dashboard.fetchOrgInfoPending,
    fetchTerritoryDataPending: state.dashboard.fetchTerritoryDataPending,
    fetchSummaryDataPending: state.dashboard.fetchSummaryDataPending,
    fetchOrgInfoError: state.dashboard.fetchOrgInfoError,
    fetchTerritoryDataError: state.dashboard.fetchTerritoryDataError,
    fetchSummaryDataError: state.dashboard.fetchSummaryDataError,
    fetchOrderReportDataError: state.dashboard.fetchOrderReportDataError,
    fetchNationalAccountDataPending: state.dashboard.fetchNationalAccountDataPending,
    fetchNationalAccountDataError: state.dashboard.fetchNationalAccountDataError,
    fetchConceptIdsDataPending: state.dashboard.fetchConceptIdsDataPending,
    fetchConceptIdsDataError: state.dashboard.fetchConceptIdsDataError,
    territoryData: state.dashboard.territoryData,
    orderTrendSummaryData: state.dashboard.orderTrendSummaryData,
    notificationStatus: state.dashboard.notificationStatus,
    isCanadaUser: state.dashboard.isCanadaUser,
    fetchOrderDetailReportError: state.reports.fetchOrderDetailReportError,
  }));
  const {saveNotificationStatus} = useSaveNotificationStatus();
  useEffect(() => {
    setIsInverse(false);
  }, [orderTrendSummaryData]);
  // useEffect(() => {
    // if(!notificationStatus){
      // openNotification();
    // }
  // }, [notificationStatus]);//eslint-disable-line
  // const key = `open${Date.now()}`;
  // const openDocument = () => {
  //   window.open(USER_GUIDE_LINK, '_blank') //open new link in new tab
    // window.location.href= USER_GUIDE_LINK; //open new link in current tab
  // }
  // const destroy = () => {
  //   notification.destroy(key);
  //   saveNotificationStatus(true);
  // }
  // const btn = (
  //     <div style={{ textAlign:'center' } }>
  //     <Button type="primary" onClick={()=>destroy()}>
  //       DISMISS IT
  //     </Button>
  //     </div>
  // );
  // const openNotification = () => {
  //   notification.open({
  //     message: <div className='notification-msg'>Welcome to the new SIA+ page</div>,
  //     description:<div>
  //       <p>This is the new SIA+ home page, You can find the SIA+ user guide in this <a onClick={openDocument}>User Guide Link</a></p>
  //       <p> Do not forget to Bookmark this page,You can find the book mark document in <a href={BOOK_MARK_FILE} target="_blank">Book Mark Link</a></p>
  //     </div>,
  //     duration: 30,
  //     placement: 'top',
  //     btn,
  //     className:'dashboard-notification',
  //     onClose: ()=>destroy(),
  //     key,
  //   });
  // };
  const isErrorPage =
    fetchOrgInfoError ||
    fetchTerritoryDataError ||
    fetchSummaryDataError ||
    fetchOrderReportDataError ||
    fetchNationalAccountDataError ||
    fetchConceptIdsDataError;
  if (
    fetchOrgInfoError === AUTH_ERROR_MSG ||
    fetchTerritoryDataError === AUTH_ERROR_MSG ||
    fetchSummaryDataError === AUTH_ERROR_MSG ||
    fetchOrderReportDataError === AUTH_ERROR_MSG ||
    fetchNationalAccountDataError === AUTH_ERROR_MSG ||
    fetchConceptIdsDataError === AUTH_ERROR_MSG || 
    fetchOrderDetailReportError === AUTH_ERROR_MSG
  ) {
    window.location.assign(redirectUrl);
  } else if (isErrorPage) {
    return <ErrorPage />;
  } else if (territoryData && territoryData.role === null) {
    return (
      <ErrorPage alertMsg={ERROR_CONTACT_ADMINISTRATOR} />
    );
  }

  const handleReportLabelClick = (label, totals) => {
    let title = null;
    let typeLabel = null;
    let key = null;
    if (ORDER_BY_TYPE_COLUMNS.hasOwnProperty(label)) {
      typeLabel = { orderType: label };
      title = ORDER_BY_TYPE_COLUMNS[label];
      key = `ORDER_BY_TYPE_COLUMNS.${label}`;
    }else if(EXCEPTIONS_DETAILS_COLUMNS.hasOwnProperty(label)){
      typeLabel = { orderStatus: label === "totalExceptions" ? "exception" : label };
      title = EXCEPTIONS_DETAILS_COLUMNS[label];
      key = `EXCEPTIONS_DETAILS_COLUMNS.${label}`;
    }else {
      typeLabel = { orderStatus: label };
      title = ORDER_HEALTH_CHECK_COLUMNS[label];
      key = `ORDER_HEALTH_CHECK_COLUMNS.${label}`;
    }

    console.log('@@@@@@@@@@@@@@@@@@@@@',key);
    setReportModalTitle(title);
    setReportModalKey(key);
    setReportModalTotals(totals);
    setDetailsTypeLabel(typeLabel);
    toggleReportModal();
  };

  const toggleReportModal = () => {
    setIsReportModalVisible(prevState => {
      return !prevState;
    });
  };

  return (
    <div className="dashboard-dashboard">
       {/* <div className="sia-container">  */}
       <Header title="Dashboard"/>
       {/* </div> */}
      <Spin
        size="large"
        tip="Loading..."
        spinning={
          fetchOrgInfoPending ||
          fetchTerritoryDataPending ||
          fetchSummaryDataPending ||
          fetchNationalAccountDataPending ||
          fetchConceptIdsDataPending
        }
      >
        <div>
          <div className='customizations-layout-sider'>
          <div className={isOpenPop ? "sys-mark fadeIn is-show-mobile":"sys-mark fadeOut none"}></div>
            <Layout.Sider  width="245" className="is-show-pc">
              <TerritoryFilters />
            </Layout.Sider>
            <Layout.Sider className="is-show-mobile" width="100%">
              <TerritoryFilters isOpenPop = {isOpenPop} isOpenPopFun={isOpenPopFun} isClosePopFun={isClosePopFun}/>
              <FontAwesomeIcon icon={faSliders} className="filter-but-right-mobile is-show-mobile" onClick={isOpenPopFun}/>
            </Layout.Sider>
            <Layout.Content className="sub-layout-content">
            <div className="chart-panel-sub01">
                <div className="chart-item-panel dashboard-dashboard-content-box dashboard-dashboard-content-box-sub01">
                  <div className="chart-title font-size-16-bold">{t("expectedSales")}</div>
                  <ExpectedSalesCard />
                </div>
                <div className="chart-item-panel dashboard-dashboard-content-box">
                  <div
                    className="chart-title cursor-pointer font-size-16-bold"
                    onClick={() => setIsInverse(prevIsInverse => !prevIsInverse)}
                  >
                    {isInverse ? t("salesTrend") : t('orderInflowTrend')}
                    {isInverse ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  </div>
                  <OrderInflowTrend isInverse={isInverse} />
                </div>
            </div>
            <div className="chart-panel">
                <div className="chart-item-panel dashboard-dashboard-content-box">
                  <div className="chart-title font-size-16-bold">{t("ordersByType")}</div>
                  <OrderByTypeCard handleOrderTypeLabelClick={handleReportLabelClick} />
                </div>
                <div className="chart-item-panel dashboard-dashboard-content-box">
                  <div className="chart-title font-size-16-bold">{t("ordersByStatus")}</div>
                  <OrderHealthCheckCard handleHealthCheckLabelClick={handleReportLabelClick} />
                </div>
                {
                  !isCanadaUser &&
                  <div className="chart-item-panel dashboard-dashboard-content-box">
                  <div className="chart-title font-size-16-bold">{t("exceptions")}</div>
                  <ExceptionsDetails handleHealthCheckLabelClick={handleReportLabelClick} />
                </div>
                }
            </div>
            <OrderReportModal
              toggleReportModal={toggleReportModal}
              isReportModalVisible={isReportModalVisible}
              title={reportModalTitle}
              totals={reportModalTotals}
              typeLabel={detailsTypeLabel}
              modalkey={reportModalKey}
            />
          </Layout.Content>
          </div>
        </div>
      </Spin>
      
    </div>
  );
}

Dashboard.propTypes = {};
Dashboard.defaultProps = {};
