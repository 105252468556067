import moment from 'moment';
import _ from 'lodash';
import { DASH_DATE_FORMAT } from '../../common/constants';
import { formatTerrirotyData } from '../dashboard/formatTerrirotyData';

export const formatFormdata = (values, territoryData) => {
  if (values) {
    const formdata = {
      deliveryDate: moment(values.deliveryDate).format(DASH_DATE_FORMAT),
      sites: values.site,
      territories: formatTerrirotyData(values, territoryData),
      conceptIds: values.conceptId !== undefined ? values.conceptId : [],
      nationalIds: values.nationalId !== undefined ? values.nationalId.split() : [],
      accountTypes: values.accountType !== undefined ? values.accountType : [],
    };

    return formdata;
  }
  return {};
};
export const convertToFooterColumns = (columnsProp)=>{
  if(columnsProp){
    let footerColumnsProp = JSON.parse(JSON.stringify(columnsProp));
    footerColumnsProp = footerColumnsProp.map(item=>{
      if (item['dataIndex'] === 'totalSales' || item['dataIndex'] === 'orderTotalSales'
        || item['dataIndex'] === 'averageSell' 
        || item['dataIndex'] === 'lostSales'
      ) {
        item['render'] = (value) => (value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`);
      }
      if (item['dataIndex'] === 'offDaySales') {
        item['render'] = (value) => (value === null || value === 0 ? '$0.00' : value && `$${returnFloat(value)}`);
      }
     if(item['dataIndex'] === 'salesPercentage' || item['dataIndex'] === 'gpPercentages' || item['dataIndex'] === 'preShipAllSubs' || item['dataIndex'] === 'preShipDirSubs'){
       item['render'] = (value) => (value === null || value === 0? '0.00%' : value && `${value.toLocaleString('zh',{minimumFractionDigits:2})}%`);
     }
     if(item['dataIndex'] === 'originalItemUnitPrice' || item['dataIndex'] === 'orderDetailExtendedPrice'){
      item['render'] = (value) => (value === null || value === 0 ? '$0.00' : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`);
     }
    //  if(item['toggleModal']){
    //    delete item['toggleModal'];
    //  }
    //  if(item['className']){
    //    delete item['className'];
    //  }
    //  if(item['renderBadge']){
    //    delete item['renderBadge'];
    //  }
    //  if(item['sorter']){
    //   delete item['sorter'];
    // }
    return item;
   });
    //insert total label
    if(footerColumnsProp[0]['toggleModal']){
      delete footerColumnsProp[0]['toggleModal'];
    }
    footerColumnsProp[0] = {...footerColumnsProp[0],dataIndex: "totalIndex",className:"all-column",
    render: (text,record,index)=>{
      if(record.totalIndex){
        return 'ALL';
      }
      // else{
      //   return `${index+1}`
      // }
    }};
    //add some space for last column if last column is numberic, otherwise the value is very closer to vertical scrollbar
    let lastColumn = footerColumnsProp[footerColumnsProp.length-1];
    if((lastColumn['dataIndex'] === 'offDayOrders') || (lastColumn['dataIndex'] === 'noOfOrders') || (lastColumn['dataIndex'] ==='totalEaches')){
      lastColumn['render'] = (value) =>  value +'' +'\xa0\xa0\xa0\xa0';
    }
    return footerColumnsProp;
  }
  return columnsProp;
}
export const groupData = (array, subGroupLength) => {
  let groupedData = [];
  let index = 0;
  while (index < array.length) {
    var childArr = array.slice(index, (index += subGroupLength));
    //if less than subGroupLength will add empty array
    // if (childArr.length < subGroupLength) {
    //   var len = subGroupLength - childArr.length;
    //   for (let i = 0; i < len; i++) {
    //     childArr.push([]);
    //   }
    // }
    groupedData.push(childArr);
  }
  return groupedData;
};
export const returnFloat = (value) =>{
  if(value === undefined){
    return value;
  }
  value  = value.toString();
  if(value.indexOf('.') !== -1){
    let newValue = value.toString().split(".");
    if(newValue[1].length === 1){
      newValue = newValue.join(".");
      newValue += "0";
      value = newValue;
    }
  }else{
    value += ".00"; 
  }
  return value;
}
export const renderMergedColumn = (firstValue,secondValue,concatChar)=>{
  if(!_.isEmpty(firstValue) && !_.isEmpty(secondValue)){
    return  firstValue + concatChar + secondValue;
  }else if(!_.isEmpty(firstValue) && _.isEmpty(secondValue)){
    return  firstValue;
  }else if(_.isEmpty(firstValue) && !_.isEmpty(secondValue)){
    return  secondValue;
  }else{
    return "";
  }
}