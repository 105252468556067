export const CONST_ACCOUNTS_TABLE_COLUMNS = [
  {
    title: 'Site ID',
    dataIndex: 'site',
    width: 100,
    blankWidth: 90,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Group/Ship To',
    dataIndex: 'nationalId',
    width: 170,
    blankWidth: 170,
    sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Managed By',
    dataIndex: 'managedBy',
    width: 110,
    blankWidth: 110,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Inc/Exc',
    dataIndex: 'maintenanceFlag',
    width: 80,
    blankWidth: 80,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Req Apv',
    dataIndex: 'approvalRequiredFlag',
    width: 85,
    blankWidth: 85,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Cut Off',
    dataIndex: 'nationalIdCutoffTime',
    width: 90,
    blankWidth: 90,
    sorter: false,
    render: false,
  },
  {
    title: 'Route Cut Off',
    dataIndex: 'routeCutoffTime',
    width: 120,
    blankWidth: 120,
    sorter: false,
    render: false,
  },
  {
    title: 'SOC',
    dataIndex: 'catalogFlag',
    width: 80,
    blankWidth: 80,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Send Email',
    dataIndex: 'startOfDay',
    width: 100,
    blankWidth: 100,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Account Owner',
    dataIndex: 'accountOwner',
    width: 140,
    blankWidth: 120,
    sorter: false,
    render: false,
    extendRender: true,
  },
  {
    title: 'Modified',
    dataIndex: 'modifiedByName',
    width: 190,
    blankWidth: 190,
    render: false,
    extendRender: true,
  },
];