import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_RECOMMENDED_PRODUCTS,
} from './constants';

export function resetRecommendedProducts() {
  return {
    type: MANAGE_SUBS_RESET_RECOMMENDED_PRODUCTS,
  };
}

export function useResetRecommendedProducts() {
  const dispatch = useDispatch();
  const recommendedProducts = useSelector(state => state.manageSubs.recommendedProducts);
  const boundAction = useCallback((...params) => dispatch(resetRecommendedProducts()), [dispatch]);
  return { recommendedProducts, resetRecommendedProducts: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommendedProducts: null,
      };

    default:
      return state;
  }
}
