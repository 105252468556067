import React, { useState, useEffect } from 'react';
import { Table, Pagination, Modal } from 'antd';
import moment from 'moment';
import { datadogRum } from '@datadog/browser-rum';
export default function CommonTable(props) {
  const { 
    columns,
    datasource,
    rowKey,
    showBordered,
    loadingData,
    triggerConditions,
    searchText,
    onFecthData,
    onResetStore,
    expandedColumns,
    rowExpandable,
    expandedItemDataKey,
    paginationStyle,
    tableClassName,
    scrollStyle,
    paginationSetting,
    resetActivePagination,
    isdropout,
    isRefreshed,
    onOutsTableChange,
  } = props;
  const [pagination, setPagination] = useState(paginationSetting);
  const [sorting, setSorting] = useState({sortedBy: null, sort: null});
  // const [isPageChg, setPageChg] = useState(false)

  useEffect(() => {
    setPagination(paginationSetting);
    // setSorting();
    onResetStore();    
    if(triggerConditions !== undefined && triggerConditions !== null) {
      const formData = {
        ...triggerConditions, //this triggerConditions should be formatted formdata, not values saved in Form component
        pageIndex: paginationSetting.pageIndex,
        pageSize: paginationSetting.pageSize,
        ...sorting,
      }
      //for manage sub and outs
      if(searchText) {
        formData.originalItem = searchText
      }
      // formData.optimizationEnable = optimizationEnable;    //COT-8330
      // formData.isPageChg = false
      const context = datadogRum.getGlobalContext('BosView');
      const timing = Date.now();
      onFecthData(formData).then((response) => {
      }).catch((err) => {
        if(err?.response && err?.response?.status && err?.response?.status === 504){
          Modal.warning({
            title: 'Warning',
            content: 'The request timed-out due large volume of data. Please narrow the search result and try again!',
            okText: 'Ok',
            // cancelText: 'Cancel',
            onOk: () => {},
            // onCancel: () =>{},
            className:'timeout-modal',
          });
        }
      });
      datadogRum.onReady(function(){
        datadogRum.addTiming(`Fetch ${context.BosView.screen} data`,timing);
      });
    }
  }, [triggerConditions, resetActivePagination, isdropout,isRefreshed]); // eslint-disable-line

  useEffect(() => {
    if(triggerConditions !== undefined && triggerConditions !== null) {
      const formData = {
        ...triggerConditions, //this triggerConditions should be formatted formdata, not values saved in Form component
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...sorting,
      }
      //for manage sub and outs
      if(searchText) {
        formData.originalItem = searchText
      }
      // formData.optimizationEnable = optimizationEnable; //COT-8330
      // formData.isPageChg = isPageChg
      const context = datadogRum.getGlobalContext('BosView');
      const timing = Date.now();
      onFecthData(formData).then((response) => {
      }).catch((err) => {
        if(err.response && err.response.status && err.response.status === 504){
          Modal.warning({
            title: 'Warning',
            content: 'The request timed-out due large volume of data. Please narrow the search result and try again!',
            okText: 'Ok',
            // cancelText: 'Cancel',
            onOk: () => {},
            // onCancel: () =>{},
            className:'timeout-modal',
          });
        }
      });
      datadogRum.onReady(function(){
        datadogRum.addTiming(`Fetch ${context.BosView.screen} data`,timing);
      });
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting.sortedBy, sorting.sort]); // eslint-disable-line

  const expandedRowRender = (record, index) => {
    return (
      <Table 
        rowKey={record => `${record.substituteItem} - ${index}`}
        className="expanded-report-table" 
        columns={expandedColumns || []} 
        dataSource={record[expandedItemDataKey]} 
        pagination={false}
        rowClassName={(record, index) => {
          const expiredDate = record.endDate && moment().diff(moment(record.endDate), 'days'); 
          if(tableClassName === 'subs-report-table'){
            if(record.endDate && expiredDate < 1){
              return
            }else if(record.endDate) {
              return 'greyed'
            }
          }
        }}
      />
    );
  }

  const onTableChange = (page, filters, sorter) => {
    if(onOutsTableChange) {
      onOutsTableChange(page, filters, sorter)
    }
    // setPageChg(false)
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : {
          sortedBy: null,
          sort: null,
        };
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  }; 

  const onPaginationChange = (page, changedPageSize) => {
    // setPageChg(true)
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
    let divElems = document.getElementsByClassName('ant-table-body')
    if(divElems.length > 0) {
      divElems[0].scrollTop = 0
    }
  };

  return (
    <div className="common-common-table">
      <Table
        rowKey={rowKey}
        className={`${tableClassName} common-report-table`}
        loading={loadingData}
        bordered={showBordered || false}
        columns={columns}
        expandedRowRender={expandedColumns === undefined ? null : record => expandedRowRender(record)}
        rowExpandable={rowExpandable === undefined ? null : record => rowExpandable(record)}
        dataSource={datasource && datasource.data}
        onChange={onTableChange}
        pagination={false}
        scroll={scrollStyle}
        rowClassName={(record, index) => {
          const expiredDate = record.endDate && moment().diff(moment(record.endDate), 'days'); 
          if(tableClassName === 'subs-report-table'){
            if(record.endDate && expiredDate < 1){
              return
            }else if(record.endDate) {
              return 'greyed'
            }
          }
        }}
      />
      <Pagination
        style={paginationStyle}
        className={'report-pagination'}
        showSizeChanger
        current={pagination.pageIndex}
        pageSize={pagination.pageSize}
        total={datasource && datasource.totals}
        pageSizeOptions={pagination.pageSizeOptions}
        onShowSizeChange={onPaginationChange}
        onChange={onPaginationChange}
        disabled={datasource == null || datasource.data == null || datasource.data.length == 0}
      />
    </div>
  );
};
