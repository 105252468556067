export const formatOrderDetailsList = eventDetailList => {
  const eventDetailListData = JSON.parse(JSON.stringify(eventDetailList));
  let eventDetailListSubs = [];
  let eventDetailListNoSubs = [];
  eventDetailListData.forEach(item => {
    if (item.substituteReferenceLineNbr !== 0) {
      eventDetailListSubs.push(item);
    } else {
      eventDetailListNoSubs.push(item);
    }
  });
  let newEventDetailList = eventDetailListNoSubs;
  if (eventDetailListSubs.length > 0) {
    eventDetailListSubs.forEach((item, index) => {
      let spliceIndex = newEventDetailList.findIndex(
        data => data.orderDetailLineNo == item.substituteReferenceLineNbr,
      );
      spliceIndex = spliceIndex > -1 ? spliceIndex + 1 : index;
      newEventDetailList.splice(spliceIndex, 0, item);
    });
  }
  return newEventDetailList;
};
