import React from 'react';
import CommonPage from './CommonPage';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
//COT-8436: UI - Show summary report in region level
export default function DailySalesRegion() {
  const tabKey = 'Summary';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].accountType.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].accountType.level;
  return (
    <div className="reports-new-daily-sales-region">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

DailySalesRegion.propTypes = {};
DailySalesRegion.defaultProps = {};
