export const CORP_USER_LIST = [
    {
        userId: 'jlon8995',
        email: 'Jennifer.Long@sysco.com',
        userName: 'Jennifer.Long',
    },
    {
        userId:'jkal2961'
    },
    {
        userId:'sunn8083'
    },
    {
        userId:'cnai3431'
    },
    //config test user's user id,recognized this kind of users as Corp
    {
        userId:'rved1458'
    },
]