import React from 'react';
import { Result } from 'antd';
import { ERROR_SOMETHING_WENT_WRONG } from './redux/constants';



export default function ErrorPage(props) {
  const errorMsg = props.alertMsg || ERROR_SOMETHING_WENT_WRONG;
  return (
    <div className="common-error-page">
      <Result
        className='result-panel'
        status="500"
        title="500"
        subTitle={errorMsg}
      />
    </div>
  );
};

ErrorPage.propTypes = {};
ErrorPage.defaultProps = {};
