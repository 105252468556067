import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_SUBS_AUDIT_TRAIL_DATA,
} from './constants';

export function resetSubsAuditTrailData() {
  return {
    type: MANAGE_SUBS_RESET_SUBS_AUDIT_TRAIL_DATA,
  };
}

export function useResetSubsAuditTrailData() {
  const dispatch = useDispatch();
  const subsAuditTrailData = useSelector(state => state.manageSubs.subsAuditTrailData);
  const boundAction = useCallback((...params) => dispatch(resetSubsAuditTrailData(...params)), [dispatch]);
  return { subsAuditTrailData, resetSubsAuditTrailData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_SUBS_AUDIT_TRAIL_DATA:
      return {
        ...state,
        subsAuditTrailData: null,
      };

    default:
      return state;
  }
}
