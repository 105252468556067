export const DEV_API_REQUEST_URL = `https://siaplus-bff-dev.sales-operations-np.sysco-go.com/sia/siaplus`;
export const QA_API_REQUEST_URL = `https://siaplus-bff-qa.sales-operations-np.sysco-go.com/sia/siaplus`;
export const PROD_API_REQUEST_URL = `https://siaplus-bff.sales-operations.sysco-go.com/sia/siaplus`;

export const DEV_EXPORT_API_REQUEST_URL = `https://sia-siapws-dev.sales-operations-np.us-east-1.aws.sysco.net/SIA_Plus`;
export const QA_EXPORT_API_REQUEST_URL = `https://sia-siapws-qa.sales-operations-np.us-east-1.aws.sysco.net/SIA_Plus`;
export const PROD_EXPORT_API_REQUEST_URL = `https://sia-siapws-prod.sales-operations.us-east-1.aws.sysco.net/SIA_Plus`;

export const DEV_BOS_EXPORT_API_REQUEST_URL = `https://sia-bos2ws-dev.sales-operations-np.us-east-1.aws.sysco.net/BOS2_0`;
export const QA_BOS_EXPORT_API_REQUEST_URL = `https://sia-bos2ws-qa.sales-operations-np.us-east-1.aws.sysco.net/BOS2_0`;
export const PROD_BOS_EXPORT_API_REQUEST_URL = `https://sia-bos2ws-prod.sales-operations.us-east-1.aws.sysco.net/BOS2_0`;

export const DEV_SIA_ENDPOINT = `https://siaplus-bff-dev.sales-operations-np.sysco-go.com`;
export const QA_SIA_ENDPOINT = `https://siaplus-bff-qa.sales-operations-np.sysco-go.com`;
export const PROD_SIA_ENDPOINT = `https://siaplus-bff.sales-operations.sysco-go.com`;

export const DEV_ORDER_TRAIL_ENDPOINT = `https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/order-trail-bff/invocations`;
export const QA_ORDER_TRAIL_ENDPOINT = `https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/order-trail-bff/invocations`;
export const PROD_ORDER_TRAIL_ENDPOINT = `https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/order-trail-bff-prod/invocations`;

export const USER_POOL_ID = `us-east-1_7PMSk6uPg`;
export const PROD_USER_POOL_ID = `us-east-1_rBWdNxD5y`;

export const APP_CLIENT_ID = `7samhb708pohh4rrlilfkjuhfr`;
export const PROD_APP_CLIENT_ID = `4ki8gib2t4rhgi42nd6aheqq5k`;

export const IDENTITY_POOL_ID = `us-east-1:2145d493-7b68-4990-9de8-e1b7a12c3c81`;
export const PROD_IDENTITY_POOL_ID = `us-east-1:a2b53b4a-711c-40c7-bdad-2af07ab3f340`;

export const AUTH_DOMAIN = `siaplus-np.auth.us-east-1.amazoncognito.com`;
export const PROD_AUTH_DOMAIN = `siaplus.auth.us-east-1.amazoncognito.com`;

export const DEV_REDIRECT = `https://siaplus-dev.sales-operations-np.sysco-go.com`;
export const QA_REDIRECT = `https://siaplus-qa.sales-operations-np.sysco-go.com`;
export const PROD_REDIRECT = `https://syscoinaction.sales-operations.sysco-go.com`;
export const LOCAL_REDIRECT = `http://localhost:3100/`;

export const DEV_ENV = 'dev';
export const QA_ENV = 'qa';
export const PROD_ENV = 'prod';
