import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';


export default function SnapshotOrderDetails(props) {
  const { snapshotData } = useSelector(state => ({
    snapshotData: state.orderTrail.snapshotData,
  }));
  const { detailsColumns } = props;
  const { eventDetailList } = snapshotData;

  const setRowStyle = (row) => {
    return row.modifiedFlag === 'Y' ? 'snapShotStyl' : '';
  }

  const setScrollValue = () => {
    if (eventDetailList.length > 4) {
      return 300;
    } else {
      return 0;
    }
  }

  return (
    <div className="order-trail-snapshot-order-details">
      <Table
        rowKey={record => `${record.id}`}
        className="orderdetail"
        columns={detailsColumns}
        dataSource={eventDetailList}
        pagination={false}
        scroll={{ x: 3000, y: setScrollValue }}
        size="small"
        rowClassName={setRowStyle}
      />
    </div>
  );
}

SnapshotOrderDetails.propTypes = {};
SnapshotOrderDetails.defaultProps = {};
