import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Button,
  Switch,
  Modal,
  TimePicker,
  message,
  Spin,
} from 'antd';
import {
  DATE_TIME_ONE_FORMAT,
  DATE_TIME_TWO_FORMAT,
  TIME_HOUR_MINUES,
} from '../../common/constants';
import {
  useResetConceptData,
  useCheckAccountIfExist,
} from './redux/hooks';
import { transferFromResultToForm, transferFromFormToResult } from './formattedAccountsData';
import { validateNumericRegExp, validateConceptIdRegExp, validateGroupIdRegExp } from './formattedSubsData';
import { CustomLabel, SiteConceptCommonPanel } from './';

export default function MaintainAccountsOperateModal(props) {
  const {
    userName,
    userId,
    conceptData,
    fetchValidationCustomerDataPending,
    checkAccountIfExistPending,
  } = useSelector(state => ({
    userName: state.home.auth.user.userName,
    userId: state.home.auth.user.userId,
    conceptData: state.manageSubs.conceptData,
    fetchValidationCustomerDataPending: state.manageSubs.fetchValidationCustomerDataPending,
    checkAccountIfExistPending: state.manageSubs.checkAccountIfExistPending,
  }));
  const {
    title,
    isModalVisible,
    width,
    siteData,
    initValueData,
    onToggleModal,
    cancelText,
    okText,
    onCancel,
    onOk,
    loading,
    onDelete,
  } = props;
  const formattedRecord = initValueData && transferFromResultToForm(initValueData);
  const isCST = (initValueData && initValueData.managedBy === 'CST') ? true : false;
  const { resetConceptData } = useResetConceptData();
  const { checkAccountIfExist } = useCheckAccountIfExist();
  const [selectConceptKey, setSelectConceptKey] = useState('');
  const [form] = Form.useForm();
  const initCreatedTimestampFields = {
    createdByName: userName,
    createdById: userId,
    createTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
  };
  const initModifiedTimestampFields = {
    modifiedByName: userName,
    modifiedById: userId,
    modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
  };
  const handleItemChange = (value, label, key, listFieldName) => {
    let objFormValues = form.getFieldsValue();
    let objCurr = {};
    switch (label) {
      case 'site':
        const siteObj = siteData.find(item => item.opcoNo === value);
        objCurr = {
          [label]: value,
          siteDesc: siteObj ? siteObj.opcoName : '',
          nationalId: '',
          nationalName: '',
          customerId: '',
          customerName: '',
        };
        resetConceptData()
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      case 'nationalId':
        if ((value === undefined || value.length < 4) && conceptData) {
          resetConceptData();
        }
        objCurr = {
          [label]: value,
          nationalName: '',
          customerId: '',
          customerName: '',
        };
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      default:
        return;
    }
  };
  const onReset = () => {
    form.resetFields();
    setExists(false);
    resetConceptData();
    onCancel && onCancel();
  };
  const formattedSubmitFormData = values => {
    const customerObj = {
      nationalId: validateNumericRegExp(values.nationalId) ? '' : values.nationalId,
      nationalName: validateNumericRegExp(values.nationalId) ? '' : values.nationalName,
      customerNumber: validateNumericRegExp(values.nationalId) ? values.nationalId : '',
      customerName: validateNumericRegExp(values.nationalId) ? values.nationalName : '',
    };
    const formData = transferFromFormToResult({ ...values, ...customerObj, ...initModifiedTimestampFields, });
    return formData;
  };
  let isFinishTrigger = false
  const [ exists, setExists] = useState(false);
  const onFinish = values => {
    let validator = true;
    let formData = null;
    isFinishTrigger = true
    form.validateFields().then(data => {
      validator = true;
      formData = data;
    }).catch(errorInfo => {
      formData = errorInfo.values;
      //ANTD 4.x BUG ISSUE: IT WILL ALWAYS GOTO CATCH EVEN NO ERRORS
      if (errorInfo.errorFields.length > 0) {
        validator = false;
        form.setFields(errorInfo.errorFields);
      }
    }).finally(() => {
      if (validator && formData) {
        formData = formattedSubmitFormData(formData);
        if(isCST) formData.managedBy = 'CST'
        resetConceptData();
        onOk(checkIfChanged(formData), onToggleModal,exists);
      }
      isFinishTrigger = false
    });
  }

  const checkIfChanged = (formData) => {
    if(initValueData) {
      if(formData.maintenanceFlag == initValueData.maintenanceFlag && formData.nationalIdCutoffTime == initValueData.nationalIdCutoffTime &&
         formData.approvalRequiredFlag == initValueData.approvalRequiredFlag && formData.startOfDay == initValueData.startOfDay &&
         formData.catalogFlag == initValueData.catalogFlag && formData.managedBy == initValueData.managedBy) {
          formData.modifiedTimestamp = initValueData.modifiedTimestamp
          formData.modifiedById = initValueData.modifiedById
          formData.modifiedByName = initValueData.modifiedByName
      }
    }
    return formData
  }
  
  const mssgFunction = (val) => {
    if(val){
      message.destroy(1);
      return;
    }
    message.warning({
      content:`Account already exist.`,
      duration:7,
      key:1}
    ); 
  }
  
  const initCreatedValues = {
    managedBy: true,
    maintenanceFlag: true,
    approvalRequiredFlag: true,
    catalogFlag: true,
    startOfDay: false,
    nationalIdCutoffTime: moment("23:30", TIME_HOUR_MINUES),
    ...initCreatedTimestampFields,
  };
  const initialValues = formattedRecord || initCreatedValues;
  const labelProps = {
    labelCol: {span: 12},
    labelAlign: 'left',
  };
  const onModalClose = () => {
    resetConceptData();
    message.destroy(1);
    onToggleModal();
  };
  const checkIfAdded = () => {
    if(isFinishTrigger) return;
    const oldValues = form.getFieldsValue()
    const {site, nationalId} = oldValues
    const formData = {
      sites: [site],
      pageIndex: 1,
      pageSize: 10,
    };
    if(validateNumericRegExp(nationalId)) {
      formData.customerNumber = nationalId
    } else if(validateConceptIdRegExp(nationalId) || validateGroupIdRegExp(nationalId)) {
      formData.nationalIds = [nationalId]
    }
    checkAccountIfExist(formData).then(data => {
      if(data.totals) {
        setExists(false);
        mssgFunction();
      }
      else{
        setExists(true);
        mssgFunction(true);
      }
    })
  };
  const handleCutOffChg = (rule, value, callback) => {
    if(!value) {
      callback("Please input cut off time!")
      return
    }
    const timeStr = value.format(TIME_HOUR_MINUES)
    const minutes = value.format('mm')
    if(timeStr === '00:00') {
      callback("Entered Cut-Off time is not valid.")
    }else if(minutes != '00' && minutes != '30') {
      callback("Minutes can only be set to 00 or 30.")
    }else {
      callback()
    }
  };
  const handleHourAndMinute = (e, flag) => {
    const formVals = form.getFieldsValue()
    let timeArr = ['00', '00']
    if(formVals.nationalIdCutoffTime) {
      timeArr = formVals.nationalIdCutoffTime.format(TIME_HOUR_MINUES).split(":")
    }
    if(flag === 'hour') {
      timeArr[0] = e.target.innerText
    }else {
      timeArr[1] = e.target.innerText
    }
    form.setFieldsValue({'nationalIdCutoffTime': moment(`${timeArr[0]}:${timeArr[1]}`, TIME_HOUR_MINUES)})
    const errorList = []
    if(timeArr.join(':') === '00:00') {
      errorList.push({
        "name": ["nationalIdCutoffTime"],
        "errors": ['Entered Cut-Off time is not valid.'],
      })
    }
    form.setFields(errorList)
  };
  const handleHourClick = (e) => {
    handleHourAndMinute(e, 'hour')
  }
  const handleMinuteClick = (e) => {
    handleHourAndMinute(e, 'minute')
  }
  const handleTimePickerPop = (isOpen) => {
    if(isOpen) {
      setTimeout(() => {
        const ulDoms = document.getElementsByClassName('ant-picker-time-panel-column')
        if(ulDoms.length === 2) {
          ulDoms[0].addEventListener('click', handleHourClick);
          ulDoms[1].addEventListener('click', handleMinuteClick);
        }
      }, 500);
    }else {
      const ulDoms = document.getElementsByClassName('ant-picker-time-panel-column')
      if(ulDoms.length === 2) {
        ulDoms[0].removeEventListener('click', handleHourClick);
        ulDoms[1].removeEventListener('click', handleMinuteClick);
      }
    }
  }

  return (
    <Modal
      className="manage-subs-maintain-accounts-operate-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onModalClose}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={false}
      width={width || 520}
    >
      <Spin
        spinning={
          loading ||
          checkAccountIfExistPending ||
          fetchValidationCustomerDataPending
        }
      >
        <Form
          form={form}
          name="manage-subs-operate-form"
          className="operate-form"
          autoComplete="off"
          initialValues={initialValues}
        >
          {title === 'Add Account' && ( //this header shows just for Add screen
            <Row gutter={4} justify="space-between" className="account-header-row">
              <SiteConceptCommonPanel
                siteData={siteData}
                siteColSpan={10}
                conceptColSpan={11}
                handleItemChange={handleItemChange}
                selectConceptKey={selectConceptKey}
                setSelectConceptKey={setSelectConceptKey}
                initModifiedTimestampFields={initModifiedTimestampFields}
                checkIfAdded={checkIfAdded}
                form={form}
              />
            </Row>
          )}
          {title === 'Edit Account' &&
          formattedRecord && ( //this header shows just for Edit screen
            <Row gutter={4} justify="space-between" className="account-header-row">
              <Col span={4}>
                <Form.Item className="form-label static-form-item-value" label="Site ID" name="site">
                  <CustomLabel />
                </Form.Item>
                <Form.Item className="tips-content-item edit-site-tip" name="siteDesc">
                  <CustomLabel />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  className="form-label static-form-item-value"
                  label="Group/Ship To"
                  name="nationalId"
                >
                  <CustomLabel />
                </Form.Item>
                <Form.Item className="tips-content-item edit-concept-tip" name="nationalName">
                  <CustomLabel />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item className="form-label static-form-item-value" label="Created By" name="createdByName">
                  <CustomLabel />
                </Form.Item>
                <div className="flex align-items-center series-tips-content-items-1">
                  <Form.Item className="tips-content-item" name="createdById">
                    <CustomLabel />
                  </Form.Item>
                  <Form.Item className="tips-content-item" name="createTimestamp">
                    <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          )}
          <Row justify="space-between">
            <Col span={12}>
              <Form.Item {...labelProps} label="Include/Exclude" colon={false}>
                <div className="toggle-desc">Yes</div>
                <Form.Item name="maintenanceFlag" valuePropName="checked">
                  <Switch
                    className="subs-switch"
                    size="small"
                    defaultChecked
                    disabled={title=='Add Account'&& !exists?true:false}
                  />
                </Form.Item>
                <div className="toggle-desc">No</div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nationalIdCutoffTime" label="Sub Maintenance Cut - Off" colon={false} className="cut-off-time" 
                validateTrigger={['onChange']}
                rules={[
                  {
                    validateTrigger: 'onChange',
                    validator: (rule, value, callback) => handleCutOffChg(rule, value, callback),
                  },
                ]}
              >
                <TimePicker minuteStep={30} format={TIME_HOUR_MINUES} disabled={title=='Add Account'&& !exists?true:false}
                  onOpenChange={(isOpen) => handleTimePickerPop(isOpen)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <Form.Item {...labelProps} label="Require approval to sub" colon={false}>
                <div className="toggle-desc">Yes</div>
                <Form.Item name="approvalRequiredFlag" valuePropName="checked">
                <Switch
                    className="subs-switch"
                    size="small"
                    defaultChecked
                    disabled={title=='Add Account'&& !exists?true:false}
                  />
                </Form.Item>
                <div className="toggle-desc">No</div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...labelProps} label="Send Start of Day email" colon={false}>
                <div className="toggle-desc" style={{paddingLeft:'11px'}}>Yes</div>
                <Form.Item name="startOfDay" valuePropName="checked">
                  <Switch
                    className="subs-switch"
                    size="small"
                    disabled={title=='Add Account'&& !exists?true:false}
                  />
                </Form.Item>
                <div className="toggle-desc">No</div>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <Form.Item {...labelProps} label="Sub out of Catalog" colon={false}>
                <div className="toggle-desc">Yes</div>
                <Form.Item name="catalogFlag" valuePropName="checked">
                  <Switch
                    className="subs-switch"
                    size="small"
                    defaultChecked
                    disabled={title=='Add Account'&& !exists?true:false}
                  />
                </Form.Item>
                <div className="toggle-desc">No</div>
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item {...labelProps} label="Managed by" colon={false}>
                {isCST && (<span>CST</span>)}
                {!isCST && (
                  <>
                  <div className="toggle-desc">Local</div>
                  <Form.Item name="managedBy" valuePropName="checked" >
                    <Switch
                      className="subs-switch non-responsive-switch"
                      size="small"
                      defaultChecked
                      disabled={title=='Add Account'&& !exists?true:false}
                    />
                  </Form.Item>
                  <div className="toggle-desc">Central</div>
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item hidden name="createdByName">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="createdById">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="createTimestamp">
            <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
          </Form.Item>
          <Form.Item hidden name="modifiedByName">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="modifiedById">
            <CustomLabel />
          </Form.Item>
          <Form.Item hidden name="modifiedTimestamp">
            <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
          </Form.Item>
          <div className="subs-footer flex justify-end">
            {title === "Edit Account" && 
            <Button type="link" onClick={() => onDelete(initValueData, onToggleModal)} className="default-btn">
              Delete
            </Button>}
            <Button type="link" onClick={onReset} className="default-btn" style={{marginLeft:"2px"}}>
                {cancelText}
              </Button>
              <Button disabled={title=='Add Account'&& !exists?true:false} onClick={onFinish} type="primary" className='primary-btn' style={{marginLeft:"2px"}}>
                {okText}
              </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
