import React from 'react';
import { Row, Col } from 'antd';

export default function HelpWindow() {
  return (
    <div className="order-trail-help-window">
      <Row>
        <Col className="help-header" span={24}>
          Order Type
          <Col>
            <span className="help-col1">REG</span>
            <span className="help-col2">-Regular,</span>
            <span className="help-col1">SAM</span>
            <span className="help-col2">-Samples,</span>
            <span className="help-col1">VRT</span>
            <span className="help-col2">-Vendor Return</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Order Status Code - Header
          <Col>
            <span className="help-col1">O</span>
            <span className="help-col2">-Open,</span>
            <span className="help-col1">D</span>
            <span className="help-col2">-Sent To Routing,</span>
            <span className="help-col1">R</span>
            <span className="help-col2">-Routed,</span>
            <span className="help-col1">L</span>
            <span className="help-col2">-Labelled,</span>
            <span className="help-col1">S</span>
            <span className="help-col2">-Shipped,</span>
            <span className="help-col1">C</span>
            <span className="help-col2">-Cancel</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Order Status Code - Detail
          <Col>
            <span className="help-col1">O</span>
            <span className="help-col2">-Open,</span>
            <span className="help-col1">D</span>
            <span className="help-col2">-Deleted,</span>
            <span className="help-col1">L</span>
            <span className="help-col2">-Labelled,</span>
            <span className="help-col1">S</span>
            <span className="help-col2">-Shipped,</span>
            <span className="help-col1">C</span>
            <span className="help-col2">-Cancel</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Delivery Method
          <Col>
            <span className="help-col1">D</span>
            <span className="help-col2">-Drop ship,</span>
            <span className="help-col1">N</span>
            <span className="help-col2">-Normal Delivery,</span>
            <span className="help-col1">W</span>
            <span className="help-col2">-Customer Will Call,</span>
            <span className="help-col1">C</span>
            <span className="help-col2">-Container</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Approval Flag
          <Col>
            <span className="help-col1">A</span>
            <span className="help-col2">-Approved,</span>
            <span className="help-col1">C</span>
            <span className="help-col2">-Cancelled,</span>
            <span className="help-col1">P</span>
            <span className="help-col2">-Pending Approval,</span>
            <span className="help-col1">R</span>
            <span className="help-col2">-Rejected,</span>
            <span className="help-col1">B</span>
            <span className="help-col2">-Bumped To Next Delivery Date,</span>
            <span className="help-col1">T</span>
            <span className="help-col2">-Request Terminated</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Stock Type
          <Col>
            <span className="help-col1">N</span>
            <span className="help-col2">-Non Stocked,</span>
            <span className="help-col1">R</span>
            <span className="help-col2">-Remote,</span>
            <span className="help-col1">S</span>
            <span className="help-col2">-Stocked</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Order Hold Reason Code
          <Col>
            <span className="help-col1">ACA</span>
            <span className="help-col2">-Order Appv Reqd,</span>
            <span className="help-col1">BUN</span>
            <span className="help-col2">-Unusable BillTo,</span>
            <span className="help-col1">CRA</span>
            <span className="help-col2">-Credit,</span>
            <span className="help-col1">CRD</span>
            <span className="help-col2">-Credit Hold,</span>
            <span className="help-col1">CSP</span>
            <span className="help-col2">-Customer Stop,</span>
            <span className="help-col1">CST</span>
            <span className="help-col2">-Customer REQSTD,</span>
            <span className="help-col1">IVC</span>
            <span className="help-col2">-Unmatched VNDR,</span>
            <span className="help-col1">IVV</span>
            <span className="help-col2">-INVLD VRT VNDR,</span>
            <span className="help-col1">MRC</span>
            <span className="help-col2">-Merchand Hold,</span>
            <span className="help-col1">MST</span>
            <span className="help-col2">-Customer ONSTOP,</span>
            <span className="help-col1">ORM</span>
            <span className="help-col2">-ORMD Order,</span>
            <span className="help-col1">PRM</span>
            <span className="help-col2">-Promotion Hold,</span>
            <span className="help-col1">QNH</span>
            <span className="help-col2">-QTY Not On Hold,</span>
            <span className="help-col1">SDC</span>
            <span className="help-col2">-Deactivated Customer,</span>
            <span className="help-col1">SIC</span>
            <span className="help-col2">-Inactive Customer,</span>
            <span className="help-col1">SUN</span>
            <span className="help-col2">- Unfinished Customer,</span>
            <span className="help-col1">VRE</span>
            <span className="help-col2">-VNDR RETURN ERR</span>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="help-header" span={24}>
          Order Reason Code Detail
          <Col>
            <span className="help-col1">AAS</span>
            <span className="help-col2">-Address A Short,</span>
            <span className="help-col1">ALM</span>
            <span className="help-col2">-Allocation Maintenance,</span>
            <span className="help-col1">AOS</span>
            <span className="help-col2">-Address Out of Stock,</span>
            <span className="help-col1">FSB</span>
            <span className="help-col2">-Force Substitution,</span>
            <span className="help-col1">IAJ</span>
            <span className="help-col2">-Inventory Adjustment,</span>
            <span className="help-col1">INR</span>
            <span className="help-col2">-Insufficient Receipt,</span>
            <span className="help-col1">MOT</span>
            <span className="help-col2">-Machine Out,</span>
            <span className="help-col1">NWS</span>
            <span className="help-col2">-New SUPC,</span>
            <span className="help-col1">OPO</span>
            <span className="help-col2">-Out due to Future Order,</span>
            <span className="help-col1">OHH</span>
            <span className="help-col2">-Out/Cust Prefer Vendor,</span>
            <span className="help-col1">OTA</span>
            <span className="help-col2">-One Time Add,</span>
            <span className="help-col1">OUT</span>
            <span className="help-col2">-Out Of Stock,</span>
            <span className="help-col1">PHO</span>
            <span className="help-col2">-Item Phase Out,</span>
            <span className="help-col1">POC</span>
            <span className="help-col2">-Purchase Order Change,</span>
            <span className="help-col1">RST</span>
            <span className="help-col2">-Cannot Buy,</span>
            <span className="help-col1">UNV</span>
            <span className="help-col2">-Unavailable Item,</span>
            <span className="help-col1">VNC</span>
            <span className="help-col2">-Vendor Product Change,</span>
            <span className="help-col1">WHO</span>
            <span className="help-col2">-WHSE Out</span>
          </Col>
        </Col>
      </Row>
    </div>
  );
}

HelpWindow.propTypes = {};
HelpWindow.defaultProps = {};
