import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { EXPORT_API_REQUEST_URL } from '../../../common/apiConfig';
import {
  REPORTS_EXPORT_ORDERS_REPORT_BEGIN,
  REPORTS_EXPORT_ORDERS_REPORT_SUCCESS,
  REPORTS_EXPORT_ORDERS_REPORT_FAILURE,
  REPORTS_EXPORT_ORDERS_REPORT_DISMISS_ERROR,
} from './constants';

export function exportOrdersReport(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_EXPORT_ORDERS_REPORT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(`${EXPORT_API_REQUEST_URL}/exportOrders`, args);
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_EXPORT_ORDERS_REPORT_SUCCESS,
            data: res.data.data,
          });
          resolve(res.data.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_EXPORT_ORDERS_REPORT_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissExportOrdersReportError() {
  return {
    type: REPORTS_EXPORT_ORDERS_REPORT_DISMISS_ERROR,
  };
}

export function useExportOrdersReport() {
  const dispatch = useDispatch();

  const { exportOrdersReportPending, exportOrdersReportError } = useSelector(
    state => ({
      exportOrdersReportPending: state.reports.exportOrdersReportPending,
      exportOrdersReportError: state.reports.exportOrdersReportError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(exportOrdersReport(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissExportOrdersReportError());
  }, [dispatch]);

  return {
    exportOrdersReport: boundAction,
    exportOrdersReportPending,
    exportOrdersReportError,
    dismissExportOrdersReportError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_EXPORT_ORDERS_REPORT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        exportOrdersReportPending: true,
        exportOrdersReportError: null,
      };

    case REPORTS_EXPORT_ORDERS_REPORT_SUCCESS:
      // The request is success
      return {
        ...state,
        exportOrdersReportPending: false,
        exportOrdersReportError: null,
      };

    case REPORTS_EXPORT_ORDERS_REPORT_FAILURE:
      // The request is failed
      return {
        ...state,
        exportOrdersReportPending: false,
        exportOrdersReportError: action.data.error,
      };

    case REPORTS_EXPORT_ORDERS_REPORT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        exportOrdersReportError: null,
      };

    default:
      return state;
  }
}
