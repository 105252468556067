import React from 'react';
// import PropTypes from 'prop-types';

export default function Dashboard() {
  return (
    <div className="dashboard-new-dashboard">
      Component content: dashboard-new/Dashboard
    </div>
  );
};

Dashboard.propTypes = {};
Dashboard.defaultProps = {};
