import React from 'react';
import { Table } from 'antd';

export default function TableSummary(props) {
  const { tabKey, summary, columns } = props;
  return (
    <Table.Summary key={tabKey} fixed>
      <Table.Summary.Row>
        {
          columns.map(item => {
            if (item.key === 'offDay') {
              return (
                <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{summary.offDayOrders}</Table.Summary.Cell>
              )
            }
            if (item.key === 'orderTotalSales' || item.key === 'totalSales') {
               let totalSales = summary.totalSales && summary.totalSales.toLocaleString();
               if(Number(totalSales) !== 0){
                totalSales = `${totalSales}`;
               }
              return (
                <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{totalSales}</Table.Summary.Cell>
              )
            }
            if (item.key === 'orderTotalCases') {
              return (
                <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{summary.totalCases}</Table.Summary.Cell>
              )
            }
            if (item.key === 'orderTotalEaches') {
              return (
                <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{summary.totalEaches}</Table.Summary.Cell>
              )
            }
            if(item.key === 'salesPercentage'){
              let salesPercentage = summary[item.key] && summary[item.key].toLocaleString();
              if(Number(salesPercentage) !== 0){
                salesPercentage = `${salesPercentage}%`;
              }
              return (
                <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{salesPercentage}</Table.Summary.Cell>
              )
            }
            if(item.key === 'averageSell'){
              let averageSell = summary[item.key] && summary[item.key].toLocaleString();
              if(Number(averageSell) !== 0){
                averageSell = `$${averageSell}`;
              }
              return (
                <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{averageSell}</Table.Summary.Cell>
              )
            }
            return (
              <Table.Summary.Cell key={item.key} index={item.key} className={item.className}>{summary[item.key]}</Table.Summary.Cell>
            )
          })
        }
      </Table.Summary.Row>
    </Table.Summary>
  );
};

TableSummary.propTypes = {};
TableSummary.defaultProps = {};
