import { Layout } from './';
import { Dashboard } from '../dashboard';
import { OrderTrail } from '../order-trail';
import manageSubsRoute from '../manage-subs/route';
import { Reports } from '../reports';
import summaryRoute from '../reports-new/route';
import ordersRoute from '../orders/route';
import fetureOrderRoute from '../future-orders/route';
import scInActionRoute from '../sc-in-action/route';
import dashboardRoute from '../dashboard-new/route';
import outOfStockRoute from '../out-of-stock/route';
import oosAccountRoute from '../oos-account/route';
export default {
  path: '',
  component: Layout,
  childRoutes: [{
    path: '/',
    component: Dashboard,
    isIndex: true,
  }, {
    path: 'order-trail',
    component: OrderTrail,
  },
  {
    path: 'reports',
    component: Reports,
  },
    manageSubsRoute,
    summaryRoute,
    ordersRoute,
    fetureOrderRoute,
    scInActionRoute,
    dashboardRoute,
    outOfStockRoute,
    oosAccountRoute,
  ],
};
