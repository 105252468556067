import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_SAVE_DASHBOARD_CONDITION_DATA,
} from './constants';

export function saveDashboardConditionData(condition) {
  return {
    type: DASHBOARD_SAVE_DASHBOARD_CONDITION_DATA,
    data: condition,
  };
}

export function useSaveDashboardConditionData() {
  const dispatch = useDispatch();
  const dashboardConditionData = useSelector(state => state.dashboard.dashboardConditionData);
  const boundAction = useCallback((...params) => dispatch(saveDashboardConditionData(...params)), [dispatch]);
  return { dashboardConditionData, saveDashboardConditionData: boundAction };
}

export function reducer(state, action) {
  if(action.type===DASHBOARD_SAVE_DASHBOARD_CONDITION_DATA){
    return {
      ...state,
      dashboardConditionData: action.data,
    };

  }
  
      return state;
  
}
