import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_BEGIN,
  ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_SUCCESS,
  ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_FAILURE,
  ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_DISMISS_ERROR,
} from './constants';

export function fetchBusinessEventLogData(formData = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios({
        method: 'get',
        url: `${API_REQUEST_URL}/getBusinessEventLogData`,
        params: formData,
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        res => {
          res.data.rows.forEach((rowList, index) => {
            let dateStamp = '';
            let timeStamp = '';
              if(rowList.timeStamp) {
                const resultYear = rowList.timeStamp.substr(0, 4);
                const resultMonth = rowList.timeStamp.substr(5, 2);
                const resultDay = rowList.timeStamp.substr(8, 2);
                const resultHour = rowList.timeStamp.substr(11, 2);
                const resultMinute = rowList.timeStamp.substr(14, 2);
                const resultSecond = rowList.timeStamp.substr(17, 15);
                dateStamp = `${resultMonth}/${resultDay}/${resultYear}`;
                timeStamp = `${resultHour}:${resultMinute}:${resultSecond}`;
              }
              rowList.snapShotPresent = rowList.snapShotPresent.toString();
              rowList.dateStamp = dateStamp;
              rowList.timeStamp = timeStamp;
            },
          );
          const data = res.data.rows;
          const orderBannerData = data.filter(
            item => item.eventCode && item.eventCode.startsWith('BN'),
          );
          const expectBNData = data.filter(item =>
            item.eventCode ? !item.eventCode.startsWith('BN') : item,
          );

          dispatch({
            type: ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_SUCCESS,
            data: {
              data: expectBNData,
              bannerData: orderBannerData,
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_FAILURE,
            data: { error: errorMsg  },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchBusinessEventLogDataError() {
  return {
    type: ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_DISMISS_ERROR,
  };
}

export function useFetchBusinessEventLogData() {
  const dispatch = useDispatch();

  const {
    businessEventLogData,
    fetchBusinessEventLogDataPending,
    fetchBusinessEventLogDataError,
  } = useSelector(
    state => ({
      businessEventLogData: state.orderTrail.businessEventLogData,
      fetchBusinessEventLogDataPending: state.orderTrail.fetchBusinessEventLogDataPending,
      fetchBusinessEventLogDataError: state.orderTrail.fetchBusinessEventLogDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchBusinessEventLogData(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchBusinessEventLogDataError());
  }, [dispatch]);

  return {
    businessEventLogData,
    fetchBusinessEventLogData: boundAction,
    fetchBusinessEventLogDataPending,
    fetchBusinessEventLogDataError,
    dismissFetchBusinessEventLogDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchBusinessEventLogDataPending: true,
        fetchBusinessEventLogDataError: null,
      };

    case ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        businessEventLogData: action.data.data,
        businessEventBannerData: action.data.bannerData,
        fetchBusinessEventLogDataPending: false,
        fetchBusinessEventLogDataError: null,
      };

    case ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchBusinessEventLogDataPending: false,
        fetchBusinessEventLogDataError: action.data.error,
      };

    case ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchBusinessEventLogDataError: null,
      };

    default:
      return state;
  }
}
