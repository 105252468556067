import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_BEGIN,
  MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_SUCCESS,
  MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_FAILURE,
  MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';

export function validateItemInCharge(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const chargeParam = {
        "customerNumber": args.customerNumber,
        "item": args.item,
        "nationalId": args.nationalId,
        "site": args.site,
      }
      const chargeRequest = axios.post(`${API_REQUEST_URL}/validateItemInCharge`, chargeParam, {
        headers: {
          'Authorization': token,
        }
      });
      const catalogParam = {
        "customerNumber": args.customerNumber,
        "productId": args.item,
        "nationalId": args.nationalId,
        "opcoId": args.site,
      }
      const catalogRequest = axios.post(`${API_REQUEST_URL}/validateCatalog`, catalogParam, {
        headers: {
          'Authorization': token,
        }
      });

      Promise.allSettled([chargeRequest, catalogRequest]).then(
        (results) => {
          const isChargeCallSuccess = results[0].status === "fulfilled"
          const isCatalogSuccess = results[1].status === "fulfilled"
          if(isChargeCallSuccess && isCatalogSuccess) {
            dispatch({
              type: MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_SUCCESS,
              data: [results[0].value.data, results[1].value.data],
            });
            resolve([results[0].value.data, results[1].value.data]);
          }else {
            const errMsg = 'There is an error. Please refresh page or contact administrator.'
            dispatch({
              type: MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_FAILURE,
              data: { error: errMsg },
            });
            reject(errMsg);
          }
        },
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_FAILURE,
            data: { error: errorMsg },
          });
          reject(errorMsg);
        },
      )
    });

    return promise;
  };
}

export function dismissValidateItemInChargeError() {
  return {
    type: MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_DISMISS_ERROR,
  };
}

export function useValidateItemInCharge() {
  const dispatch = useDispatch();

  const { validateItemInChargePending, validateItemInChargeError } = useSelector(
    state => ({
      validateItemInChargePending: state.manageSubs.validateItemInChargePending,
      validateItemInChargeError: state.manageSubs.validateItemInChargeError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(validateItemInCharge(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissValidateItemInChargeError());
  }, [dispatch]);

  return {
    validateItemInCharge: boundAction,
    validateItemInChargePending,
    validateItemInChargeError,
    dismissValidateItemInChargeError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        validateItemInChargePending: true,
        validateItemInChargeError: null,
      };

    case MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_SUCCESS:
      // The request is success
      return {
        ...state,
        validateItemInChargePending: false,
        validateItemInChargeError: null,
      };

    case MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_FAILURE:
      // The request is failed
      return {
        ...state,
        validateItemInChargePending: false,
        validateItemInChargeError: action.data.error,
      };

    case MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        validateItemInChargeError: null,
      };

    default:
      return state;
  }
}
