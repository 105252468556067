import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_SAVE_IS_CORP_PROFILE_USER,
} from './constants';

export function saveIsCorpProfileUser(data,) {
  return {
    type: MANAGE_SUBS_SAVE_IS_CORP_PROFILE_USER,
    data,
  };
}

export function useSaveIsCorpProfileUser() {
  const dispatch = useDispatch();
  const isCorpProfileUser = useSelector(state => state.manageSubs.isCorpProfileUser);
  const boundAction = useCallback((...params) => dispatch(saveIsCorpProfileUser(...params)), [dispatch]);
  return { isCorpProfileUser, saveIsCorpProfileUser: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_SAVE_IS_CORP_PROFILE_USER:
      return {
        ...state,
        isCorpProfileUser: action.data
      };

    default:
      return state;
  }
}
