import axios from 'axios'
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  HOME_FEEDBACK_BEGIN,
  HOME_FEEDBACK_SUCCESS,
  HOME_FEEDBACK_FAILURE,
  HOME_FEEDBACK_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';

export function feedback(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: HOME_FEEDBACK_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/feedback`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_FEEDBACK_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: HOME_FEEDBACK_FAILURE,
            data: { error: errorMsg },
          });
          reject(errorMsg);
        },
      );
    });

    return promise;
  };
}

export function dismissFeedbackError() {
  return {
    type: HOME_FEEDBACK_DISMISS_ERROR,
  };
}

export function useFeedback() {
  const dispatch = useDispatch();

  const { feedbackPending, feedbackError } = useSelector(
    state => ({
      feedbackPending: state.home.feedbackPending,
      feedbackError: state.home.feedbackError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(feedback(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFeedbackError());
  }, [dispatch]);

  return {
    feedback: boundAction,
    feedbackPending,
    feedbackError,
    dismissFeedbackError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_FEEDBACK_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        feedbackPending: true,
        feedbackError: null,
      };

    case HOME_FEEDBACK_SUCCESS:
      // The request is success
      return {
        ...state,
        feedbackPending: false,
        feedbackError: null,
      };

    case HOME_FEEDBACK_FAILURE:
      // The request is failed
      return {
        ...state,
        feedbackPending: false,
        feedbackError: action.data.error,
      };

    case HOME_FEEDBACK_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        feedbackError: null,
      };

    default:
      return state;
  }
}
