import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_SEARCH_CONDITION_DATA,
} from './constants';

export function resetSearchConditionData() {
  return {
    type: MANAGE_SUBS_RESET_SEARCH_CONDITION_DATA,
  };
}

export function useResetSearchConditionData() {
  const dispatch = useDispatch();
  const searchConditionData = useSelector(state => state.manageSubs.searchConditionData);
  const boundAction = useCallback((...params) => dispatch(resetSearchConditionData()), [dispatch]);
  return { searchConditionData, resetSearchConditionData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_SEARCH_CONDITION_DATA:
      return {
        ...state,
        searchConditionData: null,
      };

    default:
      return state;
  }
}
