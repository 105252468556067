import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_BEGIN,
  MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_SUCCESS,
  MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_FAILURE,
  MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';

export function updateSubstitutionsData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/updateSubstitutionsData`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_FAILURE,
            data: { error: errorMsg },
          });
          if(err && err.response && err.response.status == '404') {
            reject({
              isInvalidShipTo: true,
              message: 'Entered Con ID/Cust is not included in BOS Manage Accounts screen'
            })
          }else {
            reject(err)
          }
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateSubstitutionsDataError() {
  return {
    type: MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_DISMISS_ERROR,
  };
}

export function useUpdateSubstitutionsData() {
  const dispatch = useDispatch();

  const { updateSubstitutionsDataPending, updateSubstitutionsDataError } = useSelector(
    state => ({
      updateSubstitutionsDataPending: state.manageSubs.updateSubstitutionsDataPending,
      updateSubstitutionsDataError: state.manageSubs.updateSubstitutionsDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateSubstitutionsData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateSubstitutionsDataError());
  }, [dispatch]);

  return {
    updateSubstitutionsData: boundAction,
    updateSubstitutionsDataPending,
    updateSubstitutionsDataError,
    dismissUpdateSubstitutionsDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateSubstitutionsDataPending: true,
        updateSubstitutionsDataError: null,
      };

    case MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        updateSubstitutionsDataPending: false,
        updateSubstitutionsDataError: null,
      };

    case MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        updateSubstitutionsDataPending: false,
        updateSubstitutionsDataError: action.data.error,
      };

    case MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateSubstitutionsDataError: null,
      };

    default:
      return state;
  }
}
