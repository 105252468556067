import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ORDER_TRAIL_RESET_ORDER_TRAIL_DATA,
} from './constants';

export function resetOrderTrailData() {
  return {
    type: ORDER_TRAIL_RESET_ORDER_TRAIL_DATA,
  };
}

export function useResetOrderTrailData() {
  const dispatch = useDispatch();
  const orderTrailData = useSelector(state => state.orderTrail.orderTrailData);
  const boundAction = useCallback((...params) => dispatch(resetOrderTrailData()), [dispatch]);
  return { orderTrailData, resetOrderTrailData: boundAction };
}

export function reducer(state, action) {

  if(action.type===ORDER_TRAIL_RESET_ORDER_TRAIL_DATA){
    return {
      ...state,
      orderTrailData: null,
    };
  }
 
      return state;
  
}
