import React from 'react';
import { useSelector } from 'react-redux';
import { CommonPage } from '.';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants'
export default function SalesForAccount() {
  const tabKey = 'Summary';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].customer.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].customer.level;
  return (
    <div className="reports-sales-for-account">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

SalesForAccount.propTypes = {};
SalesForAccount.defaultProps = {};
