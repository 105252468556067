import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { TagCard } from './';
import { ORDER_HEALTH_CHECK_COLUMNS } from '../../common/chartConstants';
import _ from 'lodash';
import { useTranslation } from "react-i18next";

export default function OrderHealthCheckCard(props) {
  const { t } = useTranslation();
  const { orderStatusSummaryData, salesSummaryData } = useSelector(state => ({
    orderStatusSummaryData: state.dashboard.orderStatusSummaryData,
    salesSummaryData: state.dashboard.salesSummaryData,
  }));
  const showHealthCheckDetails = (label, totals) => {
    const { handleHealthCheckLabelClick } = props;
    if(!disabledBtn(label)) {
      handleHealthCheckLabelClick(label, totals);
    }
  }
  const disabledBtn = (label) => {
    // if(label === 'exception'){
    //   return _.isNull(salesSummaryData['totalExceptions']) || salesSummaryData['totalExceptions'] === 0;
    // }
    // if(label === 'exactExceptions'){
    //   return _.isNull(salesSummaryData['exactExceptions']) || salesSummaryData['exactExceptions'] === 0;
    // }
    if(orderStatusSummaryData) {
      return _.isNull(orderStatusSummaryData[label]) || orderStatusSummaryData[label] === 0;
    }
    return true;
  }
  const renderTagCardItems = (startIndex, renderSize) => {
    return Object.keys(ORDER_HEALTH_CHECK_COLUMNS).map((key, index) => {
      if(index >= startIndex && index < startIndex + renderSize) {
        const title = ORDER_HEALTH_CHECK_COLUMNS[key];
        let className;
        if(key === 'open' || key === 'routing' || key === 'labelled' || key === 'shippedOrInvoiced'){
          className ='green-card' ;
         }
         else{ 
           if(key === 'lockedOrErrored'){
           className='red-card';
         }
           else{
             className='orange-card';
           }
 
         }
         // const className = key === 'open' || key === 'routing' || key === 'labelled' || key === 'shippedOrInvoiced' ? 'green-card' 
         //     : key === 'lockedOrErrored' ? 'red-card' : 'orange-card';
        
        if(orderStatusSummaryData.hasOwnProperty(key)) {
          const count = orderStatusSummaryData[key];
          return <TagCard key={key} title={t(`ORDER_HEALTH_CHECK_COLUMNS.${key}`)} className={className} count={count} onClick={() => showHealthCheckDetails(key, count)}/>
        // } else if(key === 'exactExceptions'){
        //     const count = salesSummaryData['exactExceptions'];
        //     return <TagCard key={key} title={title} className={className} count={count} onClick={() => showHealthCheckDetails(key, count)}/>
        } else if(key === 'exception'){
          const count = salesSummaryData['totalExceptions'];
          return <TagCard key={key} title={t(`EXCEPTIONS_DETAILS_COLUMNS.${key}`)} className={className} count={count} onClick={() => showHealthCheckDetails(key, count)}/>
        }
      }
      return null;
    });
  }
  return (
    <div className="dashboard-order-health-check-card">
      <Row justify="space-between">
        {/* <Col span={11} className="health-check-row">
          <Row justify="space-between">
            <Col span={11}>
              {renderTagCardItems(0, 3)}
            </Col>
            <Col span={11} className="sub02">
              {renderTagCardItems(3, 3)}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="health-check-row">
          {renderTagCardItems(6, 1)}
        </Col>
        <Col span={6} className="health-check-row">
          {renderTagCardItems(7, 2)}
        </Col> */}
        {/* <Col span={11} className="health-check-row"> */}
          {/* <Row justify="space-between"> */}
            <Col span={5} className="health-check-row">
              {renderTagCardItems(0, 3)}
            </Col>
            <Col span={5} className="health-check-row">
              {renderTagCardItems(3, 3)}
            </Col>
          {/* </Row> */}
        {/* </Col> */}
        <Col span={5} className="health-check-row">
          {renderTagCardItems(6, 1)}
        </Col>
        <Col span={5} className="health-check-row sub02">
          {renderTagCardItems(7, 2)}
        </Col>
      </Row>
    </div>
  );
};

OrderHealthCheckCard.propTypes = {};
OrderHealthCheckCard.defaultProps = {};
