import { Modal, Button, Rate, Input, message,Radio,Tooltip } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
// import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { faFaceMeh, faFaceFrown, faFaceFrownOpen, faFaceSmile, faFaceGrin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFeedback } from './redux/hooks'
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
const customIcons = {
  // 1: <FrownOutlined style={{"fontSize":"34px"}} />,
  // 2: <FrownOutlined style={{"fontSize":"34px"}} />,
  // 3: <MehOutlined style={{"fontSize":"34px"}} />,
  // 4: <SmileOutlined style={{"fontSize":"34px"}} />,
  // 5: <SmileOutlined style={{"fontSize":"34px"}} />,
  1: <FontAwesomeIcon icon={faFaceFrownOpen} style={{ height: "34px", width: "34px" }}/>,
  2: <FontAwesomeIcon icon={faFaceFrown} style={{ height: "34px", width: "34px" }}/>,
  3: <FontAwesomeIcon icon={faFaceMeh} style={{ height: "34px", width: "34px" }}/>,
  4: <FontAwesomeIcon icon={faFaceSmile} style={{ height: "34px", width: "34px" }}/>,
  5: <FontAwesomeIcon icon={faFaceGrin} style={{ height: "34px", width: "34px" }}/>,
};

export default function Feedback(props) {
  const { t } = useTranslation();
  const { title, isModalVisible, width, onToggleModal, cancelText, okText, } = props;
  const { userId, fullName } = useSelector(state => ({
    userId: state.home.auth.user.userId,
    fullName: state.home.auth.user.fullName,
  }));
  const [ textVal, setTextVal ] = useState('');
  const [ rateVal, setRateVal ] = useState(0);
  const { feedback } = useFeedback();
  const handleRateChg = (val) => {
    setRateVal(val.target.value);
    if (_.isEmpty(val.target.value)) return;
    const img = document.getElementsByClassName("feedback-svg");
    if (img === undefined) return;
    for (let i = 0; i < img.length; i++) {
      let imgItem = img[i];
      if (i === (parseInt(val.target.value) - 1)) {
        if (val.target.value === "1") {
          imgItem.style.color = "#FF3333";
        }
        if (val.target.value === "2") {
          imgItem.style.color = "#FFFF00";
        }
        if (val.target.value === "3") {
          imgItem.style.color = "#FFFF00";
        }
        if (val.target.value === "4") {
          imgItem.style.color = "#00DD00";
        }
        if (val.target.value === "5") {
          imgItem.style.color = "#00DD00";
        }
      } else {
        imgItem.style.color = "#DDDDDD";
      }
    }
  };

  const onTextChange = (e) => {
    setTextVal(e.target.value)
  };

  const onModalClose = () => {
    onToggleModal();
  };

  const onCancel = () => {
    setTextVal('')
  };

  const onFinish = () => {
    if(rateVal) {
      const formData = {
        userId,
        score: rateVal,
        comment: textVal,
      };
      feedback(formData).then(res => {
        if(res) {
          message.warning("Something went wrong, please try again!")
        }else {
          message.info("Thank you for your feedback!")
        }
      })
      onToggleModal()
    }else {
      message.warning('Please select a score to submit.');
    }
  };
  const handleOnHoverChange = (val)=>{
    console.log('handleOnHoverChange-->' + val);
  }
  return (
    <Modal
      className="feedback-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onModalClose}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={false}
      width={width || 520}
    >
      <p className='title'>{t('hello')} {fullName},</p>
      <p className='desc' style={{"marginBottom":"2px"}}>{t('feedbackDecription1')}</p>
      <p className='desc'>{t('feedbackDecription2')}</p>
      {/* <Rate value={rateVal} onChange={handleRateChg} onHoverChange={handleOnHoverChange} character={({ index }) => customIcons[index + 1]} tooltips={desc} className="flex justify-center" /> */}
      <div className='feeback-color'>
        <Radio.Group onChange={handleRateChg}>
          <Tooltip title={desc[0]}><Radio.Button value="1" className="feeback-color feedback-svg"><FontAwesomeIcon icon={faFaceFrownOpen} style={{ height: "34px", width: "34px"}} /></Radio.Button></Tooltip>
          <Tooltip title={desc[1]}><Radio.Button value="2" className="feeback-color feedback-svg"><FontAwesomeIcon icon={faFaceFrown} style={{ height: "34px", width: "34px" }} /></Radio.Button></Tooltip>
          <Tooltip title={desc[2]}><Radio.Button value="3" className="feeback-color feedback-svg"><FontAwesomeIcon icon={faFaceMeh} style={{ height: "34px", width: "34px" }} /></Radio.Button></Tooltip>
          <Tooltip title={desc[3]}><Radio.Button value="4" className="feeback-color feedback-svg"><FontAwesomeIcon icon={faFaceSmile} style={{ height: "34px", width: "34px" }} /></Radio.Button></Tooltip>
          <Tooltip title={desc[4]}><Radio.Button value="5" className="feeback-color feedback-svg"><FontAwesomeIcon icon={faFaceGrin} style={{ height: "34px", width: "34px" }} /></Radio.Button></Tooltip>
        </Radio.Group>
      </div>
      <TextArea value={textVal} onChange={onTextChange} rows={6} placeholder={t('feedbackTextReminder')} maxLength={500} className="flex justify-center text-feedback" />
      <div className="btn-footer flex justify-end">
        <Button onClick={onCancel} className="default-btn">
          {t(cancelText)}
        </Button>
        <Button onClick={onFinish} type="primary" className='primary-btn' style={{marginLeft:"4px"}}>
          {t(okText)}
        </Button>
      </div>
    </Modal>
  );
};

Feedback.propTypes = {};
Feedback.defaultProps = {};
