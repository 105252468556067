import { useCallback } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
  MANAGE_SUBS_SAVE_INIT_TERRITORY_FILTERS,
} from './constants';

export function saveInitTerritoryFilters(data) {
  return {
    type: MANAGE_SUBS_SAVE_INIT_TERRITORY_FILTERS,
    data
  };
}

export function useSaveInitTerritoryFilters() {
  const dispatch = useDispatch();
  const initTerritoryFilters = useSelector(state => state.manageSubs.initTerritoryFilters);
  const boundAction = useCallback((...params) => dispatch(saveInitTerritoryFilters(...params)), [dispatch]);
  return {initTerritoryFilters,saveInitTerritoryFilters: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_SAVE_INIT_TERRITORY_FILTERS:
      return {
        ...state,
        initTerritoryFilters: action.data,
      };

    default:
      return state;
  }
}
