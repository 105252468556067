import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Table, Pagination, Button, Badge } from 'antd';
import { OrdersDetailsReportModal } from './';
import { DetailsReportModal } from './';
import { ExportAlertModal } from '../common';
import { CONST_PAGINATION, CONST_PAGINATION_TEN, DEFAULT_REPORT_MODAL_KEY_VALUE } from '../../common/constants';
import { downloadFile } from '../../common/downloadReportFunc';
import { renderTableColumns } from '../../common/renderColumns';
import { useFetchScInActionData, useFetchSummaryData, useExportScInActionReportData, 
  useExportSummaryReport, useResetReportsData, useFetchOrdersData,
  useExportOrdersReport,useFetchOrdersReportByChannelType,useExportOrdersReportByChannelType } from './redux/hooks';
import { convertToFooterColumns, formatFormdata } from './formatFormdata';
import TableFooter from './TableFooter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import BusinessEventLogModal from './BusinessEventLogModal';
export default function ReportTable(props) {
  const {
    reportsData,
    reportsTotals,
    fetchScInActionDataPending,
    fetchSummaryDataPending,
    fetchOrdersReportByChannelTypePending,
    dashboardConditionData,
    territoryData,
    summary,
    exportSummaryReportPending,
    exportOrdersReportByChannelTypePending,
    exportScInActionReportDataPending,
  } = useSelector(state => ({
    reportsData: state.reports.reportsData,
    reportsTotals: state.reports.reportsTotals,
    fetchScInActionDataPending: state.reports.fetchScInActionDataPending,
    fetchSummaryDataPending: state.reports.fetchSummaryDataPending,
    fetchOrdersReportByChannelTypePending: state.reports.fetchOrdersReportByChannelTypePending,
    dashboardConditionData: state.dashboard.dashboardConditionData,
    territoryData: state.dashboard.territoryData,
    summary: state.reports.summary,
    exportSummaryReportPending: state.reports.exportSummaryReportPending,
    exportOrdersReportByChannelTypePending: state.reports.exportOrdersReportByChannelTypePending,
    exportScInActionReportDataPending: state.reports.exportScInActionReportDataPending,
  }));
  const { columnsProp, customerColumnsProp, orderColumnsProp, tabKey,accountColumnsProp } = props;
  const { fetchScInActionData } = useFetchScInActionData();
  const { fetchSummaryData } = useFetchSummaryData();
  const { exportScInActionReportData } = useExportScInActionReportData();
  const { exportSummaryReport } = useExportSummaryReport();
  const { resetReportsData } = useResetReportsData();
  const { fetchOrdersData } = useFetchOrdersData();
  const { exportOrdersReport } = useExportOrdersReport();
  const { fetchOrdersReportByChannelType } = useFetchOrdersReportByChannelType();
  const { exportOrdersReportByChannelType } = useExportOrdersReportByChannelType();
  const [pagination, setPagination] = useState(CONST_PAGINATION_TEN);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
  const [reportModalKey, setReportModalKey] = useState(DEFAULT_REPORT_MODAL_KEY_VALUE);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isOrderModalVisibleOrders, setIsOrderModalVisibleOrders] = useState(false);
  const [isBusinessModalVisible,setIsBusinessModalVisible] = useState(false);
  const condition = formatFormdata(dashboardConditionData, territoryData);
  useEffect(() => {
    if (summary) {
      //     let warp = document.querySelector(".ant-table-body");
      //   warp.addEventListener('scroll', handleScroll);
      //     return () => {
      //       window.removeEventListener('scroll', handleScroll);
      //     }
      const parentScroll = document.querySelector('.report-table .ant-table-body');
      const footerScroll = document.querySelector('.ant-table-footer .ant-table-body');
      if (parentScroll && footerScroll) {
        const scale = (parentScroll.scrollWidth - parentScroll.clientWidth) / (footerScroll.scrollWidth - footerScroll.clientWidth);
        let flag = 0;

        parentScroll.addEventListener('scroll', () => {
          if (flag !== 1) return;
          footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
        });
        footerScroll.addEventListener('scroll', () => {
          if (flag !== 2) return;
          parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
        });
        parentScroll.addEventListener('mouseover', () => {
          flag = 1;
        }, true);
        footerScroll.addEventListener('mouseover', () => {
          flag = 2;
        }, true);
        return () => {
          parentScroll.removeEventListener('scroll', () => {
            if (flag !== 1) return;
            footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
          });
          footerScroll.removeEventListener('scroll', () => {
            if (flag !== 2) return;
            parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
          });
          parentScroll.removeEventListener('mouseover', () => {
            flag = 1;
          }, true);
          footerScroll.removeEventListener('mouseover', () => {
            flag = 2;
          }, true);
        }
      }
    }
  }, [summary]);
  const reportActionIndex = {
    Summary: data => fetchSummaryData(data),
    'SC in Action': data => fetchScInActionData(data),
    'Orders': data => fetchOrdersReportByChannelType(data),
    'Future Orders': data => fetchOrdersReportByChannelType(data),
  };
  const exportReportActionIndex = {
    Summary: data => exportSummaryReport(data),
    'SC in Action': data => exportScInActionReportData(data),
    'Orders': data => exportOrdersReportByChannelType(data),
    'Future Orders': data => exportOrdersReportByChannelType(data),
  };

  useEffect(() => {
    setPagination(CONST_PAGINATION_TEN);
    setSorting();
    setShowAlert(false);
    resetReportsData();
  }, [dashboardConditionData]); // eslint-disable-line

  useEffect(() => {
    if(dashboardConditionData) {
      fetchOrderReportList(pagination, sorting);
    }
  }, [pagination, sorting, dashboardConditionData]); // eslint-disable-line

  const fetchOrderReportList = (paginationInfo, sortingInfo) => {
    const formdata = {
      ...condition,
      futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null,
      pageIndex: paginationInfo.pageIndex,
      pageSize: paginationInfo.pageSize,
      ...sortingInfo,
    };
    reportActionIndex[tabKey](formdata);
  };

  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };

  const onPaginationChange = (page, changedPageSize) => {
    setShowAlert(false);
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
  };

  const onToggleCustomerModal = (value, label, record) => {
    let objOffday = {};
    setIsCustomerModalVisible(prevState => {
      return !prevState;
    });
    //OffDay need accountType to filter data
    if(!isCustomerModalVisible && label === 'offDaySales') {
      objOffday = {
        accountType: record.accountType,
      };
    }
    if(tabKey === 'Orders' || tabKey === 'Future Orders') {
      if(!isCustomerModalVisible) {
        setReportModalKey({
          ...reportModalKey,
          opco: record.site,
          orderNo: record.orderNumber,
          customerNumber: record.customerNumber,
          customerName: record.customerName,
          status: 'OPEN',
        });
      }
    } else {
      setReportModalKey({
        ...reportModalKey,
        ...objOffday,
        [label]: !isCustomerModalVisible ? value : null,
      });
    }
  };
  const onToggleOrderModalOrders = (value, label,record) => {
    setIsOrderModalVisibleOrders(prevState => {
      return !prevState;
    });
    //OffDay need accountType to filter data
    let objOffday = {};
    if(!isOrderModalVisibleOrders && label === 'offDaySales') {
      objOffday = {
        accountType: record.accountType,
      };
    }
    //store parameters for fetch order report api
    setReportModalKey({
      ...reportModalKey,
      ...objOffday,
      [label]: !isOrderModalVisibleOrders ? value : null,
    });
  };
  const onToggleAccountModal = (value, label, record) => {
    setIsAccountModalVisible(prevState => {
      return !prevState;
    });
    // if(tabKey === 'Orders' || tabKey === 'Future Orders') {
      //store parameters for fetch summary report group by account api
      if(!isAccountModalVisible) {
        setReportModalKey({
          ...reportModalKey,
          opco: record.site,
          orderNo: record.orderNumber,
          customerNumber: record.customerNumber,
          customerName: record.customerName,
          status: 'OPEN',
          remoteOrderEntryMode: record.remoteOrderEntryMode,
          orderChannelType: record.orderChannelType,
        });
      }
    // } else {
    //   setReportModalKey({
    //     ...reportModalKey,
    //     ...objOffday,
    //     [label]: !isAccountModalVisible ? value : null,
    //   });
    // }
  };
  const onToggleBusinessModal = (value, label,record) => {
    setIsBusinessModalVisible(prevState => {
      return !prevState;
    });
    //store parameters for business event api
    setReportModalKey({
      ...reportModalKey,
      [label]: !isBusinessModalVisible ? value : null,
      opco: record && record.site,
      orderNo: record && record.orderNumber,
      customerNumber: !isBusinessModalVisible ? record.customerNumber : null,
    });
  };
  const onToggleOrderModal = (value, label) => {
    setIsOrderModalVisible(prevState => {
      return !prevState;
    });
    setReportModalKey({
      ...reportModalKey,
      [label]: !isOrderModalVisible ? value : null,
    });
  };

  const exportReports = () => {
    if (reportsTotals > 5001) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      let sheetColConfig = [];
      columnsProp.forEach(col => {
        let sheetColWidth = 12;
        if(col.width) {
          sheetColWidth = col.width/10;
        }
        sheetColConfig.push({ wch: sheetColWidth });
      });
      exportReportActionIndex[tabKey]({
        ...condition,
        futureOrders: tabKey === 'Future Orders' ? 'futureOrders' : null,
      }).then(data => {
        const sheetObj = [{
          headers: renderTableColumns(columnsProp, tabKey, renderElementIndex),
          data: data,
          sheetName: null,
          sheetColConfig,
        }];
        downloadFile(`${tabKey} Report`, sheetObj);
      });
    }
  };

  const submitExceptions = () => {};

  const renderTableLinkButton = (value, label, record, isNestColumn) => {
    if(tabKey === 'Orders' || tabKey === 'Future Orders'){
      return (
        <Button
          type="link"
          onClick={() => 
            {
              (isNestColumn !==undefined && !isNestColumn && isAccountModalVisible)?onToggleBusinessModal(value, label, record):
              (isNestColumn && isAccountModalVisible)?onToggleOrderModalOrders(value, label, record):onToggleAccountModal(value, label, record)
            }
          }
          className={label === 'offDaySales' && parseInt(value) === 0?"ant-btn-link-disabled":""}
        >
          {value}
        </Button>
       
      );
    }
    return (
      <Button
        type="link"
        onClick={() => (isNestColumn ? onToggleOrderModal(value, label) : onToggleCustomerModal(value, label, record))}
        className={label === 'offDaySales' && parseInt(value) === 0?"ant-btn-link-disabled":""}
      >
        {value}
      </Button>
    );
  };

  const renderBadge = (value, label) => {
    if(label === 'largeHits' && value === 'Y') {
      return <Badge className="order-badge-item" color="#56B146"/>;
    } else if(label === 'underXOrY' && value === 'Y') {
      return <Badge className="order-badge-item" color="#D0021B"/>;
    } else if(label === 'offDay' && value === 'Y') {
      return <Badge className="order-badge-item" color="#6A737B"/>;
    } else {
      return null;
    }
  };

  const renderElementIndex = {
    toggleModal: renderTableLinkButton,
    renderBadge: renderBadge,
  };
  //insert all index into summary data
  let footerData = JSON.parse(JSON.stringify(summary));
  if(footerData){
    footerData.totalIndex = true;
  }
  let footerTableColumns = renderTableColumns(convertToFooterColumns(columnsProp), tabKey, renderElementIndex);
  const renderFooter = ()=>{
    if (summary && footerData && !_.isEmpty(reportsData)) {
      return (
        <TableFooter tabKey={tabKey} summary={footerData} columns={footerTableColumns} />
      )
    }
  }
  let footerClassName = tabKey === 'Orders' || tabKey === 'Future Orders'?"justify-space-between":"justify-end";
  return (
    <div className="reports-report-table">
      <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
      <div className="report-export-button flex justify-end">
        {tabKey === 'Exceptions' ? <Button onClick={submitExceptions}>Submit</Button> : null}
        <Button type="link" title="Export" disabled={_.isEmpty(reportsData)} style={{ marginLeft: '-20px' }} onClick={exportReports}>
          <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(reportsData) ? "#a1a1a2" : "#6b6b6c"} style={{ height: "20px", width: "20px" }} />
          <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: (_.isEmpty(reportsData) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
        </Button>
      </div>
      <Table
        rowKey={record => tabKey === 'Orders' || tabKey === 'Future Orders'?`${record.orderChannelType}`:`${record.accountType}-${record.customerNumber}-${record.orderNumber}-${record.scId}`}
        className="report-table"
        loading={fetchScInActionDataPending || fetchSummaryDataPending || fetchOrdersReportByChannelTypePending 
          || exportSummaryReportPending || exportOrdersReportByChannelTypePending || exportScInActionReportDataPending}
        columns={renderTableColumns(columnsProp, tabKey, renderElementIndex)}
        dataSource={reportsData}
        pagination={false}
        onChange={onTableChange}
        scroll={{ x: 'max-content', y: tabKey === 'Out & Subs' ? 320 : 350 }}
        footer={renderFooter}
      //   summary={() => (
      //   <Table.Summary fixed>
      //     <Table.Summary.Row>
      //       <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
      //       <Table.Summary.Cell index={1}>This is a summary content</Table.Summary.Cell>
      //     </Table.Summary.Row>
      //   </Table.Summary>
      // )}
      />
      <div className='flex justify-end'>
        {/* { tabKey === 'Orders' || tabKey === 'Future Orders' ?
          <div>
            <Badge className="tips-badge-item" color="#56B146" text="Large Hits"/>
            <Badge className="tips-badge-item" color="#D0021B" text="Orders under X$ or Y Cases"/>
            <Badge className="tips-badge-item" color="#6A737B" text="Offday Orders"/>
          </div>
          : null
        } */}
        <Pagination
          style={{ textAlign: 'right', margin: '16px 0px' }}
          className={'report-pagination'}
          showSizeChanger
          current={pagination.pageIndex}
          pageSize={pagination.pageSize}
          total={reportsTotals}
          pageSizeOptions={pagination.pageSizeOptions}
          onShowSizeChange={onPaginationChange}
          onChange={onPaginationChange}
        />
      </div>

      {/* Customer level modal */}
      <DetailsReportModal
        columns={renderTableColumns(customerColumnsProp, tabKey, renderElementIndex, true)}
        tabKey={tabKey}
        keyValue={reportModalKey}
        isVisible={isCustomerModalVisible}
        onToggleModal={onToggleCustomerModal}
        onChangeReportModalKey={setReportModalKey}
        fromCustomer={true}
      />
      {/* Order level modal */}
      <DetailsReportModal
        columns={renderTableColumns(orderColumnsProp, tabKey, renderElementIndex)}
        tabKey={tabKey}
        keyValue={reportModalKey}
        isVisible={isOrderModalVisible}
        onToggleModal={onToggleOrderModal}
        onChangeReportModalKey={setReportModalKey}
        fromCustomer={false}
      />
      {/* Account level modal for order/future order*/}
      <OrdersDetailsReportModal
        columns={renderTableColumns(accountColumnsProp, tabKey, renderElementIndex,true)}
        tabKey={tabKey}
        keyValue={reportModalKey}
        isVisible={isAccountModalVisible}
        onToggleModal={onToggleAccountModal}
        onChangeReportModalKey={setReportModalKey}
        fromChannelType={true}
      />
      <OrdersDetailsReportModal
        columns={renderTableColumns(orderColumnsProp, tabKey, renderElementIndex,false)}
        tabKey={tabKey}
        keyValue={reportModalKey}
        isVisible={isOrderModalVisibleOrders}
        onToggleModal={onToggleOrderModalOrders}
        onChangeReportModalKey={setReportModalKey}
      />
        <BusinessEventLogModal
        columns={renderTableColumns(customerColumnsProp, tabKey, renderElementIndex)}
        tabKey={tabKey}
        keyValue={reportModalKey}
        isVisible={isBusinessModalVisible}
        onToggleModal={onToggleBusinessModal}
        onChangeReportModalKey={setReportModalKey}
        />
    </div>
  );
}

ReportTable.propTypes = {};
ReportTable.defaultProps = {};
