import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_BEGIN,
  MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_SUCCESS,
  MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_FAILURE,
  MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function validateActiveConceptCus(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/validateActiveConceptCus`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_FAILURE,
            data: { error: errorMsg },
          });
          reject(errorMsg);
        },
      );
    });

    return promise;
  };
}

export function dismissValidateActiveConceptCusError() {
  return {
    type: MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_DISMISS_ERROR,
  };
}

export function useValidateActiveConceptCus() {
  const dispatch = useDispatch();

  const { validateActiveConceptCusPending, validateActiveConceptCusError } = useSelector(
    state => ({
      validateActiveConceptCusPending: state.manageSubs.validateActiveConceptCusPending,
      validateActiveConceptCusError: state.manageSubs.validateActiveConceptCusError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(validateActiveConceptCus(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissValidateActiveConceptCusError());
  }, [dispatch]);

  return {
    validateActiveConceptCus: boundAction,
    validateActiveConceptCusPending,
    validateActiveConceptCusError,
    dismissValidateActiveConceptCusError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        validateActiveConceptCusPending: true,
        validateActiveConceptCusError: null,
      };

    case MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_SUCCESS:
      // The request is success
      return {
        ...state,
        validateActiveConceptCusPending: false,
        validateActiveConceptCusError: null,
      };

    case MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_FAILURE:
      // The request is failed
      return {
        ...state,
        validateActiveConceptCusPending: false,
        validateActiveConceptCusError: action.data.error,
      };

    case MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        validateActiveConceptCusError: null,
      };

    default:
      return state;
  }
}
