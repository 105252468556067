import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  MANAGE_SUBS_RETAIN_CONDITION_DATA,
} from './constants';

export function retainConditionData(data) {
  return {
    type: MANAGE_SUBS_RETAIN_CONDITION_DATA,
    data,
  };
}

export function useRetainConditionData() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(retainConditionData(...params)), [dispatch]);
  return { retainConditionData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RETAIN_CONDITION_DATA:
      return {
        ...state,
        conditionData: action.data,
      };

    default:
      return state;
  }
}
