import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  DASHBOARD_SAVE_NOTIFICATION_STATUS,
} from './constants';

export function saveNotificationStatus(data) {
  return {
    type: DASHBOARD_SAVE_NOTIFICATION_STATUS,
    data,
  };
}

export function useSaveNotificationStatus() {
  const dispatch = useDispatch();
  const notificationStatus = useSelector(state => state.dashboard.initTerritoryFiltersData);
  const boundAction = useCallback((...params) => dispatch(saveNotificationStatus(...params)), [dispatch]);
  return { notificationStatus,saveNotificationStatus: boundAction };
}

export function reducer(state, action) {
  if (action.type === DASHBOARD_SAVE_NOTIFICATION_STATUS) {
    return {
      ...state,
      notificationStatus: action.data,
    };
  }
  return state;
}
