export const sortFiltersListByKey = (data, key) => {
  if(key) {
    return data.sort((itemOne, itemTwo) => {
      return itemOne[key] ? itemOne[key].localeCompare(itemTwo[key]) : 0;
    });
  }
  if(data) {
    return data.sort((itemOne, itemTwo) => {
      return itemOne ? itemOne.localeCompare(itemTwo) : 0;
    });
  }
  return data;
}

export const sortString = (itemOne, itemTwo) => {
  const itemOneUpper = itemOne.toUpperCase();
  const itemTwoUpper = itemTwo.toUpperCase();
  if (itemOneUpper < itemTwoUpper) {
    return -1;
  }
  if (itemOneUpper > itemTwoUpper) {
    return 1;
  }
  return 0;
};
export const sortObject = (obj, sortedBy, sort, optionSortedBy) => {
  return obj.sort(function (itemOne, itemTwo) {
    var x = itemOne[sortedBy]; var y = itemTwo[sortedBy];
    if (sort === 'ascend') {
      // return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      } else {
        if (optionSortedBy) {
          if (itemOne[optionSortedBy] < itemTwo[optionSortedBy]) {
            return -1;
          } else if (itemOne[optionSortedBy] > itemTwo[optionSortedBy]) {
            return 1;
          } else {
            return 0;
          }
        }
        return 0;
      }
    } else if (sort === 'descend') {
      // return ((x < y) ? ((x > y) ? 1 : 0) : -1);
      if (x < y) {
        return 1;
      } else if (x > y) {
        return -1;
      } else {
        if (optionSortedBy) {
          if (itemOne[optionSortedBy] < itemTwo[optionSortedBy]) {
            return 1;
          } else if (itemOne[optionSortedBy] > itemTwo[optionSortedBy]) {
            return -1;
          } else {
            return 0;
          }
        }
        return 0;
      }
    } else {
      return 0;
    }
  });
}
