import React from 'react';
import { useLocation } from 'react-router-dom';
import { CONST_REPORT_TABS_NEW_COLUMNS, } from '../../common/reportsConstants';
import {CommonPage} from '../reports-new';

export default function OrderChannelType() {
  const location = useLocation();
  let tabKey = '';
  if(location.pathname === '/orders'){
    tabKey = 'Orders';
  }else{
    tabKey = 'Future Orders';
  }
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].summary.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].summary.level;
  return (
    <div className="orders-order-channel-type">
     <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

OrderChannelType.propTypes = {};
OrderChannelType.defaultProps = {};
