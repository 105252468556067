import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { getDate, getFrDate } from './date';
import { useSaveReportsKeyValue } from './redux/hooks';
import { Button } from 'antd';
import { useResetReportsOrderLevelData, useResetReportsCustomerLevelData,useResetReportsDataAccount, useResetReportsDataOrders,useResetReportsOrderDetailLevelData, useResetReportsData } from '../reports/redux/hooks';
import { useResetBusinessEventLogData } from '../order-trail/redux/hooks';
import { DEFAULT_REPORT_MODAL_KEY_VALUE } from '../../common/constants';
import { useTranslation } from 'react-i18next';
export default function Header({tabKey1,backTo, title, fromCustomer,fromChannelType,isEventLog ,level}) {
  const { i18n } = useTranslation();
  const { reportsKeyValue,isSiteUser } = useSelector(state => ({ 
    reportsKeyValue: state.reportsNew.reportsKeyValue ,
    isSiteUser: state.dashboard.isSiteUser,
  }))
  const { saveReportsKeyValue } = useSaveReportsKeyValue();
  const { resetReportsOrderLevelData } = useResetReportsOrderLevelData();
  const { resetReportsCustomerLevelData } = useResetReportsCustomerLevelData();
  const { resetReportsDataAccount } = useResetReportsDataAccount();
  const { resetReportsDataOrders } = useResetReportsDataOrders();
  const {resetBusinessEventLogData} = useResetBusinessEventLogData();
  const { resetReportsOrderDetailLevelData } = useResetReportsOrderDetailLevelData();
  const { resetReportsData } = useResetReportsData();
  const history = useHistory();
  let tabKey = reportsKeyValue.tabKey;
  const handleBackTo = (backTo) => {
    let historyLink = backTo ? backTo : "/";
    history.push(historyLink);
    if(tabKey === 'Summary'){
      switch (level) {
        case 1:
          if(!isSiteUser){
            saveReportsKeyValue(DEFAULT_REPORT_MODAL_KEY_VALUE);
            resetReportsData();
          }
          break;
        case 2:
          if(!isSiteUser){
            saveReportsKeyValue({
              ...reportsKeyValue,
              accountType: null,
            });
          }else{
            saveReportsKeyValue(DEFAULT_REPORT_MODAL_KEY_VALUE);
          }
          resetReportsCustomerLevelData();
          break;
        case 3:
          saveReportsKeyValue({
            ...reportsKeyValue,
            customerNumber: null,
          });
          resetReportsOrderLevelData();
          break;
        case 4:
          saveReportsKeyValue({
            ...reportsKeyValue,
            orderNumber: null,
          });
          resetReportsOrderDetailLevelData();
          break;
          
      }
    }
    if(tabKey ==='Orders' || tabKey === 'Future Orders'){
      switch (level) {
        case 2:
          saveReportsKeyValue(DEFAULT_REPORT_MODAL_KEY_VALUE);
          resetReportsCustomerLevelData();
          break;
          case 3:
            saveReportsKeyValue({
                  ...reportsKeyValue,
                  accountType:null,
                  offDaySales:null,
                  opco:null,
                });
            resetReportsOrderLevelData();
            break;
        case 4:
          saveReportsKeyValue({
            ...reportsKeyValue,
            orderNumber: null,
          });
          resetReportsOrderDetailLevelData();
          break;
      }
    }
    if(tabKey === 'SC in Action' || tabKey === 'OOS Account'){
      switch (level) {
        case 2:
          saveReportsKeyValue(DEFAULT_REPORT_MODAL_KEY_VALUE);
          resetReportsCustomerLevelData();
          break;
          case 3:
            saveReportsKeyValue({
                  ...reportsKeyValue,
                  customerNumber: null,
                });
            resetReportsOrderLevelData();
            break;
        case 4:
          saveReportsKeyValue({
            ...reportsKeyValue,
            orderNumber: null,
          });
          resetReportsOrderDetailLevelData();
          break;
      }
    }
    if(tabKey === 'OOS Item'){
      switch (level) {
        case 2:
          saveReportsKeyValue(DEFAULT_REPORT_MODAL_KEY_VALUE);
          resetReportsOrderLevelData();
          break;
        case 3:
          saveReportsKeyValue({
            ...reportsKeyValue,
            itemNumber: null,
            orderNumber: null,
          });
          resetReportsCustomerLevelData();
          break;
      }
    }
  }
  return (
    <div className="reports-new-header">
      {
        backTo && <Button type="link" onClick={() => handleBackTo(backTo)}><FontAwesomeIcon icon={faArrowLeft} style={{ height: "15px", width: "15px",paddingRight:"5px" }} color="#1C1B1F" /></Button>
      }

      <label className='title font-size-18'>{title}</label>
      {
        title !== 'Dashboard' && <p className='date font-size-14'>{i18n.language === 'fr' ? getFrDate() : getDate()}</p>
      }
    </div>
  );
};

Header.propTypes = {};
Header.defaultProps = {};
