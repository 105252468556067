import React from 'react';
import { CommonPage } from '../reports-new';
import { CONST_REPORT_TABS_NEW_COLUMNS, } from '../../common/reportsConstants';

export default function OosAccountItem() {
  const tabKey = 'OOS Account';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.level;
  return (
    <div className="oos-account-oos-account-item">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

OosAccountItem.propTypes = {};
OosAccountItem.defaultProps = {};
