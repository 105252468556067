export const ORDER_HEALTH_CHECK_COLUMNS = {
  open: 'open',
  lockedOrErrored: 'lockedOrErrored',
  cancelled: 'cancelled',
  creditHold: 'creditHold',
  merchHold: 'merchHold',
  pendingApproval: 'pendingApproval',
  routing: 'routing',
  labelled: 'labelled',
  shippedOrInvoiced: 'shippedOrInvoiced',
};

export const ORDER_BY_TYPE_COLUMNS = {
  regular: 'regular',
  willCall: 'willCall',
  hotShot: 'hotShot',
  unconfirmed: 'unconfirmed',
  dropShip: 'dropShip',
  others: 'others',
};

export const EXPECTED_SALES_COLUMNS_ONE = {
  totalSales: 'totalSales',
  totalCases: 'totalCases',
  totalAllocatedCases: 'totalAllocatedCases',
};

export const EXPECTED_SALES_COLUMNS_TWO = {
  totalOrders: 'totalOrders',
  totalEaches: 'totalEaches',
  totalAllocatedEaches: 'totalAllocatedEaches',
};

export const EXPECTED_SALES_COLUMNS_THREE = {
  totalLines: 'totalLines',
  totalExceptions: 'three_totalExceptions',
  totalPickups: 'totalPickups',
};
//unuse
export const EXPECTED_SALES_REPORT_COLUMNS = {
  totalSales: 'Total Sales',
  totalGPPercentage: 'Total GP Percentage',
  totalOrders: 'Total Orders',
  totalLines: 'Total Lines',
  totalExceptions: 'Total Lines w/ Exceptions',
  totalPieces: 'Total Pieces',
  totalConfPieces: 'Total Conf Pieces',
  avgPricePerPiece: 'Avg Price Per Piece',
  totalPickPieces: 'Total Pick Pieces',
  perfectOrderRate: 'Perfect Order Rate',
};
export const EXCEPTIONS_DETAILS_COLUMNS = {
  exactExceptions: 'exactExceptions',
  totalExceptions: 'details_totalExceptions',
  subsExceptions: 'subsExceptions',
};
