import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_FETCH_OOS_ACCOUNT_ORDER_BEGIN,
  REPORTS_FETCH_OOS_ACCOUNT_ORDER_SUCCESS,
  REPORTS_FETCH_OOS_ACCOUNT_ORDER_FAILURE,
  REPORTS_FETCH_OOS_ACCOUNT_ORDER_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function fetchOosAccountOrder(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_OOS_ACCOUNT_ORDER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "ascend",
          sortedBy: "site",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getOosAccountItem`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
        //   res.data={
        //     "data": [
        //         {
        //             "oos": 4,
        //             "lostSales": 47.8,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 4,
        //             "site": "056",
        //             "itemNumber": "1954148",
        //             "itemDescription": "THERMOMETER FRZR REFR -40-80F",
        //             "originalItemBrand": "SYSCO BRAND",
        //             "orderNumber": 4,
        //             "vendorNumber": "       858",
        //             "vendorName": "COOPER ATKINS CORP",
        //         },
        //         {
        //             "oos": 2,
        //             "lostSales": 38.5,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 2,
        //             "site": "056",
        //             "itemNumber": "4035697",
        //             "itemDescription": "MASHER POTATO 4X4 BASE",
        //             "originalItemBrand": "HAROLD LEONARD CO",
        //             "orderNumber": 4,
        //             "vendorNumber": "    403741",
        //             "vendorName": "CENTRAL WAREHOUSE NORTHEAST"
        //         },
        //         {
        //             "oos": 2,
        //             "lostSales": 17.1,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 2,
        //             "site": "056",
        //             "itemNumber": "4517256",
        //             "itemDescription": "KNIFE DINNER WINDSOR MEDWGHT",
        //             "originalItemBrand": "SYSCO BRAND",
        //             "orderNumber": 4,
        //             "vendorNumber": "      4112",
        //             "vendorName": "WORLD TABLEWARE INC"
        //         },
        //         {
        //             "oos": 4,
        //             "lostSales": 46.2,
        //             "preShipDirSubs": 0,
        //             "preShipAllSubs": 0,
        //             "preShipDirSub": 0,
        //             "preShipAllSub": 0,
        //             "preShipAllQnty": 4,
        //             "site": "056",
        //             "itemNumber": "4518304",
        //             "itemDescription": "SPOON BOUILLON WINDSOR MEDWGHT",
        //             "originalItemBrand": "SYSCO BRAND",
        //             "orderNumber": 4,
        //             "vendorNumber": "      4112",
        //             "vendorName": "WORLD TABLEWARE INC"
        //         }
        //     ],
        //     "totals": 4,
        //     "summary": {
        //         "oos": 12,
        //         "preShipAllSubs": 0,
        //         "lostSales": 149.6,
        //         "preShipDirSubs": 0
        //     }
        // }
          dispatch({
            type: REPORTS_FETCH_OOS_ACCOUNT_ORDER_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              orderSummary: res.data && res.data.summary
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_FETCH_OOS_ACCOUNT_ORDER_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOosAccountOrderError() {
  return {
    type: REPORTS_FETCH_OOS_ACCOUNT_ORDER_DISMISS_ERROR,
  };
}

export function useFetchOosAccountOrder() {
  const dispatch = useDispatch();

  const { reportsOrderData, reportsOrderTotals, orderSummary,fetchOosAccountOrderPending, fetchOosAccountOrderError } = useSelector(
    state => ({
      reportsOrderData: state.reports.reportsOrderData,
      reportsOrderTotals: state.reports.reportsOrderTotals,
      orderSummary: state.reports.orderSummary,
      fetchOosAccountOrderPending: state.reports.fetchOosAccountOrderPending,
      fetchOosAccountOrderError: state.reports.fetchOosAccountOrderError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOosAccountOrder(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOosAccountOrderError());
  }, [dispatch]);

  return {
    reportsOrderData, 
    reportsOrderTotals, 
    orderSummary,
    fetchOosAccountOrder: boundAction,
    fetchOosAccountOrderPending,
    fetchOosAccountOrderError,
    dismissFetchOosAccountOrderError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_OOS_ACCOUNT_ORDER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOosAccountOrderPending: true,
        fetchOosAccountOrderError: null,
      };

    case REPORTS_FETCH_OOS_ACCOUNT_ORDER_SUCCESS:
      // The request is success
      return {
        ...state,
        reportsOrderData: action.data.data,
        reportsOrderTotals: action.data.totals,
        orderSummary: action.data.orderSummary,
        fetchOosAccountOrderPending: false,
        fetchOosAccountOrderError: null,
      };

    case REPORTS_FETCH_OOS_ACCOUNT_ORDER_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOosAccountOrderPending: false,
        fetchOosAccountOrderError: action.data.error,
      };

    case REPORTS_FETCH_OOS_ACCOUNT_ORDER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOosAccountOrderError: null,
      };

    default:
      return state;
  }
}
