import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_RESET_REPORTS_CUSTOMER_LEVEL_DATA,
} from './constants';

export function resetReportsCustomerLevelData() {
  return {
    type: REPORTS_RESET_REPORTS_CUSTOMER_LEVEL_DATA,
  };
}

export function useResetReportsCustomerLevelData() {
  const dispatch = useDispatch();
  const { reportsCustomerData } = useSelector(state => ({
    reportsCustomerData: state.reports.reportsCustomerData,
  }), shallowEqual);
  const boundAction = useCallback((...params) => dispatch(resetReportsCustomerLevelData()), [dispatch]);
  return { reportsCustomerData, resetReportsCustomerLevelData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_RESET_REPORTS_CUSTOMER_LEVEL_DATA:
      return {
        ...state,
        reportsCustomerData: null,
        customerSummary: null,
        reportsCustomerTotals: 0,
      };

    default:
      return state;
  }
}
