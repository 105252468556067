// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { ReportsLayoutContent } from '../reports-new';
import {ScCustomer,ScOrder, ScOrderDetail
} from './';
import ScInAction from './ScInAction';

export default {
  path: 'sc-in-action',
  component: ReportsLayoutContent,
  childRoutes: [
    {
      path: '/',
      component: ScInAction,
      isIndex: true,
    },
    {
      path: 'customer',
      component: ScCustomer,
    },
    {
      path: 'order',
      component: ScOrder,
    },
    {
      path: 'order-detail',
      component: ScOrderDetail,
    },
    
  ],
};
