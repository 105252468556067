import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_RESET_REPORTS_ORDER_LEVEL_DATA,
} from './constants';

export function resetReportsOrderLevelData() {
  return {
    type: REPORTS_RESET_REPORTS_ORDER_LEVEL_DATA,
  };
}

export function useResetReportsOrderLevelData() {
  const dispatch = useDispatch();
  const { reportsOrderData } = useSelector(state => ({
    reportsOrderData: state.reports.reportsOrderData,
  }), shallowEqual);
  const boundAction = useCallback((...params) => dispatch(resetReportsOrderLevelData()), [dispatch]);
  return { reportsOrderData, resetReportsOrderLevelData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_RESET_REPORTS_ORDER_LEVEL_DATA:
      return {
        ...state,
        reportsOrderData: null,
        reportsOrderTotals: 0,
        orderSummary: null,
      };

    default:
      return state;
  }
}
