import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_VALIDATION_ITEM_DATA,
} from './constants';

export function resetValidationItemData() {
  return {
    type: MANAGE_SUBS_RESET_VALIDATION_ITEM_DATA,
  };
}

export function useResetValidationItemData() {
  const dispatch = useDispatch();
  const validationItemData = useSelector(state => state.manageSubs.validationItemData);
  const boundAction = useCallback((...params) => dispatch(resetValidationItemData()), [dispatch]);
  return { validationItemData, resetValidationItemData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_VALIDATION_ITEM_DATA:
      return {
        validationItemData: null,
        ...state,
      };

    default:
      return state;
  }
}
