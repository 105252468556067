import React from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from '@fortawesome/free-solid-svg-icons';
export default function ManageOutsWarningModal(props) {
  const { title, footer, isModalVisible, width, onToggleModal, cancelText, okText, content, isReasonCodeHint, record } = props;
  let modalClassName = 'manage-subs-manage-outs-warning-modal';
  if(isReasonCodeHint) {
    modalClassName = 'manage-subs-manage-outs-warning-modal reason-code-modal';
  }
  const listItems = record && record.orderReasonCodes && record.orderReasonCodes.map((item) =>
    <p style={{ fontWeight: 600, marginBottom: 0 }} key={item}>
      {item}
    </p>
  );
  return (
    <>
    {isReasonCodeHint ?
      (
        <Modal
          className={modalClassName}
          title={title}
          visible={isModalVisible}
          onCancel={onToggleModal}
          cancelText={cancelText || 'Close'}
          okText={okText || 'Save'}
          destroyOnClose={true}
          maskClosable={false}
          footer={footer || null}
          width={width || 520}
        >
          <p style={{ marginBottom: '2px' }}>{content}</p>
          {listItems}
          <p style={{ marginBottom: 0, marginTop: '10px' }}>Please confirm to proceed with allocating original item</p>
        </Modal>
      ) :
      (
        <Modal
          className={modalClassName}
          title={title}
          visible={isModalVisible}
          onCancel={onToggleModal}
          cancelText={cancelText || 'Close'}
          okText={okText || 'Save'}
          destroyOnClose={true}
          maskClosable={false}
          footer={footer || null}
          width={width || 520}
        >
          <p className='content-title'>WARNING
            <FontAwesomeIcon icon={faWarning} color="gold" style={{ height: "16px", width: "16px" }} />
          </p>
          <p style={{ fontWeight: 600 }}>{content}</p>
        </Modal>
      )
    }
    </>
  );
};

ManageOutsWarningModal.propTypes = {};
ManageOutsWarningModal.defaultProps = {};
