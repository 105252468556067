import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  ORDER_TRAIL_FETCH_SNAPSHOT_DATA_BEGIN,
  ORDER_TRAIL_FETCH_SNAPSHOT_DATA_SUCCESS,
  ORDER_TRAIL_FETCH_SNAPSHOT_DATA_FAILURE,
  ORDER_TRAIL_FETCH_SNAPSHOT_DATA_DISMISS_ERROR,
  ORDER_TRAIL_ERROR_CONTACT_ADMIN
} from './constants';
import { formatOrderDetailsList } from '../formatOrderDetailsList';

export function fetchSnapshotData(formData = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: ORDER_TRAIL_FETCH_SNAPSHOT_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios({
        method: 'get',
        url: `${API_REQUEST_URL}/getSnapshotData`,
        params: formData,
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        res => {
          const data = res.data.mapData;
          data.eventDetailList = formatOrderDetailsList(data.eventDetailList);
          dispatch({
            type: ORDER_TRAIL_FETCH_SNAPSHOT_DATA_SUCCESS,
            data: data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          const errorMsg = err && err.response ? err.response.data.message :ORDER_TRAIL_ERROR_CONTACT_ADMIN;
          dispatch({
            type: ORDER_TRAIL_FETCH_SNAPSHOT_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchSnapshotDataError() {
  return {
    type: ORDER_TRAIL_FETCH_SNAPSHOT_DATA_DISMISS_ERROR,
  };
}

export function useFetchSnapshotData() {
  const dispatch = useDispatch();

  const { snapshotData, fetchSnapshotDataPending, fetchSnapshotDataError } = useSelector(
    state => ({
      snapshotData: state.orderTrail.snapshotData,
      fetchSnapshotDataPending: state.orderTrail.fetchSnapshotDataPending,
      fetchSnapshotDataError: state.orderTrail.fetchSnapshotDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchSnapshotData(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchSnapshotDataError());
  }, [dispatch]);

  return {
    snapshotData,
    fetchSnapshotData: boundAction,
    fetchSnapshotDataPending,
    fetchSnapshotDataError,
    dismissFetchSnapshotDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ORDER_TRAIL_FETCH_SNAPSHOT_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchSnapshotDataPending: true,
        fetchSnapshotDataError: null,
      };

    case ORDER_TRAIL_FETCH_SNAPSHOT_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        snapshotData: action.data,
        fetchSnapshotDataPending: false,
        fetchSnapshotDataError: null,
      };

    case ORDER_TRAIL_FETCH_SNAPSHOT_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSnapshotDataPending: false,
        fetchSnapshotDataError: action.data.error,
      };

    case ORDER_TRAIL_FETCH_SNAPSHOT_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchSnapshotDataError: null,
      };

    default:
      return state;
  }
}
