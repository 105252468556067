import axios from '../../common/redux/interceptor';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  REPORTS_FETCH_SUMMARY_DATA_BEGIN,
  REPORTS_FETCH_SUMMARY_DATA_SUCCESS,
  REPORTS_FETCH_SUMMARY_DATA_FAILURE,
  REPORTS_FETCH_SUMMARY_DATA_DISMISS_ERROR,
} from './constants';

export function fetchSummaryData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_SUMMARY_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "ascend",
          sortedBy: "site",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getSalesSummaryReport`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_FETCH_SUMMARY_DATA_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              summary: res.data && res.data.summary,
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_FETCH_SUMMARY_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchSummaryDataError() {
  return {
    type: REPORTS_FETCH_SUMMARY_DATA_DISMISS_ERROR,
  };
}

export function useFetchSummaryData() {
  const dispatch = useDispatch();

  const { reportsData, summary,fetchSummaryDataPending, fetchSummaryDataError } = useSelector(
    state => ({
      reportsData: state.reports.reportsData,
      summary:  state.reports.summary,
      fetchSummaryDataPending: state.reports.fetchSummaryDataPending,
      fetchSummaryDataError: state.reports.fetchSummaryDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchSummaryData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchSummaryDataError());
  }, [dispatch]);

  return {
    reportsData,
    summary,
    fetchSummaryData: boundAction,
    fetchSummaryDataPending,
    fetchSummaryDataError,
    dismissFetchSummaryDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_SUMMARY_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchSummaryDataPending: true,
        fetchSummaryDataError: null,
      };

    case REPORTS_FETCH_SUMMARY_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        reportsData: action.data.data,
        reportsTotals: action.data.totals,
        summary: action.data.summary,
        fetchSummaryDataPending: false,
        fetchSummaryDataError: null,
      };

    case REPORTS_FETCH_SUMMARY_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSummaryDataPending: false,
        fetchSummaryDataError: action.data.error,
      };

    case REPORTS_FETCH_SUMMARY_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchSummaryDataError: null,
      };

    default:
      return state;
  }
}
