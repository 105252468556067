import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import AUTH_ERROR_MSG from '../../../common/constants';
import i18n from '../../../i18n/i18n';
axios.interceptors.request.use(
    config =>{
        const url = config.url;
        const lastSlashIndex = url.lastIndexOf('/');
        const acitonPath = url.substring(lastSlashIndex,url.length);
        if(config.method === 'post'){
            datadogRum.addAction(`${acitonPath}`, {
                requestPayload: config.data,
                url: config.url
            });
        }else{
            datadogRum.addAction(`${acitonPath}`, {
                url: config.url,
            });
        }
        config.headers['Accept-Language'] = i18n.language;
        return config;
    },
    error =>{
        datadogRum.addError(error,{
            // pageStatus:'beta'
          });
        // return Promise.reject(error);
    }
)
axios.interceptors.response.use(
    response =>{
        return Promise.resolve(response);
    },
    error =>{
        const errorMsg = error && error.response ? error.response.data.message : 'There is an error. Please refresh page or contact administrator.';
        if(error && error.response && error.response.status === 401){
            datadogRum.addError(AUTH_ERROR_MSG ,{
              });
        }else{
            datadogRum.addError(errorMsg,{
                // pageStatus:'beta'
              });
        }
        return Promise.reject(error);
    }
)
export default axios;