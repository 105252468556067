import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  MANAGE_SUBS_FETCH_CONCEPT_DATA_BEGIN,
  MANAGE_SUBS_FETCH_CONCEPT_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_CONCEPT_DATA_FAILURE,
  MANAGE_SUBS_FETCH_CONCEPT_DATA_DISMISS_ERROR,
} from './constants';
import { validateGroupIdPrefix } from '../formattedSubsData';

export function fetchConceptData(sites, nationalId, isFrom) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_CONCEPT_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let doRequest = null;
      if(validateGroupIdPrefix(nationalId)){
        const args = {
          key: nationalId,
          sites: sites,
          isFrom: isFrom
        }
        doRequest = axios.post(`${API_REQUEST_URL}/similarGroupId`, args,{
          headers: {
            Authorization: token,
          },
        });
      }else{
        doRequest = axios.get(`${API_REQUEST_URL}/getAllConcepts?nationalAccounts=${nationalId}&opcoIds=${sites[0]}&isFrom=${isFrom}`, {
          headers: {
            Authorization: token,
          },
        });
      }
     
      /*let data = {
        nationalAccounts: nationalId,
      }
      if(sites) {
        data = Object.assign(data, {opcoIds: sites});
      }
      const doRequest = axios({
        method: 'post',
        url: `${API_REQUEST_URL}/getNationalAccountsByNationalId`,
        data,
        headers: {
          'Authorization': token,
        }
      });*/
      doRequest.then(
        (res) => {
          let ret = [];
            ret = res.data.data
            if(nationalId) {
              ret = ret.filter(item => item.nationalId.toUpperCase().includes(nationalId.toUpperCase()))
            }
          dispatch({
            type: MANAGE_SUBS_FETCH_CONCEPT_DATA_SUCCESS,
            data: ret,
          });
          resolve(ret);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_CONCEPT_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchConceptDataError() {
  return {
    type: MANAGE_SUBS_FETCH_CONCEPT_DATA_DISMISS_ERROR,
  };
}

export function useFetchConceptData() {
  const dispatch = useDispatch();

  const { conceptData, fetchConceptDataPending, fetchConceptDataError } = useSelector(
    state => ({
      conceptData: state.manageSubs.conceptData,
      fetchConceptDataPending: state.manageSubs.fetchConceptDataPending,
      fetchConceptDataError: state.manageSubs.fetchConceptDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((sites, nationalId, isFrom) => {
    return dispatch(fetchConceptData(sites, nationalId, isFrom));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchConceptDataError());
  }, [dispatch]);

  return {
    conceptData,
    fetchConceptData: boundAction,
    fetchConceptDataPending,
    fetchConceptDataError,
    dismissFetchConceptDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_CONCEPT_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchConceptDataPending: true,
        fetchConceptDataError: null,
      };

    case MANAGE_SUBS_FETCH_CONCEPT_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        conceptData: action.data,
        fetchConceptDataPending: false,
        fetchConceptDataError: null,
      };

    case MANAGE_SUBS_FETCH_CONCEPT_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchConceptDataPending: false,
        fetchConceptDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_CONCEPT_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchConceptDataError: null,
      };

    default:
      return state;
  }
}
