import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Modal, Table, Button, Pagination, Spin, Empty } from 'antd';
import { ExportAlertModal } from '../common';
import { SLASH_DATE_FORMAT, DASH_DATE_FORMAT, CONST_PAGINATION } from '../../common/constants';
import {
  useFetchOrderDetailReport,
  useExportOrderDetailReport,
  useResetReportsOrderDetailLevelData,
} from '../reports/redux/hooks';
import { downloadFile } from '../../common/downloadReportFunc';
import { datadogRum } from '@datadog/browser-rum';
import { ExportButton } from '../reports-new';
import { useTranslation } from 'react-i18next';


export default function OrderDetailReportModal(props) {
  const { t } = useTranslation();
  const {
    reportsOrderDetailData,
    reportsOrderDetailTotals,
    detailsCondition,
    reportsKeyValue,
    fetchOrderDetailReportPending,
    exportOrderDetailReportPending,
  } = useSelector(state => ({
    reportsOrderDetailData: state.reports.reportsOrderDetailData,
    reportsOrderDetailTotals: state.reports.reportsOrderDetailTotals,
    detailsCondition: state.dashboard.detailsCondition,
    reportsKeyValue: state.reportsNew.reportsKeyValue,
    fetchOrderDetailReportPending: state.reports.fetchOrderDetailReportPending,
    exportOrderDetailReportPending: state.reports.exportOrderDetailReportPending,
  }));
  const { exportOrderDetailReport } = useExportOrderDetailReport();
  const { resetReportsOrderDetailLevelData } = useResetReportsOrderDetailLevelData();
  const { fetchOrderDetailReport } = useFetchOrderDetailReport();
  const { title, typeLabel, isOrderDetailModalVisible, setOrderDetailModalVisible } = props;
  const [pagination, setPagination] = useState(CONST_PAGINATION);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  let columns = [{
    title: t("itemNumber"),
    dataIndex: 'itemNumber',
    width: 150,
    blankWidth: 150,
    sorter: true,
    render: false,
  },{
    title: t("itemDescription"),
    dataIndex: 'itemDescription',
    width: 190,
    blankWidth: 190,
    sorter: true,
    render: false,
  },{
    title: t("order_quantity"),
    dataIndex: 'quantity',
    width: 100,
    blankWidth: 100,
    sorter: true,
    render: false,
  },{
    title: t("order_quantityAllocAgainstOnHand"),
    dataIndex: 'quantityAllocAgainstOnHand',
    width: 110,
    blankWidth: 110,
    sorter: true,
    render: false,
  },{
    title: t("splitCode"),
    dataIndex: 'splitCode',
    width: 90,
    blankWidth: 90,
    sorter: true,
    render: false,
  },{
    title: t("originalItemUnitPrice"),
    dataIndex: 'originalItemUnitPrice',
    width: 150,
    blankWidth: 150,
    sorter: true,
    render: value =>
        value === null || value === 0
          ? '$0.00'
          : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
  },{
    title: t("order_orderDetailExtendedPrice"),
    dataIndex: 'orderDetailExtendedPrice',
    width: 150,
    blankWidth: 150,
    sorter: true,
    render: value =>
        value === null || value === 0
          ? '$0.00'
          : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
  }];
  let orderReportData = reportsOrderDetailData;
  let totals = reportsOrderDetailTotals;
  let condition = {
    // ...detailsCondition,
    site: reportsKeyValue.site,
    orderNumber: reportsKeyValue.orderNumber,
  };
  useEffect(() => {
    if (isOrderDetailModalVisible) {
      if (pagination.total === totals) {
        handleOrderReportList(pagination, sorting);
      } else {
        setPagination({
          ...pagination,
          total: totals,
        });
      }
    } else {
      setPagination(CONST_PAGINATION);
      setSorting();
      setShowAlert(false);
      resetReportsOrderDetailLevelData();
    }
  }, [isOrderDetailModalVisible, pagination, sorting]); // eslint-disable-line
  const handleOrderReportList = (pagination, sorting) => {
    const formdata = {
      ...condition,
      ...typeLabel,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      ...sorting,
    };
    const timing = Date.now();
    fetchOrderDetailReport(formdata);
    datadogRum.onReady(function() {
      datadogRum.addTiming(`${title}`, timing);
    });
  };
  const exportHealthCheckReports = () => {
    if (totals > 5001) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      const sheetColConfig = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 13 },
        { wch: 16 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ];
      const formdata = {
        ...condition,
        // ...typeLabel,
      };
      const timing = Date.now();
      exportOrderDetailReport(formdata).then(data => {
        const sheetObj = [
          {
            headers: columns,
            data,
            sheetName: null,
            sheetColConfig,
          },
        ];
        downloadFile(`${title} Report`, sheetObj);
        datadogRum.addAction(`Export ${title} report`, {
          headers: columns,
          data,
          sheetName: null,
          sheetColConfig,
        });
      });
      datadogRum.onReady(function() {
        datadogRum.addTiming(`Export ${title} report`, timing);
      });
    }
  };
  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };

  const onPaginationChange = (page, changedPageSize) => {
    setShowAlert(false);
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
  };
  const toggleReportModal = () => {
    setOrderDetailModalVisible(prevState => {
      return !prevState;
    });
  };
  const renderMobileValue = (value, dataIndex, record, colItem) => {
  if (dataIndex === 'originalItemUnitPrice' || dataIndex === 'orderDetailExtendedPrice') {
      if (value === 0) return '$0.00';
      return value && `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`;
    } else {
      return value && `${value}`;
    }
  };
  return (
    <Modal
      className="dashboard-order-detail-report-modal"
      title={
        <>
          {`${title}`}
          <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
        </>
      }
      visible={isOrderDetailModalVisible}
      onCancel={toggleReportModal}
      width={1030}
      destroyOnClose={true}
      footer={null}
      // afterClose={resetOrderReport}
      maskClosable={false}
    >
      <div className="report-export-button-modal flex justify-end">
        <ExportButton dataSource={orderReportData} exportReports={exportHealthCheckReports} />
      </div>
      <Table
        rowKey={record =>
          `${record.site}-${record.customerNumber}-${record.orderNumber}-${record.itemNumber}-${record.itemQuantity}`
        }
        className="dashboard-order-report-table is-show-pc"
        loading={fetchOrderDetailReportPending || exportOrderDetailReportPending}
        columns={columns}
        dataSource={orderReportData}
        pagination={false}
        onChange={onTableChange}
        scroll={{ x: 'max-content', y: 250 }}
      />
      <div className="is-show-mobile">
        <div className="reports-mobile-list">
          {/* <input type="checkbox" className="sia-mobile-checkbox_all"></input> */}
          <Spin spinning={fetchOrderDetailReportPending || exportOrderDetailReportPending }>
            <div className={!orderReportData ? "reports-no-data":"none"}>
              <Empty />
            </div>
          {orderReportData &&
            orderReportData.length > 0 &&
            orderReportData.map((record, index) => {
              return (
                <div className="sia-mobile-box">
                  {/* <input type="checkbox" className="sia-mobile-checkbox"></input> */}
                  <div className="sia-mobile-editandDelete">
                    {/* <Button title="Edit" style={{ width: '24px' }} disabled type="link" icon={<EditCalendarIcon/>}
                  onClick={() => { handleEdit(record) }}>
                  </Button>
                  <Button title="Delete" style={{ paddingLeft: '5px', width: '42px' }} disabled type="link" icon={<CancelIcon/>} onClick={() => { handleDelete(record) }}>
                   
                  </Button> */}
                  </div>
                  <ul key={`${title}-${index}`}>
                    {columns.map((colItem, colIndex) => {
                      const titleOrder = colItem.title;
                      const dataIndex = colItem.dataIndex;
                      const value = record[dataIndex];
                      return (
                        <li key={`${titleOrder}-${dataIndex}-${colIndex}`}>
                          <span className="sub01">{titleOrder}</span>
                          <span className="sub02">
                            {renderMobileValue(value, dataIndex, record, colItem)}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
            </Spin>
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination
          style={{ textAlign: 'left', margin: '16px 0px' }}
          className={'report-pagination'}
          showSizeChanger
          current={pagination.pageIndex}
          pageSize={pagination.pageSize}
          total={totals}
          pageSizeOptions={pagination.pageSizeOptions}
          onShowSizeChange={onPaginationChange}
          onChange={onPaginationChange}
        />
      </div>
    </Modal>
  );
}

OrderDetailReportModal.propTypes = {};
OrderDetailReportModal.defaultProps = {};
