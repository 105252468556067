import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'
import history from './history';
import homeReducer from '../features/home/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import dashboardReducer from '../features/dashboard/redux/reducer';
import orderTrailReducer from '../features/order-trail/redux/reducer';
import reportsReducer from '../features/reports/redux/reducer';
import manageSubsReducer from '../features/manage-subs/redux/reducer';
import reportsNewReducer from '../features/reports-new/redux/reducer';
import ordersReducer from '../features/orders/redux/reducer';
import futureOrdersReducer from '../features/future-orders/redux/reducer';
import scInActionReducer from '../features/sc-in-action/redux/reducer';
import dashboardNewReducer from '../features/dashboard-new/redux/reducer';
import outOfStockReducer from '../features/out-of-stock/redux/reducer';
import oosAccountReducer from '../features/oos-account/redux/reducer';

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  dashboard: dashboardReducer,
  orderTrail: orderTrailReducer,
  reports: reportsReducer,
  manageSubs: manageSubsReducer,
  reportsNew: reportsNewReducer,
  orders: ordersReducer,
  futureOrders: futureOrdersReducer,
  scInAction: scInActionReducer,
  dashboardNew: dashboardNewReducer,
  outOfStock: outOfStockReducer,
  oosAccount: oosAccountReducer,
};

export default combineReducers(reducerMap);
