import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row, Button, Badge, Upload,notification, message, Modal, Spin, Input, Space, } from 'antd';
import { CONST_SUBSTITUTIONS_TABLE_PARENT_COLUMNS, CONST_SUBSTITUTIONS_TABLE_EXPANDED_COLUMNS, 
  CONST_SUB_RULE_TYPE, CONST_NONE_SUB_RULE_TYPE,CONST_NEVER_SUB_RULE_TYPE, CONST_DROP_OUT_RULE_TYPE } from '../../common/manageSubsConstants';
import { renderTableColumns } from '../../common/renderColumns';
import { SearchFiltersPanel, ManageSubstitutionsOperateModal } from './';
import { CommonTable } from '../common';
import { NonSubIcon, SubIcon , NeverSubIcon} from '../common/CustomerIcons';
import { CONST_PAGINATION_TEN, DATE_TIME_ONE_FORMAT } from '../../common/constants';
import { CONST_SITE_LIST } from '../../common/orderTrailConstants';
import BULK_TEMPLATE_FILE from '../../assets/ManageSub-BulkTemplate.xlsx';
import { useFetchSubstitutionsData, useResetSubstitutionsData, useUpdateSubstitutionsData, useDownloadSubstitutionsFile, 
  useUploadSubstitutions, useDeleteManageSubs, useCheckEditSubsLock, useUpdateEidtSubsLock, useSaveSearchConditionData, useGetSubstitutionsData, } from './redux/hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport, faPen } from '@fortawesome/free-solid-svg-icons';
import ManageOutsWarningModal from './ManageOutsWarningModal';
import { SearchOutlined,PlayCircleFilled } from '@ant-design/icons';
import { uniqueTerritoryList } from './getOriginalSelectedList';
import { formatTerrirotyData } from './formattedSearchCondition';
import { CORP_USER_LIST } from '../../common/corpProfile';
import { datadogRum } from '@datadog/browser-rum';

export default function ManageSubstitutions() {
  const {
    substitutionsData,
    fetchSubstitutionsDataPending,
    updateSubstitutionsDataPending,
    searchConditionData,
    downloadSubstitutionsFilePending,
    uploadSubstitutionsPending,
    deleteManageSubsPending,
    territoryData,
    originalList,
    userId,
    checkEditSubsLockPending,
    updateEidtSubsLockPending,
    getSubstitutionsDataPending,
  } = useSelector(state => ({
    substitutionsData: state.manageSubs.substitutionsData,
    fetchSubstitutionsDataPending: state.manageSubs.fetchSubstitutionsDataPending,
    updateSubstitutionsDataPending: state.manageSubs.updateSubstitutionsDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    downloadSubstitutionsFilePending: state.manageSubs.downloadSubstitutionsFilePending,
    uploadSubstitutionsPending: state.manageSubs.uploadSubstitutionsPending,
    deleteManageSubsPending: state.manageSubs.deleteManageSubsPending,
    territoryData: state.dashboard.territoryData,
    originalList: state.manageSubs.originalList,
    userId: state.home.auth.user.userId,
    checkEditSubsLockPending: state.manageSubs.checkEditSubsLockPending,
    updateEidtSubsLockPending: state.manageSubs.updateEidtSubsLockPending,
    getSubstitutionsDataPending: state.manageSubs.getSubstitutionsDataPending,
  }));
  const { fetchSubstitutionsData } = useFetchSubstitutionsData();
  const { getSubstitutionsData } = useGetSubstitutionsData();
  const { resetSubstitutionsData } = useResetSubstitutionsData();
  const { updateSubstitutionsData } = useUpdateSubstitutionsData();
  const { downloadSubstitutionsFile } = useDownloadSubstitutionsFile();
  const { uploadSubstitutions } = useUploadSubstitutions();
  const { deleteManageSubs } = useDeleteManageSubs()
  const { saveSearchConditionData } = useSaveSearchConditionData();
  const { checkEditSubsLock } = useCheckEditSubsLock();
  const { updateEidtSubsLock } =useUpdateEidtSubsLock();
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [resetActivePagination, setResetActivePagination] = useState(false);

  const [isLockedWarningModalVisible, setLockedWarningModalVisible] = useState(false);
  const handleEditSubCheckLock = (record)=>{
    const formData = {
      site: record.site,
      originalItem: record.originalItem,
      nationalId: record.nationalId,
      // orders:record.orderNumbers,
      customerNumber:record.customerNumber
    }
    checkEditSubsLock(formData).then((response)=>{
      if(response && response.lockStatus === 'Y'){
        onToggleModal(setLockedWarningModalVisible);
      }else{
        handleUpdateEditSubsLock(record);
      }
    },(err)=>{
      message.destroy();
      message.error(err);
    })
  }
  const handleUpdateEditSubsLock =(record)=>{
    const timing = Date.now();
    const formData = {
      site: record.site,
      originalItem: record.originalItem,
      lockStatus:!isEditModalVisible?'Y':'N',
      customerNumber: record.customerNumber,
      nationalId: record.nationalId,
      // orders:record.orderNumbers
    }
    updateEidtSubsLock(formData).then((response)=>{
      if(formData.lockStatus === 'Y') {
        if(searchConditionData.ruleType === 'sub' || searchConditionData.ruleType === 'nosub' || searchConditionData.ruleType === 'dropout') {
          getSubstitutionsData({
            ...searchConditionData,
            sites: [record.site],
            customerNumber: record.customerNumber ? record.customerNumber : '',
            nationalIds: record.nationalId ? [record.nationalId] : [],
            originalItem: record.originalItem,
            ruleType: 'all',
          }).then(res => {
            if(res.data && Array.isArray(res.data) && res.data.length > 0) {
              if(res.data.length === 1) {
                setEditableData(res.data[0]);
                onToggleModal(setEditModalVisible);
              }else if(res.data.length === 2) {
                let temp = res.data.filter(item => item.originalUom === record.originalUom)
                if(temp.length === 1) {
                  setEditableData(temp[0]);
                  onToggleModal(setEditModalVisible);
                }else {
                  setEditableData(record);
                  onToggleModal(setEditModalVisible);
                }
              }else {
                setEditableData(record);
                onToggleModal(setEditModalVisible);
              }
            }else {
              setEditableData(record);
              onToggleModal(setEditModalVisible);
            }
          })
        }else {
          setEditableData(record);
          onToggleModal(setEditModalVisible);
        }
      }
    },(err)=>{
      message.error(`Update Outs Record Lock Failed, please try again.`);
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Update Subs Record Lock`,timing);
    });
  }
  const onUploadSubs = () => {
    const timing = Date.now();
    if (_.isEmpty(uploadFileList)) {
      message.destroy();
      message.info('Please select a file.');
      return;
    }
    let formData = new FormData();
    uploadFileList.forEach(file => {
      formData.append("file", file);
    });
    uploadSubstitutions(formData).then((response) => {
      setUploadFileList([]);
      message.destroy();
      message.info(JSON.stringify(response.data));
    },
    (err) => {
      let errMsg = err && err.response ? err.response.data.error : 'There is an error. Please refresh page or contact administrator.'
      message.destroy();
      message.error('Uploaded failed: ' + errMsg);
    });
    datadogRum.onReady(function () {
      datadogRum.addTiming(`Upload subs`, timing);
    });
  }
  const onExportSubs = () => {
    const timing = Date.now();
    const params = {...searchConditionData}
    if(substitutionsData.totals > 100000) {
      message.error(`The maximum number of rules to be exported exceeds the 100,000 record limit. Please include additional filter criteria to refine the number of records`);
      return;
    }
    if(searchText) {
      params.originalItem = searchText
    }
    downloadSubstitutionsFile(params);
    datadogRum.onReady(function () {
      datadogRum.addTiming(`Download subs`, timing);
    });
  }
  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  }
  const onUpdateSubs = (args, onCloseModal,wasDropout,expandedLength) => {
    const timing = Date.now();
    updateSubstitutionsData(args).then(data => {
      if(wasDropout && args.expandedItems != null) {
        if(expandedLength === null && args.expandedItems.length === 0) {
          message.success(`Deleted Drop out rule Successfully.`);
        }else if(expandedLength === null && args.expandedItems.length > 0 && args.dropOutRuleEndDate === undefined) {
          message.success(`Deleted Drop out rule Successfully.`);
          message.success(`Updated Substitutions Successfully.`);
        }else if(expandedLength !== null && args.expandedItems.length >= 0 && args.dropOutRuleEndDate === undefined) {
          message.success(`Deleted Drop out rule Successfully.`);
          message.success(`Updated Substitutions Successfully.`);
        }else{
          message.success(`Updated Substitutions Successfully.`);
        }
      }else{
        message.success(`Updated Substitutions Successfully.`);
      }
      onCloseModal();
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      if(wasDropout && args.expandedItems != null) {
        if(expandedLength === null && args.expandedItems.length === 0) {
          message.error(`Delete Drop out Sub rule Failed, please try again.`);
        }else{
          if(err.isInvalidShipTo) {
            message.error(err.message)
          }else {
            message.error(`Update Substitutions Failed, please try again.`);
          }
        }
      }else{
        if(err.isInvalidShipTo) {
          message.error(err.message)
        }else {
          message.error(`Update Substitutions Failed, please try again.`);
        }
      }
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Update Subs`,timing);
    });
  }
  const onDeleteNeverSubs = (args, onCloseModal) => {
    const timing = Date.now();
    let isDropout=false;
    if(editableData!==null && editableData.ruleType==='dropout'){
      isDropout=true;
    }
    deleteManageSubs(args)
    .then(data => {
      if(isDropout){
      message.success(`Deleted Drop out rule Successfully.`);
      }
      else{
        message.success(`Deleted Never Sub rule Successfully.`);
        }

      onCloseModal();
      setResetActivePagination(!resetActivePagination);
    })
    .catch(err => {
      if(isDropout){
      message.error(`Delete Drop out Sub rule Failed, please try again.`);
      }
      else{
        message.error(`Delete Never Sub rule Failed, please try again.`);
        }

      
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Delete Never Subs`,timing);
    });
  }
  const rowExpandable = (record) => {
    return record.expandedItems && record.expandedItems.length > 1;
  }
  const renderParentElement = [
    { //render siteID
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.siteDesc}</div>
          </>
        )
      }
    },
    { //render conceptID
      extendRender: (value, label, record) => {
        if(value && value.length > 0) {
          return (
            <>
              <div className="col-title">{value}</div>
              <div className="col-desc">{record.nationalName}</div>
            </>
          )
        }
        return (
          <>
            <div className="col-title">{record.customerNumber}</div>
            <div className="col-desc">{record.customerName}</div>
          </>
        )
      }
    },
    { //render originalItem
      extendRender: (value, label, record) => {
        let originalItemDetails = ``;
        if(value) {
          // originalItemDetails = `${record.originalItemDescription} <br/> ${record.originalUom} | ${record.originalPack}/${record.originalSize} | ${record.originalBrand} | ${record.originalStorageCode}`;
          originalItemDetails = `${record.originalUom} | ${record.originalPack}/${record.originalSize} | ${record.originalBrand} | ${record.originalStorageCode}`;
        }
        let category = record.itemCategory
        let categoryIcon = ''
        if(category) {
          category = category.trim()
          categoryIcon = category === 'C' ? <Badge className="sub-badge-item" color="#7AC943"/>
            : category === 'D' ? <Badge className="sub-badge-item" color="#FF931E"/>
            : category === 'B' ? <Badge className="sub-badge-item" color="#FFFF00"/>
            : category === 'A' ? <Badge className="sub-badge-item" color="#FF1022"/>
            : <span className="blank-badge" />
        } else {
          categoryIcon = <span className="blank-badge" />
        }
        return (
          <>
            <div className="col-title">
              {categoryIcon}
              {value}
            </div>
            <div className="col-desc handle-overflow detail-info">{record.originalItemDescription}</div>
            <div className="col-desc handle-overflow detail-info">{originalItemDetails}</div>
          </>
        )
      }
    },
    { //render subItem
      extendRender: (value, label, record) => {
        let subItemDetails = ``;
        let description=''

        if(value && value !== 'Multiple') {
          // subItemDetails = `${record.substituteItemDescription} | ${record.substituteUom} | ${record.substitutePack}/${record.substituteSize} | ${record.substituteBrand} | ${record.substituteStorageCode}`;
          subItemDetails = `${record.substituteUom} | ${record.substitutePack}/${record.substituteSize} | ${record.substituteBrand} | ${record.substituteStorageCode}`;
        }
        if(record.ruleType===CONST_NEVER_SUB_RULE_TYPE){
         description= <div className="col-desc handle-overflow">NEVER SUB</div>;
        }
        else{
          description=<div className="col-desc handle-overflow">{record.substituteItemDescription}</div>;
        }
        // if((value==='' && ( record.ruleType===CONST_SUB_RULE_TYPE || record.ruleType===CONST_NONE_SUB_RULE_TYPE) )|| record.ruleType==='dropout'){
        //COT-5289,dropout rule will returned as dropout rule type
        if(record.ruleType === CONST_DROP_OUT_RULE_TYPE){ 
          description= <div className="col-desc handle-overflow">DROPOUT</div>;
        }
        return (
          <>
            <Row>
              <Col span={3} className='flex align-items-center' style={{justifyContent:'center'}}>
                {record.ruleType === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : record.ruleType === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> : record.ruleType === CONST_NEVER_SUB_RULE_TYPE?<NeverSubIcon style={{ fontSize: '16px', color: '#db6f6f' }} />: record.ruleType === CONST_DROP_OUT_RULE_TYPE ?<PlayCircleFilled  rotate={90} style={{ fontSize: '16px', color: '#db6f6f'  }}/>:null}
              </Col>
              <Col span={21}>
                <div className="col-title">{value}</div>
                {description}
                <div className="col-desc  handle-overflow">{subItemDetails}</div>
              </Col>
            </Row>
          </>
        )
      }
    },
    {//render sub ratio
      extendRender: (value, label, record) => {
        if (record.ruleType === CONST_DROP_OUT_RULE_TYPE || record.ruleType === CONST_NONE_SUB_RULE_TYPE) {
          return (
            <>
              <div className="col-title">{record.reasonCode}</div>
              <div className="col-desc">{record.reasonDescription}</div>
            </>
          )
        } else {
          return value;
        }
      }                   
    },
    {},//endDate placeholder
    { //render modifiedBy
      extendRender: (value, label, record) => {
       
         const modifiedDate = record.modifiedTimestamp && moment(record.modifiedTimestamp).format(DATE_TIME_ONE_FORMAT);
        //const lastModifiedDate = value && moment(value).format(DATE_TIME_ONE_FORMAT);

        return value && (
          <>
            <div className="col-title">{record.modifiedByName}</div>
            <div className="col-desc">{`${record.modifiedById} ${modifiedDate}`}</div>
          </>
        )
      }
    },
  ];
  const renderExpandedElement = [
    {}, //priority placeholder
    { //render subItem
      extendRender: (value, label, record) => {
        let subItemDetails = ``;
        if(value) {
          subItemDetails = `${record.substituteUom} | ${record.substitutePack}/${record.substituteSize} | ${record.substituteBrand} | ${record.substituteStorageCode}`;
        }
        return (
          <>
            <Row>
              <Col span={3} className='flex align-items-center' >
                {record.ruleType === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : record.ruleType === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> :record.ruleType === CONST_NEVER_SUB_RULE_TYPE?<NeverSubIcon style={{ fontSize: '16px', color: '#db6f6f' }} />: record.ruleType === CONST_DROP_OUT_RULE_TYPE ?<PlayCircleFilled  rotate={90} style={{ fontSize: '16px', color: '#db6f6f'  }}/>:null}
                {/* {record.ruleType === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : record.ruleType === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> : null} */}
              </Col>
              <Col span={21}>
                <div className="col-title">{value}</div>
                {record.ruleType === CONST_DROP_OUT_RULE_TYPE ? <div className="col-desc handle-overflow">DROPOUT</div> : <div className="col-desc">{record.substituteItemDescription}</div>}
                <div className="col-desc">{subItemDetails}</div>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      extendRender: (value, label, record) => {
        if (record.ruleType === CONST_DROP_OUT_RULE_TYPE || record.ruleType === CONST_NONE_SUB_RULE_TYPE) {
          return (
            <>
              <div className="col-title">{record.reasonCode}</div>
              <div className="col-desc">{record.reasonDescription}</div>
            </>
          )
        } else {
          return value;
        }
      }
    }, //reasonCode placeholder
    {}, //subRatio placeholder
    {}, //endDate placeholder
    // { //render createBy
    //   extendRender: (value, label, record) => {
    //     const createdDate = record.createTimestamp && moment(record.createTimestamp).format(DATE_TIME_ONE_FORMAT);
    //     return value && (
    //       <>
    //         <div className="col-title">{value}</div>
    //         <div className="col-desc">{`${record.createdById} ${createdDate}`}</div>
    //       </>
    //     )
    //   }
    { //rerender lastModified
      extendRender: (value, label, record) => {
        const lastModifiedDate = value && moment(value).format(DATE_TIME_ONE_FORMAT);
        return value && (
          <>
            <div className="col-title">{record.modifiedByName}</div>
            <div className="col-desc">{`${record.modifiedById} ${lastModifiedDate}`}</div>
          </>
        )
      }
    }
  ]
  const inputRef = useRef();
  let searchForm = null;
  let clearFiltersRef = null;
  let confirmRef = null;
  const handleTerritoryParam = (values) => {
    let isCorpProfileUser = false
    if(originalList.length === 0) {
      const user = CORP_USER_LIST.filter(item => item.userId === userId);
      if (!_.isEmpty(user)) {
        isCorpProfileUser = true
      }
    }
    if((territoryData && ((territoryData.role && territoryData.role.toLowerCase() === 'corp') || _.isEmpty(territoryData.role))) || isCorpProfileUser) {
      let temp = values.territoryInfoList ? values.territoryInfoList.trim() : ''
      if(temp) {
        return [{"terrName": temp}]
      }else {
        return []
      }
    }else {
      return values.territoryInfoList !== undefined && values.territoryInfoList !== null ? uniqueTerritoryList(formatTerrirotyData(values, territoryData)) : null
    }
  }
  const handleOriginalItemSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        ruleType: formVals.ruleType !== undefined ? formVals.ruleType : 'all',
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const handleOriginalItemReset = (clearFilters, confirm, dataIndex) => {
    setSearchText('');
    clearFilters();
    confirm({
      closeDropdown: true,
    });
    setSearchedColumn(dataIndex);
    const formVals = searchForm.getFieldsValue()
    if(formVals && formVals.sites && formVals.sites.length > 0) {
      saveSearchConditionData({
        ...formVals,
        ruleType: formVals.ruleType !== undefined ? formVals.ruleType : 'all',
        territoryInfoList: handleTerritoryParam(formVals),
      });
    } else {
      message.error('Please select Site!')
    }
  };
  const originalItemSearchReset = () => {
    setSearchText('');
    if(clearFiltersRef) {
      clearFiltersRef()
    }
    if(confirmRef) {
      confirmRef({
        closeDropdown: true,
      });
    }
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      clearFiltersRef = clearFilters;
      confirmRef = confirm;
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={inputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleOriginalItemSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleOriginalItemReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: filtered => <SearchOutlined style={{ fontSize: '16px', color: filtered ? 'blue' : undefined }} />,
    // onFilter: (value, record) =>{
    //   return record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : ''
    // },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select(), 100);
      }
    },
  });
  const searchFormCb = (form) => {
    searchForm = form;
  };
  CONST_SUBSTITUTIONS_TABLE_PARENT_COLUMNS[2] = {...CONST_SUBSTITUTIONS_TABLE_PARENT_COLUMNS[2], ...getColumnSearchProps('originalItem')};
  let parentColumns = renderTableColumns(CONST_SUBSTITUTIONS_TABLE_PARENT_COLUMNS, null, renderParentElement);
  let expandedColumns = renderTableColumns(CONST_SUBSTITUTIONS_TABLE_EXPANDED_COLUMNS, null, renderExpandedElement);

  parentColumns.push({
    title: 'Actions',
    dataIndex: 'ruleType',
    width: 76,
    render: (value, record) => {
      return (
        <div className="parent-actions">
          <Button disable="false" type="link" onClick={() => handleEditSubCheckLock(record)}>
            <FontAwesomeIcon icon={faPen} color="#6b6b6c" style={{ height: "15px", width: "15px" }}/> 
          </Button>
          {/* {value === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : value === CONST_NONE_SUB_RULE_TYPE ? <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} /> : null} */}
      </div>
      );
    },
    // fixed:"right"
  });
  // expandedColumns.push({
  //   title: '',
  //   width: 65,
  //   dataIndex: 'ruleType',
  //   render: (value, record) => {
  //     return (
  //       <div className="expanded-actions">
  //         {value === CONST_SUB_RULE_TYPE ? <SubIcon style={{ fontSize: '16px', color: '#7ac943' }} /> : <NonSubIcon style={{ fontSize: '19px', color: '#db6f6f' }} />}
  //       </div>
  //     );
  //   }
  // });

  const uploadProps = {
    multiple: false,
    onRemove: file => {
      setUploadFileList(prevState => {
        const index = prevState.indexOf(file);
        const fileList = prevState.slice();
        fileList.splice(index, 1);
        return fileList;
      });
    },
    beforeUpload: file => {
      const extensionName = file.name.split('.').pop().toLowerCase();
      if (extensionName !== 'xlsx') {
        message.error(`Please upload the latest xlsx template file.`);
        return Upload.LIST_IGNORE;
      }
      setUploadFileList(prevState => {
        return [file];
      });
      return false;
    },
    fileList: uploadFileList,
  };
  const renderLockedWarningModal = (
    <ManageOutsWarningModal
      title=""
      isModalVisible={isLockedWarningModalVisible}
      footer={false}
      width={500}
      onToggleModal={() => {onToggleModal(setLockedWarningModalVisible);}}
      content="Maintenance is disabled for this record as this is being worked on by another user session."
    />
  );
  const renderUploadDownloadModal = (
    <Modal
      className="manage-subs-manage-substitutions-operate-modal"
      title={"Upload File"}
      visible={isUploadModalVisible}
      onCancel={() => {onToggleModal(setUploadModalVisible);setUploadFileList([])}}
      okText="Done"
      destroyOnClose={true}
      maskClosable={false}
      onOk={onUploadSubs}
    >
      <Spin spinning={downloadSubstitutionsFilePending || uploadSubstitutionsPending} tip="Uploding...">
        {/* <div>
          <Button className="subs-upload-btn flex align-items-center" icon={<DownloadIcon style={{ fontSize: '36px' }} />} >
            <a href={BULK_TEMPLATE_FILE} download='ManageSub-BulkTemplate.xlsx'>Download the template</a> */}
      <div className='export-import-btn'>
        <Button className="subs-upload-btn flex" >
        <FontAwesomeIcon icon={faFileImport} color="#6b6b6c" style={{ height: "36px", width: "36px" }}/> 
            &ensp;&ensp;&ensp;
          <a href={BULK_TEMPLATE_FILE} download='Template.xlsx'>Download the template</a>
        </Button>
      </div>
      <div className='export-import-btn'>
        <Upload {...uploadProps}>
          <Button className="subs-upload-btn flex" onClick={onUploadSubs}>
            <FontAwesomeIcon icon={faFileExport} color="#6b6b6c" style={{ height: "36px", width: "36px" }}/> 
            &ensp;&ensp;&ensp;
            Import file
          </Button>
          </Upload>
        </div>
        {/* <div>
          <Upload {...uploadProps}>
            <Button className="subs-upload-btn flex align-items-center" icon={<UploadIcon style={{ fontSize: '36px' }} />} >
            Upload the file
            </Button>
         
        </div> */}
      </Spin>
    </Modal>
  );

  const renderAddModal = (
    <ManageSubstitutionsOperateModal
      title="Add sub rule"
      isModalVisible={isAddModalVisible}
      footer={false}
      width={1390}
      siteData={CONST_SITE_LIST}
      onToggleModal={() => onToggleModal(setAddModalVisible)}
      cancelText="Reset"
      okText="Save"
      onOk={onUpdateSubs}
      onDelete={onDeleteNeverSubs}
      loading={updateSubstitutionsDataPending || deleteManageSubsPending}
    />
  );

  const renderEditModal = (
    <ManageSubstitutionsOperateModal
      title="Edit sub rule"
      isModalVisible={isEditModalVisible}
      footer={false}
      width={1390}
      onToggleModal={() => {onToggleModal(setEditModalVisible); setEditableData(null);handleUpdateEditSubsLock(editableData)}}
      cancelText="Reset"
      okText="Save"
      onOk={onUpdateSubs}
      onDelete={onDeleteNeverSubs}
      initValueData={editableData}
      loading={updateSubstitutionsDataPending || deleteManageSubsPending}
      idle={()=>{notification.warning({
        message: `Idle Time Surpassed 5 minutes`,
        placement:'top',
        duration:0,
      })}}
      
    />
  );

  return (
    <div className="manage-subs-manage-substitutions">
    <Spin spinning={downloadSubstitutionsFilePending}>
      <div className="chart-title font-size-16-bold" style={{font: "Myriad Bold"}}>Search Sub Rules</div>
      <SearchFiltersPanel searchForm={searchFormCb} originalItemSearchReset={originalItemSearchReset} />
      <div className="flex align-item-center justify-end" style={{marginBottom:"20px", marginTop:"15px"}}>
        <Button className="add-btn" onClick={() => onToggleModal(setAddModalVisible)} >
          Add Sub Rules
        </Button>
        <span style={{fontSize:"25px", marginTop:"-7px", fontWeight:"lighter", transform:"scale(.5, 1)", color:"#c2c2c3"}}>&ensp;|</span>
        <Button type="link" title="Import" onClick={() => onToggleModal(setUploadModalVisible)}>
          <FontAwesomeIcon icon={faFileImport} color="#6b6b6c" style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"2px", color:"#6b6b6c"}}>Import</p>
        </Button>
        <Button type="link" title="Export" disabled={substitutionsData === null} onClick={onExportSubs}>
          <FontAwesomeIcon icon={faFileExport} color={substitutionsData?"#6b6b6c":"#a1a1a2"} style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"-3px", color:(substitutionsData?"#6b6b6c":"#a1a1a2")}}>Export</p>
        </Button> 
      </div> 
      <CommonTable 
        rowKey={record => `${record.site}-${record.nationalId}-${record.customerNumber}-${record.originalItem}-${record.originalUom}`}
        columns={parentColumns}
        tableClassName={substitutionsData ? "subs-report-table" : ""}
        datasource={substitutionsData}
        loadingData={fetchSubstitutionsDataPending || updateSubstitutionsDataPending || checkEditSubsLockPending || updateEidtSubsLockPending || getSubstitutionsDataPending}
        expandedColumns={expandedColumns}
        expandedItemDataKey="expandedItems"
        rowExpandable={rowExpandable}
        paginationStyle={{textAlign: "right", margin: '16px 0px'}}
        triggerConditions={searchConditionData}
        searchText={searchText}
        onFecthData={fetchSubstitutionsData}
        onResetStore={resetSubstitutionsData}
        scrollStyle={{ x: 'max-content', y: 300 }}
        paginationSetting={CONST_PAGINATION_TEN}
        resetActivePagination={resetActivePagination}
      />
      {isUploadModalVisible && renderUploadDownloadModal}
      {isAddModalVisible && renderAddModal}
      {isEditModalVisible && renderEditModal}
      {isLockedWarningModalVisible && renderLockedWarningModal}
      </Spin>
    </div>
  );
};
