import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_BEGIN,
  MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_FAILURE,
  MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_DISMISS_ERROR,
} from './constants';

export function fetchGroupsAuditTrailData(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "descend",
          sortedBy: "timestamp",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getCreateGroupsEvent`, conditions, {
          headers: {
              'Authorization': token,
          }
      });
      doRequest.then(
        (res) => {
          res.data.data.forEach((rowList, index) => {
            let modifiedDate = '';
            let modifiedTimestamp = '';
              if(rowList?.timestamp) {
                modifiedDate = rowList?.timestamp?.split(' ')[0];
                modifiedTimestamp = rowList?.timestamp?.split(' ')[1];
              }
              rowList.modifiedDate = modifiedDate;
              rowList.modifiedTimestamp = modifiedTimestamp + " CST";
            },
          );
          dispatch({
            type: MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(errorMsg);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchGroupsAuditTrailDataError() {
  return {
    type: MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_DISMISS_ERROR,
  };
}

export function useFetchGroupsAuditTrailData() {
  const dispatch = useDispatch();

  const { createGroupsEvents,fetchGroupsAuditTrailDataPending, fetchGroupsAuditTrailDataError } = useSelector(
    state => ({
      createGroupsEvents: state.manageSubs.createGroupsEvents,
      fetchGroupsAuditTrailDataPending: state.manageSubs.fetchGroupsAuditTrailDataPending,
      fetchGroupsAuditTrailDataError: state.manageSubs.fetchGroupsAuditTrailDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchGroupsAuditTrailData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchGroupsAuditTrailDataError());
  }, [dispatch]);

  return {
    createGroupsEvents,
    fetchGroupsAuditTrailData: boundAction,
    fetchGroupsAuditTrailDataPending,
    fetchGroupsAuditTrailDataError,
    dismissFetchGroupsAuditTrailDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchGroupsAuditTrailDataPending: true,
        fetchGroupsAuditTrailDataError: null,
      };

    case MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        createGroupsEvents: action.data,
        fetchGroupsAuditTrailDataPending: false,
        fetchGroupsAuditTrailDataError: null,
      };

    case MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchGroupsAuditTrailDataPending: false,
        fetchGroupsAuditTrailDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_GROUPS_AUDIT_TRAIL_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchGroupsAuditTrailDataError: null,
      };

    default:
      return state;
  }
}
