import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HOME_AUTHRIZATION_RESET } from './constants';

export function authrizationReset() {
  return {
    type: HOME_AUTHRIZATION_RESET,
  };
}

export function useAuthrizationReset() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.home.auth);
  const boundAction = useCallback((...params) => dispatch(authrizationReset()), [
    dispatch,
  ]);
  return { auth, authrizationReset: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_AUTHRIZATION_RESET:
      return {
        ...state,
        auth: {
          cognitoIdToken: null,
          validUser: false,
          user: {
            userId: '',
            email: '',
            userName: '',
          },
        },
      };

    default:
      return state;
  }
}
