import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './styles/index.less';
import { datadogRum } from '@datadog/browser-rum';
import { ENV_NAME } from './common/apiConfig';
import './i18n/i18n';

datadogRum.init({
  applicationId: '5ff4b29f-699f-4850-9378-2f9da4722952',
  clientToken: 'pub0a689d7ce9102ba765267a3fc537b991',
  site: 'datadoghq.com',
  service: 'sia_plus_ui',
  env: ENV_NAME,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  replaySampleRate:0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  actionNameAttribute: 'data-dd-custom-name',
});
// datadogRum.startSessionReplayRecording();
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
