import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import {
  MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_BEGIN,
  MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_SUCCESS,
  MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_FAILURE,
  MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import { sortFiltersListByKey } from '../../../common/sorterFunc';
import { getEnabledSites, getInitAllDropdownList } from '../getOriginalSelectedList';
export function fetchSearchPanelOrgInfo(market, region, sites) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/fetchOrgData`, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          const sortedOrgData = sortFiltersListByKey(res.data, 'market');
          let initOrgList = getInitAllDropdownList(sortedOrgData, market, region, sites);
          const doRequest = axios.get(`${API_REQUEST_URL}/getEnabledSites`, {
            headers: {
              'Authorization': token,
            }
          });
          doRequest.then(
            (res) => { 
              const enabledData = res.data.siteDetails;
              let enabledInitOrglist = {};
              if (!_.isEmpty(enabledData)) {
                enabledInitOrglist = getEnabledSites(initOrgList, enabledData);
              }
              const data = {
                originalList: sortedOrgData,
                originalConstData: !_.isEmpty(enabledInitOrglist) ? enabledInitOrglist : initOrgList,
              };
              dispatch({
                type: MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_SUCCESS,
                data: data,
              });
              resolve(data);
            },
            // Use rejectHandler as the second argument so that render errors won't be caught.
            (err) => {
              const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
              dispatch({
                type: MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_FAILURE,
                data: { error: errorMsg },
              });
              reject(err);
            },
          )
         
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchSearchPanelOrgInfoError() {
  return {
    type: MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_DISMISS_ERROR,
  };
}

export function useFetchSearchPanelOrgInfo() {
  const dispatch = useDispatch();

  const {originalList, originalConstData, fetchSearchPanelOrgInfoPending, fetchSearchPanelOrgInfoError } = useSelector(
    state => ({
      originalList: state.manageSubs.originalList,
      originalConstData: state.manageSubs.originalConstData,
      fetchSearchPanelOrgInfoPending: state.manageSubs.fetchSearchPanelOrgInfoPending,
      fetchSearchPanelOrgInfoError: state.manageSubs.fetchSearchPanelOrgInfoError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchSearchPanelOrgInfo(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchSearchPanelOrgInfoError());
  }, [dispatch]);

  return {
    originalList,
    originalConstData,
    fetchSearchPanelOrgInfo: boundAction,
    fetchSearchPanelOrgInfoPending,
    fetchSearchPanelOrgInfoError,
    dismissFetchSearchPanelOrgInfoError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchSearchPanelOrgInfoPending: true,
        fetchSearchPanelOrgInfoError: null,
      };

    case MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_SUCCESS:
      // The request is success
      return {
        ...state,
        originalList: action.data.originalList,
        originalConstData: action.data.originalConstData,
        fetchSearchPanelOrgInfoPending: false,
        fetchSearchPanelOrgInfoError: null,
      };

    case MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSearchPanelOrgInfoPending: false,
        fetchSearchPanelOrgInfoError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchSearchPanelOrgInfoError: null,
      };

    default:
      return state;
  }
}
