import React, {useState} from 'react';
import { Button, Layout } from 'antd';
import { HeadPanel, CustomMenu, Feedback } from './';
import { MessageOutlined } from '@ant-design/icons';
import { QuickPhrasesIcon } from '../common/CustomerIcons';

export default function CustomLayout({children}) {
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  };

  const handleFeedback = () => {
    onToggleModal(setFeedbackModalVisible)
  }

  const [isOpenNav, setIsOpenNav] = useState(false);
  const headerOpenNav = () => {
    setIsOpenNav(!isOpenNav)
  };
  const renderFeedbackModal = (
    <Feedback
      title=""
      isModalVisible={feedbackModalVisible}
      width={380}
      onToggleModal={() => onToggleModal(setFeedbackModalVisible)}
      cancelText="clear"
      okText="submit"
    />
  );

  return (
    <div className="home-layout">
      <HeadPanel headerOpenNav = {headerOpenNav} isOpenNav = {isOpenNav}/>
      <Layout>
      <div className={isOpenNav ? "sys-mark fadeIn is-show-mobile":"sys-mark fadeOut none"} onClick={headerOpenNav}></div>
        <Layout.Sider width="62" theme="light" 
        className={isOpenNav ?"custom-menu-sider custom-menu-sider-none slideInLeft custom-left-0 is-show-mobile" : "custom-menu-sider custom-menu-sider-none slideOutLeft is-show-mobile"} >
          <CustomMenu headerOpenNav = {headerOpenNav}/>
          <Button type="link" className='feedback' title="Feedback" onClick={handleFeedback}>
            <QuickPhrasesIcon className='feedback-icon' />
            <p style={{marginTop:"0px", fontSize:"10px", marginLeft:"-7px", color:"#000000"}}>Feedback</p>
          </Button> 
        </Layout.Sider>
        <Layout.Sider width="62" theme="light" 
        className="is-show-pc" >
          <CustomMenu headerOpenNav = {headerOpenNav}/>
          <Button type="link" className='feedback' title="Feedback" onClick={handleFeedback}>
            <QuickPhrasesIcon className='feedback-icon' />
            <p style={{marginTop:"0px", fontSize:"10px", marginLeft:"-7px", color:"#000000"}}>Feedback</p>
          </Button> 
        </Layout.Sider>       
        <div className="layout-container">{children}</div>
      </Layout>
      {feedbackModalVisible && renderFeedbackModal}
    </div>
  );
};

CustomLayout.propTypes = {};
CustomLayout.defaultProps = {};
