import React from 'react';
import { TIME_HOUR_MINUES } from '../../common/constants';
import { Form, Row, Switch, Col, TimePicker } from 'antd';
import moment from 'moment';
// import PropTypes from 'prop-types';

export default function OnboardCommonPanel(props) {
  const { isCST, form,exists,title } = props;
  // const isCST = (initValueData && initValueData.managedBy === 'CST') ? true : false;
  const labelProps = {
    labelCol: { span: 12 },
    labelAlign: 'left',
  };
  const handleCutOffChg = (rule, value, callback) => {
    if (!value) {
      callback("Please input cut off time!")
      return
    }
    const timeStr = value.format(TIME_HOUR_MINUES)
    const minutes = value.format('mm')
    if (timeStr === '00:00') {
      callback("Entered Cut-Off time is not valid.")
    } else if (minutes != '00' && minutes != '30') {
      callback("Minutes can only be set to 00 or 30.")
    } else {
      callback()
    }
  };
  const handleHourAndMinute = (e, flag) => {
    const formVals = form.getFieldsValue()
    let timeArr = ['00', '00']
    if (formVals.nationalIdCutoffTime) {
      timeArr = formVals.nationalIdCutoffTime.format(TIME_HOUR_MINUES).split(":")
    }
    if (flag === 'hour') {
      timeArr[0] = e.target.innerText
    } else {
      timeArr[1] = e.target.innerText
    }
    form.setFieldsValue({ 'nationalIdCutoffTime': moment(`${timeArr[0]}:${timeArr[1]}`, TIME_HOUR_MINUES) })
    const errorList = []
    if (timeArr.join(':') === '00:00') {
      errorList.push({
        "name": ["nationalIdCutoffTime"],
        "errors": ['Entered Cut-Off time is not valid.'],
      })
    }
    form.setFields(errorList)
  };
  const handleHourClick = (e) => {
    handleHourAndMinute(e, 'hour')
  }
  const handleMinuteClick = (e) => {
    handleHourAndMinute(e, 'minute')
  }
  const handleTimePickerPop = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        const ulDoms = document.getElementsByClassName('ant-picker-time-panel-column')
        if (ulDoms.length === 2) {
          ulDoms[0].addEventListener('click', handleHourClick);
          ulDoms[1].addEventListener('click', handleMinuteClick);
        }
      }, 500);
    } else {
      const ulDoms = document.getElementsByClassName('ant-picker-time-panel-column')
      if (ulDoms.length === 2) {
        ulDoms[0].removeEventListener('click', handleHourClick);
        ulDoms[1].removeEventListener('click', handleMinuteClick);
      }
    }
  }
  return (
    <>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item {...labelProps} label="Include/Exclude" colon={false}>
            <div className="toggle-desc">Yes</div>
            <Form.Item name="maintenanceFlag" valuePropName="checked">
              <Switch
                className="subs-switch"
                size="small"
                defaultChecked
              disabled={title == 'Create Customer Group' && !exists ? true : false}
              />
            </Form.Item>
            <div className="toggle-desc">No</div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nationalIdCutoffTime" label="Sub Maintenance Cut - Off" colon={false} className="cut-off-time"
            validateTrigger={['onChange']}
            rules={[
              {
                validateTrigger: 'onChange',
                validator: (rule, value, callback) => handleCutOffChg(rule, value, callback),
              },
            ]}
          >
            <TimePicker
              minuteStep={30}
              format={TIME_HOUR_MINUES}
              disabled={title == 'Create Customer Group' && !exists ? true : false}
              onOpenChange={(isOpen) => handleTimePickerPop(isOpen)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item {...labelProps} label="Require approval to sub" colon={false}>
            <div className="toggle-desc">Yes</div>
            <Form.Item name="approvalRequiredFlag" valuePropName="checked">
              <Switch
                className="subs-switch"
                size="small"
                defaultChecked
              disabled={title == 'Create Customer Group' && !exists ? true : false}
              />
            </Form.Item>
            <div className="toggle-desc">No</div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...labelProps} label="Send Start of Day email" colon={false}>
            <div className="toggle-desc" style={{ paddingLeft: '11px' }}>Yes</div>
            <Form.Item name="startOfDay" valuePropName="checked">
              <Switch
                className="subs-switch"
                size="small"
              disabled={title == 'Create Customer Group' && !exists ? true : false}
              />
            </Form.Item>
            <div className="toggle-desc">No</div>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item {...labelProps} label="Sub out of Catalog" colon={false}>
            <div className="toggle-desc">Yes</div>
            <Form.Item name="catalogFlag" valuePropName="checked">
              <Switch
                className="subs-switch"
                size="small"
                defaultChecked
              disabled={title == 'Create Customer Group' && !exists ? true : false}
              />
            </Form.Item>
            <div className="toggle-desc">No</div>
          </Form.Item>
        </Col>
        <Col span={12} >
          <Form.Item {...labelProps} label="Managed by" colon={false}>
            {/* {isCST && (<span>CST</span>)} */}
            {/* {!isCST && ( */}
              <>
                <div className="toggle-desc">Local</div>
                <Form.Item name="managedBy" valuePropName="checked" >
                  <Switch
                    className="subs-switch non-responsive-switch"
                    size="small"
                    defaultChecked
                  disabled={title == 'Create Customer Group' && !exists ? true : false}
                  />
                </Form.Item>
                <div className="toggle-desc">Central</div>
              </>
            {/* )} */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}