import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   MANAGE_SUBS_RESET_OUTS_DATA,
} from './constants';

export function resetOutsData() {
  return {
    type:  MANAGE_SUBS_RESET_OUTS_DATA,
  };
}

export function useResetOutsData() {
  const dispatch = useDispatch();
  const  outsData = useSelector(state => state.manageSubs.outsData);
  const boundAction = useCallback((...params) => dispatch(resetOutsData()), [dispatch]);
  return {  outsData, resetOutsData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case  MANAGE_SUBS_RESET_OUTS_DATA:
      return {
        ...state,
         outsData: null,
      };

    default:
      return state;
  }
}
