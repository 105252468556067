import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Layout } from 'antd';
import { ManageSubsNavigation } from './';
import { ErrorPage } from '../common';
import { AUTH_ERROR_MSG } from '../../common/constants';
import { redirectUrl } from '../../common/apiConfig';

export default function ManageSubs({children}) {
  const [collapse, setCollapse] = useState(false);
    const {
    fetchSubstitutionsDataError,
    fetchValidationItemDataError,
    fetchConceptDataError,
    fetchValidationCustomerDataError,
    updateSubstitutionsDataError,
    fetchAddedSubsDataError,
    fetchAccountDataError,
    updateAccountDataError,
    deleteAccountDataError,
    fetchOutsDataError,
    fetchOrderDetailDataError,
    fetchInventoryDataError,
    checkAccountIfExistError,
    fetchRecommendedProductsError,
    searchSubstituteProductsError,
    removeDropOutsError,
    removeAllocatedItemError,
    checkOutsLockError,
    updateOutsRecordLockError,
    deleteManageSubsError,
    fetchTerritoryDataError,
    fetchSearchPanelOrgInfoError,
    fetchDropoutsDataError,
    updateEidtSubsLockError,
    updateEditAccountLockError,
    validateItemInChargeError,
    checkEditAccountLockError,
    checkEditSubsLockError,
    allocateOriginalItemError,
    fetchCustomerGroupDataError,
    fetchValidateGroupError,
    fetchGroupIdsDataError,
    fetchCustomerDataError,
    removeCustomerError,
    updateCustomerGroupError,
    addCustomersError,
    fetchDropOutsReasonError,
    removeCustomersError,
    fetchSubsAuditTrailDataError,
    fetchAccountsAuditTrailDataError,
    exportManageAccountAuditTrailError,
    exportSubsAuditTrailError,
    fetchGroupsAuditTrailDataError,
    fetchConceptIdsDataError,
    exportGroupsAuditTrailError,
  } = useSelector(state => ({
    fetchSubstitutionsDataError: state.manageSubs.fetchSubstitutionsDataError,
    fetchValidationItemDataError: state.manageSubs.fetchValidationItemDataError,
    fetchConceptDataError: state.manageSubs.fetchConceptDataError,
    fetchValidationCustomerDataError: state.manageSubs.fetchValidationCustomerDataError,
    updateSubstitutionsDataError: state.manageSubs.updateSubstitutionsDataError,
    fetchAddedSubsDataError: state.manageSubs.fetchAddedSubsDataError,
    fetchAccountDataError: state.manageSubs.fetchAccountDataError,
    updateAccountDataError: state.manageSubs.updateAccountDataError,
    deleteAccountDataError: state.manageSubs.deleteAccountDataError,
    fetchOutsDataError: state.manageSubs.fetchOutsDataError,
    fetchOrderDetailDataError: state.manageSubs.fetchOrderDetailDataError,
    fetchInventoryDataError: state.manageSubs.fetchInventoryDataError,
    checkAccountIfExistError: state.manageSubs.checkAccountIfExistError,
    fetchRecommendedProductsError: state.manageSubs.fetchRecommendedProductsError,
    searchSubstituteProductsError: state.manageSubs.searchSubstituteProductsError,
    removeDropOutsError: state.manageSubs.removeDropOutsError,
    removeAllocatedItemError: state.manageSubs.removeAllocatedItemError,
    checkOutsLockError: state.manageSubs.checkOutsLockError,
    updateOutsRecordLockError: state.manageSubs.updateOutsRecordLockError,
    deleteManageSubsError: state.manageSubs.deleteManageSubsError,
    fetchTerritoryDataError: state.dashboard.fetchTerritoryDataError,
    fetchSearchPanelOrgInfoError:state.manageSubs.fetchSearchPanelOrgInfoError,
    fetchDropoutsDataError: state.manageSubs.fetchDropoutsDataError,
    updateEidtSubsLockError: state.manageSubs.updateEidtSubsLockError,
    updateEditAccountLockError: state.manageSubs.updateEditAccountLockError,
    validateItemInChargeError: state.manageSubs.validateItemInChargeError,
    checkEditAccountLockError: state.manageSubs.checkEditAccountLockError,
    checkEditSubsLockError: state.manageSubs.checkEditSubsLockError,
    allocateOriginalItemError: state.manageSubs.allocateOriginalItemError,
    fetchCustomerGroupDataError: state.manageSubs.fetchCustomerGroupDataError,
    fetchValidateGroupError: state.manageSubs.fetchValidateGroupError,
    fetchGroupIdsDataError: state.manageSubs.fetchGroupIdsDataError,
    fetchCustomerDataError: state.manageSubs.fetchCustomerDataError,
    removeCustomerError: state.manageSubs.removeCustomerError,
    updateCustomerGroupError: state.manageSubs.updateCustomerGroupError,
    addCustomersError: state.manageSubs.addCustomersError,
    fetchDropOutsReasonError: state.manageSubs.fetchDropOutsReasonError,
    removeCustomersError: state.manageSubs.removeCustomersError,
    fetchSubsAuditTrailDataError: state.manageSubs.fetchSubsAuditTrailDataError,
    fetchAccountsAuditTrailDataError: state.manageSubs.fetchAccountsAuditTrailDataError,
    exportManageAccountAuditTrailError: state.manageSubs.exportManageAccountAuditTrailError,
    exportSubsAuditTrailError: state.manageSubs.exportSubsAuditTrailError,
    fetchGroupsAuditTrailDataError: state.manageSubs.fetchGroupsAuditTrailDataError,
    fetchConceptIdsDataError: state.dashboard.fetchConceptIdsDataError,
    exportGroupsAuditTrailError: state.manageSubs.exportGroupsAuditTrailError,
  }));
  const isErrorPage = fetchSubstitutionsDataError || fetchAccountDataError;
  if (fetchSubstitutionsDataError === AUTH_ERROR_MSG ||
    fetchValidationItemDataError === AUTH_ERROR_MSG ||
    fetchValidationCustomerDataError === AUTH_ERROR_MSG ||
    fetchConceptDataError === AUTH_ERROR_MSG ||
    updateSubstitutionsDataError === AUTH_ERROR_MSG ||
    fetchAddedSubsDataError === AUTH_ERROR_MSG ||
    fetchAccountDataError === AUTH_ERROR_MSG ||
    updateAccountDataError === AUTH_ERROR_MSG ||
    deleteAccountDataError === AUTH_ERROR_MSG ||
    fetchOutsDataError === AUTH_ERROR_MSG|| 
    fetchOrderDetailDataError === AUTH_ERROR_MSG ||
    fetchInventoryDataError === AUTH_ERROR_MSG|| 
    checkAccountIfExistError === AUTH_ERROR_MSG|| 
    fetchRecommendedProductsError === AUTH_ERROR_MSG|| 
    searchSubstituteProductsError === AUTH_ERROR_MSG|| 
    removeDropOutsError === AUTH_ERROR_MSG|| 
    removeAllocatedItemError === AUTH_ERROR_MSG||
    checkOutsLockError === AUTH_ERROR_MSG|| 
    updateOutsRecordLockError === AUTH_ERROR_MSG|| 
    deleteManageSubsError === AUTH_ERROR_MSG||
    fetchTerritoryDataError === AUTH_ERROR_MSG ||
    fetchSearchPanelOrgInfoError === AUTH_ERROR_MSG || 
    fetchDropoutsDataError === AUTH_ERROR_MSG ||  updateEidtSubsLockError === AUTH_ERROR_MSG || updateEditAccountLockError === AUTH_ERROR_MSG || 
    validateItemInChargeError === AUTH_ERROR_MSG || checkEditAccountLockError === AUTH_ERROR_MSG || checkEditSubsLockError === AUTH_ERROR_MSG || 
    allocateOriginalItemError === AUTH_ERROR_MSG ||
    fetchCustomerGroupDataError === AUTH_ERROR_MSG || fetchValidateGroupError === AUTH_ERROR_MSG ||
    fetchGroupIdsDataError === AUTH_ERROR_MSG || fetchCustomerDataError === AUTH_ERROR_MSG ||
    removeCustomerError === AUTH_ERROR_MSG || updateCustomerGroupError === AUTH_ERROR_MSG || 
    addCustomersError === AUTH_ERROR_MSG || fetchDropOutsReasonError === AUTH_ERROR_MSG || 
    removeCustomersError === AUTH_ERROR_MSG ||
    fetchSubsAuditTrailDataError === AUTH_ERROR_MSG || fetchAccountsAuditTrailDataError === AUTH_ERROR_MSG
    || exportManageAccountAuditTrailError === AUTH_ERROR_MSG ||  exportSubsAuditTrailError === AUTH_ERROR_MSG
    || fetchGroupsAuditTrailDataError === AUTH_ERROR_MSG
    || fetchConceptIdsDataError === AUTH_ERROR_MSG
    || exportGroupsAuditTrailError === AUTH_ERROR_MSG) {
    window.location.assign(redirectUrl);
  } else if (isErrorPage) {
    return <ErrorPage />;
  }

  const handleCollapse=()=>{
    setCollapse((prevState) => (!prevState))
  }

  return (
    <div className="manage-subs-manage-subs">
      <Layout>
        <Layout.Sider width={collapse?"0":"180"} theme="light" className="manage-subs-menu" >
          <ManageSubsNavigation />
          
        </Layout.Sider>
        
        
        <div style={{position:'relative',width:collapse?"10px":"0px"}}>
        <Button style={{width:collapse?"10px":"0px"}} className='collapse-btn2' disabled></Button>
        <Button style={{transform:collapse?'translateX(0%)':'translateX(-50%)'}} className='collapse-btn' onClick={handleCollapse}>{collapse?(<span style={{fontWeight:'900'}}>&gt;</span>):(<span style={{fontWeight:'900'}}>&lt;</span>)}</Button>
        </div>
        
        
        <div className={collapse?"subs-layout-content collapsed-menu-content":"subs-layout-content"}>
          {children}
        </div>
        
      </Layout>
      
    </div>
  );
};
