import React,{ useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CONST_PAGINATION_TEN,SLASH_DATE_FORMAT } from '../../common/constants';
import { CONST_REPORT_TABS_COLUMNS } from '../../common/reportsConstants';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Table, Pagination, Empty, Spin } from 'antd';
import { ExportAlertModal } from '../common';
import { downloadFile } from '../../common/downloadReportFunc';
import { useFetchBusinessEventLogData, useFetchOrderTrailData, 
 useResetOrderTrailData, useResetBusinessEventLogData } from '../order-trail/redux/hooks';
import { formatFormdata } from '../reports/formatFormdata';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { ExportButton, Header } from '../reports-new';
import { renderTableColumns } from '../../common/renderColumns';
import { datadogRum } from '@datadog/browser-rum';


export default function BusinessEventLog() {
  const {
    businessEventLogData,
    fetchBusinessEventLogDataPending,
    dashboardConditionData,
    territoryData,
    fetchOrderTrailDataPending,
    reportsKeyValue,
  } = useSelector(state => ({
    businessEventLogData: state.orderTrail.businessEventLogData,
    fetchBusinessEventLogDataPending: state.orderTrail.fetchBusinessEventLogDataPending,
    dashboardConditionData: state.dashboard.dashboardConditionData,
    territoryData: state.dashboard.territoryData,
    fetchOrderTrailDataPending: state.orderTrail.fetchOrderTrailDataPending,
    reportsKeyValue: state.reportsNew.reportsKeyValue,
  }));
  const [pagination, setPagination] = useState(CONST_PAGINATION_TEN);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const { fetchOrderTrailData } = useFetchOrderTrailData();
  const { fetchBusinessEventLogData } = useFetchBusinessEventLogData();
 
  const location = useLocation();
  let columnsProp = [];
  let tabKey = '';
  const strCurrPath = location.pathname.replace('/', '').split('/');
  if(strCurrPath[0] === 'orders'){
    columnsProp = CONST_REPORT_TABS_COLUMNS.filter(item => item.key === 'Orders')[0].customerColumns;
    tabKey = 'Orders';
  }else{
    columnsProp = CONST_REPORT_TABS_COLUMNS.filter(item => item.key === 'Future Orders')[0].customerColumns;
    tabKey = 'Future Orders';
  }
  let condition = formatFormdata(dashboardConditionData, territoryData);
  // condition = {
  //   ...condition,
  //   customerNumber: reportsKeyValue.customerNumber || '',
  //   scId: (tabKey === 'SC in Action' && reportsKeyValue.scId) || '',
  //   accountType: (tabKey === 'Summary' && reportsKeyValue.accountType) || '',
  //   offDay: tabKey === 'Summary' && reportsKeyValue.offDaySales ? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
  // };
  const columns = renderTableColumns(columnsProp, tabKey);
  let title = `Track My ${tabKey} Details - ${reportsKeyValue.orderNo}`;
  useEffect(() => {
    if(!_.isEmpty(dashboardConditionData)){
      let formdata = {
        ...condition,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...sorting,
      }
      //fetch customer level report data, and update state
      fetchOrderDetailsData(formdata);
    }
  }, [pagination, sorting,dashboardConditionData]); // eslint-disable-line
  const fetchOrderDetailsData = (formdata) => {
    const orderTrailCond = {
      opco: reportsKeyValue.opco,
      orderNo: reportsKeyValue.orderNo,
      customerNo: '',
      uomOrderNo: '',
      fromDate: '',//don't need pass date when we call based on site id and order number. 
      toDate: '',//don't need pass date when we call based on site id and order number. 
    };
    const timing = Date.now();
    fetchOrderTrailData(orderTrailCond).then(order => {
      if(order) {
        const { opco, orderNo, orderDate, uomOrderNo } = order[0];
        const logCond = {
          opco: opco,
          orderNo: orderNo,
          customerNo: reportsKeyValue.customerNumber,
          uomOrderNo: uomOrderNo ? uomOrderNo : '',
          orderDate: orderDate,
        };
        fetchBusinessEventLogData(logCond);
      }
    });
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch ${title} report`,timing);
    });
  }
  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };
  const exportReports = () => {
    setShowAlert(false);
    let sheetColConfig = [];
    columns.forEach(col => {
      let sheetColWidth = 10;
      if (col.width) {
        sheetColWidth = col.width / 10;
      }
      sheetColConfig.push({ wch: sheetColWidth });
    });
    const timing = Date.now();
    const sheetObj = [{
      headers: columns,
      data: businessEventLogData,
      sheetName: null,
      sheetColConfig,
    }];
    downloadFile(`${title} Report`, sheetObj);
    datadogRum.addAction(`Export ${title} report`, {
      headers: columns,
      data: businessEventLogData,
      sheetName: null,
      sheetColConfig,
    });
    datadogRum.onReady(function () {
      datadogRum.addTiming(`Export ${title} report`, timing);
    });
  };
  return (
    <div className="orders-business-event-log">
      <div className="sia-container">
       <Header tabKey={tabKey} backTo={`${tabKey === 'Orders'?'/orders/order':'/future-orders/order'}`} title={title} fromChannelType={false} isEventLog={true}/>
      <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
      <div className="report-new-export">
        <ExportButton dataSource={businessEventLogData} exportReports={exportReports} />
      </div>
      </div>
      <div className='reports-new-tables'>
        <div className='reports-result-panel'>
          <Table
            rowKey={record => `${record.eventId}-${record.event}`}
            className="report-new-table is-show-pc"
            loading={fetchOrderTrailDataPending || fetchBusinessEventLogDataPending}
            columns={columns}
            dataSource={businessEventLogData}
            pagination={false}
            onChange={onTableChange}
            scroll={{
              x: 'max-content',
              y: 300
            }}
          />
            <div className="is-show-mobile">
            <div className="reports-mobile-list">
            <Spin spinning={fetchOrderTrailDataPending || fetchBusinessEventLogDataPending}>
            <div className={!businessEventLogData ? "reports-no-data":"none"}>
              <Empty />
            </div>
            {businessEventLogData && businessEventLogData.length >0 && businessEventLogData.map((record,index) => {
              return (
                <ul key={`${tabKey}-${index}`}>
                  {
                    columnsProp.map((colItem,colIndex) => {
                      const title = colItem.title;
                      const dataIndex = colItem.dataIndex;
                      const value = record[dataIndex];
                      return (<li key={`${tabKey}-${dataIndex}-${colIndex}`}>
                        <span className="sub01">{title}</span>
                        <span className="sub02">
                        {value}
                        </span>
                      </li>)
                    })
                  }
                </ul>
                );
            })}
            </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BusinessEventLog.propTypes = {};
BusinessEventLog.defaultProps = {};
