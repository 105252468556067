import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_TRAIL_RESET_BUSINESS_EVENT_LOG_DATA } from './constants';

export function resetBusinessEventLogData() {
  return {
    type: ORDER_TRAIL_RESET_BUSINESS_EVENT_LOG_DATA,
  };
}

export function useResetBusinessEventLogData() {
  const dispatch = useDispatch();
  const { businessEventLogData, businessEventBannerData } = useSelector(state => ({
    businessEventLogData: state.orderTrail.businessEventLogData,
    businessEventBannerData: state.orderTrail.businessEventBannerData,
  }));
  const boundAction = useCallback((...params) => dispatch(resetBusinessEventLogData()), [
    dispatch,
  ]);
  return { businessEventLogData, businessEventBannerData, resetBusinessEventLogData: boundAction };
}

export function reducer(state, action) {
  if(action.type===ORDER_TRAIL_RESET_BUSINESS_EVENT_LOG_DATA){
    return {
      ...state,
      businessEventLogData: null,
      businessEventBannerData: null,
    };
  }
  
    
      return state;
  
}
