import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchFiltersPanel from './SearchFiltersPanel';
import { CommonTable } from '../common';
import { AUDIT_TRAIL_EXPORT_LIMIT, AUDIT_TRAIL_EXPORT_LIMIT_MSG, CONST_PAGINATION_TEN } from '../../common/constants';
import { renderTableColumns } from '../../common/renderColumns';
import { CONST_CREATE_GROUPS_AUDIT_TRAIL_TABLE_COLUMNS } from '../../common/createGroupsAuditTrail';
import { useExportGroupsAuditTrail, useFetchGroupsAuditTrailData, useResetGroupsAuditTrailData } from './redux/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Button, Spin, message } from 'antd';
import { datadogRum } from '@datadog/browser-rum';
export default function CreateGroupsAuditTrail() {
  const {
    createGroupsEvents,
    fetchGroupsAuditTrailDataPending,
    searchConditionData,
    exportGroupsAuditTrailPending, 
  } = useSelector(state => ({
    createGroupsEvents: state.manageSubs.createGroupsEvents,
    fetchGroupsAuditTrailDataPending: state.manageSubs.fetchGroupsAuditTrailDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    exportGroupsAuditTrailPending: state.manageSubs.exportGroupsAuditTrailPending,
  }));
  const { fetchGroupsAuditTrailData }  = useFetchGroupsAuditTrailData();
  const { resetGroupsAuditTrailData } = useResetGroupsAuditTrailData();
  const { exportGroupsAuditTrail } = useExportGroupsAuditTrail();
  const renderColumnsElement = [
    {},//site placeholder
    { //render siteID
      extendRender: (value, label, record) => {
        if(value && value.length > 0) {
          return (
            <>
              {value}
            </>
          )
        }
        return (
          <>
            {record.customerNumber}
          </>
        )
      }
    },
    {},//log placeholder
    {},//oldValue placeholder
    {},//newValue placeholder
    { //render modified log
      extendRender: (value, label, record) => {
        return value && (
          <>
            <div className="col-title">{record?.username}</div>
            <div className="col-desc">{`${record?.userId} ${record?.modifiedDate} ${record?.modifiedTimestamp}`}</div>
          </>
        )
      }
    },
  ]
  let columns = renderTableColumns(CONST_CREATE_GROUPS_AUDIT_TRAIL_TABLE_COLUMNS, null, renderColumnsElement);
  const onExportGroupsAuditTrail = () =>{
    if(createGroupsEvents.totals > AUDIT_TRAIL_EXPORT_LIMIT) {
      message.error(AUDIT_TRAIL_EXPORT_LIMIT_MSG);
      return;
    }
    const params = {...searchConditionData}
    const context = datadogRum.getGlobalContext('BosView');
    const timing = Date.now();
    exportGroupsAuditTrail(params); 
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Export ${context.BosView.screen} data`,timing);
    });
  }
  return (
    <div className="manage-subs-create-groups-audit-trail">
      <Spin spinning={exportGroupsAuditTrailPending}>
        <div className="chart-title font-size-16-bold" style={{ font: "Myriad Bold" }}>Create Groups Audit Trail</div>
        <SearchFiltersPanel />
        <div className="flex align-item-center justify-end" style={{ marginBottom: "20px"}}>
          <Button type="link" title="Export" disabled={createGroupsEvents === null} onClick={onExportGroupsAuditTrail} style={{ marginLeft: '-20px' }}>
            <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(searchConditionData) ? "#a1a1a2" : "#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: (_.isEmpty(searchConditionData) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
          </Button>
        </div>
        <CommonTable
          rowKey={Math.random()}
          columns={columns}
          tableClassName={createGroupsEvents ? "outs-report-table" : ""}
          datasource={createGroupsEvents}
          loadingData={fetchGroupsAuditTrailDataPending}
          paginationStyle={{ textAlign: "right", margin: '16px 0px' }}
          triggerConditions={searchConditionData}
          onFecthData={fetchGroupsAuditTrailData}
          onResetStore={resetGroupsAuditTrailData}
          scrollStyle={{ x: 'max-content', y: 300 }}
          paginationSetting={CONST_PAGINATION_TEN}
        />
      </Spin>
    </div>
  );
};

CreateGroupsAuditTrail.propTypes = {};
CreateGroupsAuditTrail.defaultProps = {};
