import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_BEGIN,
  REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_SUCCESS,
  REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_FAILURE,
  REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function fetchOosReportByCategory(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "ascend",
          sortedBy: "site",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getOosReportByCategory`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              summary: res.data && res.data.summary,
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOosReportByCategoryError() {
  return {
    type: REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR,
  };
}

export function useFetchOosReportByCategory() {
  const dispatch = useDispatch();

  const {reportsOrderData,reportsOrderTotals,orderSummary,fetchOosReportByCategoryPending, fetchOosReportByCategoryError } = useSelector(
    state => ({
      reportsOrderData: state.reports.reportsOrderData,
      reportsOrderTotals: state.reports.reportsOrderTotals,
      orderSummary: state.reports.orderSummary,
      fetchOosReportByCategoryPending: state.reports.fetchOosReportByCategoryPending,
      fetchOosReportByCategoryError: state.reports.fetchOosReportByCategoryError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOosReportByCategory(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOosReportByCategoryError());
  }, [dispatch]);

  return {
    reportsOrderData,
    reportsOrderTotals,
    orderSummary,
    fetchOosReportByCategory: boundAction,
    fetchOosReportByCategoryPending,
    fetchOosReportByCategoryError,
    dismissFetchOosReportByCategoryError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOosReportByCategoryPending: true,
        fetchOosReportByCategoryError: null,
      };

    case REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchOosReportByCategoryPending: false,
        fetchOosReportByCategoryError: null,
        reportsOrderData: action.data.data,
        reportsOrderTotals: action.data.totals,
        orderSummary: action.data.summary, 
      };

    case REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOosReportByCategoryPending: false,
        fetchOosReportByCategoryError: action.data.error,
      };

    case REPORTS_FETCH_OOS_REPORT_BY_CATEGORY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOosReportByCategoryError: null,
      };

    default:
      return state;
  }
}
