import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN,
  REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS,
  REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE,
  REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR,
} from './constants';

export function fetchOrdersReportByChannelType(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/getOrdersReportByChannelType`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              summary: res.data && res.data.summary,
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOrdersReportByChannelTypeError() {
  return {
    type: REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR,
  };
}

export function useFetchOrdersReportByChannelType() {
  const dispatch = useDispatch();

  const {reportsData, reportsTotals, summary, fetchOrdersReportByChannelTypePending, fetchOrdersReportByChannelTypeError } = useSelector(
    state => ({
      reportsData: state.reports.reportsData,
      reportsTotals: state.reports.reportsTotals,
      summary: state.reports.summary,
      fetchOrdersReportByChannelTypePending: state.reports.fetchOrdersReportByChannelTypePending,
      fetchOrdersReportByChannelTypeError: state.reports.fetchOrdersReportByChannelTypeError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOrdersReportByChannelType(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOrdersReportByChannelTypeError());
  }, [dispatch]);

  return {
    reportsData,
    reportsTotals,
    summary,
    fetchOrdersReportByChannelType: boundAction,
    fetchOrdersReportByChannelTypePending,
    fetchOrdersReportByChannelTypeError,
    dismissFetchOrdersReportByChannelTypeError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOrdersReportByChannelTypePending: true,
        fetchOrdersReportByChannelTypeError: null,
      };

    case REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_SUCCESS:
      // The request is success
      return {
        ...state,
        reportsData: action.data.data,
        reportsTotals: action.data.totals,
        summary: action.data.summary,
        fetchOrdersReportByChannelTypePending: false,
        fetchOrdersReportByChannelTypeError: null,
      };

    case REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOrdersReportByChannelTypePending: false,
        fetchOrdersReportByChannelTypeError: action.data.error,
      };

    case REPORTS_FETCH_ORDERS_REPORT_BY_CHANNEL_TYPE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOrdersReportByChannelTypeError: null,
      };

    default:
      return state;
  }
}
