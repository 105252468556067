import { Row, Col } from 'antd';
import React from 'react';
import { groupData } from './formatData';
import { useTranslation } from "react-i18next";

export default function TotalPanel({ footerColumns, footerData, tabKey, fromCustomer,fromChannelType,level}) {
  const { t } = useTranslation();
  const CONST_REPORTS_TOTAL_PANEL_COLUMNS = [
    {
      key: 'Summary',
      columns: [
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'totalPiece',
          dataIndex: 'totalPiece',
        },
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'holdOrders',
          dataIndex: 'holdOrders',
        },
        {
          title: 'offDayOrders',
          dataIndex: 'offDayOrders',
        },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
        },
        {
          title: 'totalConfirmedPiece',
          dataIndex: 'totalConfirmedPiece',
        },
        {
          title: 'totalConfirmedFreezer',
          dataIndex: 'totalConfirmedFreezer',
        },
        {
          title: 'totalConfirmedCooler',
          dataIndex: 'totalConfirmedCooler',
        },
        {
          title: 'totalConfirmedDry',
          dataIndex: 'totalConfirmedDry',
        },
        // {
        //   title: 'Total Offday Orders',
        //   dataIndex: 'offDayOrders',
        // },
      ],
      customerColumns: [
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'customerColumns_totalCases',
          dataIndex: 'totalCases',
        },
        {
          title: 'customerColumns_totalEaches',
          dataIndex: 'totalEaches',
        }
      ],
      orderColumns: [
        {
          title: 'orderTotalSales',
          dataIndex: 'orderTotalSales',
        },
        {
          title: 'orderTotalCases',
          dataIndex: 'orderTotalCases',
        },
        {
          title: 'orderTotalEaches',
          dataIndex: 'orderTotalEaches',
        }
      ],
      orderDetailColumns:[
        {
          title: 'orderDetailExtendedPrice',
          dataIndex: 'orderDetailExtendedPrice',
        },
        {
          title: 'quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'quantityAllocAgainstOnHand',
          dataIndex: 'quantityAllocAgainstOnHand',
        },
        {
          title: 'orderSource',
          dataIndex: 'orderSource',
        },
      ]
    },
    {
      key: 'Orders',
      columns:[
        {
          title: 'noOfCust',
          dataIndex: 'noOfCust',
        },
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'offDayOrders',
          dataIndex: 'offDayOrders',
        },
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'orderTotalCases',
          dataIndex: 'totalCases',
        },
        {
          title: 'orderTotalEaches',
          dataIndex: 'totalEaches',
        },
      ],
      customerColumns:[
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'totalPiece',
          dataIndex: 'totalPiece',
        },
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'holdOrders',
          dataIndex: 'holdOrders',
        },
        {
          title: 'offDayOrders',
          dataIndex: 'offDayOrders',
        },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
        },
        {
          title: 'totalConfirmedPiece',
          dataIndex: 'totalConfirmedPiece',
        },
        {
          title: 'totalConfirmedFreezer',
          dataIndex: 'totalConfirmedFreezer',
        },
        {
          title: 'totalConfirmedCooler',
          dataIndex: 'totalConfirmedCooler',
        },
        {
          title: 'totalConfirmedDry',
          dataIndex: 'totalConfirmedDry',
        },
      ],
      orderColumns: [
        {
          title: 'orderTotalSales',
          dataIndex: 'orderTotalSales',
        },
        {
          title: 'orderTotalCases',
          dataIndex: 'orderTotalCases',
        },
        {
          title: 'orderTotalEaches',
          dataIndex: 'orderTotalEaches',
        }
      ],
      orderDetailColumns:[
        {
          title: 'orderDetailExtendedPrice',
          dataIndex: 'orderDetailExtendedPrice',
        },
        {
          title: 'quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'quantityAllocAgainstOnHand',
          dataIndex: 'quantityAllocAgainstOnHand',
        },
        {
          title: 'orderSource',
          dataIndex: 'orderSource',
        },
      ]
    },
    {
      key: 'Future Orders',
      columns:[
        {
          title: 'noOfCust',
          dataIndex: 'noOfCust',
        },
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'offDayOrders',
          dataIndex: 'offDayOrders',
        },
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'totalCases',
          dataIndex: 'totalCases',
        },
        {
          title: 'totalEaches',
          dataIndex: 'totalEaches',
        },
      ],
      customerColumns:[
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'totalPiece',
          dataIndex: 'totalPiece',
        },
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'holdOrders',
          dataIndex: 'holdOrders',
        },
        {
          title: 'offDayOrders',
          dataIndex: 'offDayOrders',
        },
        {
          title: 'averageSell',
          dataIndex: 'averageSell',
        },
        {
          title: 'totalConfirmedPiece',
          dataIndex: 'totalConfirmedPiece',
        },
        {
          title: 'totalConfirmedFreezer',
          dataIndex: 'totalConfirmedFreezer',
        },
        {
          title: 'totalConfirmedCooler',
          dataIndex: 'totalConfirmedCooler',
        },
        {
          title: 'totalConfirmedDry',
          dataIndex: 'totalConfirmedDry',
        },
      ],
      orderColumns: [
        {
          title: 'orderTotalSales',
          dataIndex: 'orderTotalSales',
        },      
        {
          title: 'orderTotalCases',
          dataIndex: 'orderTotalCases',
        },
        {
          title: 'orderTotalEaches',
          dataIndex: 'orderTotalEaches',
        },
        
      ],
      orderDetailColumns:[
        {
          title: 'orderDetailExtendedPrice',
          dataIndex: 'orderDetailExtendedPrice',
        },
        {
          title: 'quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'quantityAllocAgainstOnHand',
          dataIndex: 'quantityAllocAgainstOnHand',
        },
        {
          title: 'orderSource',
          dataIndex: 'orderSource',
        },
      ]
    },
    {
      key: 'SC in Action',
      columns: [
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        // {
        //   title: 'Total Cases',
        //   dataIndex: 'totalCases',
        // },
        // {
        //   title: 'Total Eaches',
        //   dataIndex: 'totalEaches',
        // },
        {
          title: 'totalPiece',
          dataIndex: 'totalPiece',
        },
        {
          title: 'noOfCust',
          dataIndex: 'noOfCust',
        },
        {
          title: 'offDayOrders',
          dataIndex: 'offDayOrders',
        },
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        }
      ],
      customerColumns: [
        {
          title: 'noOfOrders',
          dataIndex: 'noOfOrders',
        },
        {
          title: 'totalSales',
          dataIndex: 'totalSales',
        },
        {
          title: 'totalCases',
          dataIndex: 'totalCases',
        },
        {
          title: 'totalEaches',
          dataIndex: 'totalEaches',
        }
      ],
      orderColumns: [
        {
          title: 'orderTotalSales',
          dataIndex: 'orderTotalSales',
        },
        {
          title: 'orderTotalCases',
          dataIndex: 'orderTotalCases',
        },
        {
          title: 'orderTotalEaches',
          dataIndex: 'orderTotalEaches',
        }
      ],
      orderDetailColumns:[
        {
          title: 'orderDetailExtendedPrice',
          dataIndex: 'orderDetailExtendedPrice',
        },
        {
          title: 'quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'quantityAllocAgainstOnHand',
          dataIndex: 'quantityAllocAgainstOnHand',
        },
        {
          title: 'orderSource',
          dataIndex: 'orderSource',
        },
      ]
    },
    {
      key: 'OOS Item',
      columns: [
        {
          title: 'totalOOS',
          dataIndex: 'oos',
        },
        {
          title: 'lostSales',
          dataIndex: 'lostSales',
        },
        {
          title: 'preShipDirSubs',
          dataIndex: 'preShipDirSubs',
        },
        {
          title: 'preShipAllSubs',
          dataIndex: 'preShipAllSubs',
        },
      ],
      customerColumns: [
        {
          title: 'totalOOS',
          dataIndex: 'oos',
        },
        {
          title: 'lostSales',
          dataIndex: 'lostSales',
        },
        // {
        //   title: 'PreShip w/Dir Subs',
        //   dataIndex: 'preShipDirSubs',
        // },
        // {
        //   title: 'PreShip w/All Subs',
        //   dataIndex: 'preShipAllSubs',
        // },
      ],
      orderColumns: [
        {
          title: 'totalOOS',
          dataIndex: 'oos',
        },
        {
          title: 'lostSales',
          dataIndex: 'lostSales',
        },
        {
          title: 'preShipDirSubs',
          dataIndex: 'preShipDirSubs',
        },
        {
          title: 'preShipAllSubs',
          dataIndex: 'preShipAllSubs',
        },
      ]
    },
    {
      key: 'OOS Account',
      columns: [
        {
          title: 'totalOOS',
          dataIndex: 'oos',
        },
        {
          title: 'lostSales',
          dataIndex: 'lostSales',
        },
        {
          title: 'preShipDirSubs',
          dataIndex: 'preShipDirSubs',
        },
        {
          title: 'preShipAllSubs',
          dataIndex: 'preShipAllSubs',
        },
      ],
      customerColumns: [
        {
          title: 'totalOOS',
          dataIndex: 'oos',
        },
        {
          title: 'lostSales',
          dataIndex: 'lostSales',
        },
        {
          title: 'preShipDirSubs',
          dataIndex: 'preShipDirSubs',
        },
        {
          title: 'preShipAllSubs',
          dataIndex: 'preShipAllSubs',
        },
      ],
      orderColumns: [
        {
          title: 'totalOOS',
          dataIndex: 'oos',
        },
        {
          title: 'lostSales',
          dataIndex: 'lostSales',
        },
        {
          title: 'preShipDirSubs',
          dataIndex: 'preShipDirSubs',
        },
        {
          title: 'preShipAllSubs',
          dataIndex: 'preShipAllSubs',
        },
      ]
    },
  ]
  let totalData = [];
  const totalColumns = CONST_REPORTS_TOTAL_PANEL_COLUMNS.filter(item => item.key === tabKey)[0];
  let panelColumns = [];
  switch (level){
    case 1:
      panelColumns = totalColumns.columns;
      break;
    case 2:
      panelColumns = totalColumns.customerColumns;
      break;
    case 3:
      panelColumns = totalColumns.orderColumns;
      break;
    case 4:
        panelColumns = totalColumns.orderDetailColumns;
        break;
    default:
      panelColumns = totalColumns.columns;
  }  
  panelColumns.map(item => {
    totalData.push({
      label: t(item.title),
      data: footerData ? footerData[item.dataIndex] : 0,
      dataIndex: item.dataIndex,
    })
  })
  const renderGrid = (item) => {
    const label = item.label;
    let count = item.data && `${item.data.toLocaleString()}`;
    const dataIndex = item.dataIndex;
    if( dataIndex === 'orderDetailExtendedPrice'){
      count = item.data && `$${item.data.toLocaleString('zh',{minimumFractionDigits:2})}`;
    }
    if(dataIndex === 'totalSales' || dataIndex === 'avgPricePerPiece' || dataIndex === 'averageSell' || dataIndex === 'offDaySales' || dataIndex === 'orderTotalSales' 
    || dataIndex === 'lostSales'){
      count = item.data && `$${item.data.toLocaleString('zh',{minimumFractionDigits:2})}`;
    }
    if(dataIndex === 'totalGP' || dataIndex === 'salesPercentage' || dataIndex === 'preShipDirSubs' || dataIndex === 'preShipAllSubs' ){
      count = item.data && `${count.toLocaleString()}%`;
    }
    return (
      <Col className="chart-item" key={label}>
        <div className="chart-item-panel">
          <p className={count === ''?'total-item-count-blank font-size-24-bold':'total-item-count font-size-24-bold'}>{count}</p>
          <p className='total-item-title font-size-14'>{label}</p>
        </div>
      </Col>
    );
  }

  return (
    <div className="reports-new-total-panel">
        {
          groupData(totalData, 5).map((item, index) => {
            return (
              <Row className="total-grid sia-grid" key={`${tabKey}-${index}`}>
                {
                  item.map(subItem => {
                    return renderGrid(subItem)
                  })
                }
              </Row>)
          })
        }
    </div>
  );
};

TotalPanel.propTypes = {};
TotalPanel.defaultProps = {};