import {
  CONST_SNAPSHOT_DETAILS_COLUMNS,
  CONST_SNAPSHOT_HEADER_COLUMNS,
} from './orderTrailConstants';

export const renderSimpleTableColumns = (data, renderEle) => {
  let columns = [];
  const dataList = data && data[0];
  for (let key in dataList) {
    if (dataList.hasOwnProperty(key)) {
      let params = {};
      if (CONST_SNAPSHOT_DETAILS_COLUMNS.hasOwnProperty(key)) {
        params.title = CONST_SNAPSHOT_DETAILS_COLUMNS[key];
        params.dataIndex = key;
      } else {
        params.title = key;
        params.dataIndex = key;
      }
      if (key === 'orderDetailLineNo') {
        params.render = (value, record) => {
          if (
            record.substituteReferenceLineNbr !== undefined &&
            record.substituteReferenceLineNbr.toString() !== '0'
          ) {
            return renderEle(value);
          }
          return value;
        };
      }
      columns.push(params);
    }
  }
  return columns;
};

export const renderSheetData = data => {
  let columns = [];
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      let params = {};
      if (CONST_SNAPSHOT_HEADER_COLUMNS.hasOwnProperty(key)) {
        params.title = CONST_SNAPSHOT_HEADER_COLUMNS[key];
        params.value = data[key];
      } else {
        params.title = key;
        params.value = data[key];
      }
      columns.push(params);
    }
  }
  return columns;
};

export const renderTableColumns = (columnsObj, tabKey, renderEleIndex, isNestColumn) => {
  let columns = [];
  let specColumn = {};
  columnsObj && columnsObj.forEach((item, index) => {
    let columnDetails = {
      title: item.title,
      dataIndex: item.dataIndex,
      key: item.dataIndex,
      sorter: item.sorter,
    };
    if(item.sortOrder) {
      columnDetails.sortOrder = item.sortOrder
    }
    if(item.filterDropdown) {
      columnDetails.filterDropdown = item.filterDropdown;
    }
    if(item.filterIcon) {
      columnDetails.filterIcon = item.filterIcon;
    }
    if(item.onFilter) {
      columnDetails.onFilter = item.onFilter;
    }
    if(item.onFilterDropdownVisibleChange) {
      columnDetails.onFilterDropdownVisibleChange = item.onFilterDropdownVisibleChange;
    }
    if(item.width) {
      columnDetails.width = item.width;
    }
    if(item.blankWidth) {
      columnDetails.onHeaderCell = column => {
        return { width: item.blankWidth };
      };
    }
    if(item.className) {
      columnDetails.className = item.className;
    }
    if(item.onCell){
      columnDetails.onCell = item.onCell;
    }
    if(item.toggleModal) {
      columnDetails.render = (value, record) => {
        return renderEleIndex['toggleModal'](value, item.dataIndex, record, isNestColumn);
      };
    } else if(item.renderBadge) {
      columnDetails.render = (value, record) => {
        return renderEleIndex['renderBadge'](value, item.dataIndex);
      };
    } else if(item.extendRender) {
      columnDetails.render = (value, record) => {
        return renderEleIndex[index]['extendRender'](value, item.dataIndex, record);
      };
    } else if(item.render) {
      columnDetails.render = item.render;
    }

    if(tabKey === 'Out & Subs') {
      //grouping table columns
      if (index === 0) {
        specColumn.title = 'Original';
        specColumn.children = [];
        specColumn.onHeaderCell = columnDetails => {
          return { width: 1012 };
        };
      } else if(index === 10) {
        specColumn.title = 'Substitue';
        specColumn.children = [];
        specColumn.onHeaderCell = columnDetails => {
          return { width: 708 };
        };
      }
      specColumn.children.push(columnDetails);
      if(index === 9 || index === 16) {
        columns.push(specColumn);
        specColumn = {};
      }
    } else {
      //normal table columns
      columns.push(columnDetails);
    }
  });

  return columns;
};
