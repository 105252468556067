import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { ExitToAppIcon } from '../common/CustomerIcons';
import { useTranslation } from 'react-i18next';

export default function ExportButton({dataSource,exportReports}) {
  const { t } = useTranslation();
  return (
    <div className="reports-new-export-button">
        {/* {tabKey === 'Exceptions' ? <Button onClick={submitExceptions}>Submit</Button> : null} */}
        <Button title="Export" onClick={exportReports}
          disabled={_.isEmpty(dataSource)} 
          className={_.isEmpty(dataSource)?'sia-export-top-but':"sia-export-top-but btn-border-color"}
          icon={<ExitToAppIcon style={{color:(_.isEmpty(dataSource)?"#a1a1a2":'#008CD2')}}/>}
          
        >
          {/* <FontAwesomeIcon icon={faArrowRight} 
          style={{ height: "12px", width: "12px",borderRadius:"2px" }}
          className={_.isEmpty(dataSource)? "font-icon-disabled":"font-icon"}/> */}
          <span style={{ color: (_.isEmpty(dataSource) ? "#a1a1a2" : "#008CD2") }}>{t('export')}</span>
        </Button>
    </div>
  );
};

ExportButton.propTypes = {};
ExportButton.defaultProps = {};
