import React from 'react';
import { Form, Button, Row, Col, Input, Select,DatePicker } from 'antd';
import { CONST_OUTS_UOM_OPTIONS } from '../../common/manageOutsConstants';
import { LeafIcon } from '../common/CustomerIcons';
import _ from 'lodash';
import { convertStorageCode } from './formattedSubsData'
import { SLASH_DATE_FORMAT } from '../../common/constants';
import moment from 'moment';

function ManageOutsProductCard(props) {
  const { product, index, onSubstitute, originalItemUom, isFromOuts, deliveryDate, }= props;
  let initialValues = product;
  
  // let productUOM = initialValues.site_details[0].shipSplitsOnly ==='Y'? 'EA' : (initialValues.site_details[0].minimunSplit===0 ? 'CS' : 'CS-EA');

 
  let csAsoh;
  let eaAsoh;
  if(originalItemUom==='CS'){
    csAsoh=initialValues.asoh;
    eaAsoh=initialValues.secondAsoh;
  }
  else{
    csAsoh=initialValues.secondAsoh;
    eaAsoh=initialValues.asoh;
  }
  const CustomLabel = props => {
    const { suffix, prefix,itemValue } = props;
    let dataValue = itemValue;
    dataValue && suffix && (dataValue = `${dataValue}${suffix}`)
    dataValue && prefix && (dataValue = `${prefix}${dataValue}`)
    return (
      <label className="product-card-custom-form-item-value">{dataValue}</label>
    );
  }
  const onClick = () => {
    onSubstitute(product);
  }
  //moment.js bug? I am not sure...
  /*let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let date = currentDate.getDate();
  if(month < 10) month = '0' + month;
  if(date < 10) date = '0' + date;
  let days = 0;
  //Create moment by year, month and day, instead of directly moment()
  let tempMoment = moment(`${year}${month}${date}`, 'YYYYMMDD')
  if(deliveryDate) {
    days = tempMoment.diff(moment(deliveryDate, 'MM/DD/YYYY'), 'days')
  }
  const startDate = tempMoment.subtract(days, 'day')
  const disablePastDt = current => {
    return current.isBefore(startDate);
  };*/
  const yesterday = moment().subtract(0, 'day');
  const disablePastDt = current => {
    return current.isBefore(yesterday);
  };
  // const uom = getUom(initialValues.site_details[0])
  let itemInfo = `${initialValues.site_details[0].originalItemUom} | ${initialValues.pack}/${initialValues.size} | ${convertStorageCode(initialValues.site_details[0].storageCode)} | ${initialValues.sysco_brand}`
  return (
    <div className="manage-subs-manage-outs-product-card" id={index}>
      <Row gutter={[4, 2]} justify="space-between">
        <Col span={12}>
          <Form.Item className='product-card-value' name={`subItem_${index}`} style={{marginTop:'3px', fontSize:'17px'}}>
            {initialValues.supc}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item colon={false} className="item-no-of-subbed" label="No. of times subbed" name={`noOfSubbed_${index}`}
            style={{fontSize:'17px'}}>
            {initialValues.frequency}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[4, 2]} justify="space-between">
        <Col span={24}>
          <Form.Item className="product-card-static-form-item-value" name="item_description" >
         
            <CustomLabel suffix=' ' itemValue={initialValues.item_description}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 2]} justify="space-between">
        <Col span={15} style={{maxHeight:'22px'}}>
          <div className="flex align-items-center series-tips-content-items-2">
            <Form.Item className="product-card-static-form-item-value">
              {/* <span style={{'whiteSpace':'normal'}} title={itemInfo} >{itemInfo}</span> */}
              <div style={{'whiteSpace':'normal'}}>
                <span title={itemInfo} style={{fontSize:15,fontWeight:700,color:'#0071bc'}}>{initialValues.site_details[0].originalItemUom}</span>
                <span style={{fontSize:15}}> | </span>
                <span title={itemInfo} style={{fontSize:15,fontWeight:700,color:'#0071bc'}}>{initialValues.pack}/{initialValues.size}</span>
                <span> | {convertStorageCode(initialValues.site_details[0].storageCode)} | {initialValues.sysco_brand}</span>
              </div>
            </Form.Item>
            
            {/* <Form.Item className="product-card-static-form-item-value" name="subUom">
              <CustomLabel suffix=' | ' itemValue={initialValues.subUom}/>
            </Form.Item>
            <Form.Item className="product-card-static-form-item-value" name="subPack">
              <CustomLabel suffix='/' itemValue={initialValues.subPack}/>
            </Form.Item>
            <Form.Item  className="product-card-static-form-item-value" name="subSize">
              <CustomLabel suffix=' | ' itemValue={initialValues.subSize}/>
            </Form.Item>
            <Form.Item  className="product-card-static-form-item-value" name="subStorageCode">
              <CustomLabel suffix=' | ' itemValue={initialValues.subStorageCode}/>
            </Form.Item>
            <Form.Item  className="product-card-static-form-item-value" name="subBrand">
              {initialValues.subBrand}
            </Form.Item> */}
          </div>
        </Col>
        
        <Col span={9}>
          <Row style={{marginLeft:initialValues.site_details[0].originalItemUom==='CS-EA'?'-14px':'0px'}}>
            <Col span={6} className="product-card-static-col" style={{marginTop:'-14px'}}>
              <Form.Item colon={false} className="product-card-static-form-item-value-1 aosh" label="ASOH" name="asoh">
              </Form.Item>
            </Col>
            {initialValues.site_details[0].originalItemUom === 'CS' && (
              <Col span={18}>
                <Row>
                  <Col className="product-card-static-col" style={{marginTop:'-14px'}}>
                    <Form.Item className="item-quantity product-card-value" name="asoh">
                      {csAsoh}
                    </Form.Item>
                  </Col>
                  <Col className="product-card-static-col" style={{marginTop:'-16px', marginLeft:'1px'}}>
                    <Form.Item colon={false} className="product-card-static-form-item-value-1 label-left" label="CS">
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            {initialValues.site_details[0].originalItemUom === 'EA' && (
              <Col span={18}>
                <Row>
                  <Col className="product-card-static-col" style={{marginTop:'-14px'}}>
                    <Form.Item className="item-quantity product-card-value" name="asoh">
                      {eaAsoh}
                    </Form.Item>
                  </Col>
                  <Col className="product-card-static-col" style={{marginTop:'-16px', marginLeft:'1px'}}>
                    <Form.Item colon={false} className="product-card-static-form-item-value-1 label-left" label="EA">
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            {initialValues.site_details[0].originalItemUom === 'CS-EA' && (
              <Col span={18}>
                <Row>
                  <Col className="product-card-static-col" style={{marginTop:'-14px'}}>
                    <Form.Item className="item-quantity product-card-value" name="asoh">
                      {csAsoh}
                    </Form.Item>
                  </Col>
                  <Col className="product-card-static-col" style={{marginTop:'-16px', marginLeft:'1px'}}>
                    <Form.Item colon={false} className="product-card-static-form-item-value-1 label-left" label='CS'>
                    </Form.Item>
                  </Col>
                  <Col className="product-card-static-col" style={{marginTop:'-14px',marginLeft:'-5px'}}>
                    <Form.Item className="item-quantity product-card-value" name="asoh">
                      {eaAsoh}
                    </Form.Item>
                  </Col>
                  <Col className="product-card-static-col" style={{marginTop:'-16px', marginLeft:'1px',marginRight:'-10px'}}>
                    <Form.Item colon={false} className="product-card-static-form-item-value-1 label-left" label='EA'>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>  
          <Row style={{justifyContent:'end'}}>
            <Col className="product-card-static-col" style={{marginRight:initialValues.site_details[0].originalItemUom==='CS-EA'?originalItemUom==='EA'?'28px':'72px':'63px',marginLeft:'-18px'}}>
              <div className="flex align-items-center">
                <Form.Item className="item-price" name="price">
                  <CustomLabel 
                  //COT-5367,when the price fetch is failed, add a ‘Market Price’ label in place of the price
                    prefix={initialValues.price === '---'?' ':' $'} 
                    itemValue={initialValues.price === '---'?'Market Price':initialValues.price}/>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Col>
        {/* <Col span={5}>
          <Row>
            <Col className="product-card-static-col">
              <Form.Item className="item-quantity product-card-value" name="splitQuantity">
                {initialValues.splitQuantity}
              </Form.Item>
            </Col>
            <Col className="product-card-static-col">
              <Form.Item colon={false} className="product-card-static-form-item-value-1 label-left" label="EA">
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="product-card-static-col">
              <div className="flex align-items-center">
                <Form.Item className="item-price" name="splitPrice">
                  <CustomLabel prefix=' $' itemValue={initialValues.splitPrice}/>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Row gutter={[8, 2]}>
      <Col span={6}>
        { initialValues.kosherItem==='Y' && (
               
              <div className='kosher-flag'>
              Kosher
            <span>K</span>
          </div>
            
            )}
        </Col> 
        <Col push={3}  style={{height:"36px"}} >
          {initialValues.site_details[0].is_sysco_brand && (
            <LeafIcon />
          )}
        </Col>
        
      </Row>
      {/* {isFromOuts && ( */}
        <div className="flex" style={{marginBottom:'10px'}}>
          <Row gutter={[16, 1]} justify="space-between">
            <Col span={5}>
              <Form.Item name={`subRatio_${index}`}>
                <Input placeholder="Sub Ratio" defaultValue='1.00'></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={`endDate_${index}`}>
                <DatePicker placeholder="End Date" format={SLASH_DATE_FORMAT} disabledDate={disablePastDt} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name={`uom_${index}`}>
                <Select placeholder="UOM" disabled={true} defaultValue={originalItemUom}>
                  {CONST_OUTS_UOM_OPTIONS.map(item => {
                    return (
                      <Select.Option key={item.key} value={item.key}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <Button type="primary" onClick={onClick} className="primary-btn">
                  <span style={{fontSize:'12px',width:'60px'}}>Substitute</span>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      {/* )} */}
      {/* {!isFromOuts && (
        <Row>
          <Col offset={18} span={6}>
            <Form.Item>
              <Button type="primary" onClick={onClick} className="primary-btn">
                Substitute
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )} */}
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return _.isEqual(prevProps.product, nextProps.product)
};

ManageOutsProductCard.propTypes = {};
ManageOutsProductCard.defaultProps = {};
export default React.memo(ManageOutsProductCard, areEqual)
