import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_BEGIN,
  ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_SUCCESS,
  ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_FAILURE,
  ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_DISMISS_ERROR,
  ORDER_TRAIL_ERROR_CONTACT_ADMIN
} from './constants';

export function fetchOrderTrailData(formData = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios({
        method: 'get',
        url: `${API_REQUEST_URL}/getOrderTrailOrderData`,
        params: formData,
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        res => {
          dispatch({
            type: ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_SUCCESS,
            data: res.data.rows,
          });
          resolve(res.data.rows);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          const errorMsg = err && err.response ? err.response.data.message : ORDER_TRAIL_ERROR_CONTACT_ADMIN;
          dispatch({
            type: ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOrderTrailDataError() {
  return {
    type: ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_DISMISS_ERROR,
  };
}

export function useFetchOrderTrailData() {
  const dispatch = useDispatch();

  const { orderTrailData, fetchOrderTrailDataPending, fetchOrderTrailDataError } = useSelector(
    state => ({
      orderTrailData: state.orderTrail.orderTrailData,
      fetchOrderTrailDataPending: state.orderTrail.fetchOrderTrailDataPending,
      fetchOrderTrailDataError: state.orderTrail.fetchOrderTrailDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchOrderTrailData(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOrderTrailDataError());
  }, [dispatch]);

  return {
    orderTrailData,
    fetchOrderTrailData: boundAction,
    fetchOrderTrailDataPending,
    fetchOrderTrailDataError,
    dismissFetchOrderTrailDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOrderTrailDataPending: true,
        fetchOrderTrailDataError: null,
      };

    case ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        orderTrailData: action.data,
        fetchOrderTrailDataPending: false,
        fetchOrderTrailDataError: null,
      };

    case ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOrderTrailDataPending: false,
        fetchOrderTrailDataError: action.data.error,
      };

    case ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOrderTrailDataError: null,
      };

    default:
      return state;
  }
}
