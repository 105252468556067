import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GROUP_ID_PREFIX } from '../../common/manageGroupsConstants';
import { useFetchValidateGroup } from './redux/hooks';
import OnboardCommonPanel from './OnboardCommonPanel';
import { transferFromFormToResult, transferFromResultToForm } from './formattedAccountsData';
import { TIME_HOUR_MINUES } from '../../common/constants';
import moment from 'moment';
import { validateGroupIdRegExp } from './formattedSubsData';

export default function CustomerGroupOperateModal(props) {
  const {
    conceptData,
    fetchConceptDataPending,
    orgDropdownList,
    updateCustomerGroupPending,
    fetchValidateGroupPending,
    userId,
    userName,
  } = useSelector(state => ({
    conceptData: state.manageSubs.conceptData,
    fetchConceptDataPending: state.manageSubs.fetchConceptDataPending,
    orgDropdownList: state.manageSubs.orgDropdownList,
    updateCustomerGroupPending: state.manageSubs.updateCustomerGroupPending,
    fetchValidateGroupPending: state.manageSubs.fetchValidateGroupPending,
    userId: state.home.auth.user.userId,
    userName: state.home.auth.user.userName,
  }));
  const { title, isModalVisible, cancelText, okText, width, onToggleModal,
     onDelete, initValueData, onCancel, onOk, siteData,loading,
  } = props;
  const [form] = Form.useForm();
  const [exists, setExists] = useState(false);
  const { fetchValidateGroup } = useFetchValidateGroup();
  const onModalClose = () => {
    // message.destroy(1);
    onToggleModal();
  };
  const onReset = () => {
    form.resetFields();
    setExists(false);
    // resetConceptData();
    onCancel && onCancel();
  };
  const handleItemChange = (value, label, key, listFieldName) => {
    let objFormValues = form.getFieldsValue();
    let objCurr = {};
    switch (label) {
      case 'sites':
        const siteObj = siteData.find(item => item.opcoNo === value);
        objCurr = {
          [label]: value,
          // siteDesc: siteObj ? siteObj.opcoName : '',
          groupId: '',
          groupDescription: '',

        };
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      case 'groupId':
        // if ((value === undefined || value.length < 4)) {
        // resetConceptData();
        // }
        objCurr = {
          [label]: value,
          groupId: '',
          groupDescription: '',
        };
        form.setFieldsValue({ ...objFormValues, ...objCurr });
        return;
      default:
        return;
    }
  }
  const initCreatedTimestampFields = {
    userName: userName,
    userId: userId,
    // createTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
  };
  const onFinish = values => {
    let validator = true;
    let formData = null;
    form.validateFields().then(data => {
      validator = true;
      formData = data;
    }).catch(errorInfo => {
      formData = errorInfo.values;
      //ANTD 4.x BUG ISSUE: IT WILL ALWAYS GOTO CATCH EVEN NO ERRORS
      if (errorInfo.errorFields.length > 0) {
        validator = false;
        form.setFields(errorInfo.errorFields);
      }
    }).finally(() => {
      if (validator && formData) {
        formData = transferFromFormToResult(formData);
        // if(isCST) formData.managedBy = 'CST'
        formData = { ...formData, ...initCreatedTimestampFields, groupId: GROUP_ID_PREFIX.concat(formData.groupId).toUpperCase() };
        onOk(formData, onToggleModal, exists);
      }
    })
  }
  const handleGroupId = (value, label) => {
    //appendHash
    // if(value.indexOf('##') < 0){
    //   form.setFieldsValue({[label]:'##'+value});
    // }
  }
  const handleGroupIdValidator = (rule, value, callback) => {
    form.validateFields(['sites']).then(() => {
      const isGroupId = validateGroupIdRegExp(GROUP_ID_PREFIX.concat(value));
      if (isGroupId) {
        const { sites } = form.getFieldsValue();
        fetchValidateGroup({ sites: sites, groupId: GROUP_ID_PREFIX.concat(value).toUpperCase()}).then(data => {
          if (!data.success) {
            setExists(false);    //if set true here, will not disable save button and other attributes when initinalize popup
            callback(data.message);
          } else {
            setExists(true);
            callback();
          }
        }).catch(err => {
          callback(err);
        })
      } else if (value && value.length < 3) {
        callback(`The length of Group ID should be greater than 3!`);
      } else {
        callback(`Invalid Group ID!`);
      }
    }).catch(errorInfo => {
      //ANTD 4.x BUG ISSUE: IT WILL ALWAYS GOTO CATCH EVEN NO ERRORS
      if (errorInfo.errorFields.length > 0) {
        form.setFields(errorInfo.errorFields);
      }
      callback();
    })
  }
  const handleGroupDesValidator = (rule, value, callback) => {
    if (value && value.trim().length === 0) {
      callback(`Invalid Group Description!`)
    } else {
      callback();
    }
  }
  const labelProps = {
    labelCol: { span: 12 },
    labelAlign: 'left',
  };
  const formattedRecord = initValueData && transferFromResultToForm(initValueData);
  // const isCST = (initValueData && initValueData.managedBy === 'CST') ? true : false;
  const initCreatedValues = {
    managedBy: true,
    maintenanceFlag: true,
    approvalRequiredFlag: false,
    catalogFlag: true,
    startOfDay: false,
    nationalIdCutoffTime: moment("23:30", TIME_HOUR_MINUES),
    ...initCreatedTimestampFields,
  };
  const initialValues = formattedRecord || initCreatedValues;
  return (
    <Modal
      className="manage-subs-customer-group-operate-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onModalClose}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={false}
      width={width || 520}
    >
       <Spin
        spinning={
          loading ||
          updateCustomerGroupPending ||
          fetchValidateGroupPending
        }
      >
      <Form
        form={form}
        name="manage-subs-operate-form"
        className="operate-form"
        autoComplete="off"
        // layout="vertical"
        initialValues={initialValues}
      >
        {title === 'Create Customer Group' && (
          <>
            <Row gutter={4} justify="space-between" className="account-header-row">
              <Col span={10}>
                <Form.Item
                  name="sites"
                  // label="Site ID"
                  rules={[{ required: true, message: 'Please select Site!' }]}
                ><Select mode="multiple" allowClear
                  maxTagCount={3}
                  maxTagTextLength={3} showSearch onChange={value => handleItemChange(value, 'sites')}
                  placeholder="Site">
                    {orgDropdownList && orgDropdownList.sites &&
                      orgDropdownList.sites.map(item => {
                        const siteNbr = item.site_nbr;
                        const siteNbrName = item.site_desc;
                        return (
                          <Select.Option key={siteNbr} value={siteNbr}>
                            {`${siteNbr} - ${siteNbrName}`}
                          </Select.Option>
                        );
                      })}
                  </Select></Form.Item></Col><Col span={8}>
                <Form.Item
                  name="groupId"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      validateTrigger: 'onBlur',
                      validator: (rule, value, callback) =>
                        handleGroupIdValidator(rule, value, callback),
                    },
                    {
                      required: true,
                      message: 'Please input Group ID!',
                    },
                  ]}

                >
                  <Input className='input-uppercase'
                    placeholder="Group ID" prefix="##" maxLength={8}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row><Col span={24}>
              <Form.Item
                name="description"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    validateTrigger: 'onBlur',
                    validator: (rule, value, callback) =>
                      handleGroupDesValidator(rule, value, callback),
                  },
                  {
                    required: true,
                    message: 'Please input Group Description!',
                  },
                ]}
              ><Input placeholder="Group Description" maxLength={50}></Input></Form.Item>
            </Col>
            </Row>
            <OnboardCommonPanel form={form} exists={exists} title='Create Customer Group'></OnboardCommonPanel>
          {/* <Row>
          <Col span={12}>
          <Form.Item {...labelProps} label="Include/Exclude" colon={false}>
            <div className="toggle-desc">Yes</div>
            <Form.Item name="maintenanceFlag" valuePropName="checked">
              <Switch
                className="subs-switch"
                size="small"
                defaultChecked
              // disabled={title == 'Add Account' && !exists ? true : false}
              />
            </Form.Item>
            <div className="toggle-desc">No</div>
          </Form.Item>
        </Col>
        </Row> */}
        </>
        )}
        <div className="subs-footer flex justify-end">
          {title === "Edit Account" &&
            <Button type="link" onClick={() => onDelete(initValueData, onToggleModal)} className="default-btn">
              Delete
            </Button>}
          <Button type="link" onClick={onReset} className="default-btn" style={{ marginLeft: "2px" }}>
            {cancelText}
          </Button>
          <Button
             disabled={title == 'Create Customer Group' && !exists ? true : false}
            onClick={onFinish} type="primary" className='primary-btn' style={{ marginLeft: "2px" }}>
            {okText}
          </Button>
        </div>
      </Form>
      </Spin>
    </Modal>
  );
};

CustomerGroupOperateModal.propTypes = {};
CustomerGroupOperateModal.defaultProps = {};
