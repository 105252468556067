import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  DASHBOARD_FETCH_CONCEPT_IDS_DATA_BEGIN,
  DASHBOARD_FETCH_CONCEPT_IDS_DATA_SUCCESS,
  DASHBOARD_FETCH_CONCEPT_IDS_DATA_FAILURE,
  DASHBOARD_FETCH_CONCEPT_IDS_DATA_DISMISS_ERROR,
  ERROR_CONTACT_ADMIN
} from './constants';
import { validateGroupIdPrefix } from '../../manage-subs/formattedSubsData';
import { uniqueGroupIdList } from '../../manage-subs/getOriginalSelectedList';

export function fetchConceptIdsData(nationalAccount, inputVal,sites,isFrom) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_CONCEPT_IDS_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let doRequest = null;
      if(validateGroupIdPrefix(nationalAccount)){
        let newArgs = { //COT-9296:BOS 3.0 - UI - Feedback of audit trail, if group has been deleted, still can be searched in audit trail screen, so need display deleted group in concept id/gruop dropdown, if isFrom is auditTrail,  similarGroupId interface will include the deleted group
          key: nationalAccount,
          sites: sites,
        };
        if (isFrom) {
          newArgs = { ...newArgs, isFrom }
        }
        doRequest = axios.post(`${API_REQUEST_URL}/similarGroupId`, newArgs,{
          headers: {
            Authorization: token,
          },
        });
      }else{
        doRequest = axios.get(`${API_REQUEST_URL}/fetchConceptIdsData?nationalAccount=${nationalAccount}`, {
          headers: {
            'Authorization': token,
          }
        });
      }
      doRequest.then(
        (res) => {
          let ret = [];
          if(validateGroupIdPrefix(nationalAccount)){
            ret = res.data.data;
            ret = ret.filter(item => item.nationalId.toUpperCase().includes(inputVal))
            ret = uniqueGroupIdList(ret);
            ret = ret.map(item => item.nationalId);//format data to array
          }else{
            ret = res.data;
            if(inputVal) {
              ret = ret.filter(item => item.toUpperCase().includes(inputVal))
            }
          }
          dispatch({
            type: DASHBOARD_FETCH_CONCEPT_IDS_DATA_SUCCESS,
            data: ret,
          });
          resolve(ret);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : ERROR_CONTACT_ADMIN;
          dispatch({
            type: DASHBOARD_FETCH_CONCEPT_IDS_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchConceptIdsDataError() {
  return {
    type: DASHBOARD_FETCH_CONCEPT_IDS_DATA_DISMISS_ERROR,
  };
}

export function useFetchConceptIdsData() {
  const dispatch = useDispatch();

  const { conceptData, fetchConceptIdsDataPending, fetchConceptIdsDataError } = useSelector(
    state => ({
      conceptData: state.dashboard.conceptData,
      fetchConceptIdsDataPending: state.dashboard.fetchConceptIdsDataPending,
      fetchConceptIdsDataError: state.dashboard.fetchConceptIdsDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchConceptIdsData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchConceptIdsDataError());
  }, [dispatch]);

  return {
    conceptData,
    fetchConceptIdsData: boundAction,
    fetchConceptIdsDataPending,
    fetchConceptIdsDataError,
    dismissFetchConceptIdsDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_CONCEPT_IDS_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchConceptIdsDataPending: true,
        fetchConceptIdsDataError: null,
      };

    case DASHBOARD_FETCH_CONCEPT_IDS_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        conceptData: action.data,
        fetchConceptIdsDataPending: false,
        fetchConceptIdsDataError: null,
      };

    case DASHBOARD_FETCH_CONCEPT_IDS_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchConceptIdsDataPending: false,
        fetchConceptIdsDataError: action.data.error,
      };

    case DASHBOARD_FETCH_CONCEPT_IDS_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchConceptIdsDataError: null,
      };

    default:
      return state;
  }
}
