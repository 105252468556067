import { Button, Spin, Upload, message, notification,Modal } from 'antd';
import React,{ useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchFiltersPanel from './SearchFiltersPanel';
import { CONST_SITE_LIST } from '../../common/orderTrailConstants';
import CustomerGroupOperateModal from './CustomerGroupOperateModal';
import AddCustomerOperateModal from './AddCustomerOperateModal';
import { faFileExport, faFileImport,faTrash, faPen,faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonTable } from '../common';
import { renderTableColumns } from '../../common/renderColumns';
import { useFetchCustomerData, useFetchCustomerGroupData,useResetCustomerGroupData, useUpdateCustomerGroup,useUploadGroups,useExportGroupsData,useAddCustomers, useDeleteCustomerGroup, useRemoveCustomers } from './redux/hooks';
import { CONST_PAGINATION_TEN,DATE_TIME_ONE_FORMAT } from '../../common/constants';
import { datadogRum } from '@datadog/browser-rum';
import { CONST_CUSTOMER_GROUP_TABLE_COLUMNS } from '../../common/manageGroupsConstants';
import moment from 'moment';
import BULK_TEMPLATE_FILE from '../../assets/ManageGroups-ImportTemplate.xlsx';
import { UploadIcon,DownloadIcon } from '../common/CustomerIcons';
export default function CustomerGroup() {
  const {
    customerGroupData,
    fetchCustomerGroupDataPending,
    searchConditionData,
    uploadGroupsPending,
    exportGroupsDataPending,
    userId,
    userName,
  } = useSelector(state => ({
    customerGroupData: state.manageSubs.customerGroupData,
    fetchCustomerGroupDataPending: state.manageSubs.fetchCustomerGroupDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    uploadGroupsPending: state.manageSubs.uploadGroupsPending,
    exportGroupsDataPending: state.manageSubs.exportGroupsDataPending,
    userId: state.home.auth.user.userId,
    userName: state.home.auth.user.userName,
  }));
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [isAddCustomerModalVisible, setAddCustomerModalVisible] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [resetActivePagination, setResetActivePagination] = useState(false);
  const { fetchCustomerGroupData } = useFetchCustomerGroupData();
  const { resetCustomerGroupData } = useResetCustomerGroupData();
  const { updateCustomerGroup } = useUpdateCustomerGroup();
  const { fetchCustomerData } = useFetchCustomerData();
  const { uploadGroups } = useUploadGroups();
  const { exportGroupsData } = useExportGroupsData();
  const { addCustomers } = useAddCustomers();
  const { deleteCustomerGroup } = useDeleteCustomerGroup();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [uploadFileList, setUploadFileList] = useState([]);
  const { removeCustomers } = useRemoveCustomers();
  const closeModal = ()=>{
    setAddCustomerModalVisible(false);
  }
  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  };
  const onUploadGroups = ()=>{
      if(_.isEmpty(uploadFileList)) {
        message.destroy();
        message.info('Please select a file.');
        return;
      }
      let formData = new FormData();
      uploadFileList.forEach(file => {
        formData.append("file", file);
      });
      uploadGroups(formData).then((response) => {
        setUploadFileList([]);
        message.destroy();
        message.info(JSON.stringify(response.data));
        //if successfully need to auto do search in search page and auto close popup
        onToggleModal(setUploadModalVisible);
        setResetActivePagination(!resetActivePagination);
      },
      (err) => {
        let errMsg = err && err.response ? err.response.data.error : 'There is an error. Please refresh page or contact administrator.'
        message.destroy();
        message.error('Uploaded failed: ' + errMsg);
      });
  }
  const onUpdateCustomerGroup= (args, onCloseModal, exists)=>{
    let mssg = `${exists ? 'Created' : 'Update'} Customer Group Successfully.`;
    updateCustomerGroup(args).then(data => {
      message.success(mssg);
      onCloseModal();
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      message.error(`Update Customer Group Failed, please try again.`);
    });
  }
  const handleAddCustomer = (record) => {
    onToggleModal(setAddCustomerModalVisible);
    setEditableData(record);
    const params = {
      "customerId": "",
      "groupId": record.groupId,
      "site": record.site
    }
    fetchCustomerData(params);
  }
  const onAddCustomerToGroup=(args,onCloseModal)=>{
    addCustomers(args).then(data => {
      //COT-8974:BOS 2.0 - Custom Groups/Sub Repositories Reset Button Functionality, if need remove customer from group, just need close modal,otherwise onCloseModal called twice and will open modal
      // onCloseModal();
      closeModal();
      message.info(`Add Customer Successfully.`)
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      message.error(`Add Customer To Group Failed, please try again.`);
    });
  }
  const removeCustomerFromGroup = (agrs) => {
    removeCustomers(agrs).then(data => {
      closeModal();//COT-8974:BOS 2.0 - Custom Groups/Sub Repositories Reset Button Functionality, if need add customer to group, just need close modal,otherwise onCloseModal called twice and will open modal
      message.info(`Remove Customer Successfully.`);
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      message.error(`Remove Customer Failed, please try again.`);
    });
  }
  const deleteGroup = (args, onCloseModal) => {
      deleteCustomerGroup(args).then(data => {
      message.success(`Delete customer group Successfully.`);
      onCloseModal();
      setResetActivePagination(!resetActivePagination);
    }).catch(err => {
      message.error(`Delete customer group Failed, please try again.`);
    });
  }
  const renderAddCustomerModal = (
    <AddCustomerOperateModal
      title="Add Customer"
      isModalVisible={isAddCustomerModalVisible}
      footer={false}
      width={1200}
      onToggleModal={() => {onToggleModal(setAddCustomerModalVisible);}}
      cancelText="Reset"
      okText="Save"
      onOk={onAddCustomerToGroup}
      // onDelete={onDeleteNeverSubs}
      initValueData={editableData}
      // loading={updateSubstitutionsDataPending }
      idle={()=>{notification.warning({
        message: `Idle Time Surpassed 5 minutes`,
        placement:'top',
        duration:0,
      })}}
      deleteGroup={deleteGroup}
      removeCustomerFromGroup={removeCustomerFromGroup}
    />
  );
  const renderAddModal = (
    <CustomerGroupOperateModal
      title="Create Customer Group"
      isModalVisible={addModalVisible}
      width={780}
      siteData={CONST_SITE_LIST}
      onToggleModal={() => onToggleModal(setAddModalVisible)}
      cancelText="Reset"
      okText="Save"
      onOk={onUpdateCustomerGroup}
      // loading={updateCustomerGroupPending}
    />
  );
  const uploadProps = {
    multiple: false,
    onRemove: file => {
      setUploadFileList(prevState => {
        const index = prevState.indexOf(file);
        const fileList = prevState.slice();
        fileList.splice(index, 1);
        return fileList;
      });
    },
    beforeUpload: file => {
      const extensionName = file.name.split('.').pop().toLowerCase();
      if(extensionName !== 'xlsx') {
        message.error(`Please upload the latest xlsx template file.`);
        return Upload.LIST_IGNORE;
      }
      setUploadFileList(prevState => {
        return [file];
      });
      return false;
    },
    fileList: uploadFileList,
  };
  const renderUploadDownloadModal = (
    <Modal
      className="manage-subs-maintain-accounts-operate-modal"
      title={"Upload File"}
      visible={uploadModalVisible}
      onCancel={() => {onToggleModal(setUploadModalVisible);setUploadFileList([])}}
      okText="Upload"
      destroyOnClose={true}
      maskClosable={false}
      onOk={onUploadGroups}
    >
      <Spin spinning={uploadGroupsPending} tip="Uploding...">
        <div>
          <Button className="subs-upload-btn flex align-items-center" icon={<DownloadIcon style={{ fontSize: '36px' }} />} >
            <a href={BULK_TEMPLATE_FILE} download='ManageGroups-ImportTemplate.xlsx'>Download the template</a>
          </Button>
        </div>
        <div>
          <Upload {...uploadProps}>
            <Button className="subs-upload-btn flex align-items-center" icon={<UploadIcon style={{ fontSize: '36px' }} />} >
            Upload the file
            </Button>
          </Upload>
        </div>
      </Spin>
    </Modal>
  );


  const onExportCustomerGroup = () => {
    const timing = Date.now();
    const params = {...searchConditionData}
    exportGroupsData(params);
    datadogRum.onReady(function () {
      datadogRum.addTiming(`Download groups`, timing);
    });
  }

  const handleEditCustomerGroup = () => {

  }
  const handleDeleteCustomerGroup = () => {

  }
  const renderColumnsElement = [
    { //render siteID
      extendRender: (value, label, record) => {
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.siteDesc}</div>
          </>
        )
      }
    },
    {},//groupId placeholder
    {
      extendRender: (value, label, record) => {
        return (
          <>
            {/* <div className="col-title">{value}</div>
            <div className="col-desc">{record.createdById}</div> */}
          {! _.isEmpty(value) && `${value} / ${record.createdById}`}
          </>
        )
      }
    },//groupDesc placeholder
    {},//numberOfCustomers placeholder
    { //render modifiedBy
      extendRender: (value, label, record) => {
        const modifiedDate = record.modifiedTimestamp && moment(record.modifiedTimestamp).format(DATE_TIME_ONE_FORMAT);
        return value && (
          <>
            <div className="col-title">{record.modifiedByName}</div>
            <div className="col-desc">{`${record.modifiedById} ${modifiedDate}`}</div>
          </>
        )
      }
    },
  ];
  let customerGroupColumns = renderTableColumns(CONST_CUSTOMER_GROUP_TABLE_COLUMNS, null, renderColumnsElement);
  customerGroupColumns.push({
    title: 'Actions',
    dataIndex: '',
    width: 76,
    render: (value, record) => {
      return (
        <div className="flex parent-actions">
          <Button title="Add Customer" style={{width:'24px'}} disable="false" type="link" onClick={()=>{handleAddCustomer(record)}}>
            <FontAwesomeIcon icon={faPlusSquare} color="#6b6b6c" style={{ height: "15px", width: "15px" }}/>
          </Button>
          {/* <Button title="Edit Customer Group" style={{paddingLeft:'10px',width:'24px'}} disable="false" type="link"  onClick={()=>{handleEditCustomerGroup(record)}}>
            <FontAwesomeIcon icon={faPen} color="#6b6b6c" style={{ height: "15px", width: "15px" }}/> 
          </Button> */}
          {/* <Button title="Delete Customer Group" style={{paddingLeft:'10px',width:'24px'}} disable="false" type="link" onClick={()=>{handleDeleteCustomerGroup(record)}}>
            <FontAwesomeIcon icon={faTrash} color="#6b6b6c" style={{ height: "15px", width: "15px" ,paddingRight:'20px'}}/> 
          </Button> */}
        </div>
      );
    }
  });
  return (
    <div className="manage-subs-customer-group">
      <Spin spinning={exportGroupsDataPending}>
        <div className="chart-title font-size-16-bold"  style={{font: "Myriad Bold"}}>Customer Group Maintenance</div>
        <SearchFiltersPanel />
        <div className="flex align-item-center justify-end" style={{marginBottom:"20px", marginTop:"15px"}}>
          <Button className="add-btn" onClick={() => onToggleModal(setAddModalVisible)} >
            Create Customer Group
          </Button>
          <span style={{fontSize:"25px", marginTop:"-7px", fontWeight:"lighter", transform:"scale(.5, 1)", color:"#c2c2c3"}}>&ensp;|</span>
        <Button type="link" title="Import" onClick={() => onToggleModal(setUploadModalVisible)}>
          <FontAwesomeIcon icon={faFileImport} color="#6b6b6c" style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"2px", color:"#6b6b6c"}}>Import</p>
        </Button>
        <Button type="link" title="Export" disabled={customerGroupData === null} onClick={onExportCustomerGroup}>
          <FontAwesomeIcon icon={faFileExport} color={customerGroupData?"#6b6b6c":"#a1a1a2"} style={{ height: "20px", width: "20px" }}/>
          <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"-3px", color:(customerGroupData?"#6b6b6c":"#a1a1a2")}}>Export</p>
        </Button>
        </div>
        <CommonTable
          rowKey={record => `${record.site}-${record.groupId}`}
          columns={customerGroupColumns}
          tableClassName={customerGroupData ? "outs-report-table" : ""}
          datasource={customerGroupData}
          loadingData={fetchCustomerGroupDataPending}
          paginationStyle={{textAlign: "right", margin: '16px 0px'}}
          triggerConditions={searchConditionData}
          onFecthData={fetchCustomerGroupData}
          onResetStore={resetCustomerGroupData}
          scrollStyle={{ x: 'max-content', y: 300 }}
          paginationSetting={CONST_PAGINATION_TEN}
          resetActivePagination={resetActivePagination}
          />
      {addModalVisible && renderAddModal}
      {isAddCustomerModalVisible && renderAddCustomerModal}
      {uploadModalVisible && renderUploadDownloadModal}
        </Spin>
    </div>
  );
};

