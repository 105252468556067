import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_UPLOAD_ACCOUNTS_BEGIN,
  MANAGE_SUBS_UPLOAD_ACCOUNTS_SUCCESS,
  MANAGE_SUBS_UPLOAD_ACCOUNTS_FAILURE,
  MANAGE_SUBS_UPLOAD_ACCOUNTS_DISMISS_ERROR,
} from './constants';
import { EXPORT_BOS_API_REQUEST_URL } from '../../../common/apiConfig';
import { DATE_TIME_THREE_FORMAT } from '../../../common/constants';
import moment from 'moment';
export function uploadAccounts(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_UPLOAD_ACCOUNTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const user = getState().home.auth.user;
      const params = {
        userId: user.userId,
        emailId: user.email,
        timestamp: moment().format(DATE_TIME_THREE_FORMAT).replaceAll(':','').replaceAll(/\s*/g,''),
        userName: user.userName,   //COT-9296:BOS 3.0 - UI - Feedback of audit trail
      }
      const doRequest = axios({
        url: `${EXPORT_BOS_API_REQUEST_URL}/accountsBulkUpload`,
        method: 'post',
        params,
        data: args,
        headers: { "content-type": "multipart/form-data" },
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_UPLOAD_ACCOUNTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MANAGE_SUBS_UPLOAD_ACCOUNTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUploadAccountsError() {
  return {
    type: MANAGE_SUBS_UPLOAD_ACCOUNTS_DISMISS_ERROR,
  };
}

export function useUploadAccounts() {
  const dispatch = useDispatch();

  const { uploadAccountsPending, uploadAccountsError } = useSelector(
    state => ({
      uploadAccountsPending: state.manageSubs.uploadAccountsPending,
      uploadAccountsError: state.manageSubs.uploadAccountsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(uploadAccounts(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUploadAccountsError());
  }, [dispatch]);

  return {
    uploadAccounts: boundAction,
    uploadAccountsPending,
    uploadAccountsError,
    dismissUploadAccountsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_UPLOAD_ACCOUNTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        uploadAccountsPending: true,
        uploadAccountsError: null,
      };

    case MANAGE_SUBS_UPLOAD_ACCOUNTS_SUCCESS:
      // The request is success
      return {
        ...state,
        uploadAccountsPending: false,
        uploadAccountsError: null,
      };

    case MANAGE_SUBS_UPLOAD_ACCOUNTS_FAILURE:
      // The request is failed
      return {
        ...state,
        uploadAccountsPending: false,
        uploadAccountsError: action.data.error,
      };

    case MANAGE_SUBS_UPLOAD_ACCOUNTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        uploadAccountsError: null,
      };

    default:
      return state;
  }
}
