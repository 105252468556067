// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { OrderChannelType,OrderAccountType,Order,OrdersDetail } from '../orders';
import { ReportsLayoutContent } from '../reports-new';
import {
} from './';

export default {
  path: 'future-orders',
  component: ReportsLayoutContent,
  // childRoutes: [
  //   {
  //     path: '/',
  //     component: OrderChannelType,
  //     isIndex: true,
  //   },
  //   {
  //     path: 'account-type',
  //     component: OrderAccountType,
  //   },
  //   {
  //     path: 'order',
  //     component: Order,
  //   },
  //   {
  //     path: 'business-event-log',
  //     component: BusinessEventLog,
  //   },
  // ],
  childRoutes: [
    {
      path: '/',
      component: OrderChannelType,
      isIndex: true,
    },
    {
      path: 'customer',
      component: OrderAccountType,
    },
    {
      path: 'order',
      component: Order,
    },
    {
      path: 'order-detail',
      component: OrdersDetail,
    },
  ],
};
