import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortObject } from '../../../common/sorterFunc';
import {
  MANAGE_SUBS_SAVE_ORDER_DETAIL_DATA,
} from './constants';

export function saveOrderDetailData(data) {
  let orderDetailData = data.orderDetail;
  if (orderDetailData && data.sort && data.sortedBy) {
    orderDetailData = sortObject(data.orderDetail, data.sortedBy, data.sort,"orderLine");
  }
  return {
    type: MANAGE_SUBS_SAVE_ORDER_DETAIL_DATA,
    data: orderDetailData,
  };
}

export function useSaveOrderDetailData() {
  const dispatch = useDispatch();
  const orderDetail = useSelector(state => state.manageSubs.orderDetail);
  const boundAction = useCallback((...params) => dispatch(saveOrderDetailData(...params)), [dispatch]);
  return { orderDetail,saveOrderDetailData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_SAVE_ORDER_DETAIL_DATA:
      return {
        ...state,
        orderDetail: action.data,
      };

    default:
      return state;
  }
}
