// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.

import initialState from './initialState';

const reducers = [
];

export default function reducer(state = initialState, action) {
  let newState;
  newState=state;
  return reducers.reduce((s, r) => r(s, action), newState);
}
