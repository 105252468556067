export const getDate = ()=>{
    var d = new Date(new Date().getTime());
    var day = d.getDate();
    // var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    var dayName = weekday[d.getDay()];
    var month = new Array();
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    var monthName = month[d.getMonth()];
    return dayName + ", " + monthName + " " + day + ", " + year;
}


export const getFrDate = ()=>{
    var d = new Date(new Date().getTime());
    var day = d.getDate();
    // var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var weekday = new Array(7);
    weekday[0] = "Dimanche";
    weekday[1] = "Lundi";
    weekday[2] = "Mardi";
    weekday[3] = "Mercredi";
    weekday[4] = "Jeudi";
    weekday[5] = "Vendredi";
    weekday[6] = "Samedi";
    var dayName = weekday[d.getDay()];
    var month = new Array();
    month[0] = "Janvier";
    month[1] = "Février";
    month[2] = "Mars";
    month[3] = "Avril";
    month[4] = "Peut";
    month[5] = "Juin";
    month[6] = "Juillet";
    month[7] = "Août";
    month[8] = "Septembre";
    month[9] = "Octobre";
    month[10] = "Novembre";
    month[11] = "Décembre";
    var monthName = month[d.getMonth()];
    return dayName + ", " + monthName + " " + day + ", " + year;
}