import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  MANAGE_SUBS_FETCH_CUSTOMER_DATA_BEGIN,
  MANAGE_SUBS_FETCH_CUSTOMER_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_CUSTOMER_DATA_FAILURE,
  MANAGE_SUBS_FETCH_CUSTOMER_DATA_DISMISS_ERROR,
} from './constants';

export function fetchCustomerData(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_CUSTOMER_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      // if(args.sortedBy === undefined || args.sortedBy === null) {
      //   conditions = {
      //     ...conditions,
      //     sort: "descend",
      //     sortedBy: "modifiedTimestamp",
      //   }
      // }
      const doRequest = axios.post(`${API_REQUEST_URL}/searchCustomer`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_FETCH_CUSTOMER_DATA_SUCCESS,
            data: res.data.customerVOS,
          });
          resolve(res.data.customerVOS);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MANAGE_SUBS_FETCH_CUSTOMER_DATA_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchCustomerDataError() {
  return {
    type: MANAGE_SUBS_FETCH_CUSTOMER_DATA_DISMISS_ERROR,
  };
}

export function useFetchCustomerData() {
  const dispatch = useDispatch();
  const {customerData,fetchCustomerDataPending, fetchCustomerDataError } = useSelector(
    state => ({
      customerData: state.manageSubs.customerData,
      fetchCustomerDataPending: state.manageSubs.fetchCustomerDataPending,
      fetchCustomerDataError: state.manageSubs.fetchCustomerDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchCustomerData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchCustomerDataError());
  }, [dispatch]);

  return {
    customerData,
    fetchCustomerData: boundAction,
    fetchCustomerDataPending,
    fetchCustomerDataError,
    dismissFetchCustomerDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_CUSTOMER_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchCustomerDataPending: true,
        fetchCustomerDataError: null,
      };

    case MANAGE_SUBS_FETCH_CUSTOMER_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        customerData: action.data,
        fetchCustomerDataPending: false,
        fetchCustomerDataError: null,
      };

    case MANAGE_SUBS_FETCH_CUSTOMER_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchCustomerDataPending: false,
        fetchCustomerDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_CUSTOMER_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchCustomerDataError: null,
      };

    default:
      return state;
  }
}
