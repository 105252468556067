import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  DASHBOARD_FETCH_ORG_INFO_BEGIN,
  DASHBOARD_FETCH_ORG_INFO_SUCCESS,
  DASHBOARD_FETCH_ORG_INFO_FAILURE,
  DASHBOARD_FETCH_ORG_INFO_DISMISS_ERROR,
  ERROR_CONTACT_ADMIN,
} from './constants';
import { sortFiltersListByKey } from '../../../common/sorterFunc';
import { getInitAllDropdownList } from '../getOrgSelectedList';

export function fetchOrgInfo(market, region, sites,role,sellerId) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_ORG_INFO_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/fetchOrgData`, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          if(role === 'Corp' && sellerId === 'CABL'){
            res.data = res.data.filter(item=>item.market === 'Canada');
          }else if(role === 'Corp' && sellerId === 'USBL'){
            res.data = res.data.filter(item=>item.market !== 'Canada');
          }
          const sortedOrgData = sortFiltersListByKey(res.data, 'market');
          const initOrgList = getInitAllDropdownList(sortedOrgData, market, region, sites);
          const data = {
            orgList: sortedOrgData,
            orgConstData: initOrgList,
          };
          dispatch({
            type: DASHBOARD_FETCH_ORG_INFO_SUCCESS,
            data: data,
          });
          resolve(data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : ERROR_CONTACT_ADMIN;
          dispatch({
            type: DASHBOARD_FETCH_ORG_INFO_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOrgInfoError() {
  return {
    type: DASHBOARD_FETCH_ORG_INFO_DISMISS_ERROR,
  };
}

export function useFetchOrgInfo() {
  const dispatch = useDispatch();

  const { orgList, orgConstData, fetchOrgInfoPending, fetchOrgInfoError } = useSelector(
    state => ({
      orgList: state.dashboard.orgList,
      orgConstData: state.dashboard.orgConstData,
      fetchOrgInfoPending: state.dashboard.fetchOrgInfoPending,
      fetchOrgInfoError: state.dashboard.fetchOrgInfoError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOrgInfo(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOrgInfoError());
  }, [dispatch]);

  return {
    orgList,
    orgConstData,
    fetchOrgInfo: boundAction,
    fetchOrgInfoPending,
    fetchOrgInfoError,
    dismissFetchOrgInfoError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_ORG_INFO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOrgInfoPending: true,
        fetchOrgInfoError: null,
      };

    case DASHBOARD_FETCH_ORG_INFO_SUCCESS:
      // The request is success
      return {
        ...state,
        orgList: action.data.orgList,
        orgConstData: action.data.orgConstData,
        fetchOrgInfoPending: false,
        fetchOrgInfoError: null,
      };

    case DASHBOARD_FETCH_ORG_INFO_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOrgInfoPending: false,
        fetchOrgInfoError: action.data.error,
      };

    case DASHBOARD_FETCH_ORG_INFO_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOrgInfoError: null,
      };

    default:
      return state;
  }
}
