import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorPage } from '../common';
import { AUTH_ERROR_MSG } from '../../common/constants';
import { redirectUrl } from '../../common/apiConfig';

export default function ReportsLayoutContent({children}) {
  const {
    fetchScInActionDataError,
    exportScInActionReportDataError,
    fetchScInActionCustomerDataError,
    fetchScInActionOrderDataError,
    exportScInActionCustomerReportError,
    exportScInActionOrderReportError,
    fetchSummaryDataError,
    exportSummaryReportError,
    fetchOrdersDataError,
    fetchOrdersReportByChannelTypeError,
    fetchSummaryCustomerDataError,
    fetchSummaryDataFromChannelTypeError,
    fetchSummaryOrderDataError,
    fetchOrderDetailReportError,
    fetchOosAccountAccountTypeError,
    fetchOosAccountCustomerError,
    fetchOosAccountOrderError,
    exportOosAccountAccountTypeError,
    exportOosAccountCustomerError,
    exportOosAccountOrderError,
    fetchOosReportBySiteError,
    fetchOosReportByCategoryError,
    fetchOosReportByOrderNumberError,
    exportOosReportBySiteError,
    exportOosReportByCategoryError,
    exportOosReportByOrderNumberError,
  } = useSelector(state => ({
    fetchScInActionDataError: state.reports.fetchScInActionDataError,
    exportScInActionReportDataError: state.reports.exportScInActionReportDataError,
    fetchScInActionCustomerDataError: state.reports.fetchScInActionCustomerDataError,
    fetchScInActionOrderDataError: state.reports.fetchScInActionOrderDataError,
    exportScInActionCustomerReportError: state.reports.exportScInActionCustomerReportError,
    exportScInActionOrderReportError: state.reports.exportScInActionOrderReportError,
    fetchSummaryDataError: state.reports.fetchSummaryDataError,
    exportSummaryReportError: state.reports.exportSummaryReportError,
    fetchOrdersDataError: state.reports.fetchOrdersDataError,
    fetchOrdersReportByChannelTypeError: state.reports.fetchOrdersReportByChannelTypeError,
    fetchSummaryCustomerDataError: state.reports.fetchSummaryCustomerDataError,
    fetchSummaryDataFromChannelTypeError: state.reports.fetchSummaryDataFromChannelTypeError,
    fetchSummaryOrderDataError: state.reports.fetchSummaryOrderDataError,
    fetchOrderDetailReportError: state.reports.fetchOrderDetailReportError,
    fetchOosAccountAccountTypeError: state.reports.fetchOosAccountAccountTypeError,
    fetchOosAccountCustomerError: state.reports.fetchOosAccountCustomerError,
    fetchOosAccountOrderError: state.reports.fetchOosAccountOrderError,
    exportOosAccountAccountTypeError: state.reports.exportOosAccountAccountTypeError,
    exportOosAccountCustomerError: state.reports.exportOosAccountCustomerError,
    exportOosAccountOrderError: state.reports.exportOosAccountOrderError,
    fetchOosReportBySiteError: state.reports.fetchOosReportBySiteError,
    fetchOosReportByCategoryError: state.reports.fetchOosReportByCategoryError,
    fetchOosReportByOrderNumberError: state.reports.fetchOosReportByOrderNumberError,
    exportOosReportBySiteError: state.reports.exportOosReportBySiteError,
    exportOosReportByCategoryError: state.reports.exportOosReportByCategoryError,
    exportOosReportByOrderNumberError: state.reports.exportOosReportByOrderNumberError,
  }));
  const isErrorPage = fetchScInActionDataError || fetchSummaryDataError || exportScInActionReportDataError ||
  fetchScInActionCustomerDataError || fetchScInActionOrderDataError || exportScInActionCustomerReportError ||
  exportScInActionOrderReportError || exportSummaryReportError 
  || fetchOrdersDataError || fetchOrdersReportByChannelTypeError 
  || fetchSummaryCustomerDataError || fetchSummaryDataFromChannelTypeError 
  || fetchSummaryOrderDataError || fetchOrderDetailReportError
  || fetchOosAccountAccountTypeError || fetchOosAccountCustomerError || fetchOosAccountOrderError
  || exportOosAccountAccountTypeError || exportOosAccountCustomerError || exportOosAccountOrderError
  || fetchOosReportBySiteError || fetchOosReportByCategoryError || fetchOosReportByOrderNumberError
  || exportOosReportBySiteError || exportOosReportByCategoryError || exportOosReportByOrderNumberError;
  if (fetchScInActionDataError === AUTH_ERROR_MSG ||
    fetchScInActionCustomerDataError === AUTH_ERROR_MSG ||
    fetchScInActionOrderDataError === AUTH_ERROR_MSG ||
    fetchSummaryDataError === AUTH_ERROR_MSG ||
    fetchOrdersDataError === AUTH_ERROR_MSG || 
    fetchOrdersReportByChannelTypeError === AUTH_ERROR_MSG || 
    fetchSummaryCustomerDataError === AUTH_ERROR_MSG || 
    fetchSummaryDataFromChannelTypeError === AUTH_ERROR_MSG || 
    fetchSummaryOrderDataError === AUTH_ERROR_MSG ||
    fetchOrderDetailReportError === AUTH_ERROR_MSG || 
    fetchOosAccountAccountTypeError === AUTH_ERROR_MSG || 
    fetchOosAccountCustomerError === AUTH_ERROR_MSG || 
    fetchOosAccountOrderError === AUTH_ERROR_MSG || 
    exportOosAccountAccountTypeError === AUTH_ERROR_MSG || 
    exportOosAccountCustomerError === AUTH_ERROR_MSG || 
    exportOosAccountOrderError === AUTH_ERROR_MSG ||
    fetchOosReportBySiteError === AUTH_ERROR_MSG || 
    fetchOosReportByCategoryError === AUTH_ERROR_MSG || 
    fetchOosReportByOrderNumberError === AUTH_ERROR_MSG || 
    exportOosReportBySiteError === AUTH_ERROR_MSG || 
    exportOosReportByCategoryError === AUTH_ERROR_MSG || 
    exportOosReportByOrderNumberError === AUTH_ERROR_MSG
    ) {
    window.location.assign(redirectUrl);
  } else if (isErrorPage) {
    return <ErrorPage />;
  }
  return (
    <div className="reports-new-reports-layout-content">
      {children}
    </div>
  );
};

ReportsLayoutContent.propTypes = {};
ReportsLayoutContent.defaultProps = {};
