import React, { useCallback,useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Space, Spin, Table, message } from 'antd';
import CustomLabel from './CustomLabel';
import { DATE_TIME_ONE_FORMAT, DATE_TIME_TWO_FORMAT } from '../../common/constants';
import { CUSTOMER_LIST_COLUMNS } from '../../common/manageGroupsConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileExport, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PlusOutlined, SearchOutlined, PlayCircleFilled } from '@ant-design/icons';
import { useValidateCustomer, useResetCustomerData,useFetchCustomerData,useRemoveCustomer, } from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';
import moment from 'moment';
import _ from 'lodash';
export default function AddCustomerOperateModal(props) {
  const {
    customerData,
    fetchCustomerDataPending,
    validateCustomerPending,
    addCustomersPending,
    userId,
    userName,
    removeCustomerPending,
  } = useSelector(state => ({
    customerData: state.manageSubs.customerData,
    fetchCustomerDataPending: state.manageSubs.fetchCustomerDataPending,
    validateCustomerPending: state.manageSubs.validateCustomerPending,
    addCustomersPending: state.manageSubs.addCustomersPending,
    userId: state.home.auth.user.userId,
    userName: state.home.auth.user.userName,
    removeCustomerPending: state.manageSubs.removeCustomerPending,
  }));
  const { validateCustomer } = useValidateCustomer();
  const { resetCustomerData } = useResetCustomerData();
  const { fetchCustomerData } = useFetchCustomerData();
  const { removeCustomer,updateCustomerList } = useRemoveCustomer();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { title, isModalVisible, onToggleModal, cancelText, okText, footer, width, initValueData,onOk, 
    deleteGroup,removeCustomerFromGroup} = props;
  const [form] = Form.useForm();
  const initCreatedTimestampFields = {
    modifiedById: userName,
    modifiedByName: userId,
    modifiedTimestamp: moment().format(DATE_TIME_TWO_FORMAT),
  };
  const inputRef = useRef();
  // let searchForm = null;
  let clearFiltersRef = null;
  let confirmRef = null;
  const handleCustomerNbrSearch=(selectedKeys, confirm, dataIndex)=>{
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const formVals = form.getFieldsValue()
    if(formVals && formVals.site && formVals.site.length > 0) {
      const params = {
        customerId: selectedKeys[0],
        groupId: formVals.groupId,
        site: formVals.site
      }
      fetchCustomerData(params);
    } else {
      message.error('Please select Site!')
    }
  }
  const handleCustomerNbrReset=(clearFilters, confirm, dataIndex)=>{
    setSearchText('');
    clearFilters();
    confirm({
      closeDropdown: true,
    });
    setSearchedColumn(dataIndex);
    const formVals = form.getFieldsValue()
    if(formVals && formVals.site && formVals.site.length > 0) {
      const params = {
        "customerId": '',
        "groupId": formVals.groupId,
        "site": formVals.site
      }
      fetchCustomerData(params);
    } else {
      message.error('Please select Site!')
    }
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      clearFiltersRef = clearFilters;
      confirmRef = confirm;
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={inputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleCustomerNbrSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleCustomerNbrSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleCustomerNbrReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: filtered => <SearchOutlined style={{ fontSize: '16px', color: filtered ? 'blue' : undefined }} />,
    // onFilter: (value, record) =>{
    //   return record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : ''
    // },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select(), 100);
      }
    },
  });
  const onReset = () => { 
    form.resetFields();
    // form.setFieldsValue(formVals);
    const formVals = form.getFieldsValue()
    //COT-8974:BOS 2.0 - Custom Groups/Sub Repositories Reset Button Functionality 
    if(formVals && formVals.site && formVals.site.length > 0 && !_.isEmpty(removeList)) {
      const params = {
        "customerId": searchText?searchText:'',
        "groupId": formVals.groupId,
        "site": formVals.site
      }
      fetchCustomerData(params);
    }
    setRemoveList({});
  }
  const onFinish = values => {
    let formData = form.getFieldsValue();
    const { expandedCustomerItems } = formData;    
    let validator = true;
    const allFieldsList = form.getFieldsError();
    const allErrorList = allFieldsList.filter(item => {
      return item.errors.length > 0;  //item has error msg
    });
    form.validateFields(['customerId']).catch(errorInfo => {  //in this page will not go to this logic, maybe replace to other fields 
      if (errorInfo.errorFields.length > 0) {
        validator = false;
      }
    }).finally(() => {
      const errorList = []
      expandedCustomerItems && expandedCustomerItems.forEach((item, index) => {
        if(item.customerId == null || item.customerId == undefined || item.customerId.trim() == '') {
          errorList.push({
            "name": ["expandedCustomerItems", index, "customerId"],
            "errors": ['Please input Customer Number!'],
          })
        }
      })
      if(errorList.length) {
        form.setFields(errorList)
        return
      }
       //COT-8974:BOS 2.0 - Custom Groups/Sub Repositories Reset Button Functionality 
      if(!_.isEmpty(removeList)){
        let customers = Object.keys(removeList);
        const removeData = {
          customerIds: customers,
          groupId: formData.groupId,
          site: formData.site,
          userId: userId,
          userName: userName,
        }
        removeCustomerFromGroup(removeData);
      }
      if (validator && formData && formData.expandedCustomerItems && formData.expandedCustomerItems.length !==0 && allErrorList.length === 0) {
        let addCustomerParams = {
          site: formData.site,
          groupId: formData.groupId,
          userId: userId,
          userName: userName,
          customerIds: formData.expandedCustomerItems.map(item => item.customerId)
        }
        onOk(addCustomerParams,onModalClose);
      } else if(allErrorList.length > 0) {
        form.setFields(allErrorList);
      }else if(formData && ((formData.expandedCustomerItems && formData.expandedCustomerItems.length ===0) || formData.expandedCustomerItems === undefined) && _.isEmpty(removeList)){
        message.info(`No data need to save.`)
      }
    });
   }
  const [removeList,setRemoveList] = useState();
  const handleRemoveCustomer = (record) => {
    const { site, groupId } = form.getFieldsValue();
    // removeCustomer({
    //   customerId: record.customerId,
    //   groupId: groupId,
    //   site: site,
    //   userId: userId,
    //   userName: userName,
    // }).then(data => {
    //   message.info(`Remove Customer Successfully.`);
    //   setSearchText('');
    //   fetchCustomerData(
    //     {
    //       customerId: "",
    //       groupId: groupId,
    //       site: site
    //     })
    // }).catch(err => {
    //   message.error(`Remove Customer Failed, please try again.`);
    // })
    setRemoveList({...removeList,[record.customerId]:record.customerId});
    updateCustomerList(record);
  }
  const handleOperateCustomer = (add, ruleType, fields, errors) => {
    add({...initCreatedTimestampFields});
  }
  const validDuplicatedItem = (value, objFormValues) => {
    const allSubItemsData = objFormValues.expandedCustomerItems;
    if(allSubItemsData){
      const dupIndex = allSubItemsData && allSubItemsData.filter(
        item => item.customerId && item.customerId.trim().toString() === value.trim().toString(),
      );
      return dupIndex;
    }else{
      return -1;
    }
  }
  const handleItemChange = (value, label, key, listFieldName) => {
    let objFormValues = form.getFieldsValue();
    let objCurr = {};

    switch (label) {
      case 'customerId':
        objCurr = {
          [label]: value,
          customerName: '',
          accountType: '',
          substitutedFlag: '',
          status: '',
        };
        objFormValues[listFieldName][key] = {
          ...objFormValues[listFieldName][key],
          ...objCurr,
          // ...initModifiedTimestampFields,
          ...initCreatedTimestampFields,
        };
        form.setFieldsValue(objFormValues);
        return;
      default:
        return;
    }
  }
  const handleCusomterNbrValidator = (rule, value, callback, label, key = 0)=>{
    // console.log('validtor----->');
    if (value && value.length) {
      const objFormValues = form.getFieldsValue();
      const dupIndex = validDuplicatedItem(value, objFormValues);
      if (dupIndex.length > 1) {
        callback('Duplicated Customer Number!');
      } else {
        validateCustomer(objFormValues.site,value).then(data=>{
          if(data.success){
            const currFields = {
              ...data.data
            };
            objFormValues[label][key] = {
              ...objFormValues[label][key],
              ...currFields,
              ...initCreatedTimestampFields,
            }
            form.setFieldsValue(objFormValues);
          }else{
            callback(data.message)
          }
        })
      }
    } else {
      callback();
    }
  }
  const handleRemoveItem = (remove, name) => {
    remove(name)
  }
  const handleItemPressEnter = (e) => {
    e.target.blur()
  };
  const handleOnCustomerNbr = (key,fields,name)=>{
    // console.log('manual validtor----->');
    // handleCusomterNbrValidator();
    // const errorList = [];
    // const objFormValues = form.getFieldsValue();
    // form.validateFields(['customerId']).then((data) => {
    //   const {customerId} = form.getFieldsValue();
    //   if (customerId && customerId.length) {
    //     const dupIndex = validDuplicatedItem(customerId, objFormValues);
    //     if (dupIndex.length > 1) {
    //       // callback('Duplicated Customer Number!');
    //         errorList.push({
    //           "name": ["customerId"],
    //           "errors": ['Duplicated Customer Number!'],
    //         })
    //         form.setFields(errorList);
    //       }
    //     } else {
    //       validateCustomer(objFormValues.site,objFormValues.customerId).then(data=>{
    //         if(data.success){
    //           const currFields = {
    //             ...data.data
    //           };
    //           objFormValues["expandedCustomerItems"]["customerId"] = {
    //             ...objFormValues["expandedCustomerItems"]["customerId"],
    //             ...currFields,
    //             ...initCreatedTimestampFields,
    //           }
    //           form.setFieldsValue(objFormValues);
    //         }else{
    //           // callback(data.message)
    //           errorList.push({
    //             "name": ["customerId"],
    //             "errors": [data.message],
    //           })
    //           form.setFields(errorList);
    //         }
    //       })
    //     }
    // }).catch((errorInfo)=>{
    //   if (errorInfo.errorFields.length > 0) {
    //     form.setFields(errorInfo.errorFields);
    //   }
    //   form.setFields(errorList);
    // }).finally(()=>{
    // })
  }
  const renderCutomerNumberItem = useCallback((key, name, fields, move, remove, ...resetField) => {
    return (
      <SubRow
        key={key}
        name={name}
        fields={fields}
        move={move}
        remove={remove}
        {...resetField}
      />
    );
  }, []); //eslint-disable-line
  const SubRow = ({key, name, fields, move, remove, ...resetField })=>{
    return (  
      <Row
        // data-handler-id={handlerId}
        gutter={6}
        className={`sub-item-list-item`}
      >
        <Col span={5}>
          <Form.Item 
            {...resetField} 
            className="sub-item-position"
            name={[name, 'customerId']} 
            validateTrigger={['onChange', 'onBlur']}
             rules={[
              {
                validateTrigger: ['onBlur'],
                validator: (rule, value, callback) =>
                  handleCusomterNbrValidator(rule, value, callback, 'expandedCustomerItems', name),
              },
              {
                validateTrigger: ['onChange', 'onBlur'],
                required: true,
                message: 'Please input Customer Number!',
              },
            ]}
            getValueFromEvent={e => {
              return e.target.value.replace(/\s/g,'');
            }}
          >
            <Input
              maxLength={6}
              onChange={e =>handleItemChange(e.target.value.replace(/\s/g,''), 'customerId', name, 'expandedCustomerItems')}
              onPressEnter={e => handleItemPressEnter(e)}
            />
          </Form.Item>
            {/* <div>
              <Button onClick={() => handleOnCustomerNbr(key,fields,name)} className={'no-border'}>
                <FontAwesomeIcon icon={faCheck} color="#6b6b6c" style={{ height: "13px", width: "13px" ,paddingRight:'20px'}}/> 
              </Button>
            </div> */}
            <div className="flex align-items-center series-subs-tips-content-items" style={{opacity:1}}>
            <Form.Item {...resetField} className="tips-content-item" name={[name, 'customerName']}>
              <CustomLabel />
            </Form.Item>
          </div>
          
        </Col>
         {/* <Col span={1}>
          <Button onClick={() => handleOnCustomerNbr(key,fields,name)} className="no-border" style={{marginTop: '15px',marginLeft:'-50px'}}>
            <FontAwesomeIcon icon={faCheck} color="#6b6b6c" style={{ height: "13px", width: "13px" ,paddingRight:'20px'}}/> 
          </Button>
          </Col> */}
        <Col span={2}>
          <Form.Item
            {...resetField}
            name={[name, 'accountType']}
          >
            <CustomLabel />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item {...resetField} name={[name, 'substitutedFlag']}>
          <CustomLabel />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item {...resetField} name={[name, 'status']}>
          <CustomLabel />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            className="flex align-items-center font-size-14-bold"
            name={[name, 'modifiedById']}
          >
            <CustomLabel />
          </Form.Item>
          <div className="flex align-items-center modifiedByName series-tips-timestamp-items">
            <Form.Item className="tips-content-item" name={[name, 'modifiedByName']}>
              <CustomLabel />
            </Form.Item>
            <Form.Item className="sub-item-position" name={[name, 'modifiedTimestamp']}>
              <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
            </Form.Item>
          </div>
        </Col>
        <Col className="flex align-items-center">
          <Button onClick={() => handleRemoveItem(remove, name)} className={'no-border'}>
            <FontAwesomeIcon icon={faTrash} color="#6b6b6c" style={{ height: "13px", width: "13px" ,paddingRight:'20px'}}/> 
          </Button>
        </Col>
      </Row>
      
    );
    }; //eslint-disable-line
    const renderColumnsElement = [
      { //render customerId
        extendRender: (value, label, record) => {
          return (
            <>
              <div className="col-title">{value}</div>
              <div className="col-desc">{record.customerName}</div>
            </>
          )
        }
      },
      {},//accountType placeholder
      {},//substituted placeholder
      {},//status placeholder
      { //render modifiedBy
        extendRender: (value, label, record) => {
          const modifiedDate = record.modifiedTimestamp && moment(record.modifiedTimestamp).format(DATE_TIME_ONE_FORMAT);
          return value && (
            <>
              <div className="col-title">{record.modifiedByName}</div>
              <div className="col-desc">{`${record.modifiedById} ${modifiedDate}`}</div>
            </>
          )
        }
      },
    ];
  CUSTOMER_LIST_COLUMNS[0] = {...CUSTOMER_LIST_COLUMNS[0], ...getColumnSearchProps('customerId')};
  let columns = renderTableColumns(CUSTOMER_LIST_COLUMNS, null,renderColumnsElement);
  columns.push({
    title: 'Actions',
    dataIndex: '',
    width: 50,
    render: (value, record) => {
      return (
        <div className="flex parent-actions">
          <Button title="Remove Customer" style={{ paddingLeft: '10px', width: '24px' }} disabled={removeCustomerPending} type="link" onClick={() => { handleRemoveCustomer(record) }}>
            <FontAwesomeIcon icon={faTrash} color="#6b6b6c" style={{ height: "13px", width: "13px", paddingRight: '20px' }} />
          </Button>
        </div>
      );
    }
  })
  const onModalClose = () => {
    resetCustomerData();
    message.destroy(1);
    onToggleModal();
  };
  const onDelete=()=>{
    let deleteData = {
      site: initValueData.site,
      groupId: initValueData.groupId,
      customerNumbers: customerData.map(item => item.customerId),
      userName: userName,
      userID: userId
    }
    deleteGroup(deleteData,onModalClose);
  }
  return (
    <Modal
      className="manage-subs-add-customer-operate-modal"
      title={title}
      visible={isModalVisible}
      onCancel={onModalClose}
      cancelText={cancelText || 'Close'}
      okText={okText || 'Save'}
      destroyOnClose={true}
      maskClosable={false}
      footer={footer || true}
      width={width || 520}
    >
      {/* <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === 'addNewCustomerForm') {
            // Do something...
              const { addNewCustomerForm } = forms;
              const values = addNewCustomerForm.getFieldsValue();
              console.log('addNewCustomer-->'+JSON.stringify(values));
          }
        }}
      > */}
       <Spin
        spinning={validateCustomerPending || fetchCustomerDataPending || addCustomersPending}>
        <Form
          form={form}
          name="addCustomerBasic"
          className="operate-form"
          autoComplete="off"
          initialValues={initValueData}
        >
          {title === 'Add Customer' &&
            initValueData && ( //this header shows just for Edit screen
              <Row gutter={4} justify="space-between" className="account-header-row">
                <Col span={4}>
                  <Form.Item className="form-label static-form-item-value" label="Site ID" name="site">
                    <CustomLabel />
                  </Form.Item>
                  <Form.Item className="tips-content-item edit-site-tip" name="siteDesc">
                    <CustomLabel />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="form-label static-form-item-value"
                    label="Group Id"
                    name="groupId"
                  >
                    <CustomLabel />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-label static-form-item-value"
                    label="Group Description"
                    name="groupDescription"
                  >
                    <CustomLabel />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item className="form-label static-form-item-value" label="Modified By" name="modifiedByName">
                    <CustomLabel />
                  </Form.Item>
                  <div className="flex align-items-center series-tips-content-items-1">
                    <Form.Item className="tips-content-item" name="modifiedById">
                      <CustomLabel />
                    </Form.Item>
                    <Form.Item className="tips-content-item" name="modifiedTimestamp">
                      <CustomLabel dataType={DATE_TIME_ONE_FORMAT} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

            )}

        {/* </Form> */}
        {/* <Form form={form} name="addedCustomerList" className='add-sub-recommended-products-form'> */}
        {/* <div className='flex justify-end'style={{marginBottom:'10px'}}>
          <Button type="link" title="Export" disabled={customerData === null ||customerData.length === 0} onClick={onExportCustomer}>
            <FontAwesomeIcon icon={faFileExport} color={customerData?"#6b6b6c":"#a1a1a2"} style={{ height: "20px", width: "20px" }}/>
            <p style={{marginTop:"-5px", fontSize:"12px", marginLeft:"-3px", color:(customerData?"#6b6b6c":"#a1a1a2")}}>Export</p>
          </Button>
        </div> */}
        
          <Table
            rowKey={record => record.customerId}
            className={`common-report-table`}
            // loading={fetchCustomerDataPending}
            bordered={false}
            columns={columns}
            dataSource={customerData}
            // onChange={onTableChange}
            pagination={false}
            scroll={{ x: 'max-content', y: 200 }}
          ></Table>
        {/* </Form> */}
        {/* <Form form={form} name="addNewCustomerForm" className='add-sub-recommended-products-form'> */}
          <Row>
            <Col span={24}>
              <Row gutter={6} className="sub-item-list-header">
                <Col span={5}>Customer Number</Col>
                {/* <Col span={1}></Col> */}
                <Col span={2}>Type</Col>
                <Col span={3}>Substituted</Col>
                <Col span={2}>Status</Col>
                {/* {title === 'Edit sub rule' && <Col span={4}>Created By</Col>} */}
                <Col span={4}>Modified</Col>
                <Col style={{ paddingRight: '20px' }}>Actions</Col>
              </Row>
              <Form.List name="expandedCustomerItems">
                {(fields, { add, move, remove }, { errors }) => (
                  // <DndProvider backend={HTML5Backend}>
                  <>
                    {fields.map(({ key, name, ...resetField }) =>
                          renderCutomerNumberItem(key, name, fields, move, remove, resetField),
                        )}
                    {fields.length < 4 && ( //for sub item, it just can be added up to 4 row
                      <Row>
                        <Col span={1} offset={23}>
                          <Form.Item>
                            <Button
                              className="subs-header-btn no-border"
                              onClick={() => handleOperateCustomer(add, true, fields, errors)}
                              icon={<PlusOutlined />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                  // </DndProvider>
                )}
              </Form.List>
            </Col>
          </Row>
          <div className="subs-footer flex justify-end">
            <Popconfirm
                title="Are you sure would like to delete the customer group?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <Button type="link" className="default-btn">
                  Delete
                </Button>
            </Popconfirm>
            <Button id="cancelbutton" className="default-btn" onClick={onReset} style={{ marginLeft: "2px" }}>
              {cancelText}
            </Button>
            <Button id="okbutton" onClick={onFinish} type="primary" className='primary-btn' style={{ marginLeft: "2px" }}>
              {okText}
            </Button>
          </div>
        </Form>
        </Spin>
      {/* </Form.Provider> */}
    </Modal>
  );
};

AddCustomerOperateModal.propTypes = {};
AddCustomerOperateModal.defaultProps = {};
