import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANAGE_SUBS_RESET_CONCEPT_DATA,
} from './constants';

export function resetConceptData() {
  return {
    type: MANAGE_SUBS_RESET_CONCEPT_DATA,
  };
}

export function useResetConceptData() {
  const dispatch = useDispatch();
  const conceptData = useSelector(state => state.manageSubs.conceptData);
  const boundAction = useCallback((...params) => dispatch(resetConceptData()), [dispatch]);
  return { conceptData, resetConceptData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_CONCEPT_DATA:
      return {
        ...state,
        conceptData: null,
      };

    default:
      return state;
  }
}
