import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_RESET_REPORTS_DATA,
} from './constants';

export function resetReportsData() {
  return {
    type: REPORTS_RESET_REPORTS_DATA,
  };
}

export function useResetReportsData() {
  const dispatch = useDispatch();
  const { reportsData } = useSelector(state => ({
    reportsData: state.reports.reportsData,
  }), shallowEqual);
  const boundAction = useCallback((...params) => dispatch(resetReportsData()), [dispatch]);
  return { reportsData, resetReportsData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_RESET_REPORTS_DATA:
      return {
        ...state,
        reportsData: null,
        reportsTotals: 0,
        summary: null,
      };

    default:
      return state;
  }
}
