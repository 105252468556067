import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Empty, Spin, Form, Select, Col, Tooltip, AutoComplete, } from 'antd';
import { CustomLabel } from './';
import { AUTH_ERROR_MSG, } from '../../common/constants';
import { validateNumericRegExp, validateConceptIdRegExp,  validateGroupIdPrefix, } from './formattedSubsData';
import { useFetchConceptData, useFetchValidationCustomerData, useValidateActiveConceptCus } from './redux/hooks';
import { useDebounce } from '../../common/hook';

export default function SiteConceptCommonPanel(props) {
  const {
    conceptData,
    fetchConceptDataPending,
    orgDropdownList,
  } = useSelector(state => ({
    conceptData: state.manageSubs.conceptData,
    fetchConceptDataPending: state.manageSubs.fetchConceptDataPending,
    orgDropdownList: state.manageSubs.orgDropdownList,
  }));
  const { siteColSpan, conceptColSpan, handleItemChange, selectConceptKey, setSelectConceptKey, initModifiedTimestampFields, checkIfAdded, form, isFrom="accounts" } = props;
  const { fetchValidationCustomerData } = useFetchValidationCustomerData();
  const { fetchConceptData } = useFetchConceptData();
  const [errorConceptId, setErrorConceptId] = useState(0)
  const [conceptErrorMessge, setConceptErrorMessge] = useState('');
  const [selectedConceptId, SetSelectedConceptId] = useState(false)
  const { validateActiveConceptCus } = useValidateActiveConceptCus();
  const handleSelectConceptId = (value, label) => {
    setSelectConceptKey(value);
    SetSelectedConceptId(true)
    const objFormValues = form.getFieldsValue();
    const item = conceptData.find(item => item.nationalId === value);
    const isConceptId = validateConceptIdRegExp(value);
    const isGroupId = validateGroupIdPrefix(value);
    if(item.nationalName) {
      if(item.isOnboarded === false) {
        form.setFields([{
          "name": ["nationalId"],
          "errors": ['The concept is not onboarded.'],
        }])
        setErrorConceptId(2)
      } else if ((isConceptId || isGroupId) && isFrom === 'subs') {
        const formData = {
          site: objFormValues.site,
          nationalId: value,
          customerNumber: "",
        };
        validateActiveConceptCus(formData).then(data => {
         if(!data?.data?.isValid){
          form.setFields([{
            "name": ["nationalId"],
            "errors": [data?.data?.message],
          }])
          setErrorConceptId(3);
          setConceptErrorMessge(data?.data?.message);
         }else{
          const objCurr = {
            [label]: value,
            "nationalName": item.nationalName,
          };
          form.setFieldsValue({ ...objFormValues, ...objCurr, ...initModifiedTimestampFields });
          setErrorConceptId(0)
          setConceptErrorMessge('');
         }
        },
        err => {
          if(err !== AUTH_ERROR_MSG) {
            setErrorConceptId(3);
            setConceptErrorMessge('Invalid national account');
          }
        },);
      }
      else {
        const objCurr = {
          [label]: value,
          "nationalName": item.nationalName,
        };
        form.setFieldsValue({ ...objFormValues, ...objCurr, ...initModifiedTimestampFields });
        setErrorConceptId(0)
      }
    }else {
      form.setFields([{
        "name": ["nationalId"],
        "errors": ['The concept is not available in this site.'],
      }])
      setErrorConceptId(1)
    }
  };
  const handleCustomerNbrValidator = (rule, value, callback) => {
    form.validateFields(['site']).then(() => {
      const isCustomerNumber = validateNumericRegExp(value);
      const isConceptId = validateConceptIdRegExp(value);
      const isGroupId = validateGroupIdPrefix(value);
      if (!isCustomerNumber && !isConceptId && !isGroupId) {
        callback('Invalid Concept ID/Ship To!');
      } else if (isCustomerNumber) {
        const objFormValues = form.getFieldsValue();
        const formData = {
          opcoId: objFormValues.site,
          customerId: value,
          isFrom: isFrom,
        };
        fetchValidationCustomerData(formData).then(customerName => {
          const currFields = {
            nationalId: value,
            nationalName: customerName,
            customerNumber: value,
            customerName: customerName,
          };
          form.setFieldsValue({
            ...objFormValues,
            ...currFields,
            ...initModifiedTimestampFields,
          });
          checkIfAdded && checkIfAdded()
          callback();
        },
        err => {
          if(err !== AUTH_ERROR_MSG) {
            callback('Invalid Ship To!');
          }
        },);
      }else if((isConceptId || isGroupId) && value !== selectConceptKey) {
        //need to compare selected nationID with inputed nationID value, to confirm if select correct national Id
        callback('Invalid Concept ID!');
      } else {
        if(errorConceptId === 1) {
          callback('The concept is not available in this site.');
        }else if(errorConceptId === 2) {
          callback('The concept is not onboarded.');
        }else if(errorConceptId === 3){
          callback(conceptErrorMessge);
        }else {
          checkIfAdded && checkIfAdded()
          callback();
        }
      }
    }).catch(errorInfo => {
      if(errorConceptId === 1) {
        callback('The concept is not available in this site.');
      }else if(errorConceptId === 2) {
        callback('The concept is not onboarded.');
      }else if(errorConceptId === 3){
        callback(conceptErrorMessge);
      }
      else {
        callback();
      }
    });
  };
  const handleConceptIdValidator = (rule, value, callback) => {
    setConceptErrorMessge('');
    handleChg(rule, value, callback)
    // callback()
  };
  const handleChg = useDebounce((rule, value, callback) => {
    form.validateFields(['site']).then(values => {
      const isCustomerNumber = validateNumericRegExp(value);
      const isConceptId = validateConceptIdRegExp(value);
      const isGroupId = validateGroupIdPrefix(value);
      if (value.length >= 4 && (isConceptId || isGroupId)) {
        if(!selectedConceptId) {
          const siteId = values.site;
          fetchConceptData([siteId], value.toUpperCase(), isFrom); //need to transfer site from string to array
        }
        if(errorConceptId === 1) {
          callback('The concept is not available in this site.');
        }else if(errorConceptId === 2) {
          callback('The concept is not onboarded.');
        }else if(errorConceptId === 3){
          callback(conceptErrorMessge);
        }else {
          callback();
        }
      } else if (isCustomerNumber || isConceptId || isGroupId) {
        callback();
      } else {
        callback('Invalid Concept ID/Ship To!');
      }
      SetSelectedConceptId(false)
    }).catch(errorInfo => {
      if(errorConceptId === 1) {
        callback('The concept is not available in this site.');
      }else if(errorConceptId === 2) {
        callback('The concept is not onboarded.');
      }else if(errorConceptId === 3) {
        callback(conceptErrorMessge);
      }else {
        callback();
      }
      SetSelectedConceptId(false)
    });
  }, 500);
  const renderConceptSelectOptions = () => {
    const conceptValue = form.getFieldValue('nationalId');
    if (conceptValue && (validateConceptIdRegExp(conceptValue) || validateGroupIdPrefix(conceptValue)) && fetchConceptDataPending) {
      //if input concept id, and fetching data
      return <Spin />;
    } else if (validateConceptIdRegExp(conceptValue) || validateGroupIdPrefix(conceptValue)) {
      //if input concept id, and fetched no record
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    //input customer number
    return null;
  };
  return (
    <>
      <Col span={siteColSpan}>
        <div className='siteID'>
        <Form.Item
          name="site"
          label="Site ID"
          rules={[{ required: true, message: 'Please select Site!' }]}
        >
          <Select showSearch onChange={value => handleItemChange(value, 'site')}>
            {orgDropdownList && orgDropdownList.sites &&
              orgDropdownList.sites.map(item => {
                const siteNbr = item.site_nbr;
                const siteNbrName = item.site_desc;
                return (
                  <Select.Option key={siteNbr} value={siteNbr}>
                    {`${siteNbr} - ${siteNbrName}`}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        </div>
        {/* <Form.Item className="tips-content-item site-tip" name="siteDesc">
          <CustomLabel />
        </Form.Item> */}
      </Col>
      <Col span={conceptColSpan}>
        {/* defect:COT-4967,Prompt message is not being displayed when hovered over the Concept id field*/}
        <Tooltip placement="top" title="Please input 4 char to retrive Concept ID.">
          <Form.Item
            name="nationalId"
            //revert defect:COT-4950
            label="Group/Ship To"
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validateTrigger: 'onChange',
                validator: (rule, value, callback) =>
                handleConceptIdValidator(rule, value, callback),
              },
              {
                validateTrigger: 'onBlur',
                validator: (rule, value, callback) =>
                handleCustomerNbrValidator(rule, value, callback),
              },
              {
                validateTrigger: ['onChange', 'onBlur'],
                required: true,
                message: 'Please input Group/Ship To!',
              },
            ]}
          >
            <AutoComplete
              allowClear
              defaultActiveFirstOption
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onChange={value => handleItemChange(value, 'nationalId')}
              onSelect={value => handleSelectConceptId(value, 'nationalId')}
              notFoundContent={renderConceptSelectOptions()}
            >
              {conceptData && conceptData.map(item => (
                <AutoComplete.Option
                  key={`${item.nationalId}`}
                  value={item.nationalId}
                >
                  {item.nationalId}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Tooltip>
        <Form.Item className="tips-content-item concept-tip" name="nationalName" style={{paddingLeft:'49px'}}>
          <CustomLabel />
        </Form.Item>
      </Col>
    </>
  );
}
