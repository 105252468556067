import Amplify from 'aws-amplify';
import { 
	DEV_API_REQUEST_URL, QA_API_REQUEST_URL, PROD_API_REQUEST_URL, DEV_EXPORT_API_REQUEST_URL, QA_EXPORT_API_REQUEST_URL,
	PROD_EXPORT_API_REQUEST_URL, DEV_BOS_EXPORT_API_REQUEST_URL, QA_BOS_EXPORT_API_REQUEST_URL, PROD_BOS_EXPORT_API_REQUEST_URL, DEV_ENV, QA_ENV, PROD_ENV 
} from './apiConstants';

let API_REQUEST_URL = DEV_API_REQUEST_URL;
let EXPORT_API_REQUEST_URL = DEV_EXPORT_API_REQUEST_URL;
let EXPORT_BOS_API_REQUEST_URL = DEV_BOS_EXPORT_API_REQUEST_URL;
let config = require('../config/config.js');
let ENV_NAME = DEV_ENV;
switch (process.env.REACT_APP_SIA_ENV) {
	case 'development':
		API_REQUEST_URL = DEV_API_REQUEST_URL;
		EXPORT_API_REQUEST_URL = DEV_EXPORT_API_REQUEST_URL;
		EXPORT_BOS_API_REQUEST_URL = DEV_BOS_EXPORT_API_REQUEST_URL;
		config = require('../config/config-dev.js');
		ENV_NAME = DEV_ENV;
		break;
	case 'qa':
    	API_REQUEST_URL = QA_API_REQUEST_URL;
		EXPORT_API_REQUEST_URL = QA_EXPORT_API_REQUEST_URL;
		EXPORT_BOS_API_REQUEST_URL = QA_BOS_EXPORT_API_REQUEST_URL;
		config = require('../config/config-qa.js');
		ENV_NAME = QA_ENV;
		break;
	case 'production':
    	API_REQUEST_URL = PROD_API_REQUEST_URL;
		EXPORT_API_REQUEST_URL = PROD_EXPORT_API_REQUEST_URL;
		EXPORT_BOS_API_REQUEST_URL = PROD_BOS_EXPORT_API_REQUEST_URL;
		config = require('../config/config-prod.js');
		ENV_NAME = PROD_ENV;
		break;
	default:
		API_REQUEST_URL = DEV_API_REQUEST_URL;
		EXPORT_API_REQUEST_URL = DEV_EXPORT_API_REQUEST_URL;
		EXPORT_BOS_API_REQUEST_URL = DEV_BOS_EXPORT_API_REQUEST_URL;
    	config = require('../config/config.js');
		ENV_NAME = DEV_ENV;
}

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.default.cognito.REGION,
		userPoolId: config.default.cognito.USER_POOL_ID,
		identityPoolId: config.default.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.default.cognito.APP_CLIENT_ID,
		oauth: config.default.oauth
	},
	API: {
		endpoints: [
			{ ...config.default.endpoints[0] },
			{ ...config.default.endpoints[1] },
		]
	}
});

const redirectUrl = 'https://' + config.default.oauth.domain + '/oauth2/authorize?redirect_uri=' +
  config.default.oauth.redirectSignIn + '&response_type=' + config.default.oauth.responseType + '&client_id=' + config.default.cognito.APP_CLIENT_ID;

export { API_REQUEST_URL, EXPORT_API_REQUEST_URL, EXPORT_BOS_API_REQUEST_URL, config, redirectUrl,ENV_NAME };