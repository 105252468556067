import React from 'react';
import { CommonPage } from '../reports-new';
import { CONST_REPORT_TABS_NEW_COLUMNS, } from '../../common/reportsConstants';
export default function OosItem() {
  const tabKey = 'OOS Item';
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].order.level;
  return (
    <div className="out-of-stock-oos-item">
      <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

OosItem.propTypes = {};
OosItem.defaultProps = {};
