import React from 'react';
import { Table } from 'antd';

export default function TableFooter(props) {
  const { tabKey,columns, summary,isFromModal } = props;
  let footerClass = (tabKey === 'Orders' || tabKey === 'Future Orders' || tabKey === 'SC in Action') && !isFromModal?'footer-padding':'' 
  return (
    <div className={`footer-${tabKey}`}>
      <Table
        className={`reports-table-footer ${footerClass}`}
        columns={columns}
        showHeader={false}
        rowKey={record => Math.random()}
        dataSource={[summary]}
        scroll={{ x: 'max-content', y: tabKey === 'Out & Subs' ? 320 : 350}}
        pagination={false}
      >
      </Table>
      </div>
  );
};

TableFooter.propTypes = {};
TableFooter.defaultProps = {};
