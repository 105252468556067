import initialState from './initialState';
import { reducer as getAuthrizationReducer } from './getAuthrization';
import { reducer as authrizationResetReducer } from './authrizationReset';
import { reducer as feedbackReducer } from './feedback';

const reducers = [
  getAuthrizationReducer,
  authrizationResetReducer,
  feedbackReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  
  newState=state;

  return reducers.reduce((s, r) => r(s, action), newState);
}
