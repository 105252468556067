import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_REPORT_MODAL_KEY_VALUE } from '../../../common/constants';
import {
  REPORTS_NEW_RESET_REPORTS_KEY_VALUE,
} from './constants';

export function resetReportsKeyValue() {
  return {
    type: REPORTS_NEW_RESET_REPORTS_KEY_VALUE,
  };
}

export function useResetReportsKeyValue() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(resetReportsKeyValue(...params)), [dispatch]);
  return { resetReportsKeyValue: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_NEW_RESET_REPORTS_KEY_VALUE:
      return {
        ...state,
        reportsKeyValue: DEFAULT_REPORT_MODAL_KEY_VALUE,
      };

    default:
      return state;
  }
}
