import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Modal, Table, Button, Pagination, Spin, Empty } from 'antd';
import { ExportAlertModal } from '../common';
import { SLASH_DATE_FORMAT, DASH_DATE_FORMAT, CONST_PAGINATION } from '../../common/constants';
import { useResetOrderReport, useFetchOrderReportData, useExportReportData } from './redux/hooks';
import { useSaveReportsKeyValue } from '../reports-new/redux/saveReportsKeyValue';
import { downloadFile } from '../../common/downloadReportFunc';
import { CancelIcon, EditCalendarIcon } from '../common/CustomerIcons';
import { renderTableColumns } from '../../common/renderColumns';
import { datadogRum } from '@datadog/browser-rum';
import { ExportButton } from '../reports-new';
import OrderDetailReportModal from './OrderDetailReportModal';
import { useTranslation } from 'react-i18next';
export default function OrderReportModal(props) {
  const { t } = useTranslation();
  const {
    orderReportData,
    fetchOrderReportDataPending,
    detailsCondition,
    exportReportDataPending,
    reportsKeyValue,
  } = useSelector(state => ({
    orderReportData: state.dashboard.orderReportData,
    fetchOrderReportDataPending: state.dashboard.fetchOrderReportDataPending,
    detailsCondition: state.dashboard.detailsCondition,
    exportReportDataPending: state.dashboard.exportReportDataPending,
    reportsKeyValue: state.reportsNew.reportsKeyValue,
  }));
  const { resetOrderReport } = useResetOrderReport();
  const { fetchOrderReportData } = useFetchOrderReportData();
  const { exportReportData } = useExportReportData();
  const { saveReportsKeyValue } = useSaveReportsKeyValue();
  const [pagination, setPagination] = useState(CONST_PAGINATION);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [isOrderDetailModalVisible, setOrderDetailModalVisible] = useState(false);
  const { title, totals, typeLabel, toggleReportModal, isReportModalVisible, modalkey } = props;

  let statusColor;
  if (
    title === 'Open Orders' ||
    title === 'Routing' ||
    title === 'Labelled' ||
    title === 'Shipped/Invoiced'
  ) {
    statusColor = 'report-status-normal';
  } else if (title === 'Locked/Errored') {
    statusColor = 'report-status-error';
  } else {
    statusColor = 'report-status-warning';
  }

  useEffect(() => {
    if (isReportModalVisible) {
      if (pagination.total === totals) {
        handleOrderReportList(pagination, sorting);
      } else {
        setPagination({
          ...pagination,
          total: totals,
        });
      }
    } else {
      setPagination(CONST_PAGINATION);
      setSorting();
      setShowAlert(false);
    }
  }, [isReportModalVisible, pagination, sorting]); // eslint-disable-line

  const columns = [
    {
      title: t('site'),
      dataIndex: 'site',
      sorter: true,
      width: 65,
      onHeaderCell: column => {
        return { width: 65 };
      },
    },
    {
      title: t('order#'),
      dataIndex: 'orderNumber',
      sorter: true,
      width: 95,
      onHeaderCell: column => {
        return { width: 95 };
      },
      // render: value => <div className="report-link">{value}</div>,
      toggleModal: title === 'EXPD Details'? false : true, //EXPD and subs, we do not need to show item detail
    },
    {
      title: t('customer#'),
      dataIndex: 'customerNumber',
      width: 95,
      sorter: true,
      onHeaderCell: column => {
        return { width: 95 };
      },
    },
    {
      title: t('dashboard_customerName'),
      dataIndex: 'customerName',
      width: 170,
      sorter: true,
      onHeaderCell: column => {
        return { width: 140 };
      },
    },
    {
      title: t('dashboard_accountType'),
      dataIndex: 'accountType',
      width: 120,
      onHeaderCell: column => {
        return { width: 120 };
      },
    },
    {
      title: t('deliveryDT'),
      dataIndex: 'deliveryDate',
      width: 100,
      onHeaderCell: column => {
        return { width: 100 };
      },
      render: value => moment(value, DASH_DATE_FORMAT).format(SLASH_DATE_FORMAT),
    },
    {
      title: t('totals'),
      dataIndex: 'totals',
      sorter: true,
      width: 100,
      onHeaderCell: column => {
        return { width: 75 };
      },
      render: value =>
        value === null || value === 0
          ? '$0.00'
          : `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`,
    },
  ];

  const commonColumnObj = {
    title: t('itemCount'),
    dataIndex: 'itemCount',
    width: 110,
    onHeaderCell: column => {
      return { width: 110 };
    },
    sorter: true,
  };

  const errorColumnObj = {
    title: t('error'),
    dataIndex: 'errorMessage',
    width: 140,
    onHeaderCell: column => {
      return { width: 130 };
    },
  };

  const statusColumnObj = {
    title: t('status'),
    dataIndex: 'status',
    width: 80,
    onHeaderCell: column => {
      return { width: 70 };
    },
    render: value => <div className={statusColor}>{value}</div>,
  };

  const itemNoColumnObj = {
    title: t('item#'),
    dataIndex: 'itemNumber',
    width: 90,
    onHeaderCell: column => {
      return { width: 90 };
    },
    sorter: true,
  };

  const itemDescColumnObj = {
    title: t('dashboard_itemDescription'),
    dataIndex: 'itemDescription',
    width: 140,
    onHeaderCell: column => {
      return { width: 120 };
    },
  };
  const expdColumnObj = [
    {
      title: t('qnty'),
      dataIndex: 'originalQuantity',
      width: 80,
      onHeaderCell: column => {
        return { width: 70 };
      },
    },
    {
      title: t('uom'),
      dataIndex: 'unitOfMeasure',
      width: 80,
      onHeaderCell: column => {
        return { width: 70 };
      },
    },
    {
      title: t('error'),
      dataIndex: 'exceptionDescription',
      width: 140,
      onHeaderCell: column => {
        return { width: 130 };
      },
    },
    {
      title: t('status'),
      dataIndex: 'exceptionStatus',
      width: 80,
      onHeaderCell: column => {
        return { width: 70 };
      },
      render: value => <div className={statusColor}>{value}</div>,
    },
  ];
  const subsExceptionsColumnObj = [
    {
      title: t('site'),
      dataIndex: 'site',
      sorter: true,
      width: 65,
      onHeaderCell: column => {
        return { width: 65 };
      },
    },
    {
      title: t('customer#'),
      dataIndex: 'customerNumber',
      width: 95,
      sorter: true,
      onHeaderCell: column => {
        return { width: 95 };
      },
    },
    {
      title: t('dashboard_customerName'),
      dataIndex: 'customerName',
      width: 170,
      sorter: true,
      onHeaderCell: column => {
        return { width: 140 };
      },
    },
    {
      title: t('order#'),
      dataIndex: 'orderNumber',
      sorter: true,
      width: 95,
      onHeaderCell: column => {
        return { width: 95 };
      },
    },
    {
      title: t('origItem'),
      dataIndex: 'itemNumber',
      sorter: true,
      width: 110,
      onHeaderCell: column => {
        return { width: 110 };
      },
    },
    {
      title: t('origItemDesc'),
      dataIndex: 'itemDescription',
      sorter: true,
      width: 170,
      onHeaderCell: column => {
        return { width: 140 };
      },
    },
    {
      title: t('origQnty'),
      dataIndex: 'itemQuantity',
      // sorter: true,
      width: 110,
      onHeaderCell: column => {
        return { width: 100 };
      },
    },
    {
      title: t('origUnitPrice'),
      dataIndex: 'originalItemUnitPrice',
      sorter: true,
      width: 135,
      render: value =>
        value === 0
          ? '$0.000'
          : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 3 })}`,
      onHeaderCell: column => {
        return { width: 100 };
      },
    },
    {
      title: t('origAlcQnty'),
      dataIndex: 'quantityAllocAgainstOnHand',
      sorter: true,
      width: 130,
      onHeaderCell: column => {
        return { width: 110 };
      },
    },
    {
      title: t('reasonCode'),
      dataIndex: 'orderReasonCode',
      sorter: true,
      width: 100,
      onHeaderCell: column => {
        return { width: 100 };
      },
    },
    {
      title: t('subItem'),
      dataIndex: 'subItemNumber',
      sorter: true,
      width: 100,
      onHeaderCell: column => {
        return { width: 80 };
      },
    },
    {
      title: t('subItemDesc'),
      dataIndex: 'subItemDescription',
      sorter: true,
      width: 170,
      onHeaderCell: column => {
        return { width: 140 };
      },
    },
    {
      title: t('subQnty'),
      dataIndex: 'subQuantity',
      // sorter: true,
      width: 110,
      onHeaderCell: column => {
        return { width: 75 };
      },
    },
    {
      title: t('subUnitPrice'),
      dataIndex: 'subItemUnitPrice',
      sorter: true,
      width: 130,
      render: value =>
        value === 0
          ? '$0.000'
          : value && `$${value.toLocaleString('zh', { minimumFractionDigits: 3 })}`,
      onHeaderCell: column => {
        return { width: 100 };
      },
    },
    {
      title: t('bosSub?'),
      dataIndex: 'bosSubRule',
      // sorter: true,
      width: 110,
      render: value => (value !== null ? String(value) : value),
      onHeaderCell: column => {
        return { width: 100 };
      },
    },
  ];
  if (title === 'Errored Lines') {
    columns.splice(5, 2, itemNoColumnObj, itemDescColumnObj, errorColumnObj);
  } else if (title === 'EXPD Details') {
    columns.splice(5, 2, itemNoColumnObj, itemDescColumnObj, ...expdColumnObj);
  } else if (title === 'Subs') {
    columns.splice(0, columns.length, ...subsExceptionsColumnObj);
  } else if (title === 'Locked/Errored') {
    columns.splice(5, 0, errorColumnObj);
  } else {
    columns.splice(5, 0, commonColumnObj);
  }

  if ((typeLabel && typeLabel.orderType !== undefined) || title === 'Locked/Errored') {
    columns.splice(7, 0, statusColumnObj);
  }

  const exportHealthCheckReports = () => {
    if (totals > 5001) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      const sheetColConfig = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 25 },
        { wch: 13 },
        { wch: 16 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ];
      const formdata = {
        ...detailsCondition,
        ...typeLabel,
      };
      const timing = Date.now();
      exportReportData(formdata).then(data => {
        const sheetObj = [
          {
            headers: columns,
            data,
            sheetName: null,
            sheetColConfig,
          },
        ];
        downloadFile(`${title} Report`, sheetObj);
        datadogRum.addAction(`Export ${title} report`, {
          headers: columns,
          data,
          sheetName: null,
          sheetColConfig,
        });
      });
      datadogRum.onReady(function() {
        datadogRum.addTiming(`Export ${title} report`, timing);
      });
    }
  };

  const handleOrderReportList = (pagination, sorting) => {
    const formdata = {
      ...detailsCondition,
      ...typeLabel,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      ...sorting,
    };
    const timing = Date.now();
    fetchOrderReportData(formdata);
    datadogRum.onReady(function() {
      datadogRum.addTiming(`${title}`, timing);
    });
  };

  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };

  const onPaginationChange = (page, changedPageSize) => {
    setShowAlert(false);
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
  };
  const [checkedRowList, setCheckedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedRowList(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
  };
  const handleEdit = record => {};
  const handleDelete = record => {};

  const actionColumn = {
    title: 'Actions',
    dataIndex: '',
    width: 60,
    blankWidth: 60,
    render: (value, record) => {
      return (
        <div className="flex parent-actions">
          <Button
            title="Edit"
            style={{ width: '24px' }}
            disabled
            type="link"
            icon={<EditCalendarIcon />}
            onClick={() => {
              handleEdit(record);
            }}
          >
            {/* <FontAwesomeIcon icon={faEdit} color="#A1A1A1" style={{ height: "15px", width: "15px" }} /> */}
          </Button>
          <Button
            title="Delete"
            style={{ paddingLeft: '5px', width: '42px' }}
            disabled
            type="link"
            icon={<CancelIcon />}
            onClick={() => {
              handleDelete(record);
            }}
          >
            {/* <FontAwesomeIcon icon={faTimesSquare} color="#A1A1A1" style={{ height: "15px", width: "15px" }} /> */}
          </Button>
        </div>
      );
    },
  };
  const handleOnRowClick = (value, label, record) => {
    setOrderDetailModalVisible(true);
    saveReportsKeyValue({...reportsKeyValue,orderNumber:value,site:record.site});
  };
  const renderTableLinkButton = (value, label, record, isNestColumn) => {
    return (
      <Button
        type="link"
        onClick={() => handleOnRowClick(value, label, record)}
        // className={(label === 'offDaySales' || ((label === 'offDayOrders' || label === 'largeHits' || label === 'underXOrY') && tabKey === 'SC in Action')) && value === 0?"ant-btn-link-disabled":""}
      >
        {value}
      </Button>
    );
  };
  const renderElementIndex = {
    toggleModal: renderTableLinkButton,
    // renderBadge: renderBadge,
  };
  let tableColumns = renderTableColumns(columns, null, renderElementIndex);
  // tableColumns.forEach(item => {
  //   item.title = t(item.title);
  // });
  // tableColumns.splice(columns.length, 0, actionColumn);
  const renderMobileValue = (value, dataIndex, record, colItem) => {
    if (colItem.toggleModal) 
    return renderTableLinkButton(value, dataIndex, record);
    if (dataIndex === 'totals') {
      if (value === null || value === 0) return '$0.00';
      return `$${value.toLocaleString('zh', { minimumFractionDigits: 2 })}`;
    } else if (dataIndex === 'deliveryDate') {
      return moment(value, DASH_DATE_FORMAT).format(SLASH_DATE_FORMAT);
    } else if (dataIndex === 'status' || dataIndex === 'exceptionStatus') {
      return <div className={statusColor}>{value}</div>;
    } else if (dataIndex === 'originalItemUnitPrice' || dataIndex === 'subItemUnitPrice') {
      if (value === 0) return '$0.000';
      return value && `$${value.toLocaleString('zh', { minimumFractionDigits: 3 })}`;
    } else {
      return value && `${value}`;
    }
  };
  return (
    <div>
      <Modal
        className="dashboard-order-report-modal"
        title={
          <>
            {`${t(modalkey)}`}
            <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
          </>
        }
        visible={isReportModalVisible}
        onCancel={toggleReportModal}
        width={1030}
        destroyOnClose={true}
        footer={null}
        afterClose={resetOrderReport}
        maskClosable={false}
      >
        {/* <div className="report-export-button-modal flex justify-end">
        <Button type="link" title="Export" style={{ marginLeft: '-20px' }} onClick={exportHealthCheckReports} disabled={_.isEmpty(orderReportData)}>
          <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(orderReportData)?"#a1a1a2":"#6b6b6c"} style={{ height: "20px", width: "20px" }} />
          <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px",  color: (_.isEmpty(orderReportData) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
        </Button>
      </div> */}
        <div className="report-export-button-modal flex justify-end">
          <ExportButton dataSource={orderReportData} exportReports={exportHealthCheckReports} />
        </div>
        <Table
          rowKey={record=>Math.random()}
          className="dashboard-order-report-table is-show-pc"
          loading={fetchOrderReportDataPending || exportReportDataPending}
          columns={tableColumns}
          dataSource={orderReportData}
          pagination={false}
          onChange={onTableChange}
          scroll={{ x: 'max-content', y: 250 }}
          // rowSelection={{
          //   type: 'checkbox',
          //   ...rowSelection,
          // }}
        />
        <div className="is-show-mobile">
          <div className="reports-mobile-list">
            {/* <input type="checkbox" className="sia-mobile-checkbox_all"></input> */}
            <Spin spinning={fetchOrderReportDataPending || exportReportDataPending }>
            <div className={!orderReportData ? "reports-no-data":"none"}>
              <Empty />
            </div>
            {orderReportData &&
              orderReportData.length > 0 &&
              orderReportData.map((record, index) => {
                return (
                  <div className="sia-mobile-box">
                    {/* <input type="checkbox" className="sia-mobile-checkbox"></input> */}
                    <div className="sia-mobile-editandDelete">
                      {/* <Button title="Edit" style={{ width: '24px' }} disabled type="link" icon={<EditCalendarIcon/>}
                  onClick={() => { handleEdit(record) }}>
                  </Button>
                  <Button title="Delete" style={{ paddingLeft: '5px', width: '42px' }} disabled type="link" icon={<CancelIcon/>} onClick={() => { handleDelete(record) }}>
                   
                  </Button> */}
                    </div>
                    <ul key={`${t(modalkey)}-${index}`}>
                      {columns.map((colItem, colIndex) => {
                        const titleOrder = colItem.title;
                        const dataIndex = colItem.dataIndex;
                        const value = record[dataIndex];
                        return (
                          <li key={`${titleOrder}-${dataIndex}-${colIndex}`}>
                            <span className="sub01">{titleOrder}</span>
                            <span className="sub02">
                              {renderMobileValue(value, dataIndex, record, colItem)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </Spin>
          </div>
        </div>
        <div className="flex justify-center">
          <Pagination
            style={{ textAlign: 'left', margin: '16px 0px' }}
            className={'report-pagination'}
            showSizeChanger
            current={pagination.pageIndex}
            pageSize={pagination.pageSize}
            total={pagination.total}
            pageSizeOptions={pagination.pageSizeOptions}
            onShowSizeChange={onPaginationChange}
            onChange={onPaginationChange}
          />
          {/* <div className="report-footer flex justify-end">
          <Button onClick={exportHealthCheckReports}>Export</Button>
          <Button onClick={toggleReportModal}>Close</Button>
        </div> */}
        </div>
      </Modal>
      <OrderDetailReportModal 
        isOrderDetailModalVisible={isOrderDetailModalVisible} 
        setOrderDetailModalVisible={setOrderDetailModalVisible}
        title={`${t(modalkey)} - ${reportsKeyValue.orderNumber}`}
      />
    </div>
  );
}

OrderReportModal.propTypes = {};
OrderReportModal.defaultProps = {};
