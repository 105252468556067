import React from 'react';
import { Tabs } from 'antd';
import { ReportTable } from './';
import { CONST_REPORT_TABS_COLUMNS } from '../../common/reportsConstants';


export default function ReportTabs() {
  
  const renderTabPaneItems = () => {
    return CONST_REPORT_TABS_COLUMNS.map((item, index) => {
      return (
        <Tabs.TabPane tab={item.key} key={item.key}>
          <ReportTable
            tabKey={item.key}
            columnsProp={item.columns}
            customerColumnsProp={item.customerColumns}
            orderColumnsProp={item.orderColumns}
            accountColumnsProp={item.accountColumns}
          />
        </Tabs.TabPane>
      );
    });
  };
  return (
    <div className="reports-report-tabs">
      <Tabs defaultActiveKey="1"  type="card" destroyInactiveTabPane>
        {renderTabPaneItems()}
      </Tabs>
    </div>
  );
}

ReportTabs.propTypes = {};
ReportTabs.defaultProps = {};
