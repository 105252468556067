import axios from 'axios'
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_BEGIN,
  MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
  MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_FAILURE,
  MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import { COMMON_DATE_FORMAT } from '../../../common/constants';
import moment from 'moment';
import { handleAsohAndPrice } from '../formattedSubsData';

export function fetchRecommendedProducts(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const { originalItem, isFromOuts, totalOuts } = args
      const splitFlag = args.originalItemUom == "EA" ? true : false
      const siteId = args.site[0]
      delete args["isFromOuts"]
      delete args["totalOuts"]
      let conditions = args;
      const doRequest = axios.post(`${API_REQUEST_URL}/searchForSub`, conditions, {
        headers: {
          'Authorization': token,
        }
      });
      
      doRequest.then(
        (res) => {
          let asohParams = {
            "businessUnitNumber": siteId,
            "products": [
              {
                splitFlag,
                "supc": originalItem,
              }
            ]
          }
          
          let secondAsohParams={
            "businessUnitNumber": siteId,
            "products": [
              {
                "splitFlag":!splitFlag,
                "supc": originalItem,
              }
            ]
          }
          
          let priceParams = {
            "businessUnitNumber": siteId,
            "customerAccount": args.customerNumber ? args.customerNumber : args.nationalId,
            "priceRequestDate": moment().format(COMMON_DATE_FORMAT),
            "priceType": "N",
            "products": [
              {
                splitFlag,
                "supc": originalItem,
              }
            ]
          }
          let subFrequencyParams = {
            "origItemNumber": originalItem,
            "site": siteId,
            "subItemNumbers": []
          }
          let recommendedProducts = res.data
         
          if(recommendedProducts.success) {
            recommendedProducts.data.forEach(subItem => {
              if(originalItem != subItem.supc) {
                asohParams.products.push({
                  splitFlag,
                  "supc": subItem.supc
                })
                secondAsohParams.products.push({
                  "splitFlag": !splitFlag,
                  "supc":subItem.supc
                })
                priceParams.products.push({
                  splitFlag,
                  "supc": subItem.supc
                })
              }
              subFrequencyParams.subItemNumbers.push(subItem.supc)
            })
  
            const asohRequest = axios.post(`${API_REQUEST_URL}/availStockFromAOSH`, asohParams, {
              headers: {
                'Authorization': token,
              }
            });
            const secondAsohRequest = axios.post(`${API_REQUEST_URL}/availStockFromAOSH`, secondAsohParams, {
              headers: {
                'Authorization': token,
              }
            });
            const priceRequest = axios.post(`${API_REQUEST_URL}/fetchPriceForItem`, priceParams, {
              headers: {
                'Authorization': token,
              }
            });
            const subFrequencyRequest = axios.post(`${API_REQUEST_URL}/getSubFrequency`, subFrequencyParams, {
              headers: {
                'Authorization': token,
              }
            });
            Promise.allSettled([asohRequest, priceRequest, subFrequencyRequest,secondAsohRequest,]).then(
              (results) => {
                const isAsohSuccess = results[0].status === "fulfilled" && Array.isArray(results[0].value.data)
                const isSecondAsohSuccess = results[3].status === "fulfilled" && Array.isArray(results[3].value.data)
                const isPriceSuccess = results[1].status === "fulfilled" && Array.isArray(results[1].value.data)
                const isSubFrequencySuccess = results[2].status === "fulfilled"
                const asohObg = {}
                const secondAsohObg={}
                const priceObg = {}
                const subFrequencyObg = {}
                if(isSubFrequencySuccess) {
                  const subFrequencyrr = results[2].value.data.subFrequencies
                  if(subFrequencyrr) {
                    subFrequencyrr.forEach(item => {
                      subFrequencyObg[item.origItemNumber] = item
                    })
                  }
                }
                if(isAsohSuccess) {
                  const asohArr = results[0].value.data
                  
                  asohArr.forEach(item => {
                    asohObg[item.supc] = item
                  })
                  const originalAsoh = asohObg[originalItem]
                  if(originalAsoh) {
                    const { availableOnHand } = originalAsoh
                    if(availableOnHand !== null && availableOnHand !== undefined && availableOnHand !== "") {
                      recommendedProducts.originalAsoh = availableOnHand
                    } else {
                      recommendedProducts.originalAsoh = "---"
                    }
                  } else {
                    recommendedProducts.originalAsoh = "---"
                  }
                } else {
                  recommendedProducts.originalAsoh = "---"
                }

                if(isSecondAsohSuccess) {
                  const asohArr = results[3].value.data
                  
                  asohArr.forEach(item => {
                    secondAsohObg[item.supc] = item
                  })
                }
  
                if(isPriceSuccess) {
                  const priceArr = results[1].value.data
                  priceArr.forEach(item => {
                    priceObg[item.supc] = item
                  })
                  const originalPrice = priceObg[originalItem]
                  if(originalPrice) {
                    const { unitPrice } = originalPrice
                    if(unitPrice !== null && unitPrice !== undefined && unitPrice !== "") {
                      recommendedProducts.originalPrice = unitPrice
                    } else {
                      recommendedProducts.originalPrice = "---"
                    }
                  } else {
                    recommendedProducts.originalPrice = "---"
                  }
                } else {
                  recommendedProducts.originalPrice = "---"
                }
                handleAsohAndPrice(recommendedProducts, isFromOuts, isAsohSuccess, asohObg, isPriceSuccess, priceObg, isSubFrequencySuccess, subFrequencyObg, totalOuts,isSecondAsohSuccess,secondAsohObg)
                dispatch({
                  type: MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
                  data: recommendedProducts,
                });
                resolve(recommendedProducts);
              }
            )
          } else {
            const errMsg = 'There is an error. Please refresh page or contact administrator.'
            dispatch({
              type: MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_FAILURE,
              data: { error: errMsg },
            });
            reject(errMsg);
          }
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchRecommendedProductsError() {
  return {
    type: MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_DISMISS_ERROR,
  };
}

export function useFetchRecommendedProducts() {
  const dispatch = useDispatch();

  const { fetchRecommendedProductsPending, fetchRecommendedProductsError } = useSelector(
    state => ({
      fetchRecommendedProductsPending: state.manageSubs.fetchRecommendedProductsPending,
      fetchRecommendedProductsError: state.manageSubs.fetchRecommendedProductsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchRecommendedProducts(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchRecommendedProductsError());
  }, [dispatch]);

  return {
    fetchRecommendedProducts: boundAction,
    fetchRecommendedProductsPending,
    fetchRecommendedProductsError,
    dismissFetchRecommendedProductsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchRecommendedProductsPending: true,
        fetchRecommendedProductsError: null,
      };

    case MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_SUCCESS:
      // The request is success
      return {
        ...state,
        recommendedProducts: action.data,
        fetchRecommendedProductsPending: false,
        fetchRecommendedProductsError: null,
      };

    case MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchRecommendedProductsPending: false,
        fetchRecommendedProductsError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchRecommendedProductsError: null,
      };

    default:
      return state;
  }
}
