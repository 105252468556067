import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  REPORTS_RESET_REPORTS_DATA_ORDERS,
} from './constants';

export function resetReportsDataOrders() {
  return {
    type: REPORTS_RESET_REPORTS_DATA_ORDERS,
  };
}

export function useResetReportsDataOrders() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(resetReportsDataOrders(...params)), [dispatch]);
  return { resetReportsDataOrders: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_RESET_REPORTS_DATA_ORDERS:
      return {
        ...state,
        reportsDataOrders: null,
        reportsTotalsOrders: 0,
        summaryOrders:null,
      };

    default:
      return state;
  }
}
