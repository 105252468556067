import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Table, Pagination, Button, Modal } from 'antd';
import { ExportAlertModal } from '../common';
import { CONST_PAGINATION, DEFAULT_REPORT_MODAL_KEY_VALUE, SLASH_DATE_FORMAT } from '../../common/constants';
import { downloadFile } from '../../common/downloadReportFunc';
import { useFetchScInActionCustomerData, useFetchScInActionOrderData, 
  useExportScInActionCustomerReport, useExportScInActionOrderReport,
  useFetchSummaryCustomerData, useFetchSummaryOrderData,
  useExportSummaryCustomerReport, useExportSummaryOrderReport,
  useResetReportsCustomerLevelData, useResetReportsOrderLevelData, } from './redux/hooks';
import { useFetchBusinessEventLogData, useFetchOrderTrailData, 
 useResetOrderTrailData, useResetBusinessEventLogData } from '../order-trail/redux/hooks';
import { convertToFooterColumns, formatFormdata } from './formatFormdata';
import TableSummary from './TableSummary';
import TableFooter from './TableFooter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

export default function DetailsReportModal(props) {
  const {
    reportsCustomerData,
    reportsOrderData,
    fetchScInActionCustomerDataPending,
    fetchScInActionOrderDataPending,
    reportsCustomerTotals,
    reportsOrderTotals,
    fetchSummaryCustomerDataPending,
    fetchSummaryOrderDataPending,
    dashboardConditionData,
    territoryData,
    businessEventLogData,
    fetchBusinessEventLogDataPending,
    fetchOrderTrailDataPending,
    customerSummary,
    orderSummary,
    exportSummaryCustomerReportPending,
    exportSummaryOrderReportPending,
    exportScInActionCustomerReportPending,
    exportScInActionOrderReportPending,
  } = useSelector(state => ({
    reportsCustomerData: state.reports.reportsCustomerData,
    reportsOrderData: state.reports.reportsOrderData,
    fetchScInActionCustomerDataPending: state.reports.fetchScInActionCustomerDataPending,
    fetchScInActionOrderDataPending: state.reports.fetchScInActionOrderDataPending,
    reportsCustomerTotals: state.reports.reportsCustomerTotals,
    reportsOrderTotals: state.reports.reportsOrderTotals,
    fetchSummaryCustomerDataPending: state.reports.fetchSummaryCustomerDataPending,
    fetchSummaryOrderDataPending: state.reports.fetchSummaryOrderDataPending,
    dashboardConditionData: state.dashboard.dashboardConditionData,
    territoryData: state.dashboard.territoryData,
    businessEventLogData: state.orderTrail.businessEventLogData,
    fetchBusinessEventLogDataPending: state.orderTrail.fetchBusinessEventLogDataPending,
    fetchOrderTrailDataPending: state.orderTrail.fetchOrderTrailDataPending,
    customerSummary: state.reports.customerSummary,
    orderSummary: state.reports.orderSummary,
    exportSummaryCustomerReportPending: state.reports.exportSummaryCustomerReportPending,
    exportSummaryOrderReportPending: state.reports.exportSummaryOrderReportPending,
    exportScInActionCustomerReportPending: state.reports.exportScInActionCustomerReportPending,
    exportScInActionOrderReportPending: state.reports.exportScInActionOrderReportPending,
  }));
  const { columns, tabKey, keyValue, isVisible, onToggleModal, onChangeReportModalKey, fromCustomer } = props;
  const [pagination, setPagination] = useState(CONST_PAGINATION);
  const [sorting, setSorting] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const { resetReportsCustomerLevelData } = useResetReportsCustomerLevelData();
  const { resetReportsOrderLevelData } = useResetReportsOrderLevelData();
  const { fetchScInActionCustomerData } = useFetchScInActionCustomerData();
  const { fetchScInActionOrderData } = useFetchScInActionOrderData();
  const { exportScInActionCustomerReport } = useExportScInActionCustomerReport();
  const { exportScInActionOrderReport } = useExportScInActionOrderReport();
  const { fetchSummaryCustomerData } = useFetchSummaryCustomerData();
  const { fetchSummaryOrderData } = useFetchSummaryOrderData();
  const { exportSummaryCustomerReport } = useExportSummaryCustomerReport();
  const { exportSummaryOrderReport } = useExportSummaryOrderReport();
  const { fetchOrderTrailData } = useFetchOrderTrailData();
  const { fetchBusinessEventLogData } = useFetchBusinessEventLogData();
  const { resetOrderTrailData } = useResetOrderTrailData();
  const { resetBusinessEventLogData } = useResetBusinessEventLogData();
  let condition = formatFormdata(dashboardConditionData, territoryData);
  condition = {
    ...condition,
    customerNumber: keyValue.customerNumber || '',
    scId: (tabKey === 'SC in Action' && keyValue.scId) || '',
    accountType: (tabKey === 'Summary' && keyValue.accountType) || '',
    offDay: tabKey === 'Summary' && (keyValue.offDaySales || parseInt(keyValue.offDaySales) === 0 )? 'OFFDAY' : '',  //OFFDAY is hardcord flag for offday sales hyperlink
  };
  let title = '';
  if(tabKey === 'Orders' || tabKey === 'Future Orders') {
    title = `Track My ${tabKey} Details`;
  } else if(keyValue.customerNumber) {
    title = `${tabKey} for Customer - ${keyValue.customerNumber}`;
  } else if(tabKey === 'SC in Action' && keyValue.scId) {
    title = `${tabKey} for Territory - ${keyValue.scId}`;
  } else if(tabKey === 'Summary' && keyValue.offDaySales) {
    title = `${tabKey} for Offday`;
  } else if(tabKey === 'Summary' && keyValue.accountType) {
    title = `${tabKey} for AccountType - ${keyValue.accountType}`;
  }

  const dataSource = fromCustomer ? reportsCustomerData : reportsOrderData;
  const dataTotals = fromCustomer ? reportsCustomerTotals : reportsOrderTotals;
  const summaryData = fromCustomer ? customerSummary : orderSummary;
  const fetchOrderDetailsData = () => {
    const orderTrailCond = {
      opco: keyValue.opco,
      orderNo: keyValue.orderNo,
      customerNo: '',
      uomOrderNo: '',
      fromDate: moment(condition.deliveryDate).format(SLASH_DATE_FORMAT),
      toDate: moment(condition.deliveryDate).format(SLASH_DATE_FORMAT),
    };
    fetchOrderTrailData(orderTrailCond).then(order => {
      if(order) {
        const { opco, orderNo, orderDate, uomOrderNo } = order[0];
        const logCond = {
          opco: opco,
          orderNo: orderNo,
          customerNo: keyValue.customerNumber,
          uomOrderNo: uomOrderNo ? uomOrderNo : '',
          orderDate: orderDate,
        };
        fetchBusinessEventLogData(logCond);
      }
    });
  }
  const reportActionIndex = {
    Summary: fromCustomer ? data => fetchSummaryCustomerData(data) : data => fetchSummaryOrderData(data),
    'SC in Action': fromCustomer ? data => fetchScInActionCustomerData(data) : data => fetchScInActionOrderData(data),
    // 'Orders': () => fetchOrderDetailsData(),
    // 'Future Orders': () => fetchOrderDetailsData(),
  };
  const exportReportActionIndex = {
    Summary: fromCustomer ? data => exportSummaryCustomerReport(data) : data => exportSummaryOrderReport(data),
    'SC in Action': fromCustomer ? data => exportScInActionCustomerReport(data) : data => exportScInActionOrderReport(data),
  };
  
  useEffect(() => {
    if (!isVisible) {
      //reset state when close report modal
      setPagination(CONST_PAGINATION);
      setSorting();
      setShowAlert(false);
    } else {
      let formdata = {
        ...condition,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...sorting,
      }
      //fetch customer level report data, and update state
      reportActionIndex[tabKey](formdata);
    }
  }, [isVisible, pagination, sorting]); // eslint-disable-line
  const exportReports = () => {
    if (dataTotals > 5001) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      let sheetColConfig = [];
      columns.forEach(col => {
        let sheetColWidth = 10;
        if(col.width) {
          sheetColWidth = col.width/10;
        }
        sheetColConfig.push({ wch: sheetColWidth });
      });
      if(tabKey === 'Orders' || tabKey === 'Future Orders') {
        const sheetObj = [{
          headers: columns,
          data: businessEventLogData,
          sheetName: null,
          sheetColConfig,
        }];
        downloadFile(`${title} Report`, sheetObj);
      } else {
        exportReportActionIndex[tabKey](condition).then(data => {
          const sheetObj = [{
            headers: columns,
            data,
            sheetName: null,
            sheetColConfig,
          }];
          downloadFile(`${title} Report`, sheetObj);
        });
      }
    }
  };

  const onTableChange = (page, filters, sorter) => {
    setShowAlert(false);
    const sort =
      Object.keys(sorter).length > 0 && sorter.order !== undefined
        ? {
            sortedBy: sorter.field,
            sort: sorter.order,
          }
        : sorting;
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setSorting(sort);
  };

  const onPaginationChange = (page, changedPageSize) => {
    setShowAlert(false);
    let currPageIndex = page;
    if (pagination.pageSize !== changedPageSize) {
      currPageIndex = 1;
    }
    setPagination({
      ...pagination,
      pageIndex: currPageIndex,
      pageSize: changedPageSize,
    });
  };

  const handleAfterClose = () => {
    if(tabKey === 'Orders' || tabKey === 'Future Orders') {
      resetOrderTrailData();
      resetBusinessEventLogData();
      onChangeReportModalKey(DEFAULT_REPORT_MODAL_KEY_VALUE);
    } else if(fromCustomer) {
      resetReportsCustomerLevelData();
      onChangeReportModalKey(DEFAULT_REPORT_MODAL_KEY_VALUE);
    } else {
      resetReportsOrderLevelData();
      onChangeReportModalKey({
        ...keyValue,
        customerNumber: null,
      });
    }
  }
  // const renderTableSummary = () => {
  //   if (summaryData) {
  //     return (
  //       <TableSummary tabKey={tabKey} summary={summaryData} colums={columns}/>
  //     )
  //   }
  // }
  useEffect(() => {
    if (summaryData) {
      const parentScroll = document.querySelector('.ant-modal-body .report-table .ant-table-body');
      const footerScroll = document.querySelector('.ant-modal-body .ant-table-footer .ant-table-body');
      if (parentScroll && footerScroll) {
        const scale = (parentScroll.scrollWidth - parentScroll.clientWidth) / (footerScroll.scrollWidth - footerScroll.clientWidth);
        let flag = 0;

        parentScroll.addEventListener('scroll', () => {
          if (flag !== 1) return;
          footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
        });
        footerScroll.addEventListener('scroll', () => {
          if (flag !== 2) return;
          parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
        });
        parentScroll.addEventListener('mouseover', () => {
          flag = 1;
        }, true);
        footerScroll.addEventListener('mouseover', () => {
          flag = 2;
        }, true);
        return () => {
          parentScroll.removeEventListener('scroll', () => {
            if (flag !== 1) return;
            footerScroll.scrollLeft = parentScroll.scrollLeft / scale;
          });
          footerScroll.removeEventListener('scroll', () => {
            if (flag !== 2) return;
            parentScroll.scrollLeft = footerScroll.scrollLeft * scale;
          });
          parentScroll.removeEventListener('mouseover', () => {
            flag = 1;
          }, true);
          footerScroll.removeEventListener('mouseover', () => {
            flag = 2;
          }, true);
        }
      }
    }
  }, [summaryData]);
    //insert all index into summary data
    let footerDetailData = JSON.parse(JSON.stringify(summaryData));
    if(footerDetailData){
      footerDetailData.totalIndex = true;
    }
    let footerDetailTableColumns = convertToFooterColumns(columns);
    const renderFooter = ()=>{
      if (summaryData && footerDetailData && !_.isEmpty(dataSource)) {
        return (
          <TableFooter tabKey={tabKey} summary={footerDetailData} columns={footerDetailTableColumns} isFromModal={true}/>
        )
      }
    }
  if(tabKey === 'Orders' || tabKey === 'Future Orders') {
    return (
      <Modal
        className="reports-details-report-modal"
        title={
          <>
            <div>{title}</div>
            <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
          </>
        }
        visible={isVisible}
        onCancel={onToggleModal}
        width={fromCustomer ? 1100 : 1000}
        destroyOnClose={true}
        footer={null}
        afterClose={handleAfterClose}
        maskClosable={false}
      >
        <div className="report-export-button-modal flex justify-end">
            {/* <Button onClick={exportReports}>Export</Button> */}
            {/* <Button onClick={onToggleModal}>Close</Button> */}
          <Button Button type="link" title="Export"  style={{ marginLeft: '-20px' }} onClick={exportReports}>
            <FontAwesomeIcon icon={faFileExport} color={"#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: "#6b6b6c" }}>Export</p>
          </Button>
        </div>
        <Table
          rowKey={record => `${record.eventId}-${record.event}`}
          className="report-table"
          loading={fetchOrderTrailDataPending || fetchBusinessEventLogDataPending}
          columns={columns}
          dataSource={businessEventLogData}
          pagination={false}
          scroll={{ x: 'max-content', y: 250 }}
        />
          {/* <div className="report-footer flex justify-end">
            <Button onClick={exportReports}>Export</Button>
            <Button onClick={onToggleModal}>Close</Button>
          </div> */}
      </Modal>
    );
  }
  
  return (
    <Modal
      className="reports-details-report-modal"
      title={
        <>
          <div>{title}</div>
          <ExportAlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
        </>
      }
      visible={isVisible}
      onCancel={onToggleModal}
      width={fromCustomer ? 1100 : 1000}
      destroyOnClose={true}
      footer={null}
      afterClose={handleAfterClose}
      maskClosable={false}
    >
      <div className="report-export-button-modal flex justify-end">
          {/* <Button onClick={exportReports}>Export</Button>
           */}
           <Button type="link" title="Export"  style={{ marginLeft: '-20px' }} onClick={exportReports} disabled={_.isEmpty(dataSource)}>
            <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(dataSource)?"#a1a1a2":"#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: (_.isEmpty(dataSource) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
          </Button>
      </div>
      <Table
        rowKey={record => `${record.customerNumber}-${record.orderNumber}`}
        className="report-table"
        loading={fetchScInActionCustomerDataPending || fetchScInActionOrderDataPending || fetchSummaryCustomerDataPending || fetchSummaryOrderDataPending 
          || exportSummaryCustomerReportPending || exportSummaryOrderReportPending || exportScInActionCustomerReportPending || exportScInActionOrderReportPending}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={onTableChange}
        scroll={{ x: 'max-content', y: 250 }}
        // summary={dataSource && renderTableSummary}
        footer={renderFooter}
      />
      <div className="align-items-center justify-space-between">
        <Pagination
          style={{ textAlign: 'right', margin: '16px 0px' }}
          className={'report-pagination'}
          showSizeChanger
          current={pagination.pageIndex}
          pageSize={pagination.pageSize}
          total={fromCustomer ? reportsCustomerTotals : reportsOrderTotals}
          pageSizeOptions={pagination.pageSizeOptions}
          onShowSizeChange={onPaginationChange}
          onChange={onPaginationChange}
        />
        {/* <div className="report-footer flex justify-end">
          <Button onClick={exportReports}>Export</Button>
          <Button onClick={onToggleModal}>Close</Button>
        </div> */}
      </div>
    </Modal>
  );
}

DetailsReportModal.propTypes = {};
DetailsReportModal.defaultProps = {};
