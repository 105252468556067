import React from 'react';
import { useLocation } from 'react-router-dom';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants';
import { CommonPage } from '../reports-new';
export default function OrdersDetail() {
  const location = useLocation();
  let tabKey = '';
  const strCurrPath = location.pathname.replace('/', '').split('/');
  if(strCurrPath[0] === 'orders'){
    tabKey = 'Orders';
  }else{
    tabKey = 'Future Orders';
  }
  const columnsProp = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].orderDetail.columns;
  const level = CONST_REPORT_TABS_NEW_COLUMNS.filter(item => item.key === tabKey)[0].orderDetail.level;
  return (
    <div className="orders-orders-detail">
       <CommonPage tabKey={tabKey} columnsProp={columnsProp} level={level} />
    </div>
  );
};

OrdersDetail.propTypes = {};
OrdersDetail.defaultProps = {};
