import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  DASHBOARD_SAVE_DROP_DOWN_LIST,
} from './constants';

export function saveDropDownList(data) {
  return {
    type: DASHBOARD_SAVE_DROP_DOWN_LIST,
    data,
  };
}

export function useSaveDropDownList() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(saveDropDownList(...params)), [dispatch]);
  return { saveDropDownList: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_SAVE_DROP_DOWN_LIST:
      return {
        ...state,
        dropDownList: action.data,
      };

    default:
      return state;
  }
}
