import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_BEGIN,
  DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_SUCCESS,
  DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_FAILURE,
  DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_DISMISS_ERROR,
  ERROR_CONTACT_ADMIN,
} from './constants';

export function fetchNationalAccountData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/fetchNationalAccountData`, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : ERROR_CONTACT_ADMIN;
          dispatch({
            type: DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchNationalAccountDataError() {
  return {
    type: DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_DISMISS_ERROR,
  };
}

export function useFetchNationalAccountData() {
  const dispatch = useDispatch();

  const { nationalData, fetchNationalAccountDataPending, fetchNationalAccountDataError } = useSelector(
    state => ({
      nationalData: state.dashboard.nationalData,
      fetchNationalAccountDataPending: state.dashboard.fetchNationalAccountDataPending,
      fetchNationalAccountDataError: state.dashboard.fetchNationalAccountDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchNationalAccountData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchNationalAccountDataError());
  }, [dispatch]);

  return {
    nationalData,
    fetchNationalAccountData: boundAction,
    fetchNationalAccountDataPending,
    fetchNationalAccountDataError,
    dismissFetchNationalAccountDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchNationalAccountDataPending: true,
        fetchNationalAccountDataError: null,
      };

    case DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        nationalData: action.data,
        fetchNationalAccountDataPending: false,
        fetchNationalAccountDataError: null,
      };

    case DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchNationalAccountDataPending: false,
        fetchNationalAccountDataError: action.data.error,
      };

    case DASHBOARD_FETCH_NATIONAL_ACCOUNT_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchNationalAccountDataError: null,
      };

    default:
      return state;
  }
}
