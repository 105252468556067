// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as fetchOrgInfoReducer } from './fetchOrgInfo';
import { reducer as fetchTerritoryDataReducer } from './fetchTerritoryData';
import { reducer as fetchSummaryDataReducer } from './fetchSummaryData';
import { reducer as fetchNationalAccountDataReducer } from './fetchNationalAccountData';
import { reducer as fetchConceptIdsDataReducer } from './fetchConceptIdsData';
import { reducer as resetConceptIdsDataReducer } from './resetConceptIdsData';
import { reducer as resetOrderReportReducer } from './resetOrderReport';
import { reducer as fetchOrderReportDataReducer } from './fetchOrderReportData';
import { reducer as exportReportDataReducer } from './exportReportData';
import { reducer as resetSummaryDataReducer } from './resetSummaryData';
import { reducer as saveDashboardConditionDataReducer } from './saveDashboardConditionData';
import { reducer as resetDashboardConditionDataReducer } from './resetDashboardConditionData';
import { reducer as saveInitTerritoryFiltersDataReducer } from './saveInitTerritoryFiltersData';
import { reducer as saveNotificationStatusReducer } from './saveNotificationStatus';
import { reducer as fetchDefaultPasswordReducer } from './fetchDefaultPassword';
import { reducer as saveDropDownListReducer } from './saveDropDownList';

const reducers = [
  fetchOrgInfoReducer,
  fetchTerritoryDataReducer,
  fetchSummaryDataReducer,
  fetchNationalAccountDataReducer,
  fetchConceptIdsDataReducer,
  resetConceptIdsDataReducer,
  resetOrderReportReducer,
  fetchOrderReportDataReducer,
  exportReportDataReducer,
  resetSummaryDataReducer,
  saveDashboardConditionDataReducer,
  resetDashboardConditionDataReducer,
  saveInitTerritoryFiltersDataReducer,
  saveNotificationStatusReducer,
  fetchDefaultPasswordReducer,
  saveDropDownListReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  newState=state;
  return reducers.reduce((s, r) => r(s, action), newState);
}
