import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/apiConfig';
import {
  MANAGE_SUBS_FETCH_GROUP_IDS_DATA_BEGIN,
  MANAGE_SUBS_FETCH_GROUP_IDS_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_GROUP_IDS_DATA_FAILURE,
  MANAGE_SUBS_FETCH_GROUP_IDS_DATA_DISMISS_ERROR,
} from './constants';

export function fetchGroupIdsData(sites,searchVal,inputVal) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_GROUP_IDS_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/getGroupIds`,{sites,key:searchVal}, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          let ret = res.data;
          if(inputVal) {
            ret = ret.filter(item => item.groupId.toUpperCase().includes(inputVal.toUpperCase()));
          }
          ret = ret.map(item => item.groupId)
          dispatch({
            type: MANAGE_SUBS_FETCH_GROUP_IDS_DATA_SUCCESS,
            data: ret,
          });
          resolve(ret);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_FETCH_GROUP_IDS_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchGroupIdsDataError() {
  return {
    type: MANAGE_SUBS_FETCH_GROUP_IDS_DATA_DISMISS_ERROR,
  };
}

export function useFetchGroupIdsData() {
  const dispatch = useDispatch();

  const {groupIdsData,fetchGroupIdsDataPending, fetchGroupIdsDataError } = useSelector(
    state => ({
      groupIdsData: state.manageSubs.groupIdsData,
      fetchGroupIdsDataPending: state.manageSubs.fetchGroupIdsDataPending,
      fetchGroupIdsDataError: state.manageSubs.fetchGroupIdsDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchGroupIdsData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchGroupIdsDataError());
  }, [dispatch]);

  return {
    groupIdsData,
    fetchGroupIdsData: boundAction,
    fetchGroupIdsDataPending,
    fetchGroupIdsDataError,
    dismissFetchGroupIdsDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_GROUP_IDS_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchGroupIdsDataPending: true,
        fetchGroupIdsDataError: null,
      };

    case MANAGE_SUBS_FETCH_GROUP_IDS_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        groupIdsData: action.data,
        fetchGroupIdsDataPending: false,
        fetchGroupIdsDataError: null,
      };

    case MANAGE_SUBS_FETCH_GROUP_IDS_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchGroupIdsDataPending: false,
        fetchGroupIdsDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_GROUP_IDS_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchGroupIdsDataError: null,
      };

    default:
      return state;
  }
}
