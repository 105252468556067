import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  REPORTS_FETCH_OOS_REPORT_BY_SITE_BEGIN,
  REPORTS_FETCH_OOS_REPORT_BY_SITE_SUCCESS,
  REPORTS_FETCH_OOS_REPORT_BY_SITE_FAILURE,
  REPORTS_FETCH_OOS_REPORT_BY_SITE_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function fetchOosReportBySite(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: REPORTS_FETCH_OOS_REPORT_BY_SITE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      let conditions = args;
      if(args.sortedBy === undefined || args.sortedBy === null) {
        conditions = {
          ...conditions,
          sort: "ascend",
          sortedBy: "site",
        }
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/getOosReportBySite`, conditions,{
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: REPORTS_FETCH_OOS_REPORT_BY_SITE_SUCCESS,
            data: {
              data: res.data.data,
              totals: res.data.totals,
              summary: res.data && res.data.summary,
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: REPORTS_FETCH_OOS_REPORT_BY_SITE_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchOosReportBySiteError() {
  return {
    type: REPORTS_FETCH_OOS_REPORT_BY_SITE_DISMISS_ERROR,
  };
}

export function useFetchOosReportBySite() {
  const dispatch = useDispatch();

  const { reportsData, summary, reportsTotals,fetchOosReportBySitePending, fetchOosReportBySiteError } = useSelector(
    state => ({
      reportsData: state.reports.reportsData,
      summary: state.reports.summary,
      reportsTotals: state.reports.reportsTotals,
      fetchOosReportBySitePending: state.reports.fetchOosReportBySitePending,
      fetchOosReportBySiteError: state.reports.fetchOosReportBySiteError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchOosReportBySite(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchOosReportBySiteError());
  }, [dispatch]);

  return {
    reportsData, 
    summary, 
    reportsTotals,
    fetchOosReportBySite: boundAction,
    fetchOosReportBySitePending,
    fetchOosReportBySiteError,
    dismissFetchOosReportBySiteError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_FETCH_OOS_REPORT_BY_SITE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchOosReportBySitePending: true,
        fetchOosReportBySiteError: null,
      };

    case REPORTS_FETCH_OOS_REPORT_BY_SITE_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchOosReportBySitePending: false,
        fetchOosReportBySiteError: null,
        reportsData: action.data.data,
        reportsTotals: action.data.totals,
        summary: action.data.summary, 
      };

    case REPORTS_FETCH_OOS_REPORT_BY_SITE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchOosReportBySitePending: false,
        fetchOosReportBySiteError: action.data.error,
      };

    case REPORTS_FETCH_OOS_REPORT_BY_SITE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchOosReportBySiteError: null,
      };

    default:
      return state;
  }
}
