import React from 'react';
export default function TagCard(props) {
  const { title, className, count, onClick } = props;
  return (
    <div className={`dashboard-tag-card flex justify-space-between ${className}`} onClick={onClick}>
      <div className="card-title">{title}</div>
      <div className="card-count">{count}</div>
    </div>
  );
}

TagCard.propTypes = {};
TagCard.defaultProps = {};
