export const ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_BEGIN = 'ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_BEGIN';
export const ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_SUCCESS = 'ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_SUCCESS';
export const ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_FAILURE = 'ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_FAILURE';
export const ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_DISMISS_ERROR = 'ORDER_TRAIL_FETCH_ORDER_TRAIL_DATA_DISMISS_ERROR';
export const ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_BEGIN = 'ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_BEGIN';
export const ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_SUCCESS = 'ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_SUCCESS';
export const ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_FAILURE = 'ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_FAILURE';
export const ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_DISMISS_ERROR = 'ORDER_TRAIL_FETCH_BUSINESS_EVENT_LOG_DATA_DISMISS_ERROR';
export const ORDER_TRAIL_RESET_ORDER_TRAIL_DATA = 'ORDER_TRAIL_RESET_ORDER_TRAIL_DATA';
export const ORDER_TRAIL_RESET_BUSINESS_EVENT_LOG_DATA = 'ORDER_TRAIL_RESET_BUSINESS_EVENT_LOG_DATA';
export const ORDER_TRAIL_FETCH_SNAPSHOT_DATA_BEGIN = 'ORDER_TRAIL_FETCH_SNAPSHOT_DATA_BEGIN';
export const ORDER_TRAIL_FETCH_SNAPSHOT_DATA_SUCCESS = 'ORDER_TRAIL_FETCH_SNAPSHOT_DATA_SUCCESS';
export const ORDER_TRAIL_FETCH_SNAPSHOT_DATA_FAILURE = 'ORDER_TRAIL_FETCH_SNAPSHOT_DATA_FAILURE';
export const ORDER_TRAIL_FETCH_SNAPSHOT_DATA_DISMISS_ERROR = 'ORDER_TRAIL_FETCH_SNAPSHOT_DATA_DISMISS_ERROR';
export const ORDER_TRAIL_RESET_SNAPSHOT_DATA = 'ORDER_TRAIL_RESET_SNAPSHOT_DATA';
export const ORDER_TRAIL_ERROR_CONTACT_ADMIN =  'There is an error. Please refresh page or contact administrator.';
export const ERROR_SELECT_SITE =  'Please select Site!';
export const ERROR_FIELD_REQUIRED =  `Please select SUS Order or Shop Order or Customer Number!`;
export const ERROR_SUS_SEVEN =  'SUS order# must be less than or equal to 7 digits' ;
export const ERROR_SUS_TEN =  'Shop order# must be less than or equal to 10 digits' ;
