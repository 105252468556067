import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_SAVE_INIT_TERRITORY_FILTERS_DATA,
} from './constants';

export function saveInitTerritoryFiltersData(data) {
  return {
    type: DASHBOARD_SAVE_INIT_TERRITORY_FILTERS_DATA,
    data,
  };
}

export function useSaveInitTerritoryFiltersData() {
  const dispatch = useDispatch();
  const initTerritoryFiltersData = useSelector(state => state.dashboard.initTerritoryFiltersData);
  const boundAction = useCallback((...params) => dispatch(saveInitTerritoryFiltersData(...params)), [dispatch]);
  return { initTerritoryFiltersData, saveInitTerritoryFiltersData: boundAction };
}

export function reducer(state, action) {
  if(action.type===DASHBOARD_SAVE_INIT_TERRITORY_FILTERS_DATA){
    return {
      ...state,
      initTerritoryFiltersData: action.data,
    };
  }
  
      return state;
  
}
