// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  DailySalesSummary, SalesForAccount, SalesForOrder, ReportsLayoutContent, OrderDetail, DailySalesRegion, DailySalesAccountType
} from './';
export default {
  path: 'summary',
  component: ReportsLayoutContent,
  // childRoutes: [
  //   {
  //     path: '/',
  //     component: DailySalesSummary,
  //     isIndex: true,
  //   },
  //   {
  //     path: 'sales-for-account',
  //     component: SalesForAccount,
  //   },
  //   {
  //     path: 'sales-for-sc',
  //     component: SalesForOrder,
  //   },
  //   {
  //     path: 'order-detail',
  //     component: OrderDetailReport,
  //   }
  // ],
  childRoutes: [
    {
      path: '/',
      component: DailySalesSummary,
      isIndex: true,
    },
    {
      path: 'account-type',
      component: DailySalesAccountType,
    },
    {
      path: 'customer',
      component: SalesForAccount,
    },
    {
      path: 'order',
      component: SalesForOrder,
    },
    {
      path: 'order-detail',
      component: OrderDetail,
    }
  ],
};
