import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_BEGIN,
  MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_SUCCESS,
  MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_FAILURE,
  MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_DISMISS_ERROR,
} from './constants';
import { API_REQUEST_URL } from '../../../common/apiConfig';
export function updateCustomerGroup(args = {}) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/updateCustomerGroup`, args, {
        headers: {
          'Authorization': token,
        }
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateCustomerGroupError() {
  return {
    type: MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_DISMISS_ERROR,
  };
}

export function useUpdateCustomerGroup() {
  const dispatch = useDispatch();

  const { updateCustomerGroupPending, updateCustomerGroupError } = useSelector(
    state => ({
      updateCustomerGroupPending: state.manageSubs.updateCustomerGroupPending,
      updateCustomerGroupError: state.manageSubs.updateCustomerGroupError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateCustomerGroup(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateCustomerGroupError());
  }, [dispatch]);

  return {
    updateCustomerGroup: boundAction,
    updateCustomerGroupPending,
    updateCustomerGroupError,
    dismissUpdateCustomerGroupError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateCustomerGroupPending: true,
        updateCustomerGroupError: null,
      };

    case MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_SUCCESS:
      // The request is success
      return {
        ...state,
        updateCustomerGroupPending: false,
        updateCustomerGroupError: null,
      };

    case MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_FAILURE:
      // The request is failed
      return {
        ...state,
        updateCustomerGroupPending: false,
        updateCustomerGroupError: action.data.error,
      };

    case MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateCustomerGroupError: null,
      };

    default:
      return state;
  }
}
