import React,{ useEffect } from 'react';
import { useGetAuthrization } from './redux/hooks';
import { redirectUrl } from '../../common/apiConfig';
import { datadogRum } from '@datadog/browser-rum';
export default function App({ children }) {
  const { auth } = useGetAuthrization();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!auth.cognitoIdToken) {
        window.location.assign(redirectUrl);
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [auth]);
  if(auth && auth.user){
    datadogRum.setUser({
      ...auth.user
    })
  }
  return (
    <div className="home-app">
      <div className="page-container">{children}</div>
    </div>
  );
}
