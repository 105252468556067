import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchFiltersPanel from './SearchFiltersPanel';
import { CommonTable } from '../common';
import { CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS } from '../../common/manageAccountsAuditTrailConstants';
import { AUDIT_TRAIL_EXPORT_LIMIT, AUDIT_TRAIL_EXPORT_LIMIT_MSG, CONST_PAGINATION_TEN } from '../../common/constants';
import { useExportManageAccountAuditTrail, useFetchAccountsAuditTrailData,useResetAccountsAuditTrailData } from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';
import { Button, Spin, message } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
export default function ManageAccountsAuditTrail() {
  const {
    accountsAuditData,
    fetchAccountsAuditTrailDataPending,
    searchConditionData, 
    exportManageAccountAuditTrailPending,
  } = useSelector(state => ({
    accountsAuditData: state.manageSubs.accountsAuditData,
    fetchAccountsAuditTrailDataPending: state.manageSubs.fetchAccountsAuditTrailDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
    exportManageAccountAuditTrailPending: state.manageSubs.exportManageAccountAuditTrailPending
  }));
  const { fetchAccountsAuditTrailData }  = useFetchAccountsAuditTrailData();
  const { resetAccountsAuditTrailData } = useResetAccountsAuditTrailData();
  const { exportManageAccountAuditTrail } = useExportManageAccountAuditTrail();
  const renderColumnsElement = [
    {},//site placeholder
    { //render siteID
      extendRender: (value, label, record) => {
        if(record.customerNumber && record.customerNumber.length>0){
          return (
            <>
              <div className="col-title">{record.customerNumber}</div>
              <div className="col-desc">{record.customerName}</div>
            </>
          )
        }
        return (
          <>
            <div className="col-title">{value}</div>
            <div className="col-desc">{record.nationalName}</div>
          </>
        )
      }
    },
    {},//log placeholder
    {},//oldValue placeholder
    {},//newValue placeholder
    { //render modified log
      extendRender: (value, label, record) => {
        return value && (
          <>
            <div className="col-title">{record?.username}</div>
            <div className="col-desc">{`${record.userId} ${record.modifiedDate} ${record.modifiedTimestamp}`}</div>
          </>
        )
      },
    }
  ]
  let columns = renderTableColumns(CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS, null, renderColumnsElement);
  const onExportManageAcctAuditTrail = ()=>{
    if(accountsAuditData.totals > AUDIT_TRAIL_EXPORT_LIMIT) {
      message.error(AUDIT_TRAIL_EXPORT_LIMIT_MSG);
      return;
    }
    const params = {...searchConditionData}
    const context = datadogRum.getGlobalContext('BosView');
    const timing = Date.now();
    exportManageAccountAuditTrail(params); 
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Export ${context.BosView.screen} data`,timing);
    });
  }
  return (
    <div className="manage-subs-manage-accounts-audit-trail">
      <Spin spinning={exportManageAccountAuditTrailPending}>
        <div className="chart-title font-size-16-bold" style={{font: "Myriad Bold"}}>Search Accounts Audit Trail</div>
        <SearchFiltersPanel />
        <div className="flex align-item-center justify-end" style={{ marginBottom: "20px"}}>
          <Button type="link" title="Export" disabled={accountsAuditData === null} onClick={onExportManageAcctAuditTrail} style={{ marginLeft: '-20px' }}>
            <FontAwesomeIcon icon={faFileExport} color={_.isEmpty(searchConditionData) ? "#a1a1a2" : "#6b6b6c"} style={{ height: "20px", width: "20px" }} />
            <p style={{ marginTop: "-5px", fontSize: "11px", marginLeft: "-3px", color: (_.isEmpty(searchConditionData) ? "#a1a1a2" : "#6b6b6c") }}>Export</p>
          </Button>
        </div>
        <CommonTable 
          rowKey={Math.random()}
          columns={columns}
          tableClassName={accountsAuditData ? "outs-report-table" : ""}
          datasource={accountsAuditData}
          loadingData={fetchAccountsAuditTrailDataPending}
          paginationStyle={{textAlign: "right", margin: '16px 0px'}}
          triggerConditions={searchConditionData}
          onFecthData={fetchAccountsAuditTrailData}
          onResetStore={resetAccountsAuditTrailData}
          scrollStyle={{ x: 'max-content', y: 300 }}
          paginationSetting={CONST_PAGINATION_TEN}
        />
      </Spin>
    </div>
  );
};

ManageAccountsAuditTrail.propTypes = {};
ManageAccountsAuditTrail.defaultProps = {};
