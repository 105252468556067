import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ORDER_TRAIL_RESET_SNAPSHOT_DATA,
} from './constants';

export function resetSnapshotData() {
  return {
    type: ORDER_TRAIL_RESET_SNAPSHOT_DATA,
  };
}

export function useResetSnapshotData() {
  const dispatch = useDispatch();
  const snapshotData = useSelector(state => state.orderTrail.snapshotData);
  const boundAction = useCallback((...params) => dispatch(resetSnapshotData()), [dispatch]);
  return { snapshotData, resetSnapshotData: boundAction };
}

export function reducer(state, action) {

  if(action.type===ORDER_TRAIL_RESET_SNAPSHOT_DATA){
    return {
      ...state,
      snapshotData: {
        eventHeader: null, 
        eventDetailList: null,
      },
    };
  }
  
      return state;
  
}
